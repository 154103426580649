import { IonButton, IonItem, IonText } from "@ionic/react";
import React from "react";

import "./button.css";

export interface iButtonProps {
  text: string;
  textColor?: string;
  buttonColor?: string;
  fill?: "solid" | "outline" | "default";
  type?: "button" | "submit";
  link?: string;
  className?: string;
  onClick?(): void;
  disabled?: boolean;
}

const Button: React.FC<iButtonProps> = ({
  fill,
  text,
  textColor,
  link,
  type = "button",
  onClick,
  className,
  disabled = false,
}) => {
  return (
    <IonItem lines="none" className={`button-item ${className}`} color="none">
      <IonButton
        disabled={disabled}
        type={type}
        routerDirection="forward"
        routerLink={link}
        className="ion-activatable"
        color="primary"
        fill={fill}
        onClick={onClick}
      >
        <IonText color={textColor}>
          <p className="chivo">{text}</p>
        </IonText>
      </IonButton>
    </IonItem>
  );
};

export default Button;
