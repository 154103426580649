import axios from "axios";
import React, { useCallback } from "react";
import { ManagerReservationsDispatchContext } from "..";
import iReservation from "../../../../interfaces/models/reservation";
import useManagerAccountState from "../../AccountContext/hooks/useManagerAccountState";

const useManagerReservationsDispatch = () => {
  const dispatch = React.useContext(ManagerReservationsDispatchContext);
  const { currentHotel } = useManagerAccountState();

  if (!dispatch)
    throw Error(
      "useManagerReservationsDispatch must be used within ManagerReservationsStateContext"
    );

  const createReservation = (
    checkInDate: string,
    checkOutDate: string,
    room: string,
    numPeople: number,
    cb?: (
      error: boolean,
      message: string,
      reservation: iReservation | null
    ) => void
  ) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .post(`/reservations/`, {
          checkIn: checkInDate,
          checkOut: checkOutDate,
          roomNum: room,
          numPeople,
          hotelId: currentHotel._id,
        })
        .then((res) => {
          console.log(res);
          const {
            data: { reservation },
          } = res;
          dispatch({ type: "new reservation", reservation });
          cb && cb(false, reservation.code, reservation);
          return resolve(false);
        })
        .catch((err) => {
          console.log(err.message);
          cb && cb(true, err.response.data.message, null);
          let errorMessage;
          try {
            errorMessage = err.response.data.message;
          } catch (err) {
            errorMessage = "Something went wrong";
          }
          dispatch({ type: "rejected", error: errorMessage });
          return reject(errorMessage);
        });
    });
  };

  const sendReservationCode = (
    code: string,
    emails: string[]
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      axios
        .post("/managers/send-reservation-code", { code, emails })
        .then((res) => {
          return resolve("Emails sent!");
        })
        .catch((err) => {
          let error;
          if (err.response && err.response.data && err.response.data.message)
            error = err.response.data.message;
          else error = "Something went wrong";
          return reject(error);
        });
    });
  };

  const deleteReservation = (reservationId: string): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/reservations/reservation/${reservationId}`)
        .then(() => {
          dispatch({ type: "delete reservation", reservationId });

          return resolve(null);
        })
        .catch((err) => {
          try {
            reject(err.response.data.message);
          } catch (error) {
            return reject(error);
          }
        });
    });
  };

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch({ type: loading ? "pending" : "resolved" });
    },
    [dispatch]
  );

  const setError = useCallback(
    (error: string) => {
      dispatch({ type: "rejected", error });
    },
    [dispatch]
  );

  return {
    dispatch,
    createReservation,
    deleteReservation,
    sendReservationCode,
    setLoading,
    setError,
  };
};

export default useManagerReservationsDispatch;
