import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import axios from "axios";
import { has } from "lodash";
import React, { useMemo, useReducer } from "react";
import IonContentColor from "../../../../../components/IonContentColor";
import ModalWrapper from "../../../../../components/ModalWrapper";
import { DatePicker } from "../../../../../components/PickerRow";
import SelectionPicker, {
  iSelectionPickerOption,
} from "../../../../../components/PickerRow/SelectionPicker";
import { Chivo18 } from "../../../../../components/Text/Chivo";
import { Text12, Text18 } from "../../../../../components/Text/Montserrat";
import { TitledConfinedInput } from "../../../../../components/TitledComponent";
import TwoSlimButtons from "../../../../../components/TwoSlimButtons";
import useManagerAccountState from "../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerCompetitionsDispatch from "../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsDispatch";
import useManagerRewardsState from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsState";
import { modal_id_new_competition_desktop } from "../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";
import useIonAlertFunctions from "../../../../../hooks/ionAlert";
import useLoading from "../../../../../hooks/loading";
import iCompetition from "../../../../../interfaces/models/competitions";
import { getUnixMomentInMs } from "../../../../../utils/others";

import styles from "./styles.module.css";

interface iNewCompetitionModalProps {}

const NewCompetitionModal: React.FC<iNewCompetitionModalProps> = () => {
  return (
    <ModalWrapper
      modal_id={modal_id_new_competition_desktop}
      cssClass={styles.modal}
    >
      <Content />
    </ModalWrapper>
  );
};

interface iState {
  title: string;
  start_at: string;
  end_at: string;
  rooms: string[];
  rewards: string[];
}

interface iAction extends Partial<iState> {
  type: string;
  key?: string;
  value?: string | string[];
}

const initial_state: iState = {
  title: "",
  start_at: "",
  end_at: "",
  rooms: [],
  rewards: [],
};

const reducer = (state: iState, action: iAction) => {
  const { type, key, value } = action;
  switch (type) {
    case "change":
      return { ...state, [key!]: value! };

    case "reset":
      return { ...initial_state };

    default:
      return { ...state };
  }
};

interface iContentProps {}

const Content: React.FC<iContentProps> = () => {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { closeModal } = useOpenModal().getModalIdFunctions(
    modal_id_new_competition_desktop
  );

  const { activeRewards } = useManagerRewardsState();
  const { currentHotel, hotelRooms } = useManagerAccountState();
  const { addCompetition } = useManagerCompetitionsDispatch();

  const { present } = useIonAlertFunctions();
  const { getLoading, setLoading } = useLoading();

  const room_options: iSelectionPickerOption[] = useMemo(() => {
    return hotelRooms.map((room) => ({
      text: room,
      value: room,
    }));
  }, [hotelRooms]);

  const reward_options = useMemo(() => {
    return [...activeRewards].map((r) => ({
      text: r.title,
      value: r._id,
    }));
  }, [activeRewards]);

  const handleChange = (key: string, value: string) => {
    dispatch({ type: "change", key, value });
  };

  const handleAddRoom = (rooms: string[]) => {
    dispatch({ type: "change", key: "rooms", value: rooms });
  };

  const handleAddReward = (rewards: string[]) => {
    dispatch({
      type: "change",
      key: "rewards",
      value: rewards,
    });
  };

  const handleConfirm = () => {
    const state2competition = (): Omit<
      iCompetition,
      "_id" | "winner" | "rooms_info"
    > | null => {
      const { rewards, rooms, title, start_at, end_at } = state;

      if (
        !rewards.length ||
        !rooms.length ||
        !title.length ||
        !start_at.length ||
        !end_at.length
      )
        return null;

      if (getUnixMomentInMs(start_at) >= getUnixMomentInMs(end_at)) {
        present("Invalid dates");
        return null;
      }

      const competition: Omit<iCompetition, "_id" | "winner" | "rooms_info"> = {
        rewards,
        rooms,
        title,
        hotel: currentHotel._id,
        start_at: getUnixMomentInMs(start_at),
        end_at: getUnixMomentInMs(end_at),
      };

      return competition;
    };
    const handleDismissAlert = () => {
      dispatch({ type: "reset" });
      closeModal();
    };
    const competition = state2competition();

    if (!competition) return;

    setLoading(true);
    axios
      .post("/competitions/", {
        ...competition,
      })
      .then((res) => {
        console.log(`res`, res);
        const {
          data: { competition },
        } = res;
        addCompetition(competition);
        present("Competition created", handleDismissAlert);
      })
      .catch((err) => {
        present(
          has(err, "response.data.message")
            ? err.response.data.message
            : "Something went wrong"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    closeModal();
  };

  const { title, start_at, end_at, rooms, rewards } = state;

  return (
    <IonContentColor style={{ height: "100%" }} className="flexc-center">
      <IonLoading isOpen={getLoading()} />
      <IonGrid style={{ marginTop: "20px" }}>
        <TitledConfinedInput
          title="Competition Title"
          value={title}
          onChange={(value: string) => handleChange("title", value)}
          placeholder="New competitions (click to edit)"
          centeredTitle
        />
        <Title title="Competition Period" />
        <DatePicker
          id="1"
          title="Check-In Date"
          value={start_at}
          onSelect={(value: string) => handleChange("start_at", value)}
        />
        <DatePicker
          id="2"
          title="Check-Out Date"
          value={end_at}
          onSelect={(value: string) => handleChange("end_at", value)}
        />

        <Title title="Rooms in Competition" />
        <SelectionPicker
          title="List all selected rooms (click to select multiple rooms)"
          onChange={handleAddRoom}
          value={rooms}
          multiple
          options={room_options}
          style={{ margin: "0 20px" }}
        />

        <Title title="Rewards" />
        {reward_options.length ? (
          <SelectionPicker
            {...{
              options: reward_options,
              value: rewards,
              onChange: handleAddReward,
              title: "Winner Rewards",
              multiple: true,
            }}
            style={{ margin: "0 20px" }}
          />
        ) : (
          <IonRow className={styles.margin}>
            <IonCol>
              <Text18 color="danger" text="No rewards available. Create one" />
            </IonCol>
          </IonRow>
        )}
        <Text12
          mh={3}
          text="Select a reward from your Reward pool. Only the winner of the competition will receive it. The winner is the room with the most points from sustainable evaluation"
        />

        <TwoSlimButtons
          style={{ marginTop: "20px" }}
          green_text="Confirm"
          red_text="Cancel"
          onGreen={handleConfirm}
          onRed={handleCancel}
          disable_buttons={getLoading()}
        />
      </IonGrid>
    </IonContentColor>
  );
};

interface iTitleProps {
  title: string;
}

const Title: React.FC<iTitleProps> = ({ title }) => {
  return (
    <IonRow>
      <IonCol>
        <Chivo18 text={title} centeredText />
      </IonCol>
    </IonRow>
  );
};

export default NewCompetitionModal;
