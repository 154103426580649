import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonLoading,
  IonRow,
  useIonAlert,
} from "@ionic/react";
import axios from "axios";
import React, { Fragment, useReducer } from "react";
import DedsktopPageColSeparator from "../../../../../components/DesktopPageColSeparator";
import IonContentColor from "../../../../../components/IonContentColor";
import RewardCard from "../../../../../components/RewardCard";
import RewardSectorCard from "../../../../../components/RewardSectorCard";
import TwoSegmentedFiltersWithMiddleButton from "../../../../../components/SegmentedFilterWithMiddleButton";
import {
  Chivo12,
  Chivo20,
  Chivo24,
} from "../../../../../components/Text/Chivo";
import { Text16 } from "../../../../../components/Text/Montserrat";
import { iReward } from "../../../../../interfaces/models/reward";
import { estimatedValue2points } from "../../../../../utils/user";
import NewEditRewardAndPreview from "../NewEditRewardAndPreview";

import edit from "../../../../../img/edit_text-icon.svg";

import styles from "./styles.module.css";
import SlimButton from "../../../../../components/SlimButton";
import useManagerAccountState from "../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerRewardsState from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsState";
import useManagerRewardsDispatch from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsDispatch";
import useIonAlertFunctions from "../../../../../hooks/ionAlert";

interface iState {
  displayedReward: iReward | null;
  displayActiveRewards: boolean;
  newRewardView: boolean;
  rewardToEdit: iReward | null;
}

interface iAction {
  type: string;
  data: Partial<iState>;
}

const initialState: iState = {
  displayedReward: null,
  displayActiveRewards: true,
  newRewardView: false,
  rewardToEdit: null,
};

const reducer = (state: iState, action: iAction) => {
  const { type, data } = action;
  switch (type) {
    case "set":
      return { ...state, ...data };

    default:
      return { ...state };
  }
};

interface iMainViewProps {}

const MainView: React.FC<iMainViewProps> = () => {
  const { currentHotel } = useManagerAccountState();
  const { addReward, setError, setLoading, editReward } =
    useManagerRewardsDispatch();
  const { activeRewards, inactiveRewards, getHotelRewardById, isLoading } =
    useManagerRewardsState();
  const { present: presentAlert } = useIonAlertFunctions();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { displayedReward, displayActiveRewards, newRewardView, rewardToEdit } =
    state;

  const setNewRewardView = (view: boolean) =>
    dispatch({ type: "set", data: { newRewardView: view } });

  const setDisplayedReward = (reward: iReward | null) =>
    dispatch({ type: "set", data: { displayedReward: reward } });

  const setDisplayActiveRewards = (display: boolean) =>
    dispatch({ type: "set", data: { displayActiveRewards: display } });

  const setEditReward = (reward: iReward) =>
    dispatch({
      type: "set",
      data: { newRewardView: true, rewardToEdit: reward },
    });

  const handleClickAddReward = () => {
    setNewRewardView(true);
  };
  const handleConfirmAddReward = (reward: iReward) => {
    setLoading(true);
    axios
      .post("/rewards/", {
        ...reward,
        discount: reward.type === "Offer" ? 100 : reward.discount,
        image: "/image",
        hotel: currentHotel._id,
      })
      .then((res) => {
        const { reward } = res.data;
        console.log("res.data", res.data);
        addReward(reward, currentHotel._id);
        presentAlert("Reward Created");
        setNewRewardView(false);
      })
      .catch((err) => {
        console.log("err", err);
        try {
          setError(err.response.data.message);
          presentAlert(err.response.data.message);
        } catch (err) {
          console.log("err 2", err);
          setError("Something went wrong.");
          presentAlert("Something went wrong.");
        }
      });
  };

  const handleConfirmEditReward = (reward: iReward) => {
    setLoading(true);
    axios
      .put(`/rewards/reward/${reward._id}/`, { ...reward })
      .then((res) => {
        console.log(`res`, res.data);
        const {
          data: { reward },
        } = res;
        editReward(reward);
        setDisplayedReward(reward);
        presentAlert("Reward Edited");
      })
      .catch((err) => {
        console.log(`err.response`, err.response);
        try {
          setError(err.response.data.message);
          presentAlert(err.response.data.message);
        } catch (err) {
          setError("Something went wrong.");
          presentAlert("Something went wrong.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelAddEditReward = () => {
    setNewRewardView(false);
  };

  const handleChangeView = (display_active: number) => {
    setDisplayActiveRewards(!display_active ? true : false);
  };

  const afterEditRewardStatus = (reward: iReward) => {
    const r = getHotelRewardById(reward._id);
    if (r) {
      setDisplayedReward(reward);
      if (
        (reward.active && !displayActiveRewards) ||
        (!reward.active && displayActiveRewards)
      ) {
        setDisplayActiveRewards(!displayActiveRewards);
      }
    }
  };

  const handleClickEdit = (reward: iReward) => {
    setEditReward(reward);
  };

  const handleDelete = () => {
    setDisplayedReward(null);
  };

  const wrapReward = (
    reward: iReward,
    col_size: number,
    justifyContent: string
  ) => (
    <IonCol
      size={`${col_size}`}
      style={{ justifyContent }}
      className={styles["list-card_container"]}
    >
      <div
        style={{
          border:
            displayedReward && reward._id === displayedReward._id
              ? "1px solid var(--ion-color-secondary)"
              : "",
        }}
      >
        <RewardCard
          onClickCard={() =>
            setDisplayedReward(
              displayedReward
                ? reward._id === displayedReward._id
                  ? null
                  : reward
                : reward
            )
          }
          {...{ reward }}
        />
      </div>
    </IonCol>
  );

  const renderRewards = () => {
    const rewards = displayActiveRewards ? activeRewards : inactiveRewards;

    // rewards.push(...rewards);

    if (rewards.length === 0)
      return (
        <IonRow>
          <IonCol>
            <Chivo20
              text="No rewards"
              bold
              centeredText
              color="danger"
              mt={5}
            />
          </IonCol>
        </IonRow>
      );

    if (displayedReward)
      return rewards.map((reward, i) => (
        <IonRow key={i}>{wrapReward(reward, 12, "center")}</IonRow>
      ));

    const list: JSX.Element[] = [];
    let i = 0;
    for (i = 0; i + 1 < rewards.length; i += 2) {
      try {
        list.push(
          <IonRow key={i}>
            {wrapReward(rewards[i], 6, "flex-end")}
            {wrapReward(rewards[i + 1], 6, "flex-start")}
          </IonRow>
        );
      } catch (err) {}
    }
    if (rewards.length % 2)
      list.push(
        <IonRow key={i}>
          {wrapReward(rewards[rewards.length - 1], 6, "flex-end")}
          <IonCol size="6"></IonCol>
        </IonRow>
      );

    return list;
  };

  const FILTERS: [string, string] = ["Active", "Inactive"];

  const sep_col_size = 0.1;
  const col_size = (12 - sep_col_size) / 2;

  const info_vertical_padding = "5vw";

  return (
    <IonGrid>
      <IonLoading isOpen={isLoading} />
      {newRewardView ? (
        <NewEditRewardAndPreview
          {...{
            reward_preview: rewardToEdit || undefined,
            onConfirm: !rewardToEdit
              ? handleConfirmAddReward
              : handleConfirmEditReward,
            onCancel: handleCancelAddEditReward,
          }}
        />
      ) : (
        <IonRow>
          <IonCol
            className={`${styles.list} ${styles.content_col}`}
            size={`${displayedReward ? col_size : 12}`}
          >
            <TwoSegmentedFiltersWithMiddleButton
              style={{ margin: `0 ${displayedReward ? 10 : 35}vw` }}
              filters={FILTERS}
              value={displayActiveRewards ? 0 : 1}
              onChange={handleChangeView}
              onClickIcon={handleClickAddReward}
            />
            {displayedReward ? (
              <IonContentColor>{renderRewards()}</IonContentColor>
            ) : (
              renderRewards()
            )}
          </IonCol>

          {displayedReward && (
            <Fragment>
              <DedsktopPageColSeparator />
              <IonCol
                size={`${col_size}`}
                style={{
                  paddingLeft: info_vertical_padding,
                  paddingRight: info_vertical_padding,
                }}
                className={styles.content_col}
              >
                <RewardInfo
                  reward={displayedReward}
                  onEditStatus={afterEditRewardStatus}
                  onDelete={handleDelete}
                  onClickEdit={handleClickEdit}
                />
              </IonCol>
            </Fragment>
          )}
        </IonRow>
      )}
    </IonGrid>
  );
};

interface iRewardInfoProps {
  reward: iReward;
  onEditStatus(reward: iReward): void;
  onDelete(): void;
  onClickEdit(reward: iReward): void;
}

const RewardInfo: React.FC<iRewardInfoProps> = ({
  reward,
  onEditStatus,
  onDelete,
  onClickEdit,
}) => {
  const [present] = useIonAlert();
  const { setLoading, setError } = useManagerRewardsDispatch();
  const { deleteReward, editReward } = useManagerRewardsDispatch();
  const {
    active,
    purchased,
    // short_description,
    full_description,
    sector,
    image,
    estimated_value,
    type,
    discount,
    _id,
  } = reward;

  const presentAlert = (message: string) => {
    present(message, [{ text: "Ok" }]);
  };

  const handleChangeActiveState = (status: boolean) => {
    if (status === active) return;

    setLoading(true);
    axios
      .put(`/rewards/reward/${_id}/set-status/`, { active: status })
      .then((res) => {
        const { reward } = res.data;
        console.log(`res`, res);
        editReward(reward);
        onEditStatus(reward);
      })
      .catch((err) => {
        console.log(`err`, err);
        try {
          setError(err.response.data.message);
          presentAlert(err.response.data.message);
        } catch (err) {
          setError("Something went wrong");
          presentAlert("Something went wrong.");
        }
      });
  };

  const handleDeleteReward = () => {
    setLoading(true);
    axios
      .delete(`/rewards/reward/${_id}`)
      .then((res) => {
        onDelete();
        console.log(`res`, res);
        deleteReward(_id);
        presentAlert("Reward Deleted");
      })
      .catch((err) => {
        console.log(`err.response`, err.response);
        try {
          setError(err.response.data.message);
          presentAlert(err.response.data.message);
        } catch (err) {
          setError("Something went wrong");
          presentAlert("Something went wrong.");
        }
      });
  };

  const handleClickEdit = () => {
    onClickEdit(reward);
  };

  return (
    <Fragment>
      {/* <IonLoading isOpen={getLoading()} /> */}
      <IonRow>
        <IonCol className="flexr-center">
          <Chivo24 text="Reward Info" centeredText />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-sb">
          <Text16 text="Quantity Purchased" />
          <Chivo20
            text={purchased}
            color="secondary"
            style={{ marginRight: "5px" }}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-sb">
          <Text16 text="Active" />
          <div
            className={`${styles.checkbox_container} flexc-center`}
            style={{ marginRight: "2px" }}
          >
            <IonCheckbox
              className={styles.checkbox}
              checked={active}
              onClick={() => handleChangeActiveState(!active)}
            />
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-sb">
          <Text16 text="Edit" />
          <IonIcon
            onClick={handleClickEdit}
            src={edit}
            style={{ cursor: "pointer", fontSize: "23px" }}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-center">
          <Chivo24 text="Reward Preview" centeredText />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Chivo20 text="Description" color="secondary" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Text16 text={full_description["en"]} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-center">
          {image === "/image" ? (
            <RewardSectorCard
              {...{
                sector,
                card_size: 20,
                icon_size: 10,
                text_size: 2,
              }}
            />
          ) : (
            <IonImg src={image} />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-bot_center">
          {type === "Discount" ? (
            <Fragment>
              <Text16 text="Discount" />
              <Chivo20
                color="secondary"
                text={` ${discount}%`}
                withTrailingWhiteSpace
              />
            </Fragment>
          ) : (
            <Chivo20 color="secondary" text="Offer" />
          )}
        </IonCol>
        <IonCol className="flexr-bot_center">
          <Chivo20
            text={estimatedValue2points(estimated_value)}
            color="secondary"
          />
          <Chivo12 text=" pts" withTrailingWhiteSpace />
        </IonCol>
      </IonRow>
      <IonRow style={{ marginTop: "3vh" }}>
        <SlimButton text="Delete" color="danger" onClick={handleDeleteReward} />
      </IonRow>
    </Fragment>
  );
};

export default MainView;
