import axios from "axios";
import { has } from "lodash";
import React, { useReducer, useEffect, useCallback } from "react";
import useManagerAccountState from "../AccountContext/hooks/useManagerAccountState";
import { nManagerCompetitions } from "./interfaces";

const LS_DATA = "__mngr-comp__";
const SAVE_DATA_TO_LS = true;
const save = (data: nManagerCompetitions.iStateData) => {
  if (SAVE_DATA_TO_LS) localStorage.setItem(LS_DATA, JSON.stringify(data));
};

const ManagerCompetitionsDispatchContext = React.createContext<
  nManagerCompetitions.tDispatchContext | undefined
>(undefined);
ManagerCompetitionsDispatchContext.displayName =
  "ManagerCompetitionsDispatchContext";
const ManagerCompetitionsStateContext = React.createContext<
  nManagerCompetitions.tStateContext | undefined
>(undefined);
ManagerCompetitionsStateContext.displayName = "ManagerCompetitionsStateContext";

const initialState: nManagerCompetitions.iState = {
  data: {
    competitions: [],
    competitionsData: {},
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nManagerCompetitions.iState,
  action: nManagerCompetitions.tAction
): nManagerCompetitions.iState => {
  switch (action.type) {
    case "set data":
      save(action.data);
      return { ...state, data: action.data, status: "resolved", error: null };
    case "new competition": {
      const data = {
        ...state.data,
        competitions: [...state.data.competitions, action.competition],
      };
      save(data);
      return { ...state, data, status: "resolved", error: null };
    }
    case "set competitions": {
      const data = { ...state.data, competitions: action.competitions };
      save(data);
      return { ...state, status: "resolved", error: null, data };
    }
    case "competition data": {
      const competitions = [...state.data.competitions];
      for (let i = 0; i < competitions.length; i++) {
        if (competitions[i]._id === action.competition._id) {
          competitions[i] = { ...competitions[i], ...action.competition };
          break;
        }
      }
      const data = {
        ...state.data,
        competitions,
        competitionsData: {
          ...state.data.competitionsData,
          [action.competition._id]: { roomsData: action.roomsData },
        },
      };
      save(data);
      return { ...state, status: "resolved", error: null, data };
    }
    case "resolved":
      return { ...state, status: "resolved", error: null };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    default:
      return { ...state };
  }
};

const ManagerCompetitionsContextProvider: React.FC<
  nManagerCompetitions.iContextProps
> = ({ children }) => {
  const [state, dispatch]: [
    nManagerCompetitions.iState,
    React.Dispatch<nManagerCompetitions.tAction>
  ] = useReducer(reducer, initialState);

  const { currentHotel } = useManagerAccountState();

  const get = useCallback((hotelId: string) => {
    dispatch({ type: "pending" });
    return axios
      .get(`/competitions/hotel-competitions/${hotelId}`)
      .then((res) => {
        const {
          data: { competitions },
        } = res;
        dispatch({ type: "set competitions", competitions });
      })
      .catch((err) => {
        dispatch({
          type: "rejected",
          error: has(err, "response.data.message")
            ? err.response.data.message
            : err.toJSON().message,
        });
      });
  }, []);

  useEffect(() => {
    function main() {
      dispatch({ type: "pending" });
      const unparsed = localStorage.getItem(LS_DATA);

      if (!unparsed) return get(currentHotel._id);

      try {
        const data = JSON.parse(unparsed);
        dispatch({ type: "set data", data });
      } catch (err) {
        localStorage.removeItem(LS_DATA);
        get(currentHotel._id);
      }
    }
    if (currentHotel && currentHotel._id) main();
  }, [currentHotel, get]);

  return (
    <ManagerCompetitionsStateContext.Provider value={state}>
      <ManagerCompetitionsDispatchContext.Provider value={{ dispatch, get }}>
        {children}
      </ManagerCompetitionsDispatchContext.Provider>
    </ManagerCompetitionsStateContext.Provider>
  );
};

export {
  ManagerCompetitionsContextProvider,
  ManagerCompetitionsDispatchContext,
  ManagerCompetitionsStateContext,
};
