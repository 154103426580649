import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React from "react";
import { iOverallDataType } from "../../context/DataModalContext/interfaces";
import ModalFooter from "../ModalFooter/ModalFooter";

import styles from "./styles.module.css";

interface iGraphsFooterDataTypesProps {
  available_types: iOverallDataType[];
  data_type_index: number;
  setDataType(index: number): void;
  onCloseModal?(): void;
  modal_id: number;
  width?: number;
}

const GraphsFooterDataTypes: React.FC<iGraphsFooterDataTypesProps> = ({
  available_types,
  data_type_index,
  setDataType,
  onCloseModal,
  modal_id,
  width = 80,
}) => {
  const renderButtons = (): JSX.Element[] => {
    return available_types.map(({ icon_unselected, icon_selected }, i) => {
      const is_current = data_type_index === i;

      return (
        <IonCol key={i} className="flexr-center">
          <IonButton
            onClick={() => handleClick(i)}
            fill="clear"
            className="flexr-center"
          >
            <IonIcon src={is_current ? icon_selected : icon_unselected} />
          </IonButton>
        </IonCol>
      );
    });
  };
  const handleClick = (index: number) => {
    if (index !== data_type_index) setDataType(index);
  };

  return (
    <ModalFooter
      buttonColor="danger"
      top_shadow={true}
      modal_id={modal_id}
      closeModal={onCloseModal}
    >
      <IonGrid className={styles.container} style={{ width: `${width}%` }}>
        <IonRow>{renderButtons()}</IonRow>
      </IonGrid>
    </ModalFooter>
  );
};

export default GraphsFooterDataTypes;
