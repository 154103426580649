import {
  IonButton,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React from "react";
import PickerRow from "..";
import { Text14 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

interface iSelectionPickerProps extends iPickerProps {}

const SelectionPicker: React.FC<iSelectionPickerProps> = ({
  title,
  options,
  value,
  onChange,
  multiple,
  ...rest
}) => {
  return (
    <PickerRow>
      <Picker {...{ options, title, value, onChange, multiple, ...rest }} />
    </PickerRow>
  );
};

export interface iSelectionPickerOption {
  text: string;
  value: (string | number) | (string | number)[];
}

interface iPickerProps {
  options: iSelectionPickerOption[];
  title: string;
  onChange(value: (string | number) | (string | number)[]): void;
  value: (string | number) | (string | number)[];
  multiple?: boolean;
  [key: string]: any;
}

const Picker: React.FC<iPickerProps> = ({
  title,
  options,
  value,
  onChange,
  multiple = false,
  ...rest
}) => {
  const renderOptions = (): JSX.Element[] => {
    return options.map(({ text, value }, key) => (
      <IonSelectOption {...{ value, key }}>
        {/* <Text14 {...{ text }} /> */}
        {text}
      </IonSelectOption>
    ));
  };

  return (
    <IonRow className={styles.selection_picker} {...rest}>
      <IonCol>
        <Text14 text={title} />
        <IonSelect
          {...{ multiple: multiple !== false }}
          value={
            typeof value === "string" ? (value.length ? value : "None") : value
          }
          placeholder="Select one"
          onIonChange={(e) => {
            onChange(e.detail.value);
          }}
          className="selection_picker-picker"
        >
          {renderOptions()}
        </IonSelect>
      </IonCol>
    </IonRow>
  );
};

export default SelectionPicker;
