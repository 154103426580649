import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonMenu,
  IonRow,
  IonText,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { closeOutline } from "ionicons/icons";
import React from "react";

import logo from "../../img/noytrall_logo_white.svg";
import settings from "../../img/settings.svg";

import "./menu.css";
import MenuButtons from "./MenuButtons/MenuButtons";

const Menu: React.FC = () => {
  return (
    <>
      <IonMenu side="end" id="menu" contentId="main">
        <IonContent scrollY={false} color="primary" className="menu-content">
          <MenuHeader />
          <MenuButtons />
          {/* <MenuFooter /> */}
        </IonContent>
      </IonMenu>
    </>
  );
};

const MenuHeader: React.FC = () => {
  return (
    <IonGrid className="menu-header">
      <IonRow>
        <IonCol size="3" className="menu-close">
          <IonButtons>
            <IonButton onClick={() => menuController.close()}>
              <IonIcon src={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonCol>
        <IonCol className="menu-logo">
          <IonImg src={logo} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const MenuFooter: React.FC = () => {
  return (
    <div className="menu-footer">
      {/* <div id="settings-line"></div> */}
      <IonItem lines="none" color="primary" type="button" button detail={false}>
        <IonGrid>
          <IonRow>
            <IonCol className="menu-footer-icon">
              <IonIcon src={settings} />
            </IonCol>
            <IonCol>
              <IonText color="light">
                <p className="montserrat">Settings</p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
    </div>
  );
};

export default Menu;
