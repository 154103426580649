/* eslint-disable */
import React from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { logOutOutline, menuOutline } from "ionicons/icons";
import { menuController } from "@ionic/core";

import logo from "../../img/noytrall_logo.svg";
import logo2 from "../../img/noytrall-logo.svg";

import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AuthContext/AuthContext";

import styles from "./styles.module.css";

interface iAuthHeader {
  backButton?: boolean;
}

export const AuthHeader: React.FC<iAuthHeader> = ({ backButton = false }) => {
  const history = useHistory();
  const menu = () => {
    return (
      <IonCol size="3" className={styles["header-menu"]}>
        <IonButtons>
          <IonButton
            onClick={() => {
              menuController.open();
            }}
          >
            <IonIcon color="menu_button" src={menuOutline} />
          </IonButton>
        </IonButtons>
      </IonCol>
    );
  };

  const leftButton = () => {
    if (!backButton) return <IonCol size="3"></IonCol>;

    return (
      <IonCol
        size="3"
        className={`${styles["header-auth-backButton"]} flexc-start`}
      >
        <IonButton
          routerDirection="back"
          fill="clear"
          onClick={() => {
            history.goBack();
          }}
        >
          <IonIcon
            color="secondary"
            style={{ transform: "scaleX(-1)" }}
            src={logOutOutline}
          />
        </IonButton>
      </IonCol>
    );
  };

  return (
    <IonHeader className={`${styles.header} ${styles["header-auth"]}`}>
      <IonToolbar className="ion-text-center" color="header">
        <IonGrid>
          <IonRow>
            {leftButton()}
            <IonCol className={styles["header-logo"]}>
              <IonIcon className={styles["header-auth-logo"]} src={logo2} />
            </IonCol>
            {menu()}
          </IonRow>
        </IonGrid>
        <IonButtons></IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export const NonAuthHeader: React.FC = () => {
  const { Logout, isLogged } = useAuth();
  const history = useHistory();

  return (
    <IonHeader className={`${styles.header} ${styles["header-non_auth"]}`}>
      <IonToolbar color="light" className="ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol size="3" className={styles["header-non_auth-home"]}>
              <IonButton
                routerDirection="back"
                fill="clear"
                onClick={() => {
                  history.goBack();
                  if (isLogged()) Logout();
                }}
              >
                <IonIcon
                  color="primary"
                  style={{ transform: "scaleX(-1)" }}
                  src={logOutOutline}
                />
              </IonButton>
            </IonCol>
            <IonCol className={styles["header-logo"]}>
              <IonIcon src={logo} />
            </IonCol>
            <IonCol size="3"></IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};
