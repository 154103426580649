import { moon, sunny } from "ionicons/icons";
import React, { useEffect, useReducer } from "react";
import { createCtx } from "../CreateContext";
import { nTheme } from "./interfaces";

export const BACKGROUND_COLOR_DARK_THEME = "#1f2023";
export const BACKGROUND_COLOR_LIGHT_THEME = "#f4f5f8";

const DEFAULT_THEME: nTheme.tTheme = "light";
const THEME_LSTORAGE = "theme";

const available_themes: nTheme.tTheme[] = ["light", "dark"];
const available_themes_icons: string[] = [sunny, moon];

const initial_state: nTheme.iState = {
  theme: DEFAULT_THEME,
};

const reducer = (
  state: nTheme.iState,
  action: nTheme.iAction
): nTheme.iState => {
  switch (action.type) {
    case "set theme":
      const theme: nTheme.tTheme = action.theme!;
      localStorage.setItem(THEME_LSTORAGE, theme);
      document.body.classList.toggle("dark", theme === "dark");
      return { ...state, theme: theme };

    default:
      return { ...state };
  }
};

const [useTheme, CtxProvider] = createCtx<nTheme.iContext>();

const ThemeContextProvider: React.FC<nTheme.iContextProps> = ({ children }) => {
  const [state, dispatch]: [nTheme.iState, React.Dispatch<nTheme.iAction>] =
    useReducer(reducer, initial_state);

  useEffect(() => {
    let t = localStorage.getItem(THEME_LSTORAGE);

    if (!t) {
      t = DEFAULT_THEME;
      // const is_dark = window.matchMedia("(prefers-color-scheme: dark)");
      // t = is_dark.matches ? "dark" : "light";
      localStorage.setItem(THEME_LSTORAGE, t);
    }

    dispatch({ type: "set theme", theme: t as nTheme.tTheme });
  }, []);

  const handleSwitchTheme = (theme: nTheme.tTheme): void => {
    if (theme !== state.theme) dispatch({ type: "set theme", theme: theme });
  };

  const getBackgroundColor = () => {
    return state.theme === "light"
      ? BACKGROUND_COLOR_LIGHT_THEME
      : BACKGROUND_COLOR_DARK_THEME;
  };

  const value = { state, handleSwitchTheme, getBackgroundColor };
  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export {
  useTheme,
  ThemeContextProvider,
  available_themes,
  available_themes_icons,
};
