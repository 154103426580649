import help from "./help";
import languages from "./languages";
import theme from "./theme";
import profile from "./profile";
import rewards from "./rewards";
import menu from "./menu";
import navbar from "./navbar";
import stays from "./stays";
import global from "./global";
import zero from "./zero";
import manager_hotel from "./manager_hotel";

const dict = {
  ...zero,
  ...global,
  ...stays,
  ...navbar,
  ...menu,
  rewards,
  ...profile,
  ...theme,
  ...languages,
  manager_hotel,
  help,
};

export default dict;
