import { IonButton } from "@ionic/react";
import React from "react";
import { Chivo20 } from "../Text/Chivo";

export interface iButtonProps {
  text: string;
  textColor?: string;
  buttonColor?: string;
  fill?: "solid" | "outline" | "default";
  type?: "button" | "submit";
  link?: string;
  className?: string;
  onClick?(): void;
  disabled?: boolean;
}

const Button: React.FC<iButtonProps> = ({
  text,
  textColor = "secondary",
  buttonColor = "primary",
  fill = "solid",
  type = "button",
  link,
  className = "",
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <IonButton
      {...{
        fill,
        routerLink: link,
        routerDirection: "forward",
        type,
        className: ` ${className}`,
        onClick,
        disabled,
        color: buttonColor,
      }}
    >
      <Chivo20 {...{ color: textColor, text }} />
    </IonButton>
  );
};

export default Button;
