import moment from "moment";
import React, { useMemo } from "react";
import { tSpending } from "../../../../interfaces/models/sensorData";
import { ManagerReservationsStateContext } from "..";
import iReservation from "../../../../interfaces/models/reservation";
import { toInteger } from "lodash";

const useManagerReservationsState = () => {
  const state = React.useContext(ManagerReservationsStateContext);

  if (!state)
    throw Error(
      "useManagerReservationsState must be used within ManagerReservationsStateContext"
    );

  const averageSpendingPerNight = useMemo(() => {
    const spending: tSpending = { Waste: 0, Water: 0, Footprint: 0, Energy: 0 };
    const { past, ongoing } = state.data.sortedReservations;

    let nights = 0;
    past.forEach(({ Footprint, Water, Energy, Waste, startAt, endAt }) => {
      nights += Math.abs(moment(endAt).diff(moment(startAt), "days")) || 1;
      spending.Footprint += Footprint || 0;
      spending.Water += Water || 0;
      spending.Energy += Energy || 0;
      spending.Waste += Waste || 0;
    });
    ongoing.forEach(({ Footprint, Water, Energy, Waste, endAt }) => {
      nights += Math.abs(moment(endAt).diff(moment(), "days")) || 1;
      spending.Footprint += Footprint || 0;
      spending.Water += Water || 0;
      spending.Energy += Energy || 0;
      spending.Waste += Waste || 0;
    });

    spending.Footprint /= nights || 1;
    spending.Water /= nights || 1;
    spending.Waste /= nights || 1;
    spending.Energy /= nights || 1;
    return spending;
  }, [state.data.sortedReservations]);

  const {
    data: {
      sortedReservations: { ongoing, past, upcoming },
    },
  } = state;

  const sortReservations = (r1: iReservation, r2: iReservation): 1 | 0 | -1 => {
    const start1 = moment(r1.startAt);
    const start2 = moment(r2.startAt);

    if (start1.isAfter(start2)) return -1;
    if (start1.isBefore(start2)) return 1;
    return 1;
  };

  const getRoomPastReservations = (room: string): iReservation[] => {
    return past.filter((r) => r.roomNum === room).sort(sortReservations);
  };
  const getRoomOngoingReservations = (room: string): iReservation[] => {
    return ongoing.filter((r) => r.roomNum === room).sort(sortReservations);
  };
  const getRoomUpcomingReservations = (room: string): iReservation[] => {
    return upcoming.filter((r) => r.roomNum === room).sort(sortReservations);
  };

  const roomsWithReservations = useMemo((): string[] => {
    const rooms = new Set();
    [...past, ...ongoing, ...upcoming].forEach((r) => rooms.add(r.roomNum));

    const list = Array.from(rooms) as string[];

    list.sort((a: string, b: string) => {
      let v1, v2;
      try {
        v1 = toInteger(a);
        v2 = toInteger(b);
      } catch (err) {
        v1 = a;
        v2 = b;
      }
      if (v1 > v2) return 1;
      if (v1 === v2) return 0;
      return -1;
    });

    return list;
  }, [ongoing, past, upcoming]);

  return {
    averageSpendingPerNight,
    ongoingReservations: ongoing.sort(sortReservations),
    pastReservations: past.sort(sortReservations),
    upcomingReservations: upcoming.sort(sortReservations),
    isLoading: state.status === "pending",
    roomsWithReservations,
    getRoomPastReservations,
    getRoomOngoingReservations,
    getRoomUpcomingReservations,
  };
};

export default useManagerReservationsState;
