import { IonGrid } from "@ionic/react";
import React, { useState } from "react";
// import useFilter from "../../../../components/Filters/useFilter";
import SegmentedFilter from "../../../../components/SegmentedFilter";
import useUserReservationsState from "../../../../context/User/UserReservationsContext/hooks/useUserReservationsState";
import strings from "../../../../localization/localization";
import Card from "./Card/Card";

import "./stats_card.css";

interface iStatsCardProps {}

// type tFilters = "Overall" | "Average";

// const filters: tFilters[] = ["Overall", "Average"];

const OPTIONS = [strings.overall_stats, strings.average_stats];

const StatsCard: React.FC<iStatsCardProps> = () => {
  const [option, setOption] = useState(0);

  const {
    measures: { overall, average },
  } = useUserReservationsState();

  const handleChange = (index: number) => {
    // setFilter(filters[index]);
    setOption(index);
  };

  return (
    <IonGrid className="stats_card-container">
      {/* <Filters {...{ filter, filters, setFilter, font_size: 5 }} /> */}
      <SegmentedFilter
        filters={OPTIONS}
        value={option}
        onChange={handleChange}
      />
      <Card info={option === 0 ? overall : average} />
    </IonGrid>
  );
};

export default StatsCard;
