import React from "react";
import { IonPage } from "@ionic/react";
import { NonAuthHeader } from "../components/Header/Header";

interface Props {}

const withNonAuthHeader =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P & Props> =>
  ({ ...props }: Props) =>
    (
      <IonPage color="background">
        <NonAuthHeader />
        <Component {...(props as P)} />
      </IonPage>
    );

export default withNonAuthHeader;
