import React from "react";
import { IonPage } from "@ionic/react";
import { AuthHeader } from "../components/Header/Header";

interface Props {}

const withAuthHeader =
  <P extends object>(
    Component: React.ComponentType<P>,
    { withBackButton }: { withBackButton: boolean } = { withBackButton: false }
  ): React.FC<P & Props> =>
  ({ ...props }: Props) =>
    (
      <IonPage id="main">
        <AuthHeader backButton={withBackButton} />
        <Component {...(props as P)} />
      </IonPage>
    );

export default withAuthHeader;
