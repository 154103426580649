import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { Chivo20 } from "../../../../../components/Text/Chivo";
import useManagerCompetitionsState from "../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsState";
import iCompetition from "../../../../../interfaces/models/competitions";
import CompetitionCard from "../../Mobile/Competitions/CompetitionCard";
import CompetitionInfo from "./CompetitionInfo";

interface iCompetitionsProps {
  filter: number;
  onClickCompetitionCard(show_competition_info: boolean): void;
}

const Competitions: React.FC<iCompetitionsProps> = ({
  filter,
  onClickCompetitionCard,
}) => {
  const [selectedCompetition, setSelectedCompetition] =
    useState<iCompetition | null>(null);

  const { isLoading, sortedCompetitions } = useManagerCompetitionsState();
  const { past: pastCompetitions, ongoing: ongoingCompetitions } =
    sortedCompetitions;

  const renderCompetitions = () => {
    const wrapCompetition = (
      competition: iCompetition,
      col_size: number,
      justifyContent: string
    ) => (
      <IonCol size={`${col_size}`} style={{ justifyContent }}>
        <CompetitionCard
          {...{
            competition,
            onClickCard: () => {
              const comp: iCompetition | null = selectedCompetition
                ? selectedCompetition._id === competition._id
                  ? null
                  : competition
                : competition;
              onClickCompetitionCard(comp !== null);

              setSelectedCompetition(comp);
            },
          }}
        />
      </IonCol>
    );

    const competitions = filter === 0 ? ongoingCompetitions : pastCompetitions;

    if (competitions.length === 0)
      return (
        <IonRow>
          <IonCol>
            <Chivo20
              text="No competitions"
              bold
              centeredText
              color="danger"
              mt={5}
            />
          </IonCol>
        </IonRow>
      );

    const list: JSX.Element[] = [];

    let i = 0;
    for (i = 0; i + 1 < competitions.length; i += 2) {
      list.push(
        <IonRow key={i}>
          {wrapCompetition(competitions[i], 6, "flex-end")}
          {wrapCompetition(competitions[i + 1], 6, "flex-start")}
        </IonRow>
      );
    }

    if (competitions.length % 2)
      list.push(
        <IonRow key={i}>
          {wrapCompetition(
            competitions[competitions.length - 1],
            6,
            "flex-end"
          )}
          <IonCol size="6"></IonCol>
        </IonRow>
      );

    return list;
  };

  const loading = isLoading || false;

  return selectedCompetition ? (
    <CompetitionInfo
      competitionId={selectedCompetition._id}
      onClose={() => {
        onClickCompetitionCard(false);
        setSelectedCompetition(null);
      }}
    />
  ) : (
    <IonGrid style={{ margin: "0 200px" }}>
      <IonLoading isOpen={loading} />
      {!loading && renderCompetitions()}
    </IonGrid>
  );
};

export default Competitions;
