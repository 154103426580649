import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Fragment, useState } from "react";
import EnterTextModal, {
  iEnterTextModalInfo,
} from "../../../../components/EnterTextModal/EnterTextModal";
import { OptionsPicker } from "../../../../components/PickerRow";
import ClickerPicker from "../../../../components/PickerRow/ClickerPicker";
import RewardCard, {
  iRewardCardRewardInfo,
} from "../../../../components/RewardCard";
import { Chivo18 } from "../../../../components/Text/Chivo";
import { Text14 } from "../../../../components/Text/Montserrat";
import { TitledConfinedInput } from "../../../../components/TitledComponent";
import { modal_id_new_reward_enter_text } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import { LANGUAGE_CODES, tLanguageCodes } from "../../../../utils/consts";
import validator from "validator";
import strings from "../../../../localization/localization";

import styles from "./styles.module.css";
import TextareaConfined from "../../../../components/TextareaConfined";
import { iEditRewardInfoReducer } from "../hooks";

import { TextFieldTypes } from "@ionic/core";

const MAX_CHAR_SHORT_DESCRIPTION = 80;
const MAX_CHAR_FULL_DESCRIPTION = 2048;

interface iState {
  estimated_value: number | null;
  sector: string | null;
  type: string | null;
  discount: number | null;
  location: string | null;
  short_description: Partial<Record<tLanguageCodes, string>>;
  full_description: Partial<Record<tLanguageCodes, string>>;
  image: string | null;
  title: string | null;
}

interface iAction extends Partial<iState> {
  action_type: string;
  language?: string;
}

const initialState: iState = {
  estimated_value: null,
  sector: null,
  type: null,
  discount: null,
  location: null,
  short_description: {},
  full_description: {},
  image: null,
  title: null,
};

const reducer = (state: iState, action: iAction) => {
  const { action_type } = action;
  function evaluate<T>(arg: string): T | null {
    if (arg !== "short_description" && arg !== "full_description")
      //   @ts-ignore
      return action[arg] !== undefined ? action[arg] : state[arg];

    //   @ts-ignore
    return action[arg];
  }

  const evaluateRecord = (arg: string): Record<string, string | null> => {
    //   @ts-ignore
    const obj: Record<string, string> = {
      //   @ts-ignore
      ...state[arg],
      //   @ts-ignore
      [action.language]: action[arg],
    };

    //   @ts-ignore
    if (action.language === undefined || action[arg] === undefined)
      //   @ts-ignore
      return state[arg];

    return obj;
  };

  switch (action_type) {
    case "set":
      return {
        ...state,
        estimated_value: evaluate<number>("estimated_value"),
        discount: evaluate<number>("discount"),
        sector: evaluate<string>("sector"),
        type: evaluate<string>("type"),
        short_description: evaluateRecord("short_description"),
        full_description: evaluateRecord("full_description"),
        image: evaluate<string>("image"),
        location: evaluate<string>("location"),
        title: evaluate<string>("title"),
      };

    case "reset":
      return { ...initialState };

    default:
      return { ...state };
  }
};

interface iEditRewardProps {
  edit_reward_info: iEditRewardInfoReducer;
  display_preview?: boolean;
}

const EditReward: React.FC<iEditRewardProps> = ({
  edit_reward_info,
  display_preview = false,
}) => {
  const { state, handleChange, sector_options, type_options } =
    edit_reward_info;

  const [enter_text_modal_info, setEnterTextModalInfo] =
    useState<iEnterTextModalInfo | null>(null);
  const { closeModal, openModal } = useOpenModal();

  const handleCloseEnterTextModal = () => {
    setEnterTextModalInfo(null);
    closeModal(modal_id_new_reward_enter_text);
  };

  const handleConfirmEnterTextNumber = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ): boolean => {
    const value = text[0];
    if (!validator.isDecimal(value)) return cb(true, "Not a number"), false;
    return true;
  };
  const handleConfirmEstimatedValue = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    if (handleConfirmEnterTextNumber(text, cb)) {
      handleChange("estimated_value", parseFloat(text[0]));
      cb(false, "");
    }
  };

  const handleOpenEstimatedValueModal = () => {
    handleOpenEnterTextModal(
      "Estimated Value (€)",
      "Enter a number",
      "number",
      handleConfirmEstimatedValue
    );
  };

  const handleConfirmDiscount = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    if (handleConfirmEnterTextNumber(text, cb)) {
      const num = parseFloat(text[0]);
      if (num <= 0 || num >= 100)
        return cb(true, "Number not between 0 and 100");
      handleChange("discount", num);
      cb(false, "");
    }
  };
  const handleOpenDiscountModal = () => {
    handleOpenEnterTextModal(
      "Discount (%)",
      "Enter a number between 0 and 100",
      "number",
      handleConfirmDiscount
    );
  };

  const handleConfirmLocation = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    handleChange("location", text[0]);
    cb(false, "");
  };
  const handleOpenLocationModal = () => {
    handleOpenEnterTextModal(
      "Location",
      "Enter a location",
      "text",
      handleConfirmLocation
    );
  };

  const handleOpenEnterTextModal = (
    title: string,
    placeholder: string,
    input_type: TextFieldTypes | undefined,
    onConfirm: (
      text: string[],
      cb: (error: boolean, message: string) => void
    ) => void
  ) => {
    const modal_info: iEnterTextModalInfo = {
      title: title,
      input_placeholder: [placeholder],
      onConfirm,
      dismiss_alert_after_success: true,
      dismiss_alert_on_true: false,
      input_type,
    };
    setEnterTextModalInfo(modal_info);
    openModal(modal_id_new_reward_enter_text);
  };

  const {
    estimated_value,
    sector,
    type,
    discount,
    location,
    short_description,
    full_description,
    title,
  } = state;

  const reward: iRewardCardRewardInfo = {
    estimated_value: estimated_value || undefined,
    sector: sector || "",
    type: type || "",
    discount: discount || undefined,
    location: location || "",
    short_description: short_description || undefined,
  };

  return (
    <IonGrid className={styles.grid}>
      {enter_text_modal_info && (
        <EnterTextModal
          modal_id={modal_id_new_reward_enter_text}
          onCancel={handleCloseEnterTextModal}
          {...enter_text_modal_info}
        />
      )}
      <TitledConfinedInput
        {...{
          titleHorizontalMargin: "10px",
          title: strings.title,
          value: title || "",
          placeholder: strings.rewards.new_reward,
          onChange: (value) => handleChange("title", value),
        }}
      />

      <IonRow className={styles.title}>
        <IonCol>
          <Chivo18 text={strings.rewards.rewards} />
        </IonCol>
      </IonRow>

      <ClickerPicker
        value={estimated_value || ""}
        title="Estimated Value (€)"
        onClick={handleOpenEstimatedValueModal}
      />
      <OptionsPicker
        value={sector || ""}
        title="Sector"
        options={sector_options}
        onSelect={(value) => handleChange("sector", value)}
      />
      <OptionsPicker
        value={type || ""}
        title="Type"
        options={type_options}
        onSelect={(value) => handleChange("type", value)}
      />
      {type === "Discount" && (
        <ClickerPicker
          value={discount || ""}
          title="Discount (%)"
          onClick={handleOpenDiscountModal}
        />
      )}
      <ClickerPicker
        value={location || ""}
        title="Location"
        onClick={handleOpenLocationModal}
      />

      <IonRow className={styles.description_title}>
        <IonCol>
          <Text14 text={"Short Description"} />
        </IonCol>
      </IonRow>
      <div>
        <ShortDescription
          value={short_description[LANGUAGE_CODES.en.code] || ""}
          language_code={LANGUAGE_CODES.en.code}
          language={LANGUAGE_CODES.en.name}
          onChange={handleChange}
        />
        <ShortDescription
          value={short_description[LANGUAGE_CODES.pt.code] || ""}
          language_code={LANGUAGE_CODES.pt.code}
          language={LANGUAGE_CODES.pt.name}
          onChange={handleChange}
        />
      </div>

      <IonRow className={styles.description_title} style={{ marginTop: "1vh" }}>
        <IonCol>
          <Text14 text={"Full Description"} />
        </IonCol>
      </IonRow>
      <div>
        <FullDescription
          value={full_description[LANGUAGE_CODES.en.code] || ""}
          language_code={LANGUAGE_CODES.en.code}
          language={LANGUAGE_CODES.en.name}
          onChange={handleChange}
        />
        <FullDescription
          value={full_description[LANGUAGE_CODES.pt.code] || ""}
          language_code={LANGUAGE_CODES.pt.code}
          language={LANGUAGE_CODES.pt.name}
          onChange={handleChange}
        />
      </div>
      {display_preview !== false && (
        <Fragment>
          <IonRow className={styles.title}>
            <IonCol>
              <Chivo18 text="Preview" />
            </IonCol>
          </IonRow>
          <RewardCard textColor="light" reward_info={reward} />
        </Fragment>
      )}
    </IonGrid>
  );
};

interface iShortDescriptionProps {
  onChange<T>(key: string, value: T, language?: string): void;
  value: string;
  language: string;
  language_code: string;
}

const ShortDescription: React.FC<iShortDescriptionProps> = ({
  onChange,
  value,
  language,
  language_code,
}) => {
  return (
    <Description
      onChange={onChange}
      title={`Short Description (${language})`}
      placeholder={`${language}, max ${MAX_CHAR_SHORT_DESCRIPTION} characters`}
      state_key="short_description"
      max_chars={MAX_CHAR_SHORT_DESCRIPTION}
      {...{ value, language_code, rows: 1 }}
    />
  );
};
interface FullDescriptionProps {
  onChange<T>(key: string, value: T, language?: string): void;
  value: string;
  language: string;
  language_code: string;
}

const FullDescription: React.FC<FullDescriptionProps> = ({
  onChange,
  value,
  language,
  language_code,
}) => {
  return (
    <Description
      onChange={onChange}
      title={`Full Description (${language})`}
      placeholder={`${language}`}
      state_key="full_description"
      max_chars={MAX_CHAR_FULL_DESCRIPTION}
      {...{ value, language_code, rows: 3, autoGrow: true }}
    />
  );
};
interface DescriptionProps {
  onChange<T>(key: string, value: T, language?: string): void;
  value: string;
  language_code: string;
  title: string;
  placeholder: string;
  state_key: string;
  max_chars: number;
  [key: string]: any;
}

const Description: React.FC<DescriptionProps> = ({
  onChange,
  value,
  language_code,
  title,
  placeholder,
  state_key,
  max_chars,
  ...rest
}) => {
  return (
    <Fragment>
      <IonRow className={styles.textarea}>
        <IonCol>
          <TextareaConfined
            placeholder={placeholder}
            value={value}
            onChange={(val) => {
              onChange(
                state_key,
                val.length > max_chars ? value : val,
                language_code
              );
            }}
            {...rest}
          />
        </IonCol>
      </IonRow>
    </Fragment>
  );
};

export default EditReward;
