import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { Fragment, useReducer } from "react";
import FormErrorMessage from "../../../../components/FormErrorMessage";
import Input from "../../../../components/Input/Input";
import LoginForm from "../../../../components/LoginForm";
import SlimButton from "../../../../components/SlimButton";
import { Text18 } from "../../../../components/Text/Montserrat";
import { useAuth } from "../../../../context/AuthContext/AuthContext";
import { LoginDataType } from "../../../../context/AuthContext/interfaces";
import useLoading from "../../../../hooks/loading";

import styles from "./styles.module.css";

interface iState {
  email: string;
  password: string;
}

interface iAction extends Partial<iState> {
  type: string;
}

const initial_state: iState = {
  email: "",
  password: "",
};

const reducer = (state: iState, action: iAction) => {
  const { type, email, password } = action;
  switch (type) {
    case "set email":
      return { ...state, email: email! };
    case "set password":
      return { ...state, password: password! };

    case "reset":
      return { ...initial_state };

    default:
      return { ...state };
  }
};

interface iLoginProps {}

const Login: React.FC<iLoginProps> = () => {
  const { getError, reset, setErrorMessage, getMessage, getLoading, setState } =
    useLoading();
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { Login } = useAuth();

  const handleChange = (key: string, value: string) => {
    if (getError()) reset();
    dispatch({ type: `set ${key}`, [key]: value });
  };

  const handleSubmit = () => {
    setState(true, false, null);
    Login(state as LoginDataType, (error: string) => {
      if (error) setErrorMessage(error);
    });
  };

  const { email, password } = state;

  return (
    <Fragment>
      <IonLoading isOpen={getLoading()} />
      <IonGrid className={styles.grid}>
        <IonRow>
          <IonCol>
            <FormErrorMessage text={getMessage()} />
          </IonCol>
        </IonRow>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <IonRow>
            <IonCol>
              <Input
                color="primary"
                value={email}
                onChange={handleChange}
                name="email"
                label="Email"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Input
                color="primary"
                value={password}
                onChange={handleChange}
                type="password"
                name="password"
                label="Password"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol style={{ marginTop: "4vh" }}>
              <input type="submit" hidden />
              <SlimButton
                type="submit"
                color="primary"
                onClick={handleSubmit}
                text="Log in"
              />
            </IonCol>
          </IonRow>
        </form>
        <IonRow>
          <IonCol>
            <div className={styles.separator} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>
    </Fragment>
  );
};

export default Login;
