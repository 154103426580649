import React, { useEffect, useReducer } from "react";
import { createCtx } from "../../../../context/CreateContext";
import { nProfilePage } from "./interfaces";

const NO_BADGE_CHOSEN: number = -1;

const initial_state: nProfilePage.iState = {
  badge: NO_BADGE_CHOSEN,
};

const reducer = (
  state: nProfilePage.iState,
  action: nProfilePage.iAction
): nProfilePage.iState => {
  const { type, badge } = action;
  switch (type) {
    case "set badge":
      return { ...state, badge: badge! };

    default:
      return { ...state };
  }
};

const [useProfilePage, CtxProvider] = createCtx<nProfilePage.iContext>();

const ProfilePageContextProvider: React.FC<nProfilePage.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [
    nProfilePage.iState,
    React.Dispatch<nProfilePage.iAction>
  ] = useReducer(reducer, initial_state);

  useEffect(() => {}, []);

  const setBadge = (badge: number) => dispatch({ type: "set badge", badge });
  const resetBadge = () => setBadge(NO_BADGE_CHOSEN);
  const getBadge = (): number => state.badge;
  const badgeIsSelected = (): boolean => state.badge !== NO_BADGE_CHOSEN;

  const getState = (): nProfilePage.iState => state;

  const value = { getState, setBadge, resetBadge, getBadge, badgeIsSelected };
  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export { useProfilePage, ProfilePageContextProvider };
