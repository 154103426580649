const rewards = {
  new_reward: "Nova recompensa",
  rewards: "Recompensas",
  estimated_value: "",
  sector: "",
  type: "",
  location: "",
};

export default rewards;
