import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import moment from "moment";
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DedsktopPageColSeparator from "../../../../components/DesktopPageColSeparator";
import IonDesktopContentColor from "../../../../components/IonDesktopContentColor";
import { RoomSelectionSliderWithTitle } from "../../../../components/RoomSelectionSlider";
import RoundInput from "../../../../components/RoundInput";
import SegmentedFilter from "../../../../components/SegmentedFilter";
import { Chivo22, Chivo24 } from "../../../../components/Text/Chivo";
import { Text16 } from "../../../../components/Text/Montserrat";
import TwoSlimButtons from "../../../../components/TwoSlimButtons";
import WarningModal from "../../../../components/WarningModal";
import useManagerReservationsDispatch from "../../../../context/Manager/ReservationsContext/hooks/useManagerReservationsDispatch";
import useManagerReservationsState from "../../../../context/Manager/ReservationsContext/hooks/useManagerReservationsState";
import { modal_id_warning } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import useIonAlertFunctions from "../../../../hooks/ionAlert";
import useLoading from "../../../../hooks/loading";
import useClickOutside from "../../../../hooks/useClickOutside";
import iReservation from "../../../../interfaces/models/reservation";
import {
  convertUnitEnergy,
  convertUnitMass,
  convertUnitVolume,
} from "../../../../utils/convertUnit";
import { epochToDate } from "../../../../utils/others";
import { slider_id_hotel_rooms_reserves } from "../../../../utils/sliders";
import List from "../List";
import Add from "./Add";

import styles from "./styles.module.css";

interface iReservesDesktopProps {}

const options = ["Past", "Ongoing", "Future"];

const ReservesDesktop: React.FC<iReservesDesktopProps> = () => {
  const [bookingTimeline, setBookingTimeline] = useState<number>(0);
  const refUpcoming = useRef();
  const refOngoing = useRef();
  const refPast = useRef();
  const refSlider = useRef();
  const refBookingHeader = useRef();
  const refBookingBody = useRef();
  const refAddBooking = useRef();
  const refMenu = useRef();
  const {
    getRoomOngoingReservations,
    getRoomPastReservations,
    getRoomUpcomingReservations,
    ongoingReservations,
    pastReservations,
    upcomingReservations,
    isLoading,
    roomsWithReservations,
  } = useManagerReservationsState();
  const { clickedOutside, reset } = useClickOutside({
    refs: [
      refUpcoming,
      refOngoing,
      refPast,
      refSlider,
      refBookingHeader,
      refBookingBody,
      refAddBooking,
      refMenu,
    ],
    names: [
      "upcoming",
      "ongoing",
      "past",
      "slider",
      "bookingHeader",
      "bookingBody",
      "addBooking",
      "menu",
    ],
  });
  const [room, setRoom] = useState(0);
  const [reservation, setReservation] = useState<iReservation | null>(null);

  useEffect(() => {
    if (clickedOutside) {
      setReservation(null);
      reset();
    }
  }, [clickedOutside, reset]);

  const handleShowReservationInfo = useCallback(
    (_reservation: iReservation) => {
      setReservation(_reservation === reservation ? null : _reservation);
    },
    [reservation]
  );

  const style1 = {
    marginLeft: "5vw",
    marginRight: "5vw",
    marginTop: "4vh",
  };

  const getReservationsList = useCallback(
    (
      reservations: iReservation[],
      getRoomReservations: (room: string) => iReservation[]
    ) => {
      return room === 0
        ? reservations
        : getRoomReservations(roomsWithReservations[room - 1]);
    },
    [roomsWithReservations, room]
  );

  const upcomingReservationsList = useMemo(() => {
    return getReservationsList(
      upcomingReservations,
      getRoomUpcomingReservations
    );
  }, [getReservationsList, upcomingReservations, getRoomUpcomingReservations]);
  const ongoingReservationsList = useMemo(() => {
    return getReservationsList(ongoingReservations, getRoomOngoingReservations);
  }, [getReservationsList, ongoingReservations, getRoomOngoingReservations]);
  const pastReservationsList = useMemo(() => {
    return getReservationsList(pastReservations, getRoomPastReservations);
  }, [getReservationsList, pastReservations, getRoomPastReservations]);

  const listProps = useMemo(() => {
    if (bookingTimeline === 2)
      return {
        ref: upcomingReservationsList.length ? refUpcoming : undefined,
        title: "Future Bookings",
        centeredTitle: true,
        onClick: handleShowReservationInfo,
        reservations: upcomingReservationsList,
        isLoading: isLoading,
        selectedReservationId: reservation?._id,
      };
    if (bookingTimeline === 1)
      return {
        ref: ongoingReservationsList.length ? refUpcoming : undefined,
        title: "Ongoing Bookings",
        centeredTitle: true,
        onClick: handleShowReservationInfo,
        reservations: ongoingReservationsList,
        isLoading: isLoading,
        selectedReservationId: reservation?._id,
      };
    if (bookingTimeline === 0)
      return {
        ref: pastReservationsList.length ? refUpcoming : undefined,
        title: "Past Bookings",
        centeredTitle: true,
        onClick: handleShowReservationInfo,
        reservations: pastReservationsList,
        isLoading: isLoading,
        selectedReservationId: reservation?._id,
      };
  }, [
    bookingTimeline,
    isLoading,
    handleShowReservationInfo,
    reservation,
    upcomingReservationsList,
    ongoingReservationsList,
    pastReservationsList,
  ]);

  return (
    <IonDesktopContentColor ref={refMenu}>
      <IonGrid className={styles.grid}>
        <IonRow>
          <IonCol className={styles["grid-left-col"]}>
            <IonRow style={{ paddingTop: "5vh" }}>
              <RoomSelectionSliderWithTitle
                {...{
                  ref: refSlider,
                  setRoom,
                  slider_id: slider_id_hotel_rooms_reserves,
                  rooms: roomsWithReservations,
                }}
              />
            </IonRow>
            <IonRow>
              <IonCol>
                <SegmentedFilter
                  filters={options}
                  value={bookingTimeline}
                  onChange={setBookingTimeline}
                />
              </IonCol>
            </IonRow>

            <IonRow style={style1}>
              {listProps && <List {...listProps} />}
            </IonRow>
          </IonCol>
          <DedsktopPageColSeparator />
          <IonCol
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <BookingInfoHeader ref={refBookingHeader} {...{ reservation }} />
            <BookingInfoBody
              ref={refBookingBody}
              {...{ reservation, setReservation }}
            />
            {!reservation && (
              <IonRow>
                <IonCol>
                  <Add
                    ref={refAddBooking}
                    onClickCode={handleShowReservationInfo}
                  />
                </IonCol>
              </IonRow>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonDesktopContentColor>
  );
};

interface iBookingInfoHeaderProps {
  reservation: iReservation | null;
}

const BookingInfoHeader = forwardRef<any, iBookingInfoHeaderProps>(
  ({ reservation }, ref) => {
    return (
      <div style={{ paddingTop: "5vh" }}>
        <IonRow ref={ref}>
          <IonCol>
            {reservation && (
              <Chivo22 centeredText text={`Room ${reservation.roomNum}`} />
            )}
          </IonCol>
          <IonCol>
            <Chivo24 centeredText text="Booking Info" color="secondary" />
          </IonCol>
          <IonCol>
            {reservation && (
              <Chivo22 centeredText text={`${reservation.code}`} />
            )}
          </IonCol>
        </IonRow>
      </div>
    );
  }
);

interface iBookingInfoBodyProps {
  reservation: iReservation | null;
  setReservation(reservation: iReservation | null): void;
}

const BookingInfoBody = forwardRef<any, iBookingInfoBodyProps>(
  ({ reservation, setReservation }, ref) => {
    return (
      <IonRow>
        <IonCol>
          {reservation ? (
            <div ref={ref}>
              <BookingInfo
                {...{
                  reservation,
                  onDelete: () => setReservation(null),
                }}
              />
            </div>
          ) : (
            <Text16
              centeredText
              text="  Select a booking to see more information"
            />
          )}
        </IonCol>
      </IonRow>
    );
  }
);

interface iBookingInfoProps {
  reservation: iReservation;
  onDelete(): void;
}

const BookingInfo: React.FC<iBookingInfoProps> = ({
  reservation,
  onDelete,
}) => {
  const { present } = useIonAlertFunctions();
  const { openModal } = useOpenModal();
  const [email, setEmail] = useState("");
  // const { isLoading } = useManagerReservationsState();
  const { setError, sendReservationCode, deleteReservation } =
    useManagerReservationsDispatch();
  const { getLoading, setLoading } = useLoading();

  // const { orderAndSetReservations } = useManager();

  const handleSendEmail = async (): Promise<void> => {
    setLoading(true);
    sendReservationCode(reservation.code, [email])
      .then((res) => present(res))
      .catch((err) => present(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendCode = () => {
    if (email) handleSendEmail();
  };

  const handleDeleteBooking = async () => {
    setLoading(true);
    try {
      await deleteReservation(reservation._id);
    } catch (err) {
      setError(err as string);
    } finally {
      setLoading(false);
    }
  };

  const renderRow = (text: string, value: any): JSX.Element => {
    return (
      <IonRow style={{ margin: "0 2vw" }}>
        <IonCol style={{ display: "flex", justifyContent: "space-between" }}>
          <Text16 {...{ text }} />
          <Text16 {...{ text: value }} />
        </IonCol>
      </IonRow>
    );
  };
  const { endAt, startAt, roomNum, numP, Footprint, Energy, Water } =
    reservation;

  return (
    <Fragment>
      <WarningModal onConfirm={handleDeleteBooking} />
      <IonLoading isOpen={getLoading()} />

      {renderRow("Check-in Date", epochToDate(startAt))}
      {renderRow("Check-out Date", epochToDate(endAt))}
      {renderRow("Room", roomNum)}
      {renderRow("Number of people", numP)}
      {moment(startAt).isBefore(moment()) && (
        <Fragment>
          {renderRow("Energy", convertUnitEnergy(Energy))}
          {renderRow("Water", convertUnitVolume(Water))}
          {renderRow("Footprint", convertUnitMass(Footprint))}
        </Fragment>
      )}
      <IonRow>
        <IonCol style={{ margin: "1vh 10vw" }}>
          <RoundInput
            value={email}
            onChange={setEmail}
            placeholder="Enter e-mail here"
            error={false}
          />
        </IonCol>
      </IonRow>
      <IonRow style={{ margin: "0 7vw" }}>
        <IonCol>
          <TwoSlimButtons
            onGreen={handleSendCode}
            green_text="Send Code"
            onRed={() => openModal(modal_id_warning)}
            red_text="Delete Booking"
            disable_green={getLoading()}
            disable_red={getLoading()}
          />
        </IonCol>
      </IonRow>
    </Fragment>
  );
};

export default ReservesDesktop;
