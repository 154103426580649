import {
  IonCol,
  IonIcon,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React from "react";

import _ from "lodash";

import big_plus from "../../img/big_plus.svg";
import { Text14 } from "../Text/Montserrat";

import styles from "./styles.module.css";

interface iTwoSegmentedFiltersWithMiddleButtonProps {
  value: number;
  onChange(index: number): void;
  filters: [string, string];
  icon?: string;
  onClickIcon(): void;
  className?: string;
  [key: string]: any;
}

const TwoSegmentedFiltersWithMiddleButton: React.FC<
  iTwoSegmentedFiltersWithMiddleButtonProps
> = ({
  value,
  onChange,
  filters,
  icon = big_plus,
  onClickIcon,
  className,
  ...rest
}) => {
  const getColor = (index: number): string =>
    `var(--ion-color-secondary${index === value ? "" : "_dim"}`;

  const setSelectedSegment = (number: number, checked: boolean) => {
    try {
      const segment_button = document.getElementById(
        `seg_${filters[number]}`
      ) as HTMLIonSegmentButtonElement;

      if (checked) {
        segment_button.click();
      } else {
        segment_button.setAttribute("aria-selected", "false");

        const c = segment_button?.getAttribute("class")?.split(" ");
        if (c) {
          const i1 = _.indexOf(c, "segment-button-checked");
          const i2 = _.indexOf(c, "segment-button-after-checked");

          if (i1 >= 0) delete c[i1];
          if (i2 >= 0) delete c[i2];

          segment_button?.setAttribute("class", c.join(" "));
        }
      }
    } catch (err) {}
  };

  const handleClickIcon = () => {
    setTimeout(() => {
      onClickIcon();
      setSelectedSegment(value, true);
    }, 100);
  };

  return (
    <IonRow className={`${styles.segments} ${className}`} {...rest}>
      <IonCol>
        <IonSegment
          value={filters[value as number]}
          onIonChange={(e) => {
            if (e.detail.value) {
              setSelectedSegment(value === 1 ? 1 : 0, false);
              onChange(e.detail.value ? filters.indexOf(e.detail.value) : 0);
            }
          }}
        >
          <Segment filter={filters[0]} color={getColor(0)} />

          <button
            className={styles.button}
            // fill="clear"
            onClick={handleClickIcon}
          >
            <IonIcon src={big_plus} color="secondary" />
          </button>

          <Segment filter={filters[1]} color={getColor(1)} />
        </IonSegment>
      </IonCol>
    </IonRow>
  );
};

interface iSegmentProps {
  filter: string;
  color: string;
}

const Segment: React.FC<iSegmentProps> = ({ filter, color }) => {
  return (
    <IonSegmentButton value={filter} id={`seg_${filter}`}>
      <Text14
        text={filter}
        bold
        style={{
          color,
        }}
      />
    </IonSegmentButton>
  );
};

export default TwoSegmentedFiltersWithMiddleButton;
