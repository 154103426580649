import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import React from "react";
import {
  REWARDS_SECTOR_INFO,
  tRewardSector,
} from "../../interfaces/models/reward";
import { Text10 } from "../Text/Montserrat";

import styles from "./styles.module.css";

interface iRewardSectorCardProps {
  onClick?(): void;
  sector: tRewardSector;
  card_size: number;
  icon_size: number;
  text_size?: number;
}

const RewardSectorCard: React.FC<iRewardSectorCardProps> = ({
  sector,
  onClick = () => {},
  card_size,
  icon_size,
  text_size,
}) => {
  const { card_color, textColor, icon } = REWARDS_SECTOR_INFO[sector];

  return (
    <IonCard
      mode="ios"
      button
      className={styles.card}
      onClick={onClick}
      style={{
        "--background": `${card_color}`,
        width: `${card_size}vw`,
        height: `${card_size}vw`,
      }}
    >
      <IonCardContent>
        <IonIcon src={icon} style={{ fontSize: `${icon_size}vw` }} />
        <Text10
          color={textColor}
          text={sector}
          style={text_size ? { fontSize: `${text_size}vw` } : {}}
        />
      </IonCardContent>
    </IonCard>
  );
};

export default RewardSectorCard;
