export type tMeasure = "te" | "tw" | "cw" | "hw" | "gw" | "el" | "es" | "ac";

export const MEASURE_TYPES: Record<string, tMeasure> = {
  TW: "tw",
  TE: "te",
  CW: "cw",
  HW: "hw",
  GW: "gw",
  EL: "el",
  ES: "es",
  AC: "ac",
};

const { TW, TE, CW, HW, GW, EL, ES, AC } = MEASURE_TYPES;

export type tSpendingTypes = "Waste" | "Energy" | "Water" | "Footprint";
export type tSpending = Record<tSpendingTypes, number>;

export type tMeasureWater = "hw" | "cw" | "gw";
export type tMeasureTotalWater = "tw";
export type tMeasureEnergy = "es" | "el" | "ac";
export type tMeasureTotalEnergy = "te";
export type tMeasureFootprint = tMeasureTotalEnergy | tMeasureTotalWater;

export const data_types_footprint: tMeasureFootprint[] = [
  TE as tMeasureFootprint,
  TW as tMeasureFootprint,
];

export const data_types: tMeasure[] = [TE, TW, CW, HW, GW, EL, ES, AC];
export const data_types_water: tMeasureWater[] = [
  HW as tMeasureWater,
  CW as tMeasureWater,
  GW as tMeasureWater,
];
export const data_types_total_water: tMeasureTotalWater =
  TW as tMeasureTotalWater;
export const data_types_energy: tMeasureEnergy[] = [
  ES as tMeasureEnergy,
  EL as tMeasureEnergy,
  AC as tMeasureEnergy,
];
export const dataTypesTotalEnergy: tMeasureTotalEnergy =
  TE as tMeasureTotalEnergy;

export interface iSensorData1 extends Partial<Record<tMeasure, number>> {} // from api
export interface iSensorData2 extends Partial<Record<tMeasure, number[]>> {}

export const defaultMeasureObject: Record<tMeasure, number> = {
  tw: 0,
  te: 0,
  cw: 0,
  hw: 0,
  gw: 0,
  es: 0,
  el: 0,
  ac: 0,
};
