import React, { useState } from "react";

import HotelCard from "../../../../components/HotelCard/HotelCard";

import "./reservations_list.css";
import strings from "../../../../localization/localization";
import TwoSegmentedFiltersWithMiddleButton from "../../../../components/SegmentedFilterWithMiddleButton";
import { modal_id_add_booking } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import { isString } from "lodash";
import useUserReservationsState from "../../../../context/User/UserReservationsContext/hooks/useUserReservationsState";

interface iReservationsListProps {}

const ReservationsList: React.FC<iReservationsListProps> = () => {
  const { pastReservations, upcomingReservations } = useUserReservationsState();
  const [option, setOption] = useState(0);
  const { openModal } = useOpenModal();

  const OPTIONS: [string, string] = [strings.past, strings.upcoming];

  const renderReservationsList = (): JSX.Element[] => {
    const reservations = option === 0 ? pastReservations : upcomingReservations;

    return reservations
      .filter((res) => {
        return !isString(res.hotel);
      })
      .map((res, i) => {
        return <HotelCard reservation={res} with_data={option === 0} key={i} />;
      });
  };

  const handleClickOption = (op: number) => {
    if (op !== option) setOption(op);
  };

  const handleAddReservation = () => {
    openModal(modal_id_add_booking);
  };

  return (
    <div color="light" className="reservations_list-list">
      <TwoSegmentedFiltersWithMiddleButton
        value={option}
        onChange={handleClickOption}
        filters={OPTIONS}
        onClickIcon={handleAddReservation}
      />
      {renderReservationsList()}
    </div>
  );
};

export default ReservationsList;
