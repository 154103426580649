import { useState } from "react";
import { iViewSelectionButton } from "../../../components/ViewSelectionButtons";
import strings from "../../../localization/localization";

import competitions_icon from "../../../img/competitions_icon.svg";
import useManagerRewardsDispatch from "../../../context/Manager/RewardsContext/hooks/managerRewardsDispatch";

const COMP_INDEX = 0;
const CHALL_INDEX = 1;

export const useGamesViewFilter = () => {
  const [view, setView] = useState(COMP_INDEX);
  const [filter, setFilter] = useState(0);
  const { loadRewardsFromAPI } = useManagerRewardsDispatch();

  const FILTERS: [string, string] = [strings.ongoing, strings.past];

  const viewSelectionButtons: iViewSelectionButton[] = [
    // { text: "Challenges", onClick: () => setView(0), icon: challenges_icon },
    {
      text: "Competitions",
      onClick: () => setView(COMP_INDEX),
      icon: competitions_icon,
    },
  ];

  const handleClickAdd = (
    onAddCompetition: () => void = () => {},
    onAddChallenge: () => void = () => {}
  ) => {
    if (view === CHALL_INDEX) {
      onAddChallenge();
    } else if (view === COMP_INDEX) {
      onAddCompetition();
    }
  };

  return {
    view,
    setView,
    filter,
    setFilter,
    getRewardsFromApi: loadRewardsFromAPI,
    FILTERS,
    viewSelectionButtons,
    handleClickAdd,
  };
};
