import { IonButton, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { helpCircleOutline } from "ionicons/icons";
import { useState } from "react";
import { Chivo20 } from "../Text/Chivo";
import styles from "./styles.module.css";

interface iHeaderWithInfoProps {
  title: string;
  info?: string;
}

const HeaderWithInfo: React.FC<iHeaderWithInfoProps> = ({ title, info }) => {
  const [display_info, setDisplayInfo] = useState<boolean>(false);

  const handleClickInfo = () => {
    setDisplayInfo((prev) => !prev);
  };

  return (
    <>
      <IonRow className={`${styles.header} ${styles.row}`}>
        <IonCol size="2"></IonCol>
        <IonCol>
          <Chivo20 text={title} bold centeredText color="secondary" />
        </IonCol>
        <IonCol size="2">
          <IonButton
            onClick={handleClickInfo}
            fill="clear"
            style={{ visibility: info ? "visible" : "hidden" }}
          >
            <IonIcon color="secondary" src={helpCircleOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
      {display_info && info && (
        <IonRow className={styles.row}>
          <IonCol>
            <IonText color="secondary_dim">
              <p className="montserrat">
                <small>{info}</small>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default HeaderWithInfo;
