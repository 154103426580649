import { IonContent, IonModal } from "@ionic/react";
import React from "react";
import { modal_id_help } from "../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import strings from "../../localization/localization";
import MenuModalHeader from "../MenuModalHeader";
import Content from "./Content/Content";

import styles from "./styles.module.css";

interface iHelpProps {}

const Help: React.FC<iHelpProps> = () => {
  const { isOpen, closeModal } = useOpenModal();

  const onExitModal = () => {
    closeModal(modal_id_help);
  };

  return (
    <IonModal isOpen={isOpen(modal_id_help)}>
      {isOpen(modal_id_help) && (
        <>
          <MenuModalHeader title={strings.help_menu} onExit={onExitModal} />
          <Content />
        </>
      )}
    </IonModal>
  );
};

export default Help;
