import useAxios from "axios-hooks";
import React from "react";
import { modal_id_edit_username } from "../../../context/OpenModalContext/modal_ids";
import useUserAccountState from "../../../context/User/UserContext/hooks/Account/useUserAccountState";
import EnterTextModal from "../../EnterTextModal/EnterTextModal";

interface iEditUsernameModalProps {}

const EditUsernameModal: React.FC<iEditUsernameModalProps> = () => {
  const [, executeChangeUsername] = useAxios(
    { url: "/users/user/", method: "PUT" },
    { manual: true }
  );
  const {
    user: { username },
  } = useUserAccountState();

  const handleChangeUsername = async (
    values: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    const newUsername = values[0];

    if (newUsername === username) return cb(false, "");

    try {
      await executeChangeUsername({ data: { username: newUsername } });

      return cb(false, "Username changed");
    } catch (err) {
      //@ts-ignore
      console.log(`err.response`, err.response);
      cb(
        true,
        //@ts-ignore
        ((err.response || {}).data || {}).message || "Something went wrong"
      );
    }
  };

  return (
    <EnterTextModal
      title="Edit username"
      input_placeholder={["Enter new username"]}
      onConfirm={handleChangeUsername}
      modal_id={modal_id_edit_username}
    />
  );
};

export default EditUsernameModal;
