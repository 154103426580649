import axios from "axios";
import React, { useEffect, useReducer } from "react";
import { nUserContext } from "./interfaces";

const LS_ACCOUNT = "__usr-acc__";

const UserAccountDispatchContext = React.createContext<
  nUserContext.tAccountDispatchContext | undefined
>(undefined);
UserAccountDispatchContext.displayName = "UserAccountDispatchContext";
const UserAccountStateContext = React.createContext<
  nUserContext.tAccountStateContext | undefined
>(undefined);
UserAccountStateContext.displayName = "UserAccountStateContext";

const accountInitialState: nUserContext.iState = {
  data: {
    user: {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      country: "",
      phoneNumber: "",
      total_points: 0,
      points: 0,
      nFriends: 0,
      friends: [],
      friendRequestsReceived: [],
      friendRequestsSent: [],
      achievements: [],
      rewards: [],
      images: [],
      footprint: 0,
      referralCode: "",
      reservations: [],
    },
  },
  error: null,
  status: "idle",
};

const accountReducer = (
  state: nUserContext.iState,
  action: nUserContext.tAccountAction
): nUserContext.iState => {
  switch (action.type) {
    case "resolved":
      localStorage.setItem(LS_ACCOUNT, JSON.stringify(action.data));
      localStorage.removeItem("account");
      return { ...state, data: action.data, status: "resolved", error: null };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    default:
      return { ...state };
  }
};

const UserContextProvider: React.FC<nUserContext.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [
    nUserContext.iState,
    React.Dispatch<nUserContext.tAccountAction>
  ] = useReducer(accountReducer, accountInitialState);

  useEffect(() => {
    const loadAccountFromAPI = () => {
      axios
        .get("/users/user")
        .then((res) => {
          const {
            data: { user },
          } = res;
          dispatch({ type: "resolved", data: { user } });
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: "rejected", error: error.response?.message });
        });
    };

    dispatch({ type: "pending" });
    const user = localStorage.getItem(LS_ACCOUNT);

    if (!user) return loadAccountFromAPI();
    try {
      const parsed = JSON.parse(user);
      dispatch({ type: "resolved", data: parsed });
    } catch (err) {
      localStorage.removeItem(LS_ACCOUNT);
      loadAccountFromAPI();
    }
  }, []);

  return (
    <UserAccountStateContext.Provider value={state}>
      <UserAccountDispatchContext.Provider value={dispatch}>
        {children}
      </UserAccountDispatchContext.Provider>
    </UserAccountStateContext.Provider>
  );
};

export default UserContextProvider;
export { UserAccountDispatchContext, UserAccountStateContext };
