import { IonCol, IonGrid, IonRow } from "@ionic/react";
import moment from "moment";
import React, { Fragment } from "react";
import Donut from "../../../../components/ChartJsGraphs/Donut/Donut";
import DonutLabels from "../../../../components/ChartJsGraphs/Donut/DonutLabels";
import IonDesktopContentColor from "../../../../components/IonDesktopContentColor";
import { RoomSelectionSliderWithTitle } from "../../../../components/RoomSelectionSlider";
import { Chivo24, Chivo32 } from "../../../../components/Text/Chivo";
import { Text16, Text22 } from "../../../../components/Text/Montserrat";
import {
  iDonutGraph,
  tDataLabel,
  tGraph,
} from "../../../../context/DataModalContext/interfaces";
import { useHotelStays } from "../../../../context/HotelStaysContext";
import useManagerAccountState from "../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import { useTheme } from "../../../../context/ThemeContext";
import {
  iSensorData1,
  tMeasure,
} from "../../../../interfaces/models/sensorData";
import {
  calculateDonutGraphData1,
  calculateDonutGraphData2Money,
} from "../../../../utils/graph";
import { slider_id_hotel_stays_rooms_stays } from "../../../../utils/sliders";
import { OccupiedSlider } from "../Filters/Occupied";
import Timespan from "../Filters/Timespan";
import { useHotelStaysFunctions } from "../hooks";
import Chart from "react-chartjs-2";
import { MONTHS } from "../../../../utils/calendar";
import {
  energyColorsHomologue,
  GRAY,
  waterColorsHomologue,
} from "../../../../utils/colors";
import { sum } from "lodash";
import axios from "axios";
// import QueryButton from "../QueryButton";

interface iDesktopProps {}

const Desktop: React.FC<iDesktopProps> = () => {
  const { getBackgroundColor } = useTheme();
  const { setRoom, getTimespan, getTimespanIndex } = useHotelStays();
  const { currentHotel, hotelRooms } = useManagerAccountState();
  const { energy2currency, water2currency } = currentHotel;
  const { getCurrentMeasures } = useHotelStaysFunctions();

  // @ts-ignore
  const [donutGraphData /* barGraphData*/, , te, tw]: [
    tGraph<iDonutGraph> | null,
    tGraph<iDonutGraph> | null,
    number,
    number
  ] = /* useMemo( */ (() => {
    try {
      const handleData = (sensorData: iSensorData1) => {
        const newSensorData = { ...sensorData };
        const { water2footprint, energy2footprint } = currentHotel;

        const donutGraphData = calculateDonutGraphData1(
          newSensorData,
          energy2footprint,
          water2footprint
        );
        return donutGraphData;
      };

      const measures = getCurrentMeasures();
      if (!measures) {
        return [null, null, 0, 0];
      }
      const donutGraphData = handleData(measures);

      return [
        donutGraphData as tGraph<iDonutGraph>,
        null,
        measures["te"] ||
          (measures["es"] || 0) + (measures["el"] || 0) + (measures["ac"] || 0),
        measures["tw"] ||
          (measures["hw"] || 0) + (measures["gw"] || 0) + (measures["cw"] || 0),
      ];
    } catch (e) {
      return [null, null, 0, 0];
    }
  })(); /* , []); */

  const renderGraph = (
    donut_data: iDonutGraph | null,
    with_labels: boolean,
    title?: string,
    icon?: string | null,
    custom_labels?: tDataLabel[]
  ) => {
    return (
      <IonCol size="3">
        <div className="flexr-center" style={{ marginBottom: "2vh" }}>
          {title && <Chivo24 text={title} />}
        </div>
        {donut_data && donut_data.labels.length ? (
          <Fragment>
            <Donut data={donut_data} width={130} />
            {with_labels && (
              <DonutLabels labels={custom_labels || donut_data.labels} />
            )}
          </Fragment>
        ) : (
          <Text22
            centeredText
            text="No data in this period of time"
            color="danger"
            mt={3}
          />
        )}
      </IonCol>
    );
  };

  const getHomologue = (measure: tMeasure, label: string, colors: string[]) => {
    const currentYear = moment().year();
    const graphData: Record<string, any> = {
      labels: [],
      datasets: [],
    };
    const datasets: Record<string, any>[] = [];
    const meanAuxiliary: number[][] = [];
    const mean: number[] = [];
    if (getTimespan() === 0) {
      graphData.labels = [...MONTHS];

      let j = 0;
      for (const { months, year } of currentHotel.measures) {
        if (year === currentYear - 1 || year === currentYear) {
          const data: number[] = [];

          let i = 0;
          for (const { month, rooms } of months) {
            let factor = 1;

            const mom = moment();
            if (year === mom.year()) {
              if (month > mom.month()) break;
              if (month === mom.month()) {
                factor = mom.date() / moment().endOf("month").date();
              }
            }

            let sum: number = 0;
            rooms.forEach((room) => {
              sum += room.data.overall[measure] || 0;
            });
            data.push(sum * factor);
            if (meanAuxiliary.length <= i) {
              meanAuxiliary.push([data[data.length - 1]]);
            } else {
              meanAuxiliary[i].push(data[data.length - 1]);
            }
            i++;
          }

          const rgba = colors[j++];

          datasets.push({
            type: "bar",
            backgroundColor: rgba,
            borderColor: rgba,
            label: `${label} ${year}`,
            data: data,
            tension: 0,
          });
        }
      }
    } else if (getTimespan() === 1) {
      const week: Record<number, string> = {
        0: "Sun",
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
      };
      const month = getTimespanIndex() % 12;
      const daysInMonth = moment().set("month", month).endOf("month").date();
      const labels: string[] = [];
      for (let i = 1; i <= daysInMonth; i++) {
        labels.push(
          `${i}-${week[moment().set("month", month).set({ date: i }).day()]}`
        );
      }
      graphData.labels = [...labels];
      // graphData.labels = ["1st week", "2nd week", "3rd week", "4th week"];

      let j = 0;
      currentHotel.measures.forEach(({ months, year }) => {
        if (year === currentYear - 1 || year === currentYear) {
          const data: number[] = [];

          for (let i = 0; i < graphData.labels.length; i++) {
            data.push(Math.round(Math.random() * 10000) / 100);
            if (meanAuxiliary.length <= i) {
              meanAuxiliary.push([data[data.length - 1]]);
            } else {
              meanAuxiliary[i].push(data[data.length - 1]);
            }
          }
          console.log(label, "colors[j]", colors[j]);
          const color = colors[j++];

          datasets.push({
            type: "bar",
            backgroundColor: color,
            borderColor: color,
            label: `${label} ${MONTHS[month]}/${year}`,
            data: data,
            tension: 0,
          });
        }
      });
    }

    meanAuxiliary.forEach((month) => {
      mean.push(Math.round((sum(month) / month.length) * 100) / 100);
    });
    datasets.unshift({
      type: "line",
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderColor: GRAY,
      label: `${label} mean`,
      data: mean,
      tension: 0,
    });

    graphData.datasets = [...datasets];
    const config = {
      type: "bar",
      data: graphData,
      options: {
        scales: {
          xAxes: [
            {
              // stacked: true,
              gridLines: { display: false },
            },
          ],
          yAxes: [
            {
              // stacked: true,
              gridLines: { display: true },
              // ticks: { display: false },
              // beginAtZero: true,
            },
          ],
        },
      },
    };

    return config;
  };

  const renderHomologueGraphs = () => {
    const info = [
      { measure: "tw", label: "Water", colors: waterColorsHomologue },
      { measure: "te", label: "Energy", colors: energyColorsHomologue },
    ];

    return info.map(({ measure, label, colors }) => (
      <div style={{ padding: "0 10vw", marginTop: "5vh" }}>
        <div
          style={{
            border: `1px solid var(--ion-color-secondary)`,
            borderRadius: 10,
          }}
        >
          <IonRow>
            <IonCol className="flexc-center">
              <Chivo32 text={label} />
            </IonCol>
          </IonRow>
          <IonRow style={{ marginTop: "5vh" }}>
            <IonCol>
              <Chart
                {...getHomologue(measure as tMeasure, label, colors)}
                height={100}
                type="bar"
              />
            </IonCol>
          </IonRow>
        </div>
      </div>
    ));
  };

  const config = getHomologue("tw", "Water", waterColorsHomologue);
  console.log("config", config);

  const marginBottomSliderTitle = 1;

  const handle = () => {
    axios
      .get(`/sensor-data/hotel/${currentHotel._id}`, {
        params: {
          from: moment().set("month", 11).startOf("month").toISOString(),
          to: moment().set("month", 11).set("date", 20).toISOString(),
          room: "12",
          binUnit: "day",
          binValue: 1,
          measures: ["te", "tw"],
        },
      })
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <IonDesktopContentColor>
      <IonGrid>
        {/* <IonRow style={{ marginTop: "3vh" }}>
          <IonCol className="flexc-center">
            <button
              style={{ color: "black", background: "red" }}
              onClick={handle}
            >
              CLICK
            </button>
            <Chivo32 text={currentHotel.nameToDisplay} />
          </IonCol>
        </IonRow> */}
        <IonRow style={{ marginTop: "3vh" }}>
          <IonCol className="flexc-center">
            <Text16 text="Room Status" mb={marginBottomSliderTitle} />
            <OccupiedSlider />
          </IonCol>
          <IonCol className="flexc-center">
            <Text16 text="Time Selection" mb={marginBottomSliderTitle} />
            <Timespan />
          </IonCol>
          <IonCol className="flexc-center">
            <RoomSelectionSliderWithTitle
              {...{
                setRoom,
                rooms: hotelRooms,
                slider_id: slider_id_hotel_stays_rooms_stays,
                margin_bottom: marginBottomSliderTitle,
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "5vh" }}>
          {renderGraph(
            donutGraphData ? donutGraphData["Energy"] : null,
            true,
            "Energy"
          )}
          {renderGraph(
            donutGraphData ? donutGraphData["Water"] : null,
            true,
            "Water"
          )}
          {renderGraph(
            donutGraphData
              ? calculateDonutGraphData2Money(
                  { te, tw },
                  energy2currency,
                  water2currency,
                  getBackgroundColor()
                )
              : null,
            true,
            "Costs",
            null,
            donutGraphData ? donutGraphData["Footprint"].labels : []
          )}
          {renderGraph(
            donutGraphData ? donutGraphData["Footprint"] : null,
            true,
            "Footprint"
          )}
        </IonRow>
        {renderHomologueGraphs()}
      </IonGrid>
    </IonDesktopContentColor>
  );
};

export default Desktop;
