import React, { useReducer } from "react";

import styles from "./signup.module.css";
import IonContentColor from "../../components/IonContentColor";
import useSignup from "./hooks";
import Form from "../../components/Form/Form";
import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Chivo18, Chivo24 } from "../../components/Text/Chivo";
import { NonAuthHeader } from "../../components/Header/Header";
import SlimButton from "../../components/SlimButton";
import { useAuth } from "../../context/AuthContext/AuthContext";
import { has } from "lodash";

interface iState {
  data: Record<string, any>;
  error: null | string;
  status: "idle" | "pending" | "resolved" | "rejected";
}

type tAction = {
  type: "tem de ser assim, porque me está a dar erro quando separo os types";
  data?: Record<string, any>;
  error?: string | null;
};

const submissionInitialState: iState = {
  data: {},
  error: null,
  status: "idle",
};

const submissionReducer = (state: iState, action: tAction): iState => {
  const { type } = action;
  switch (type) {
    case "tem de ser assim, porque me está a dar erro quando separo os types": {
      return {
        ...state,
        ...(action.data ? { status: "resolved", data: action.data } : {}),
        ...(action.error ? { status: "rejected", error: action.error } : {}),
      };
    }
    // case "resolved": {
    //   return { ...state, status: "resolved", error: null };
    // }
    // case "rejected":
    //   // const a = action.error;
    //   return { ...state, status: "rejected" };

    default:
      return { ...state };
  }
};

const WrappedSignup: React.FC = () => {
  const { onLogin } = useAuth();
  const [submissionState, submissionDispatch] = useReducer(
    submissionReducer,
    submissionInitialState
  );

  const setError = (error: string) => {
    submissionDispatch({
      type: "tem de ser assim, porque me está a dar erro quando separo os types",
      error,
    });
  };

  const { error } = submissionState;

  const {
    formInputs,
    handleChange,
    buttonProps,
    validateData,
    SignupPromise,
    state: data,
  } = useSignup();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const error = validateData();
    if (error) {
      return setError(error);
    }
    SignupPromise(data)
      .then((res) => {
        console.log("res.data", res.data);
        submissionDispatch({
          type: "tem de ser assim, porque me está a dar erro quando separo os types",
          data: { token: res.data.token, account: res.data.account },
          error: null,
        });
      })
      .catch((err) => {
        setError(
          has(err, "response.data.message")
            ? err.response.data.message
            : "Something went wrong"
        );
        console.log("err.response", err.response);
      });
  };

  if (submissionState.status === "resolved") {
    const handleClickStartUsingApp = () => {
      onLogin(submissionState.data.token, false, submissionState.data.account);
    };
    const { referralCode } = submissionState.data.account;
    return (
      <IonContent color="primary">
        <IonGrid className={styles["greeting-grid"]}>
          <IonRow className={styles["greeting-thankyou_row"]}>
            <IonCol className="flexc-center">
              <Chivo24
                textAlign="center"
                centeredText
                color="light"
                text={["Thank you", "for signing up"]}
              />
            </IonCol>
          </IonRow>
          <IonRow className={styles["greeting-points_row"]}>
            <IonCol className="flexc-center">
              <Chivo24
                centeredText
                textAlign="center"
                color="light"
                text="100 points (5€)"
              />
              <Chivo18
                textAlign="center"
                centeredText
                color="light"
                text="will be credited to you"
                bold={false}
              />
            </IonCol>
          </IonRow>
          <IonRow className={styles["greeting-button_row"]}>
            <IonCol>
              <SlimButton
                onClick={handleClickStartUsingApp}
                buttonStyles={{ height: "60px", width: "60%" }}
                color="secondary"
                text="Star using app"
              />
            </IonCol>
          </IonRow>
          <IonRow className={styles["greeting-code_row"]}>
            <IonCol className="flexc-center">
              <Chivo18
                textAlign="center"
                centeredText
                color="light"
                text="your referral code is"
                bold={false}
              />
              <Chivo24
                centeredText
                textAlign="center"
                color="light"
                text={`${referralCode}`}
              />
            </IonCol>
          </IonRow>
          <IonRow className={styles["greeting-description_row"]}>
            <IonCol className="flexc-center">
              <Chivo18
                textAlign="center"
                color="light"
                text={[
                  "for each friend you refer",
                  "you and your friend will both",
                  "get 100 points",
                ]}
                bold={false}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    );
  }
  console.log("error", error);
  return (
    <>
      <NonAuthHeader />
      <IonContentColor id={styles["signup-page"]} className="login_singup-page">
        <Form
          color="primary_dim"
          title="Create Account"
          formData={formInputs}
          onChange={handleChange}
          onSubmit={handleSubmit}
          buttonProps={buttonProps}
          error={error}
        />
      </IonContentColor>
    </>
  );
};

const Signup: React.FC = () => {
  return (
    <IonPage>
      <WrappedSignup />
    </IonPage>
  );
};

export default Signup;
