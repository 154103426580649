const languages = {
  default_language: "Preferência do equipamento",
  selected_language: "Idioma seleccionado",
  language_pt: "Português",
  language_en: "Inglês",
  language_fr: "Francês",
  language_es: "Espanhol",
};

export default languages;
