import { IonIcon, IonText } from "@ionic/react";
import React from "react";

import location_light from "../../img/location-light.svg";
import location_dark from "../../img/location-dark.svg";

import styles from "./address.module.css";
import { iHotelLocation } from "../../interfaces/models/hotel";
import { openHotelLocationOnMaps } from "../../utils/hotels/hotel";

export interface iAddressProps {
  location: iHotelLocation;
  color?: "light" | "secondary";
  weight?: "normal" | "bold";
  className?: string;
  fontSize?: string;
  gotoMaps?: boolean;
}

const Address: React.FC<iAddressProps> = ({
  location,
  color = "light",
  weight,
  className,
  fontSize,
  gotoMaps = true,
}) => {
  const { address } = location;

  const handleClick = () => {
    openHotelLocationOnMaps(location);
  };

  return (
    <div
      className={styles.container + " " + className}
      onClick={gotoMaps ? handleClick : () => {}}
    >
      <IonIcon
        color={color}
        src={color === "light" ? location_light : location_dark}
      />
      <IonText color={color}>
        <p
          style={{
            fontWeight: weight || "normal",
            ...(fontSize ? { fontSize } : {}),
          }}
          className="montserrat"
        >
          {address.substring(0, 40)}
        </p>
      </IonText>
    </div>
  );
};

export default Address;
