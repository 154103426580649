import React, { useCallback, useEffect, useState } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useClickOutside({
  refs,
  names = [],
  initValue = false,
}: {
  refs: React.MutableRefObject<any>[];
  names?: string[];
  initValue?: boolean;
}) {
  //   const ref = useRef<any>(null);
  const [clickedOutside, setClickedOutside] = useState(initValue);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      let outside = 0;
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i];

        if (
          !ref.current ||
          (ref.current && !ref.current.contains(event.target))
        ) {
          // setClickedOutside((prev) => !prev);
          outside++;
        } else {
          if (names && names.length >= i + 1) {
            console.log(`CLICKED INSIDE REF ${names[i]}`);
          }
        }
      }
      if (outside === refs.length) {
        setClickedOutside(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs]);

  const reset = useCallback(() => setClickedOutside(false), []);

  return { clickedOutside, reset };
}
