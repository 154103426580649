const stays = {
  add_booking: "Add Booking",
  no_reservations_caption:
    "You don’t have any reservation at the moment. If you have a booking you can add it here.",
  explore_stays_caption:
    "Or explore sustainable stays and start planning your next neutral trip.",
  explore_stays_button: "Explore sustainable stays",
  past: "Past",
  upcoming: "Upcoming",
  ongoing: "Ongoing",
};

export default stays;
