import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React from "react";

import "./input.css";

export interface iInputData {
  name: string;
  required?: boolean;
  type?:
    | "number"
    | "time"
    | "text"
    | "date"
    | "email"
    | "password"
    | "search"
    | "tel"
    | "url"
    | "week"
    | "month"
    | "datetime-local"
    | undefined;
  label: string;
  labelPosition?: "floating" | "fixed" | "stacked" | undefined;
  value: string;
  placeholder?: string;
  component?(): JSX.Element;
  [key: string]: any;
}

interface iInputProps extends iInputData {
  onChange(name: string, value: string): void;
  color: string;
}

const Input: React.FC<iInputProps> = ({
  color,
  name,
  required,
  type = "text",
  label,
  placeholder = "",
  labelPosition = "floating",
  value,
  onChange,
  component,
  ...rest
}) => {
  return (
    <>
      <IonItem
        color="light"
        className="input-item"
        style={{ "--border-color": `var(--ion-color-${color})` }}
      >
        <IonLabel color={color} position={labelPosition}>
          {/* <Text16 text={label} color="primary" bold={false} /> */}
          <p className="montserrat">{label}</p>
        </IonLabel>
        <IonInput
          {...{
            placeholder,
            color,
            name,
            required: required === undefined ? false : true,
            type,
          }}
          onIonChange={(e) => {
            onChange(name, e.detail.value!);
          }}
          {...rest}
        ></IonInput>
      </IonItem>
      {component && component()}
    </>
  );
};

export default Input;
