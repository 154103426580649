import {
  IonCol,
  IonGrid,
  IonImg,
  IonLoading,
  IonRow,
  useIonActionSheet,
  useIonAlert,
} from "@ionic/react";
import React, { useMemo } from "react";
import EditButton from "../../../../components/EditButton/EditButton";
import HotelNameAddress from "../../../../components/ManagerComponents/HotelInfo/HotelNameAddress/HotelNameAddress";
import IonContentColor from "../../../../components/IonContentColor";
import { SpendingDataCardIonCol } from "../../../../components/SpendingDataCard";
import { Text12 } from "../../../../components/Text/Montserrat";
import strings from "../../../../localization/localization";
import { getHotelImage, MAX_NUM_IMAGES } from "../../../../utils/hotels/hotel";

import edit_pics from "../../../../img/edit_pics-icon.svg";

import styles from "./styles.module.css";
import SelectImageModal from "../../../../components/SelectImageModal";
import iManager from "../../../../interfaces/models/manager";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import { modal_id_select_image_hotel } from "../../../../context/OpenModalContext/modal_ids";
import useFileUploader from "../../../../components/FileUploader/hooks";
import { input_id_user_hotel_photos } from "../../../../utils/input_ids";
import { trash } from "ionicons/icons";
import axios from "axios";

import Slider from "react-slick";
import useManagerAccountDispatch from "../../../../context/Manager/AccountContext/hooks/useManagerAccountDispatch";
import useManagerAccountState from "../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerReservationsState from "../../../../context/Manager/ReservationsContext/hooks/useManagerReservationsState";

interface iMobileProps {}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  vertical: false,
  slidesToScroll: 1,
  rows: 1,
  arrow: false,
};

const Mobile: React.FC<iMobileProps> = () => {
  const { isLoading, currentHotel } = useManagerAccountState();
  const { updateAccount, setLoading } = useManagerAccountDispatch();
  const { averageSpendingPerNight } = useManagerReservationsState();
  // eslint-disable-next-line
  const [present, dismiss] = useIonActionSheet();
  const { openModal } = useOpenModal();
  const [presentAlert] = useIonAlert();

  const file_uploader = useFileUploader(
    input_id_user_hotel_photos,
    `hotel/${currentHotel._id}`
  );

  const { nameToDisplay, location, images } = currentHotel;

  const handleAddNewPhoto = (account: iManager) => {
    updateAccount(account);
    // window.location.reload();
  };

  const handleClickAddNewPhoto = () => {
    if (images.length >= MAX_NUM_IMAGES)
      return presentAlert({
        message: `Maximum number of images is ${MAX_NUM_IMAGES}`,
        buttons: ["Ok"],
      });

    openModal(modal_id_select_image_hotel);
  };

  const renderImages = useMemo(() => {
    const handleClickPhoto = (img: string) => {
      const handleDelete = () => {
        setLoading(true);
        axios
          .delete(`/images/hotel`, {
            data: { hotel_id: currentHotel._id, img },
          })
          .then((res) => {
            console.log(`res`, res);
            updateAccount(res.data.data.account);
            // window.location.reload();
          })
          .catch((err) => {
            console.log(`err`, err);
            console.log(`err.response`, err.response);
          });
      };

      present({
        buttons: [
          {
            text: "Delete",
            role: "destructive",
            icon: trash,
            handler: handleDelete,
          },
          {
            text: "Cancel",
            role: "cancel",
            cssClass: styles["action_sheet-cancel"],
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
    };

    if (images.length === 0) return <IonImg src={getHotelImage()} />;

    return (
      <Slider {...settings} className={styles.slider}>
        {[...images].map((img, i) => (
          <div key={img}>
            <IonImg
              className={styles.hotel_img}
              src={img}
              onClick={() => handleClickPhoto(img)}
            />
          </div>
        ))}
      </Slider>
    );
  }, [images, currentHotel._id, setLoading, present, updateAccount]);

  return (
    <IonContentColor>
      <IonLoading isOpen={isLoading} />
      <SelectImageModal
        title="New hotel photo"
        onConfirm={handleAddNewPhoto}
        modal_id={modal_id_select_image_hotel}
        {...file_uploader}
      />
      <IonGrid className={styles.hotel_imgs}>
        <IonRow>
          <IonCol>
            {renderImages}
            <div className={styles.edit_imgs} style={{ zIndex: 100 }}>
              <EditButton icon={edit_pics} onClick={handleClickAddNewPhoto} />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <HotelNameAddress
        {...{
          nameToDisplay,
          location,
        }}
      />
      <IonGrid>
        <IonRow className={styles.spending_title}>
          <IonCol>
            <Text12
              text={strings.manager_hotel.average_concumption_per_night}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <SpendingDataCardIonCol
            color="secondary"
            {...averageSpendingPerNight}
          />
        </IonRow>
      </IonGrid>
      {/* TODO add props: description, sustainability */}
      {/* <HotelInfoEdit  /> */}
    </IonContentColor>
  );
};

export default Mobile;
