import { IonCol, IonRow } from "@ionic/react";
import React, { Fragment } from "react";
import { iWrapperComponent } from "../../interfaces";
import InputConfined, { iInputConfinedProps } from "./InputConfined";
import { Chivo18 } from "../Text/Chivo";

import styles from "./styles.module.css";

interface iTitledComponentProps extends iWrapperComponent {
  title: string;
  titleHorizontalMargin?: string;
  centeredTitle?: boolean;
}

const TitledComponent: React.FC<iTitledComponentProps> = ({
  children,
  title,
  titleHorizontalMargin = "3vw",
  centeredTitle = false,
}) => {
  return (
    <Fragment>
      <IonRow
        className={styles.title}
        style={{ margin: `0 ${titleHorizontalMargin}` }}
      >
        <IonCol>
          <Chivo18 text={title} centeredText={centeredTitle} />
        </IonCol>
      </IonRow>
      {children}
    </Fragment>
  );
};

interface iTitledConfinedInputProps
  extends Partial<iWrapperComponent>,
    iInputConfinedProps {
  title: string;
  value: string;
  onChange(value: string): void;
  placeholder?: string;
  titleHorizontalMargin?: string;
  centeredTitle?: boolean;
  [key: string]: any;
}

export const TitledConfinedInput: React.FC<iTitledConfinedInputProps> = ({
  title,
  titleHorizontalMargin,
  centeredTitle = false,
  children,
  ...rest
}) => {
  return (
    <TitledComponent {...{ title, titleHorizontalMargin, centeredTitle }}>
      <IonRow className={styles.input}>
        <IonCol>
          <InputConfined {...rest} />
        </IonCol>
      </IonRow>
      {children}
    </TitledComponent>
  );
};

export default TitledComponent;
