import React, { useState } from "react";
import { useLocalization } from "../../context/LocalizationContext";
import { modal_id_language } from "../../context/OpenModalContext/modal_ids";
import strings, { tAvailableLanguages } from "../../localization/localization";
import MenuModal from "../MenuModal";
import Content from "./Content";

import styles from "./styles.module.css";

interface iLanguageModalProps {}

const LanguageModal: React.FC<iLanguageModalProps> = () => {
  const [state, setState] = useState(true);
  const { handleSwitchLanguage } = useLocalization();

  const onSwitchLangauge = (l: tAvailableLanguages) => {
    handleSwitchLanguage(l);
    setState(!state);
  };

  return (
    <MenuModal title={strings.language_menu} modal_id={modal_id_language}>
      <Content onSwitchLangauge={onSwitchLangauge} />
    </MenuModal>
  );
};

export default LanguageModal;
