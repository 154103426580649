import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHotelModal } from "../../../../../context/HotelModalContext/HotelModalContext";
import { modal_id_edit_about_hotel } from "../../../../../context/OpenModalContext/modal_ids";

import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";

import edit_text from "../../../../../img/edit_text-icon.svg";
import EditButton from "../../../../EditButton/EditButton";
import { Chivo } from "../../../../Text/Chivo";
import Edit from "./Edit/Edit";

// ES Modules
import parse from "html-react-parser";

import styles from "./about.module.css";
import "./markdown_styles.css";

interface iAboutProps {
  children?: React.ReactChild;
  text: string;
}

const About: React.FC<iAboutProps> = ({ children, text }) => {
  const wrapMarkdownText = (text: string): string =>
    `<div class="additional_styles_to_markdown_hotel_description">${text}</div>`;

  return (
    <IonGrid style={{ "--ion-grid-padding": 0 }}>
      <IonRow className={styles.title}>
        <IonCol>
          <Chivo text="About" bold size="2.5vh" mr={0.5} />

          {children}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className={`${styles.text}`}>
          {parse(wrapMarkdownText(text))}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

interface iAboutNoEditProps {}

export const AboutNoEdit: React.FC<iAboutNoEditProps> = () => {
  const {
    state: { hotel },
  } = useHotelModal();

  return <About text={hotel?.description || "No description."} />;
};

interface iAboutEditProps {
  // button_position: "end" | ""
  description: string;
}

export const AboutEdit: React.FC<iAboutEditProps> = ({ description }) => {
  const [text, setText] = useState<string>("");
  const { openModal } = useOpenModal();

  useEffect(() => {
    if (description.length) setText(description);
  }, [description]);

  return (
    <>
      <Edit init_text={text} />
      <About text={text}>
        <div>
          {/* <EditButton
            icon={edit_text}
            onClick={() => openModal(modal_id_edit_about_hotel)}
          /> */}
        </div>
      </About>
    </>
  );
};
