import { IonCol, IonImg, IonRow } from "@ionic/react";
import React, { Fragment, useMemo } from "react";
import DedsktopPageColSeparator from "../../../../../components/DesktopPageColSeparator";
import RewardCard from "../../../../../components/RewardCard";
import RewardSectorCard from "../../../../../components/RewardSectorCard";
import {
  Chivo12,
  Chivo20,
  Chivo24,
} from "../../../../../components/Text/Chivo";
import { Text16 } from "../../../../../components/Text/Montserrat";
import TwoSlimButtons from "../../../../../components/TwoSlimButtons";
import { iReward } from "../../../../../interfaces/models/reward";
import { estimatedValue2points } from "../../../../../utils/user";
import EditReward from "../../EditReward";
import { useEditRewardInfoReducer } from "../../hooks";

interface iNewEditRewardProps {
  reward_preview?: iReward;
  onConfirm(reward: iReward): void;
  onCancel(): void;
}

const NewEditRewardAndPreview: React.FC<iNewEditRewardProps> = ({
  reward_preview,
  onConfirm,
  onCancel,
}) => {
  const edit_reward_info = useEditRewardInfoReducer(reward_preview);
  const { validateState, getReward } = edit_reward_info;

  const handleConfirm = () => {
    const missing: string[] = validateState();

    if (!missing.length) onConfirm(getReward());
  };

  const [header] = useMemo(() => {
    if (reward_preview) return ["Edit Reward"];

    return ["New Reward"];
  }, [reward_preview]);

  return (
    <IonRow>
      <IonCol
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <IonRow>
          <IonCol>
            <Chivo24 text={header} centeredText />
            <EditReward edit_reward_info={edit_reward_info} />
          </IonCol>
        </IonRow>
        <IonRow>
          <TwoSlimButtons onGreen={() => handleConfirm()} onRed={onCancel} />
        </IonRow>
      </IonCol>
      <DedsktopPageColSeparator />
      <Preview reward={getReward()} />
    </IonRow>
  );
};

interface iPreviewProps {
  reward: iReward;
}

const Preview: React.FC<iPreviewProps> = ({ reward }) => {
  const { full_description, discount, type, sector, image, estimated_value } =
    reward;

  return (
    <IonCol>
      <IonRow>
        <IonCol>
          <Chivo24 text="Reward Preview" centeredText bold color="secondary" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol style={{ margin: "0 10vw" }}>
          <RewardCard {...{ reward }} />
        </IonCol>
      </IonRow>
      {full_description["en"] && (
        <IonRow>
          <IonCol className="flexc-start" style={{ margin: "0 10vw" }}>
            <Chivo20 text="Full Description (EN)" bold color="secondary" />
            <Text16
              textAlign="justify"
              text={full_description["en"]}
              style={{ textAlign: "justify" }}
            />
          </IonCol>
        </IonRow>
      )}
      {full_description["pt"] && (
        <IonRow>
          <IonCol className="flexc-start" style={{ margin: "0 10vw" }}>
            <Chivo20 text="Full Description (PT)" bold color="secondary" />
            <Text16
              textAlign="justify"
              text={full_description["pt"]}
              style={{ textAlign: "justify" }}
            />
          </IonCol>
        </IonRow>
      )}
      {(image || sector) && (
        <IonRow style={{ marginTop: "4vh" }}>
          <IonCol className="flexr-center">
            {sector || image === "/image" ? (
              <RewardSectorCard
                {...{
                  sector,
                  card_size: 20,
                  icon_size: 6,
                  text_size: 1.5,
                }}
              />
            ) : (
              <IonImg src={image} />
            )}
          </IonCol>
        </IonRow>
      )}
      {(estimated_value !== null || type || discount) && (
        <IonRow>
          <IonCol className="flexr-center">
            {type === "Discount" ? (
              <Fragment>
                <Text16 text="Discount  " withTrailingWhiteSpace />
                <Chivo20 text={`${discount || "?"}%`} color="secondary" bold />
              </Fragment>
            ) : (
              type === "Offer" && (
                <Chivo20 text="Offer" bold color="secondary" />
              )
            )}
          </IonCol>
          <IonCol className="flexr-center">
            {estimated_value !== null && (
              <Fragment>
                <Chivo20
                  text={estimatedValue2points(estimated_value || 0)}
                  bold
                  color="secondary"
                />
                <Chivo12 text=" pts" withTrailingWhiteSpace />
              </Fragment>
            )}
          </IonCol>
        </IonRow>
      )}
    </IonCol>
  );
};

export default NewEditRewardAndPreview;
