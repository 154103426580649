import React, { useCallback } from "react";
import iManager, { iManagerHotel } from "../../../../interfaces/models/manager";
import { ManagerAccountDispatchContext } from "../AccountContext";
import useManagerAccountState from "./useManagerAccountState";

const useManagerAccountDispatch = () => {
  const dispatch = React.useContext(ManagerAccountDispatchContext);
  const context = useManagerAccountState();

  if (!dispatch)
    throw Error(
      "useManagerAccountDispatch must be used within ManagerAccountDispatchContext"
    );
  const { state, hotels } = context;

  const updateAccount = useCallback(
    (account: iManager) => {
      dispatch({ type: "set data", data: { ...state.data, ...account } });
    },
    [state, dispatch]
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch({ type: loading ? "pending" : "resolved" });
    },
    [dispatch]
  );

  const updateHotel = useCallback(
    (hotel: iManagerHotel) => {
      const hotelsCopy = [...hotels];

      let i = 0;
      for (i = 0; i < hotelsCopy.length; i++) {
        if (hotelsCopy[i]._id === hotel._id) {
          console.log("UPDATED", hotelsCopy[i], hotel);
          hotelsCopy[i] = { ...hotelsCopy[i], ...hotel };
          console.log("hotelsCopy", hotelsCopy);
          break;
        }
      }
      if (i === hotelsCopy.length) {
        hotelsCopy.push(hotel);
      }

      dispatch({ type: "set hotels", hotels: hotelsCopy });
    },
    [dispatch, hotels]
  );

  return { updateAccount, setLoading, updateHotel };
};

export default useManagerAccountDispatch;
