import { IonGrid, IonRow } from "@ionic/react";
import React from "react";
import SlimButton from "../SlimButton";

interface iTwoSlimButtonsProps extends iContentProps {
  grid?: boolean;
}

const TwoSlimButtons: React.FC<iTwoSlimButtonsProps> = ({
  grid = true,
  ...rest
}) => {
  const renderContent = (): JSX.Element => {
    return <Content {...rest} />;
  };

  return grid ? <IonGrid>{renderContent()} </IonGrid> : renderContent();
};

interface iContentProps {
  onGreen?(): void;
  green_text?: string;
  onRed?(): void;
  red_text?: string;
  disable_green?: boolean;
  disable_red?: boolean;
  disable_buttons?: boolean;
  invert_order?: boolean;
  [key: string]: any;
}

const Content: React.FC<iContentProps> = (props) => {
  const {
    onGreen = undefined,
    onRed = undefined,
    green_text = "Confirm",
    red_text = "Cancel",
    disable_green = false,
    disable_red = false,
    disable_buttons = false,
    invert_order = false,
    ...rest
  } = props;

  return (
    <>
      {!invert_order ? (
        <IonRow {...rest}>
          {onGreen !== undefined && green_text && (
            <SlimButton
              onClick={onGreen}
              text={green_text}
              color="primary"
              disabled={disable_buttons || disable_green}
            />
          )}
          {onRed !== undefined && red_text && (
            <SlimButton
              onClick={onRed}
              text={red_text}
              color="danger"
              disabled={disable_buttons || disable_red}
            />
          )}
        </IonRow>
      ) : (
        <IonRow>
          {onRed !== undefined && red_text && (
            <SlimButton
              onClick={onRed}
              text={red_text}
              color="danger"
              disabled={disable_buttons || disable_red}
            />
          )}
          {onGreen !== undefined && green_text && (
            <SlimButton
              onClick={onGreen}
              text={green_text}
              color="primary"
              disabled={disable_buttons || disable_green}
            />
          )}
        </IonRow>
      )}
    </>
  );
};

export default TwoSlimButtons;
