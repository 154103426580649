import { SignupDataType } from "../../context/AuthContext/interfaces";
import { useAuth } from "../../context/AuthContext/AuthContext";
import useForm from "../../hooks/form";
import { iFormData } from "../../components/Form/Form";
import { iButtonProps } from "../../components/ItemButton/Button";

const initialState: SignupDataType = {
  username: "",
  email: "",
  password: "",
  password2: "",
  referralCode: "",
};

const useSignup = () => {
  const { state, handleChange } = useForm<SignupDataType>(initialState);
  const { SignupPromise } = useAuth();

  const validateInput = (name: string, value: string): string | null => {
    const whitespaceRegex = new RegExp(/\s/);
    const nonAlphanumRegex = new RegExp(/\W/);
    const digitRegex = new RegExp(/[0-9]/);
    const emailRegex = new RegExp(
      RegExp(
        // eslint-disable-next-line
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    );
    const passwordRegex = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/);

    const minLength = 3;
    const maxLength = 20;

    let inputName = "";
    if (
      (name === "firstName" && (inputName = "First Name")) ||
      (name === "lastName" && (inputName = "Last Name"))
    ) {
      if (
        value.length < minLength ||
        value.length > maxLength ||
        nonAlphanumRegex.test(value) ||
        digitRegex.test(value) ||
        whitespaceRegex.test(value)
      )
        return `${inputName} must be ${minLength} to ${maxLength} alphabet characters long`;
    } else if (name === "username") {
      if (
        value.length < minLength ||
        value.length > maxLength ||
        whitespaceRegex.test(value)
      )
        return `Username must be ${minLength} to ${maxLength} non-whitespace characters long`;
    } else if (name === "email") {
      if (!emailRegex.test(value)) return "Invalid email";
    } else if (name === "password") {
      if (value.length < 6 || value.length > 32 || !passwordRegex.test(value))
        return `Password must be 6 to 32 characters long and contain at least 1 letter and 1 number`;
    } else if (name === "password2") {
      if (value !== state.password) {
        return "Passwords don't match";
      }
    }
    return null;
  };

  const validateData = (): string | null => {
    for (const key of ["username", "email", "password", "referralCode"]) {
      // @ts-ignore
      const error = validateInput(key, state[key]);
      if (error) {
        return error;
      }
    }
    return null;
  };

  const { email, password, referralCode, username } = state;
  const required = false;
  const labelPosition = "floating";
  const formInputs: iFormData = [
    {
      name: "username",
      required,
      type: "text",
      label: "Username",
      labelPosition,
      value: username,
    },
    {
      name: "email",
      required,
      type: "email",
      label: "Email",
      labelPosition,
      value: email,
    },
    {
      name: "password",
      required,
      type: "password",
      label: "Password",
      labelPosition,
      value: password,
    },

    {
      name: "referralCode",
      //   required,
      type: "text",
      label: "Reference Code (Optional)",
      labelPosition,
      value: referralCode,
    },
  ];

  const buttonProps: iButtonProps = {
    text: "Sign up",
    textColor: "light",
    fill: "solid",
  };

  return {
    validateData,
    buttonProps,
    formInputs,
    handleChange,
    SignupPromise,
    state,
  };
};

export default useSignup;
