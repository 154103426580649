import { IonCard, IonImg } from "@ionic/react";
import React from "react";

import CardContent from "./CardContent/CardContent";
import CardHeader from "./CardHeader/CardHeader";

import "./current_reservation.css";
import { useDataModal } from "../../../../context/DataModalContext/DataModalContext";
import iHotel from "../../../../interfaces/models/hotel";
import useUserReservationsState from "../../../../context/User/UserReservationsContext/hooks/useUserReservationsState";
import { getHotelImage } from "../../../../utils/hotels/hotel";

interface iCurrentReservationProps {}

const CurrentReservation: React.FC<iCurrentReservationProps> = () => {
  const { openReportModal } = useDataModal();
  const { ongoingReservations } = useUserReservationsState();
  const current = ongoingReservations[0];

  const handleClickCard = () => {
    openReportModal(current);
  };

  return (
    <IonCard
      onClick={() => handleClickCard()}
      button
      color="secondary"
      className="current_res-card"
    >
      <CardHeader
        hotel={current.hotel as iHotel}
        startAt={current.startAt}
        endAt={current.endAt}
      />
      <IonImg src={getHotelImage((current.hotel as iHotel).images[0])} />
      <CardContent reservation={current} />
    </IonCard>
  );
};

export default CurrentReservation;
