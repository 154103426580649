import { IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import React from "react";
import { useDataModal } from "../../../../context/DataModalContext/DataModalContext";

import energy_icon from "../../../../img/energy.svg";
import water_icon from "../../../../img/water.svg";
import waste_icon from "../../../../img/waste.svg";
import footprint_icon from "../../../../img/footprint.svg";

import styles from "./styles.module.css";
import Title from "../Title/Title";
import {
  convertUnitEnergy,
  convertUnitMass,
  convertUnitVolume,
} from "../../../../utils/convertUnit";
import { getNumberOfNights } from "../../../../utils/reservations/reservation";
import iReservation from "../../../../interfaces/models/reservation";
import { round } from "lodash";

interface iPriceProps {}

const Price: React.FC<iPriceProps> = () => {
  const {
    state: { reservation },
  } = useDataModal();

  const {
    Water,
    Energy,
    Waste,
    Footprint,
    hotel: { energy2currency, water2currency },
  } = reservation!;

  const renderData = (): JSX.Element[] => {
    const DATA: iDataProps[] = [
      {
        type: "Energy",
        icon: energy_icon,
        value: round((Energy || 0) * energy2currency, 2),
      },
      {
        type: "Water",
        icon: water_icon,
        value: round((Water || 0) * water2currency, 2),
      },
    ];

    return DATA.map((data, i) => <Data key={i} {...data} />);
  };

  return <>{renderData()}</>;
};

interface iDataProps {
  type: string;
  icon: string;
  value?: number | string;
}

const Data: React.FC<iDataProps> = ({ type, icon, value }) => {
  return (
    <IonRow className={styles.data}>
      <IonCol className={styles["data-type"]}>
        <IonText color="secondary">
          <p className="montserrat">
            <strong>{type}</strong>
          </p>
        </IonText>
        <IonIcon src={icon} />
      </IonCol>
      <IonCol size="0.5"></IonCol>
      <IonCol className={styles.value}>
        {value !== undefined ? (
          <IonText color="secondary">
            <p className="chivo">
              <strong>{value} €</strong>
            </p>
          </IonText>
        ) : (
          <IonText color="danger">
            <p className="montserrat">Not being monitored</p>
          </IonText>
        )}
      </IonCol>
    </IonRow>
  );
};

export default Price;
