import React, { useState } from "react";
import EnterTextModal, {
  iEnterTextModalInfo,
} from "../../../../components/EnterTextModal/EnterTextModal";
import IonContentColor from "../../../../components/IonContentColor";
import { modal_id_send_booking_code } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import iReservation from "../../../../interfaces/models/reservation";
import Add from "./Add";
import List from "../List";
import useManagerReservationsState from "../../../../context/Manager/ReservationsContext/hooks/useManagerReservationsState";
import useManagerReservationsDispatch from "../../../../context/Manager/ReservationsContext/hooks/useManagerReservationsDispatch";
import useLoading from "../../../../hooks/loading";
import useIonAlertFunctions from "../../../../hooks/ionAlert";

interface iReservesProps {}

const Reserves: React.FC<iReservesProps> = () => {
  const { closeModal, openModal } = useOpenModal();
  const { present } = useIonAlertFunctions();
  const [code, setCode] = useState<string>("");
  const { setLoading, getLoading } = useLoading();

  const {
    ongoingReservations,
    pastReservations,
    isLoading: isReservationsLoading,
  } = useManagerReservationsState();
  const { sendReservationCode } = useManagerReservationsDispatch();

  const handleSendEmail = (text: string[]): void => {
    const email = text[0].trim();
    setLoading(true);
    sendReservationCode(code, [email])
      .then((res) => present(res))
      .catch((err) => present(err))
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCancel = () => {
    closeModal(modal_id_send_booking_code);
  };

  const handleOpenModal = (text: string) => {
    setCode(text);
    openModal(modal_id_send_booking_code);
  };

  const props: iEnterTextModalInfo = {
    title: "Send Booking Code",
    info: "",
    input_placeholder: ["Enter email here"],
    onConfirm: handleSendEmail,
    input_is_required: true,
    dismiss_alert_after_success: true,
    isLoading: getLoading(),
  };

  return (
    <IonContentColor>
      <EnterTextModal
        modal_id={modal_id_send_booking_code}
        onCancel={handleCancel}
        confirm_text="Send"
        {...props}
      />
      <Add onSend={handleOpenModal} />
      <List
        title="Ongoing"
        onClick={(reservation: iReservation) =>
          handleOpenModal(reservation.code)
        }
        reservations={pastReservations}
        isLoading={isReservationsLoading}
      />
    </IonContentColor>
  );
};

export default Reserves;
