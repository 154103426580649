import { setupConfig } from "@ionic/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext/AuthContext";
import { OpenModalContextProvider } from "./context/OpenModalContext/OpenModalContext";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
// import * as serviceWorker from "./service-worker";
import { LocalizationContextProvider } from "./context/LocalizationContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { FirebaseContextProvider } from "./context/FirebaseContext";
// import GlobalErrorBoundary from "./errorBoudaries/GlobalErrorBoundary";

// setupConfig({
//   mode: "ios",
// });

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <FirebaseContextProvider>
        <OpenModalContextProvider>
          <LocalizationContextProvider>
            <ThemeContextProvider>
              <App />
            </ThemeContextProvider>
          </LocalizationContextProvider>
        </OpenModalContextProvider>
      </FirebaseContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
