import React, { useState } from "react";
import { IonLoading } from "@ionic/react";

import IonContentColor from "../../components/IonContentColor";
import LoginForm from "../../components/LoginForm";

import "./login.css";

interface iLoginProps {}

const Login: React.FC<iLoginProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <IonContentColor id="login-page" className="login_singup-page">
      <IonLoading isOpen={loading} message={"Please wait..."} />
      <LoginForm {...{ setLoading }} />
    </IonContentColor>
  );
};

export default Login;
