const stays = {
  add_booking: "Adicionar Reserva",
  no_reservations_caption:
    "Não tem nenhuma reserva, de momento. Se tem código de pré-reserva, adicione-o aqui.",
  explore_stays_caption:
    "Explore estadias sustentáveis e comece a planear a sua próxima viagem neutra.",
  explore_stays_button: "Explore estadias sustentáveis",
  past: "Passadas",
  upcoming: "Próximas",
  ongoing: "Em progresso",
};

export default stays;
