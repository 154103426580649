const global = {
  water: "Água",
  energy: "Energia",
  footprint: "Pegada",

  overall: "geral",
  occupied: "ocupado",
  vacant: "vago",

  title: "Título",

  sector: "Setor",

  competitions: "Competições",

  friends: "Amizades",
  friend_requests: "Pedidos de Amizade",
};

export default global;
