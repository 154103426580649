import axios from "axios";
import React from "react";
import { modal_id_new_password } from "../../../context/OpenModalContext/modal_ids";
import EnterTextModal from "../../EnterTextModal/EnterTextModal";

interface iNewPasswordModalProps {}

const NewPasswordModal: React.FC<iNewPasswordModalProps> = () => {
  const handleConfirmNewPassword = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    console.log(`text`, text);

    axios
      .post("users/changePassword", { password: text[0] })
      .then((res) => {
        console.log(`res`, res);
        cb(false, "Password Changed");
      })
      .catch((err) => {
        console.log(`err.response`, err.response);
        cb(true, "Something went wrong");
      });
  };

  return (
    <EnterTextModal
      title="New password"
      input_placeholder={["New password", "Confirm new password"]}
      input_type={"password"}
      onConfirm={handleConfirmNewPassword}
      modal_id={modal_id_new_password}
    />
  );
};

export default NewPasswordModal;
