import { useMemo, useReducer, VoidFunctionComponent } from "react";
import { iOptionsPickerOption } from "../../../components/PickerRow/OptionsPicker";
import { iReward } from "../../../interfaces/models/reward";
import { tLanguageCodes } from "../../../utils/consts";

interface iState {
  estimated_value: number | null;
  sector: string | null;
  type: string | null;
  discount: number | null;
  location: string | null;
  short_description: Partial<Record<tLanguageCodes, string>>;
  full_description: Partial<Record<tLanguageCodes, string>>;
  image: string | null;
  title: string | null;
  [key: string]: any;
}

interface iAction extends Partial<iState> {
  action_type: string;
  language?: string;
}

const initial_state: iState = {
  estimated_value: null,
  sector: null,
  type: null,
  discount: null,
  location: null,
  short_description: {},
  full_description: {},
  image: null,
  title: null,
};

const reducer = (state: iState, action: iAction) => {
  const { action_type } = action;
  function evaluate<T>(arg: string): T | null {
    if (arg !== "short_description" && arg !== "full_description")
      //   @ts-ignore
      return action[arg] !== undefined ? action[arg] : state[arg];

    //   @ts-ignore
    return action[arg];
  }

  const evaluateRecord = (arg: string): Record<string, string | null> => {
    //   @ts-ignore
    const obj: Record<string, string> = {
      //   @ts-ignore
      ...state[arg],
      //   @ts-ignore
      [action.language]: action[arg],
    };

    //   @ts-ignore
    if (action.language === undefined || action[arg] === undefined)
      //   @ts-ignore
      return state[arg];

    return obj;
  };

  switch (action_type) {
    case "set":
      return {
        ...state,
        estimated_value: evaluate<number>("estimated_value"),
        discount: evaluate<number>("discount"),
        sector: evaluate<string>("sector"),
        type: evaluate<string>("type"),
        short_description: evaluateRecord("short_description"),
        full_description: evaluateRecord("full_description"),
        image: evaluate<string>("image"),
        location: evaluate<string>("location"),
        title: evaluate<string>("title"),
      };

    case "reset":
      return { ...initial_state };

    default:
      return { ...state };
  }
};

export interface iEditRewardInfoReducer {
  state: iState;
  handleResetInputs(): void;
  handleChange<T>(key: string, value: T, language?: string): void;
  validateState(): string[];
  sector_options: iOptionsPickerOption[];
  type_options: iOptionsPickerOption[];
  getReward(): iReward;
  editedProperties(): Partial<iReward>;
}

const useEditRewardInfoReducer = (
  reward: Partial<iReward> = {}
): iEditRewardInfoReducer => {
  //@ts-ignore
  const [state, dispatch] = useReducer(reducer, {
    //@ts-ignore
    ...initial_state,
    //@ts-ignore
    ...reward,
    //@ts-ignore
  });

  const editedProperties = (): Partial<iReward> => {
    const new_properties: Partial<iReward> = {};

    Object.keys(state).forEach((key: string) => {
      // @ts-ignore
      if (state[key] !== null) {
        // @ts-ignore
        if (typeof state[key] === "object") {
          // @ts-ignore
          Object.keys(state[key]).forEach((inner_key: string) => {
            // @ts-ignore
            if (state[key][inner_key] !== reward[key][inner_key]) {
              // @ts-ignore
              if (!new_properties.hasOwnProperty(key)) new_properties[key] = {};
              // @ts-ignore
              new_properties[key][inner_key] = state[key][inner_key];
            }
          });
        } else {
          // @ts-ignore
          if (state[key] !== reward[key]) new_properties[key] = state[key];
        }
      }
    });

    return new_properties;
  };

  const handleResetInputs = () => {
    //@ts-ignore
    dispatch({ action_type: "reset" });
  };

  function handleChange<T>(key: string, value: T, language?: string) {
    //@ts-ignore
    dispatch({ action_type: "set", [key]: value, language });
  }

  const [SECTORS, sector_options, TYPES, type_options] = useMemo(() => {
    const SECTORS: string[] = [
      "Hospitality",
      "Food&Drinks",
      "Services",
      "Products",
      "Wellness",
      "Adventure",
      "Leisure",
      "Cultural",
      //   "Other",
    ];

    const sector_options: iOptionsPickerOption[] = SECTORS.map((sector) => ({
      text: sector,
      value: sector,
    }));

    const TYPES: string[] = ["Discount", "Offer"];

    const type_options: iOptionsPickerOption[] = TYPES.map((sector) => ({
      text: sector,
      value: sector,
    }));

    return [SECTORS, sector_options, TYPES, type_options];
  }, []);

  const validateState = (): string[] => {
    const {
      estimated_value,
      sector,
      type,
      discount,
      location,
      short_description,
      full_description,
      title,
    } = state;
    const missing: string[] = [];

    if (title === null) missing.push("Title");

    if (estimated_value === null) missing.push("Estimated Value");

    if (sector === null) missing.push("Sector");

    if (type === null) missing.push("Type");
    else if (type === "Discount" && discount === null) missing.push("Discount");

    if (location === null) missing.push("Location");

    if (short_description === {}) missing.push("Short Description");
    else {
      if (!short_description.en || !short_description.en.length)
        missing.push("English Short Description");
      if (!short_description.pt || !short_description.pt.length)
        missing.push("Portuguese Short Description");
    }

    if (full_description === {}) missing.push("Full Description");
    else {
      if (!full_description.en || !full_description.en.length)
        missing.push("English Full Description");
      if (!full_description.pt || !full_description.pt.length)
        missing.push("Portuguese Full Description");
    }

    return missing;
  };

  const getReward = (): iReward => {
    return state as iReward;
  };

  return {
    state,
    handleResetInputs,
    handleChange,
    validateState,
    getReward,
    editedProperties,
    sector_options,
    type_options,
  };
};

export { useEditRewardInfoReducer };
