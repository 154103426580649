import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonText,
} from "@ionic/react";
import React from "react";

import "./tip_card.css";

interface iTipCardProps {
  icon: string;
  title: string;
  text: string;
}

const TipCard: React.FC<iTipCardProps> = ({ icon, title, text }) => {
  return (
    <IonItem color="none" lines="none" className="tip_card-item">
      <IonCard className="tip_card">
        <IonCardTitle>
          <IonItem lines="none">
            <IonIcon src={icon} />
            <IonText color="secondary">
              <p className="montserrat">{title}</p>
            </IonText>
          </IonItem>
        </IonCardTitle>
        <IonCardContent>
          <IonText color="secondary">
            <p className="montserrat">{text}</p>
          </IonText>
        </IonCardContent>
      </IonCard>
    </IonItem>
  );
};

export default TipCard;
