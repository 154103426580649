import React from "react";
import { ManagerRewardsDispatchContext } from "../";
import { iReward } from "../../../../interfaces/models/reward";
import useManagerAccountState from "../../AccountContext/hooks/useManagerAccountState";
import useManagerRewardsState from "./managerRewardsState";

const useManagerRewardsDispatch = () => {
  const context = React.useContext(ManagerRewardsDispatchContext);
  const { rewards } = useManagerRewardsState();
  const { currentHotel } = useManagerAccountState();

  if (!context)
    throw Error(
      "useManagerRewardsDispatch must be used within ManagerRewardsStateContext"
    );
  const { dispatch, get } = context;

  const setHotelRewards = (
    rewards: iReward[],
    hotelId: string | null = null
  ) => {
    dispatch({
      type: "set rewards",
      rewards,
      hotelId: hotelId || currentHotel._id,
    });
  };

  const addReward = (reward: iReward, hotelId: string | null = null) => {
    dispatch({
      type: "set rewards",
      rewards: [...rewards, reward],
      hotelId: hotelId || currentHotel._id,
    });
  };

  const setError = (error: string) => {
    dispatch({ type: "rejected", error });
  };
  const setIdle = () => {
    dispatch({ type: "idle" });
  };

  const setLoading = (loading: boolean) => {
    dispatch({ type: loading ? "pending" : "resolved" });
  };

  const editReward = (updatedReward: iReward) => {
    const rewardsCopy = [...rewards];

    for (let i = 0; i < rewardsCopy.length; i++) {
      if (updatedReward._id === rewardsCopy[i]._id) {
        rewardsCopy[i] = { ...rewardsCopy[i], ...updatedReward };
        break;
      }
    }
    setHotelRewards(rewardsCopy);
  };

  const deleteReward = (rewardId: string) => {
    const rewardsCopy = [...rewards];

    for (let i = 0; i < rewardsCopy.length; i++) {
      if (rewardsCopy[i]._id === rewardId) {
        rewardsCopy.splice(i, 1);
        break;
      }
    }
    setHotelRewards(rewardsCopy);
  };

  // const loadRewardsFromAPI = useCallback((fromApi: boolean = true) => {
  //   if (fromApi)
  // }, []);

  return {
    setHotelRewards,
    addReward,
    editReward,
    deleteReward,
    dispatch,
    setError,
    setIdle,
    setLoading,
    loadRewardsFromAPI: get,
  };
};

export default useManagerRewardsDispatch;
