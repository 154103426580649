import React, { useEffect, useReducer } from "react";
import strings, {
  available_languages,
  tAvailableLanguages,
} from "../../localization/localization";
import { createCtx } from "../CreateContext";
import { nLocalization } from "./interfaces";

const LANGUAGE_LSTORAGE = "language";

const initial_state: nLocalization.iState = {
  language: "",
  loading: true,
};

const reducer = (
  state: nLocalization.iState,
  action: nLocalization.iAction
): nLocalization.iState => {
  switch (action.type) {
    case "change language":
      localStorage.setItem(LANGUAGE_LSTORAGE, action.language!);
      return { ...state, language: action.language! };

    case "load language":
      return { ...state, language: action.language!, loading: false };

    default:
      return { ...state };
  }
};

const [useLocalization, CtxProvider] = createCtx<nLocalization.iContext>();

const LocalizationContextProvider: React.FC<nLocalization.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [
    nLocalization.iState,
    React.Dispatch<nLocalization.iAction>
  ] = useReducer(reducer, initial_state);

  useEffect(() => {
    let l = localStorage.getItem(LANGUAGE_LSTORAGE);

    if (!l || !available_languages.includes(l as tAvailableLanguages)) {
      l = strings.getInterfaceLanguage();
      strings.setLanguage(l);
      localStorage.setItem(LANGUAGE_LSTORAGE, l);
    } else {
      strings.setLanguage(l);
    }
    dispatch({ type: "load language", language: l as tAvailableLanguages });
  }, []);

  const handleSwitchLanguage = (l: tAvailableLanguages) => {
    if (state.language !== l) {
      strings.setLanguage(l);
      dispatch({ type: "change language", language: l });
      window.location.reload();
    }
  };
  const value = { state, handleSwitchLanguage };

  return <CtxProvider value={value}>{!state.loading && children}</CtxProvider>;
};

export { useLocalization, LocalizationContextProvider };
