import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { modal_id_new_reward } from "../../../../context/OpenModalContext/modal_ids";
import Content from "./Content";

import styles from "./styles.module.css";

interface iNewRewardModalProps {}

const NewRewardModal: React.FC<iNewRewardModalProps> = () => {
  return (
    <ModalWrapper cssClass={styles.modal} modal_id={modal_id_new_reward}>
      <Content />
    </ModalWrapper>
  );
};

export default NewRewardModal;
