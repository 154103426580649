import React from "react";
import Tag, { iTextProps } from "..";

import styles from "./styles.module.css";

const ChivoTag: React.FC<iTextProps> = ({ className, ...props }) => {
  return (
    <Tag {...props} className={`${className} ${styles.tag}`} font="chivo" />
  );
};

const Chivo: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <ChivoTag
      {...{
        className: `${className || ""}`,
        ...rest,
      }}
    ></ChivoTag>
  );
};

const Chivo12: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <ChivoTag
      {...{
        className: `${styles.chivo12} ${className || ""}`,
        ...rest,
      }}
    ></ChivoTag>
  );
};

const Chivo14: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <ChivoTag
      {...{
        className: `${styles.chivo14} ${className || ""}`,
        ...rest,
      }}
    ></ChivoTag>
  );
};

const Chivo16: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <ChivoTag
      {...{
        className: `${styles.chivo16} ${className || ""}`,
        ...rest,
      }}
    ></ChivoTag>
  );
};

const Chivo18: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <ChivoTag
      {...{ bold, className: `${styles.chivo18} ${className || ""}`, ...rest }}
    ></ChivoTag>
  );
};

const Chivo22: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <ChivoTag
      {...{ bold, className: `${styles.chivo22} ${className || ""}`, ...rest }}
    />
  );
};
const Chivo24: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <ChivoTag
      {...{ bold, className: `${styles.chivo24} ${className || ""}`, ...rest }}
    />
  );
};
const Chivo30: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <ChivoTag
      {...{ bold, className: `${styles.chivo30} ${className || ""}`, ...rest }}
    />
  );
};
const Chivo32: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <ChivoTag
      {...{ bold, className: `${styles.chivo32} ${className || ""}`, ...rest }}
    />
  );
};

const Chivo20: React.FC<iTextProps> = ({
  color = "primary",
  className,
  bold = true,
  ...rest
}) => {
  return (
    <ChivoTag
      {...{
        bold,
        color,
        className: `${styles.chivo20} ${className || ""}`,
        ...rest,
      }}
    />
  );
};

export {
  Chivo18,
  Chivo20,
  Chivo22,
  Chivo16,
  Chivo14,
  Chivo12,
  Chivo24,
  Chivo32,
  Chivo30,
  Chivo,
};
