import { IonCard, IonCardContent, IonGrid } from "@ionic/react";
import React from "react";
import iUser, {
  iAchievementElement,
  iUserNotOwnAccount,
} from "../../interfaces/models/user";
import Badges from "./Badges";
import Level from "./Level";
import MainInfo from "./MainInfo";

import { orderBy } from "lodash";

import styles from "./styles.module.css";

interface iUserCardProps {
  user: iUser | iUserNotOwnAccount;
  cardColor: string;

  displayLevelInsteadOfNumberOfFriends?: boolean;

  displayLevelProgressBar?: boolean;

  friendsUsernameColor?: string;

  // avatar?: any;

  handleClickFriends?(): void;
  handleClickUsername?(): void;
  handleClickAvatar?(): void;
  handleClickReferenceCode?(): void;
}

const UserCard: React.FC<iUserCardProps> = ({
  user,
  cardColor,

  displayLevelInsteadOfNumberOfFriends,

  displayLevelProgressBar = false,
  friendsUsernameColor,

  // avatar,

  handleClickFriends = () => {},
  handleClickUsername = () => {},
  handleClickAvatar = () => {},
  handleClickReferenceCode = void 0,
}) => {
  const {
    images,
    username,
    firstName,
    lastName,
    friends,
    achievements,
    total_points,
    referralCode,
  } = user;

  const getFavoriteAchievements = (): iAchievementElement[] => {
    const fa: iAchievementElement[] = [];

    (achievements as iAchievementElement[]).forEach((ach) => {
      if (ach.favorite_position >= 0) fa.push(ach);
    });

    orderBy(fa, ["favorite_position"], ["asc"]);

    return fa;
  };
  return (
    <IonCard color={cardColor}>
      <IonCardContent className={styles.card_content}>
        <IonGrid className={styles.grid}>
          <MainInfo
            {...{
              username,
              referralCode,
              firstName,
              lastName,
              nFriends: (friends || []).length,
              images: images,
              totalPoints: total_points,
              displayLevelInsteadOfNumberOfFriends,
              handleClickAvatar,
              handleClickFriends,
              handleClickUsername,
              handleClickReferenceCode,
              friendsUsernameColor,
            }}
          />
          {/* {displayLevelProgressBar && <Level {...{ total_points }} />} */}
          {/* <Badges {...{ achievements: getFavoriteAchievements() }} /> */}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default UserCard;
