// eslint-disable
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import React from "react";
import { convertUnitMass } from "../../../utils/convertUnit";
import { roundNumberToFixed } from "../../../utils/others";

import styles from "./data.module.css";

export interface iDataProps {
  footprint: number;
  points: number;
  cost: number;
}

const Data: React.FC<iDataProps> = ({ footprint, points, cost }) => {
  return (
    <IonCol size="4" className={styles.container}>
      <IonGrid>
        <IonRow className={styles["data-top"]}>
          <IonCol>
            <IonText color="light" className="ion-text-center">
              <p className="chivo">
                {`${footprint ? convertUnitMass(footprint) : "0 kg"}`}{" "}
                <small>CO2 eq</small>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow className={styles["data-bottom"]}>
          <IonCol>
            <IonText color="light" className="ion-text-center">
              <p className="montserrat">{`${roundNumberToFixed(
                cost || 0,
                2
              )} €`}</p>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCol>
  );
};
export default Data;
