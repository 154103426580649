const help = [
  {
    category: "About noytrall",
    qas: [
      {
        q: "What service does noytrall provide?",
        a: "Noytrall provides a service capable of follow up with environmental impact caused by each guest on housing units. Noytrall also provides the guests about consumptions, upon information collected on the housing unit. We calculate and help to decrease and mitigate the environmental imopact of your stay.",
      },
      {
        q: "How to benefit from noytrall's service?",
        a: "Through the app, available on the web. Android and iOS on the way!",
      },
      {
        q: "Is it required to have an account?",
        a: "Yes, to be able to benefit from our service you need to sign up to the noytrall's app.",
      },
      {
        q: "How can I contact noytrall's support?",
        a: "You can get in touch with us through Messenger, Instagram, Twitter, or at noytrall.pt, with our chatbot. You can also send an e-mail to help@noytrall.pt",
      },
      {
        q: "How are my consumptions measured?",
        a: "Noytall provides a monitoring service, alied with housing units and specific equipments.",
      },
      {
        q: "Can I book/cancel reservations with noytrall?",
        a: "Noytrall does not provide a booking service with all housing units.",
      },
      {
        q: "Can I invite friends?",
        a: "Sure! Sustainability and our planet's future depend on all of us. Invite your family and friends and together we'll make tourism more sustainable.",
      },
    ],
  },
  {
    category: "Rewards",
    qas: [
      {
        q: "How does the rewards system works?",
        a: "When evaluating your environmental impact, points are assigned according to evaluation. Your consumption is compared with sustainability standards and the hotel's average consumptions. The more sustainable is the consumption, the more points you'll win. You can then trade your points for vouchers of partner entities.",
      },
      {
        q: "Can I trade a voucher after its acquisition?",
        a: "Once purchased, vouchers won't be refunded.",
      },
    ],
  },
];

export default help;
