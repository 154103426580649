import moment from "moment";
import iReservation, {
  iSortedReservations,
  iSortedReservationsById,
  iSortedReservationsByIndex,
} from "../../interfaces/models/reservation";

const sortReservations = (
  reservations: iReservation[],
  exec_per_reservation?: (r: iReservation) => void
): iSortedReservations => {
  const sorted: iSortedReservations = { past: [], ongoing: [], upcoming: [] };

  for (const r of reservations) {
    if (exec_per_reservation) exec_per_reservation(r);
    if (moment(r.startAt).isAfter(moment())) sorted.upcoming.push(r);
    else if (moment(r.endAt).isAfter(moment())) sorted.ongoing.push(r);
    else sorted.past.push(r);
  }

  return sorted;
};
const sortReservationsByIndex = (
  reservations: iReservation[],
  exec_per_reservation?: (r: iReservation) => void
): iSortedReservationsByIndex => {
  const sorted: iSortedReservationsByIndex = {
    past: [],
    ongoing: [],
    upcoming: [],
  };

  for (let i = 0; i < reservations.length; i++) {
    const r = reservations[i];
    if (exec_per_reservation) exec_per_reservation(r);
    if (moment(r.startAt).isAfter(moment())) sorted.upcoming.push(i);
    else if (moment(r.endAt).isAfter(moment())) sorted.ongoing.push(i);
    else sorted.past.push(i);
  }

  return sorted;
};
const sortReservationsById = (
  reservations: iReservation[],
  exec_per_reservation?: (r: iReservation) => void
): iSortedReservationsById => {
  const sorted: iSortedReservationsById = {
    past: [],
    ongoing: [],
    upcoming: [],
  };

  for (let i = 0; i < reservations.length; i++) {
    const r = reservations[i];
    if (exec_per_reservation) exec_per_reservation(r);
    if (moment(r.startAt).isAfter(moment())) sorted.upcoming.push(r._id);
    else if (moment(r.endAt).isAfter(moment())) sorted.ongoing.push(r._id);
    else sorted.past.push(r._id);
  }

  return sorted;
};

export { sortReservations, sortReservationsByIndex, sortReservationsById };
