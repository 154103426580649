import iHotel from "../../interfaces/models/hotel";

export const defaultHotel: iHotel = {
  _id: "",
  nameToDisplay: "",
  description: "",
  images: [],
  location: {
    coordinates: [0, 0],
    type: "point",
    address: "",
  },
  sustainability: [],
  timestreamDBName: "",
  water2currency: 1,
  water2footprint: 1,
  energy2currency: 1,
  energy2footprint: 1,
  footprint2points: 1,
  website: "",
  measures: [],
  isDummyHotel: false,
};
