import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext/AuthContext";
import { LoginDataType } from "../../context/AuthContext/interfaces";
import useForm from "../../hooks/form";
import ForgotPassword from "../../pages/Login/ForgotPassword";
import { iButtonProps } from "../Button";
import Form, { iFormData } from "../Form/Form";

const initial_state: LoginDataType = {
  email: "",
  password: "",
};

interface iLoginFormProps {
  setLoading(loading: boolean): void;
}

const LoginForm: React.FC<iLoginFormProps> = ({ setLoading }) => {
  const { Login } = useAuth();
  const { state, handleChange } = useForm(initial_state);
  const [error, setError] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    Login(state as LoginDataType, (error: string) => {
      setError(error);
      setLoading(false);
    });
  };

  const { email, password } = state;
  const formData: iFormData = [
    {
      name: "email",
      required: true,
      type: "email",
      label: "Email",
      labelPosition: "floating",
      value: email,
    },
    {
      name: "password",
      required: true,
      type: "password",
      label: "Password",
      labelPosition: "floating",
      value: password,
      component: () => <ForgotPassword />,
    },
  ];

  const buttonProps: iButtonProps = {
    text: "Log in",
    textColor: "light",
    fill: "solid",
  };

  return (
    <Form
      error={error}
      color="primary_dim"
      title="Welcome Back"
      formData={formData}
      onChange={handleChange}
      onSubmit={handleSubmit}
      buttonProps={buttonProps}
    />
  );
};

export default LoginForm;
