import React from "react";
import { Doughnut, Chart } from "react-chartjs-2";
import _ from "lodash";

import "./donut.css";
import {
  convertUnitEnergy,
  convertUnitMass,
  convertUnitVolume,
} from "../../../utils/convertUnit";

const Donut = ({ data, height = 60, width = 100 }) => {
  var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function () {
      originalDoughnutDraw.apply(this, arguments);

      let chart = this.chart;
      let width = chart.chart.width,
        height = chart.chart.height,
        ctx = chart.chart.ctx;

      let sum = 0;
      for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
        sum += chart.config.data.datasets[0].data[i];
      }

      // let text = `${Math.round(sum * 100) / 100}`;
      let text;

      const { title, middle_value } = chart.config.data;
      if (title === "Water") {
        // text += " ltr";
        text = convertUnitVolume(sum);
      } else if (title === "Energy") {
        // text += " Wh";
        text = convertUnitEnergy(sum);
      } else if (title === "Footprint") {
        // text += " kWh";
        // text += " kg";
        text = convertUnitMass(sum);
        const underText = "CO2 eq";

        let fontSize = (height / 350).toFixed(2);
        ctx.font = fontSize + "em Chivo";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#1A697F";

        const underTextX = Math.round(
            (width - ctx.measureText(underText).width) / 2
          ),
          underTextY = height / 1.7;

        ctx.fillText(underText, underTextX, underTextY);
      } else if (title === "Money") {
        text = `${middle_value} €`;
      }

      // let fontSize = (height / 160).toFixed(2);
      // ctx.font = fontSize + "em Chivo";
      ctx.font = "20px Chivo";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#1A697F";

      const textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
    },
  });

  const options = {
    borderColor: "red",
    cutoutPercentage: 65,
    title: { display: false },
    legend: { display: false },
    animation: { animateScale: true },
    rotation: -0.3 * Math.PI,
    tooltips: {
      callbacks: {
        label: (ttItem, data) => {
          let unit = "";
          switch (data.title) {
            case "Money":
              unit = "€";
              break;
            case "Water":
              unit = "l";
              break;
            case "Energy":
              unit = "Wh";
              break;
            case "Footprint":
              unit = "kg";
              break;
            default:
              break;
          }

          return `${data.labels[ttItem.index]}: ${(
            (data.datasets[ttItem.datasetIndex].data[ttItem.index] /
              _.sum(data.datasets[ttItem.datasetIndex].data)) *
            100
          ).toFixed(2)}% (${
            Math.round(
              data.datasets[ttItem.datasetIndex].data[ttItem.index] * 100
            ) / 100
          }${unit})`;
        },
      },
    },
  };

  return <Doughnut {...{ data, height, width, options }} />;
};

export default Donut;
