const help = [
  {
    category: "Sobre a noytrall",
    qas: [
      {
        q: "Que serviço disponibiliza a nøytrall?",
        a: "A nøytrall disponibiliza um serviço de acompanhamento do impacto ambiental durante a estadia do hóspede numa das unidades de alojamento aderentes. Informamos o hóspede sobre os consumos, mediante a informação recolhida na unidade de alojamento. Calculamos e ajudamos a diminuir e mitigar o impacto ambiental da sua estadia.",
      },
      {
        q: "Como usufruir do serviço?",
        a: "Através da aplicação, disponível na web. Android e iOS a caminho!",
      },
      {
        q: "É necessário ter conta?",
        a: "Sim, para poder aceder ao nosso serviço precisa de se registrar na nossa aplicação.",
      },
      {
        q: "Como é que posso contactar o suporte da nøytrall?",
        a: "Pode contactar a nøytrall através do Messenger, Instagram, Twitter, ou no chatbot disponível em noytrall.pt. Pode ainda enviar um e-mail para help@noytrall.pt",
      },
      {
        q: "Como é que os meus consumos são medidos?",
        a: "A nøytrall disponibiliza um serviço de monitorização juntamente com as unidades de alojamento aderentes, através da instalação de equipamento especializado",
      },
      {
        q: "Posso fazer reservas e cancelamentos através da nøytall?",
        a: "A nøytrall não disponibiliza um serviço de reservas nas unidades de alojamento.",
      },
      {
        q: "Posso convidar amigos?",
        a: "Claro! A sustentabilidade e o futuro do planeta dependem de todos. Convide os seus amigos e familiares para juntos tornarmos o turismo mais sustentável.",
      },
    ],
  },
  {
    category: "Recompensas",
    qas: [
      {
        q: "Como funciona o sistema de recompensas?",
        a: "Ao avaliarmos o seu impacto ambiental atribuímos pontos consoante a avaliação. Comparamos o seu consumo com os padrões de sustentabilidade e a média do hotel. Quanto mais sustentável for o seu consumo em comparação aos padrões, mais pontos ganha. Depois, pode trocar os pontos por vouchers das entidades parceiras.",
      },
      {
        q: "Posso trocar vouchers depois de comprar?",
        a: "Depois de adquiridos, os vouchers não podem ser reembolsados.",
      },
    ],
  },
];

export default help;
