import { useTheme } from "../context/ThemeContext";

type tRgba = [number, number, number, number];

const useBoxShadow = (
  rgba_light: tRgba = [0, 0, 0, 0.5],
  rgba_dark: tRgba = [255, 255, 255, 0.5],
  shadow: string = "0px 3px 5px"
) => {
  const {
    state: { theme },
  } = useTheme();

  const arrayToString = (rgba: tRgba) => rgba.join(",");

  return `${shadow} rgba(${
    theme === "light" ? arrayToString(rgba_light) : arrayToString(rgba_dark)
  })`;
};

export default useBoxShadow;
