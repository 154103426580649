import React from "react";

import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import { modal_id_add_booking } from "../../../../context/OpenModalContext/modal_ids";

import EnterTextModal, {
  iEnterTextModalInfo,
} from "../../../../components/EnterTextModal/EnterTextModal";
import useUserReservationsState from "../../../../context/User/UserReservationsContext/hooks/useUserReservationsState";
import useUserReservationsDispatch from "../../../../context/User/UserReservationsContext/hooks/useUserReservationsDispatch";

interface iAddBookingModalProps {}

const AddBookingModal: React.FC<iAddBookingModalProps> = () => {
  const { isLoading } = useUserReservationsState();
  const { insertReservationCode } = useUserReservationsDispatch();
  const modal = useOpenModal();

  const closeModal = () => {
    modal.closeModal(modal_id_add_booking);
  };

  const handleConfirm = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ): void => {
    insertReservationCode(text[0]);
  };

  const info: iEnterTextModalInfo = {
    title: "Add Booking",
    info: "",
    input_placeholder: ["Enter reservation code here"],
    onConfirm: handleConfirm,
    dismiss_alert_on_true: !isLoading,
    dismiss_alert_after_success: true,
  };

  return (
    <EnterTextModal
      {...{
        modal_id: modal_id_add_booking,
        onCancel: closeModal,
        ...info,
      }}
    />
  );
};

export default AddBookingModal;
