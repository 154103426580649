import { IonContent, IonModal, IonSpinner } from "@ionic/react";
import React from "react";
import { useDataModal } from "../../context/DataModalContext/DataModalContext";
import IonContentColor from "../IonContentColor";
import ModalFooter from "../ModalFooter/ModalFooter";
// import Content from "./ModalContent/ModalContent";
import Content from "./Content/Content";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

import "./stay_data_modal.css";

const StayDataModal: React.FC = () => {
  const {
    is_open,
    state: { loading, reservation },
  } = useDataModal();

  return (
    <IonModal isOpen={is_open}>
      {reservation && (
        <>
          <Header />
          <IonContentColor className="data_modal-content">
            {!loading ? (
              <Content />
            ) : (
              <div className="data_modal-content-spinner">
                <IonSpinner />
              </div>
            )}
          </IonContentColor>
        </>
      )}
      {/* <ModalFooter
        top_shadow
        width={80}
        buttonColor="danger"
        closeModal={closeGraphModal}
      > */}
      <Footer width={80} />
      {/* </ModalFooter> */}
    </IonModal>
  );
};

export default StayDataModal;
