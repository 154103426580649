import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Fragment } from "react";
import {
  iReward,
  iRewardDescription,
  REWARDS_SECTOR_INFO,
  tRewardSector,
} from "../../interfaces/models/reward";
import { tLanguageCodes } from "../../utils/consts";
import { estimatedValue2points } from "../../utils/user";
import { Chivo12, Chivo18, Chivo20 } from "../Text/Chivo";
import { Text14 } from "../Text/Montserrat";

import styles from "./styles.module.css";

export interface iRewardCardRewardInfo {
  location?: string;
  sector?: string;
  short_description?: iRewardDescription | undefined;
  type?: string;
  discount?: number | undefined;
  estimated_value?: number;
}

interface iRewardCardProps {
  card_color?: string;
  textColor?: string;
  reward?: iReward;
  reward_info?: iRewardCardRewardInfo;
  onClickCard?(): void;
  className?: string;
  style?: Record<string, string>;
  [key: string]: any;
}

const RewardCard: React.FC<iRewardCardProps> = ({
  card_color,
  textColor,
  reward,
  reward_info,
  onClickCard = () => {},
  className = "",
  style = {},
  ...rest
}) => {
  let location, sector, short_description, type, discount, estimated_value;

  if (reward)
    ({ location, sector, short_description, type, discount, estimated_value } =
      reward);
  else if (reward_info)
    ({ location, sector, short_description, type, discount, estimated_value } =
      reward_info);
  const _text_color =
    textColor ||
    (sector && sector !== "Other"
      ? REWARDS_SECTOR_INFO[sector as tRewardSector].textColor
      : "light");

  const getDescription = (description: iRewardDescription): string => {
    const d = { ...description };

    if (d.en) return d.en || "";

    delete d["en"];

    const keys: tLanguageCodes[] = Object.keys(d) as tLanguageCodes[];
    if (keys.length) return d[keys[0]] || "";

    return "";
  };

  const handleClickCard = () => {
    onClickCard();
  };

  return (
    <IonCard
      mode="ios"
      onClick={handleClickCard}
      style={{
        "--background":
          card_color ||
          (sector && sector !== "Other"
            ? REWARDS_SECTOR_INFO[sector as tRewardSector].card_color
            : "var(--ion-color-medium)"),
        ...style,
      }}
      className={`${styles.card} ${className}`}
      {...rest}
    >
      <IonCardContent>
        <IonGrid className={styles.grid}>
          <IonRow>
            <LeftSide
              {...{
                textColor: _text_color,
                location: location,
                description:
                  typeof short_description === "object"
                    ? getDescription(short_description)
                    : "",
              }}
            />
            <RightSide
              {...{
                textColor: _text_color,
                estimated_value,
                sector,
                type,
                discount,
              }}
            />
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

interface iLeftSideProps {
  textColor: string;
  location?: string;
  description: string;
}

const LeftSide: React.FC<iLeftSideProps> = ({
  textColor,
  location,
  description,
}) => {
  return (
    <Fragment>
      <IonCol size="8" className={styles.left}>
        <IonRow>
          <IonCol>
            <Text14 color={textColor} text={location || "location"} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <Chivo18 color={textColor} text={description || ""} />
          </IonCol>
        </IonRow>
      </IonCol>
    </Fragment>
  );
};

interface iRightSideProps {
  textColor: string;
  sector?: string;
  type?: string;
  discount?: number;
  estimated_value?: number;
}

const RightSide: React.FC<iRightSideProps> = ({
  textColor,
  sector,
  discount,
  type,
  estimated_value,
}) => {
  return (
    <IonCol size="4" className={styles.right}>
      <IonRow>
        <IonCol>
          <Text14 color={textColor} text={sector || "sector"} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Chivo20
            color={textColor}
            text={
              (type === "Discount" ? `- ${discount || "?"} %` : type) || "type"
            }
          />
        </IonCol>
      </IonRow>
      {estimated_value !== undefined && estimated_value !== null ? (
        <IonRow className={styles.estimated_value}>
          <IonCol>
            <Chivo20
              color={textColor}
              text={estimatedValue2points(estimated_value)}
            />
            <Chivo12 text="  pts" color="light" withTrailingWhiteSpace />
          </IonCol>
        </IonRow>
      ) : (
        <Chivo20 text="value" color="light" />
      )}
    </IonCol>
  );
};

export default RewardCard;
