import defaultHotelImage from "../../img/hotel.png";
import { iHotelLocation } from "../../interfaces/models/hotel";

export const MAX_NUM_IMAGES = 3;

export const getHotelImage = (
  image: string | undefined = undefined
): string => {
  return image || defaultHotelImage;
};

export const openHotelLocationOnMaps = (location: iHotelLocation) => {
  const { coordinates, maps_url } = location;

  window
    .open(
      maps_url ||
        `https://www.google.com/maps/@${coordinates[0]},${coordinates[1]},14z`,
      "_blank"
    )
    ?.focus();
};
