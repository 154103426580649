import React from "react";
import { modal_id_reward_info } from "../../context/OpenModalContext/modal_ids";
import { iWrapperComponent } from "../../interfaces";
import { iReward } from "../../interfaces/models/reward";
import IonContentColor from "../IonContentColor";
import MenuModalHeader from "../MenuModalHeader";
import ModalFooter2, { iModalFooter2Props } from "../ModalFooter2/ModalFooter2";
import ModalWrapper from "../ModalWrapper";
import RewardFullInfo from "../RewardFullInfo";

interface iRewardInfoModalProps extends iWrapperComponent {
  reward: iReward;
  title?: string | null;
  footerProps: iModalFooter2Props;
}

const RewardInfoModal: React.FC<iRewardInfoModalProps> = ({
  reward,
  title = "Reward",
  children,
  footerProps,
}) => {
  return (
    <ModalWrapper modal_id={modal_id_reward_info}>
      {title && (
        <MenuModalHeader /* onExit={handleExitModal} */ {...{ title }} />
      )}
      <IonContentColor>
        {reward && <RewardFullInfo {...{ reward }}>{children}</RewardFullInfo>}
      </IonContentColor>
      <ModalFooter2 {...footerProps} />
    </ModalWrapper>
  );
};

export default RewardInfoModal;
