import { IonTextarea } from "@ionic/react";
import React from "react";

import styles from "./styles.module.css";

interface iTextareaConfinedProps {
  value: string;
  onChange(value: string): void;
  background_color?: string;
  border_color?: string;
  placeholder?: string;
  [key: string]: any;
}

const TextareaConfined: React.FC<iTextareaConfinedProps> = ({
  value,
  onChange,
  background_color = "beje",
  border_color = "secondary",
  placeholder = "Enter Text",
  ...rest
}) => {
  return (
    <IonTextarea
      className={styles.textarea}
      placeholder={placeholder}
      //   className={styles.input}
      style={{
        "--background": `var(--ion-color-${background_color})`,
        border: `1px solid var(--ion-color-${border_color})`,
        "--color": `var(--ion-color-${border_color})`,
      }}
      value={value}
      onIonChange={(e) => {
        onChange(e.detail.value || "");
      }}
      {...rest}
    />
  );
};

export default TextareaConfined;
