import { IonCard, IonCardContent } from "@ionic/react";
import React from "react";
import SpendingData from "../../../../../components/SpendingData/SpendingData";
import { tSpending } from "../../../../../interfaces/models/sensorData";

import "./card.css";

interface iCardProps {
  info: tSpending;
}

const Card: React.FC<iCardProps> = ({ info }) => {
  return (
    // <SpendingDataCardIonCol {...info} className="stats_card" />
    <IonCard color="secondary" className="stats_card">
      <IonCardContent>
        <SpendingData {...info} />
      </IonCardContent>
    </IonCard>
  );
};

export default Card;
