import { IonContent, IonGrid, IonItem } from "@ionic/react";
import React from "react";
import IonContentColor from "../../IonContentColor";

import styles from "./content.module.css";
import Points from "./Points";
import Price from "./Price";
import Summary from "./Summary";
import Tariff from "./Tariff";
import Title from "./Title/Title";

const Content: React.FC = () => {
  return (
    <IonContentColor>
      {[
        { component: Summary, title: "Stays Summary" },
        // { component: Tariff, title: "Tariff Detail" },
        // { component: Points, title: "Points Detail" },
        { component: Price, title: "Price Detail" },
      ].map(({ component, title }, i) => {
        const Component = wrapper(component);
        return <Component title={title} key={i} />;
      })}
    </IonContentColor>
  );
};

interface Props {
  title: string;
}

const wrapper =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P & Props> =>
  ({ title, ...props }: Props) =>
    (
      <IonItem className={styles.item} color="none">
        <IonGrid className={styles.grid}>
          <Title title={title} />
          <Component {...(props as P)} />
        </IonGrid>
      </IonItem>
    );

export default Content;
