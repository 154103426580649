import {
  iBarGraph,
  iBarGraphDataset,
  iBarGraphDatasetOptions,
  iDonutGraph,
  iOverallDataType,
  tDataColor,
  tDataLabel,
  tDataModalData,
  tDataSignature,
  tGraph,
} from "../context/DataModalContext/interfaces";
import {
  iSensorData2,
  iSensorData1,
  tSpendingTypes,
} from "../interfaces/models/sensorData";

import _, { sum } from "lodash";
import moment from "moment";

export const sensor_data1_init: iSensorData1 = {
  ac: 0,
  es: 0,
  el: 0,
  gw: 0,
  hw: 0,
  cw: 0,
  te: 0,
  tw: 0,
};

export const label2type_translation_table: Record<tDataLabel, tDataModalData> =
  {
    Water: "Footprint",
    Electricity: "Footprint",
    "Cold Water": "Water",
    "Hot Water": "Water",
    "Grey Water": "Water",
    Lights: "Energy",
    Sockets: "Energy",
    "Air Conditioner": "Energy",
  };

export const signature2label_translation_table: Record<
  tDataSignature,
  tDataLabel
> = {
  tw: "Water",
  te: "Electricity",
  cw: "Cold Water",
  hw: "Hot Water",
  gw: "Grey Water",
  el: "Lights",
  es: "Sockets",
  ac: "Air Conditioner",
};

const signature2type_translation_table: Record<tDataSignature, tSpendingTypes> =
  {
    tw: "Footprint",
    te: "Footprint",
    cw: "Water",
    hw: "Water",
    gw: "Water",
    el: "Energy",
    es: "Energy",
    ac: "Energy",
  };

export const label2color_translation_table: Record<tDataLabel, tDataColor> = {
  "Hot Water": "#FF8373",
  "Cold Water": "#85DEF3",
  "Grey Water": "#C4C4C4",
  Lights: "#D7F0BC",
  "Air Conditioner": "#62C890",
  Sockets: "#C4C4C4",
  Water: "#2D9BB4",
  Electricity: "#62CD90",
};

export const label2colorRGBA = (
  dataLabel: tDataLabel,
  alpha: number = 1
): string => {
  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const hex = label2color_translation_table[dataLabel];

  const rgb = hexToRgb(hex);

  if (!rgb) return hex;

  const { r, g, b } = rgb;

  return `rgba(${r}, ${g}, ${b}, ${`${alpha}`})`;
};

export const donut_graph_template_object: iDonutGraph = {
  datasets: [
    { backgroundColor: [], data: [], borderColor: [], borderWidth: [] },
  ],
  labels: [],
  title: "",
};

export const bar_graph_datasets_options: iBarGraphDatasetOptions = {
  barPercentage: 0.98,
  categoryPercentage: 1,
  minBarLength: 0,
};

export const bar_graph_template_object: iBarGraph = {
  datasets: [],
  labels: [],
};

export const translatetDataSignatureToLabel = (
  sig: tDataSignature
): tDataLabel => signature2label_translation_table[sig];

export const translateDataLabelToColor = (label: tDataLabel): tDataColor =>
  label2color_translation_table[label];

export const translateDataLabelToType = (label: tDataLabel): tDataModalData =>
  label2type_translation_table[label];

export const translateDataSignarureToType = (
  sig: tDataSignature
): tSpendingTypes => signature2type_translation_table[sig];

export const calculateDonutGraphData2Money = (
  data: iSensorData1,
  energy2currency: number,
  water2currency: number,
  borderColor: string
): iDonutGraph => {
  const { te, tw } = data;

  const e2c = (te || 0) * (energy2currency || 0);
  const w2c = (tw || 0) * (water2currency || 0);
  const total = e2c + w2c;

  const donut: iDonutGraph = { ..._.cloneDeep(donut_graph_template_object) };
  donut.title = "Money";
  donut.middle_value = Math.round(total * 100) / 100;

  Object.entries(data).forEach((entry) => {
    const [signature] = entry;

    // if (val) {
    const label = translatetDataSignatureToLabel(signature as tDataSignature);

    donut["datasets"][0]["backgroundColor"].push(
      translateDataLabelToColor(label)
    );

    donut["datasets"][0]["borderColor"].push(translateDataLabelToColor(label));
    donut.labels.push(label);

    if (label === "Electricity")
      // donut["datasets"][0]["data"].push(Math.round((e2c / total) * 100));
      donut["datasets"][0]["data"].push(Math.round(e2c * 100) / 100);
    else if (label === "Water")
      // donut["datasets"][0]["data"].push(Math.round((w2c / total) * 100));
      donut["datasets"][0]["data"].push(Math.round(w2c * 100) / 100);
    // }
  });

  return donut;
};

const handleDonutGraphData = (
  donut_data: tGraph<iDonutGraph>,
  val: number,
  type: tDataModalData,
  label: tDataLabel,
  energy2footprint: number,
  water2footprint: number
) => {
  if (type === "Footprint") {
    if (label === "Water") val *= water2footprint;
    else if (label === "Electricity") val *= energy2footprint;
  }

  donut_data[type].title = type;
  donut_data[type]["datasets"][0]["backgroundColor"].push(
    translateDataLabelToColor(label)
  );
  donut_data[type]["datasets"][0]["borderColor"].push(
    translateDataLabelToColor(label)
  );
  donut_data[type]["datasets"][0]["borderWidth"].push(0);

  donut_data[type].labels.push(label);
  donut_data[type]["datasets"][0]["data"].push(val);
};

const cleanDonutData = (
  donut_data: tGraph<iDonutGraph>,
  type: tDataModalData
) => {
  donut_data[type] = { ..._.cloneDeep(donut_graph_template_object) };
};

export const calculateDonutGraphData2 = (
  _data: iSensorData2,
  energy2footprint: number,
  water2footprint: number
): tGraph<iDonutGraph> => {
  const data = { ..._data };
  // if (!data["te"] || !data["te"].length) {
  //   data["te"] = data[""];
  // }
  // if (!data["tw"]) {
  //   data["te"] = (data["hw"] || 0) + (data["gw"] || 0) + (data["cw"] || 0);
  // }

  const handle = (label: tDataLabel, type: tDataModalData, sum: number) => {
    if (
      label !== "Grey Water" &&
      label !== "Air Conditioner" &&
      label !== "Sockets" &&
      label !== "Lights"
    )
      handleDonutGraphData(
        donut_data,
        sum,
        type,
        label,
        energy2footprint,
        water2footprint
      );
  };

  const donut_data: tGraph<iDonutGraph> = {
    Water: { ..._.cloneDeep(donut_graph_template_object) },
    Energy: { ..._.cloneDeep(donut_graph_template_object) },
    Footprint: { ..._.cloneDeep(donut_graph_template_object) },
    Waste: { ..._.cloneDeep(donut_graph_template_object) },
    Money: { ..._.cloneDeep(donut_graph_template_object) },
  };

  Object.entries(data).forEach((entry) => {
    const [signature, arr] = entry;

    if ((arr as number[]).length) {
      const sum = _.sum(arr as number[]);
      if (sum) {
        const label = translatetDataSignatureToLabel(
          signature as tDataSignature
        );
        const type = translateDataLabelToType(label);

        handle(label, type, sum);
      }
    }
  });

  if (
    !donut_data["Energy"].labels.length ||
    !sum(donut_data["Energy"].datasets[0].data)
  ) {
    donut_data["Energy"] = _.cloneDeep(donut_graph_template_object);
    const arr = data["te"];
    handle("Electricity", "Energy", _.sum(arr as number[]));
  }
  if (
    !donut_data["Water"].labels.length ||
    !sum(donut_data["Water"].datasets[0].data)
  ) {
    donut_data["Water"].labels = [];
    const arr = data["tw"];
    handle("Water", "Water", _.sum(arr as number[]));
  }
  console.log(`donut_data`, donut_data);
  return donut_data;
};

export const calculateDonutGraphData1 = (
  _data: iSensorData1,
  energy2footprint: number,
  water2footprint: number
): tGraph<iDonutGraph> => {
  const data = { ..._data };
  if (!data["te"]) {
    data["te"] = (data["ac"] || 0) + (data["el"] || 0) + (data["es"] || 0);
  }
  if (!data["tw"]) {
    data["tw"] = (data["hw"] || 0) + (data["gw"] || 0) + (data["cw"] || 0);
  }

  const handle = (value: number, label: tDataLabel, type: tDataModalData) => {
    if (
      label !== "Grey Water" &&
      label !== "Air Conditioner" &&
      label !== "Sockets" &&
      label !== "Lights"
    )
      handleDonutGraphData(
        donut_data,
        value,
        type,
        label,
        energy2footprint,
        water2footprint
      );
  };

  const donut_data: tGraph<iDonutGraph> = {
    Water: { ..._.cloneDeep(donut_graph_template_object) },
    Energy: { ..._.cloneDeep(donut_graph_template_object) },
    Footprint: { ..._.cloneDeep(donut_graph_template_object) },
    Waste: { ..._.cloneDeep(donut_graph_template_object) },
    Money: { ..._.cloneDeep(donut_graph_template_object) },
  };
  Object.entries(data).forEach((entry) => {
    const [signature, val] = entry;

    const label = translatetDataSignatureToLabel(signature as tDataSignature);
    const type = translateDataLabelToType(label);

    handle(val as number, label, type);
  });

  if (
    !donut_data["Energy"].labels.length ||
    !sum(donut_data["Energy"].datasets[0].data)
  ) {
    cleanDonutData(donut_data, "Energy");
    const val = data["te"];
    const label = "Electricity";
    const type = "Energy";
    handle(val as number, label, type);
  }
  if (
    !donut_data["Water"].labels.length ||
    !sum(donut_data["Water"].datasets[0].data)
  ) {
    cleanDonutData(donut_data, "Water");

    const val = data["tw"];
    const label = "Water";
    const type = "Water";
    handle(val as number, label, type);
  }

  return donut_data;
};

export const getDonutGraphDataTypeData = () => {};

export const calculateBarGraphData = (
  data: iSensorData2,
  startAt: number,
  endAt: number
): tGraph<iBarGraph> => {
  // @ts-ignore
  const formatter = (value, ctx) => {
    const otherDatasetIndex = ctx.datasetIndex === 0 ? 1 : 0;
    const total =
      ctx.chart.data.datasets[otherDatasetIndex].data[ctx.dataIndex] + value;

    return `${((value / total) * 100).toFixed(0)}%`;
  };

  const handle = (
    values: number[],
    label: tDataLabel,
    type: tDataModalData
  ) => {
    if (
      label !== "Grey Water" &&
      label !== "Air Conditioner" &&
      label !== "Sockets" &&
      label !== "Lights"
    ) {
      const backgroundColor = translateDataLabelToColor(label);

      const dataset: iBarGraphDataset = {
        ...bar_graph_datasets_options,
        backgroundColor,
        data: values,
        label,
        datalabels: { formatter },
      };

      bar_data[type].datasets.push(dataset);
    }
  };

  const bar_data: tGraph<iBarGraph> = {
    Water: { ..._.cloneDeep(bar_graph_template_object) },
    Energy: { ..._.cloneDeep(bar_graph_template_object) },
    Footprint: { ..._.cloneDeep(bar_graph_template_object) },
    Waste: { ..._.cloneDeep(bar_graph_template_object) },
    Money: { ..._.cloneDeep(bar_graph_template_object) },
  };

  // CREATE LABELS
  const labels: string[] = [];
  for (
    let i = 0;
    i <
    moment(endAt).endOf("day").diff(moment(startAt).startOf("day"), "days") + 1;
    i++
  ) {
    labels.push(moment(startAt).add(i, "days").format("DD/MM"));
  }
  bar_data["Water"].labels = [...labels];
  bar_data["Energy"].labels = [...labels];
  bar_data["Footprint"].labels = [...labels];

  // FILL DATASETS
  Object.entries(data).forEach((entry) => {
    const [signature, arr] = entry;
    if ((arr as number[]).length && _.sum(arr as number[])) {
      // console.log(123);
      const values = [...(arr as number[])];
      const label = translatetDataSignatureToLabel(signature as tDataSignature);
      const type = translateDataLabelToType(label);
      handle(values, label, type);
    }
  });

  if (!bar_data["Energy"].datasets.length) {
    const label: tDataLabel = "Electricity";
    const values = [...(data["te"] || [])];
    const type = "Energy";
    handle(values, label, type);
  }
  if (!bar_data["Water"].datasets.length) {
    const label: tDataLabel = "Water";
    const values = [...(data["tw"] || [])];
    const type: tDataModalData = "Water";
    handle(values, label, type);
  }
  return bar_data;
};

export const getGraphLabels = (
  donut_graph_data: tGraph<iDonutGraph>,
  data_type: iOverallDataType
): tDataLabel[] => {
  return (donut_graph_data as tGraph<iDonutGraph>)[data_type.title].labels;
};
