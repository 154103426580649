import React from "react";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import MenuModalHeader from "../MenuModalHeader";
import ModalWrapper from "../ModalWrapper";

interface iMenuModalProps {
  modal_id: number;
  children: React.ReactChild;
  title: string;
}

const MenuModal: React.FC<iMenuModalProps> = ({
  modal_id,
  title,
  children,
}) => {
  const { closeModal } = useOpenModal();

  const onExitModal = () => {
    closeModal(modal_id);
  };

  return (
    <ModalWrapper {...{ modal_id }}>
      <MenuModalHeader {...{ title, onExit: onExitModal }} />
      {children}
    </ModalWrapper>
  );
};

export default MenuModal;
