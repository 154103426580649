import { IonContent, IonIcon } from "@ionic/react";
import React from "react";
import {
  available_themes,
  available_themes_icons,
  useTheme,
} from "../../../context/ThemeContext";
import RadioButtonList, { iRadioButtonListItem } from "../../RadioButtonList";

interface iContentProps {}

const Content: React.FC<iContentProps> = () => {
  const {
    state: { theme },
    handleSwitchTheme,
  } = useTheme();

  const getItems = (): iRadioButtonListItem[] => {
    return available_themes.map((theme, i) => {
      return {
        Component: (
          <IonIcon src={available_themes_icons[i]} color="secondary" />
        ),
        value: theme as string,
        label: theme as string,
      };
    });
  };

  return (
    <IonContent>
      <RadioButtonList
        items={getItems()}
        value={theme}
        onClickRadio={handleSwitchTheme}
      />
    </IonContent>
  );
};

export default Content;
