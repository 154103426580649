import { IonCol } from "@ionic/react";
import React from "react";

interface iDedsktopPageColSeparatorProps {
  size?: number;
}

const DedsktopPageColSeparator: React.FC<iDedsktopPageColSeparatorProps> = ({
  size = 0.1,
}) => {
  return (
    <IonCol size={`${size}`} style={{ position: "relative" }}>
      <div
        style={{
          border: "1px solid var(--ion-color-secondary)",
          background: "var(--ion-color-secondary)",
          height: `${97}vh`,
          position: "fixed",
        }}
      ></div>
    </IonCol>
  );
};

export default DedsktopPageColSeparator;
