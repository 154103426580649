import {
  IonCol,
  IonGrid,
  IonButton,
  IonRow,
  IonSkeletonText,
} from "@ionic/react";
import React, { forwardRef, Fragment, useEffect, useState } from "react";
import { Chivo18 } from "../../../../components/Text/Chivo";
import _ from "lodash";

import styles from "./styles.module.css";
import { Text14, Text16 } from "../../../../components/Text/Montserrat";
import iReservation from "../../../../interfaces/models/reservation";
import { epochTimelineToString } from "../../../../utils/others";
import useMediaQuery from "../../../../hooks/mediaQuery";

interface iListProps {
  title: string;
  centeredTitle?: boolean;
  onClick(reservation: iReservation): void;
  reservations: iReservation[];
  isLoading: boolean;
  selectedReservationId?: string;
}

const List = forwardRef<any, iListProps>(
  (
    {
      title,
      centeredTitle = false,
      onClick,
      reservations,
      isLoading,
      selectedReservationId,
    },
    ref
  ) => {
    const { isDesktop } = useMediaQuery();
    const [request, setRequest] = useState<boolean>(false);

    const loading = isLoading;

    useEffect(() => {
      if (loading && !request) {
        setRequest(true);
      }
    }, [loading, request]);

    const renderList = (): JSX.Element[] | JSX.Element => {
      const list = reservations;

      if (list.length)
        return list.map((reservation) => (
          <IonRow key={reservation._id}>
            <IonCol>
              <IonButton
                fill="clear"
                className={styles.list_button}
                onClick={() => onClick(reservation)}
              >
                <IonCol
                  style={
                    reservation._id === selectedReservationId
                      ? { background: "var(--ion-color-primary)" }
                      : {}
                  }
                >
                  <div className={styles["button-content"]}>
                    {isDesktop ? (
                      <Fragment>
                        <Text16 text={`room ${reservation.roomNum}`} />
                        <Text16
                          text={epochTimelineToString(
                            reservation.startAt,
                            reservation.endAt
                          )}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Text14 text={`room ${reservation.roomNum}`} />
                        <Text14
                          text={epochTimelineToString(
                            reservation.startAt,
                            reservation.endAt
                          )}
                        />
                      </Fragment>
                    )}
                  </div>
                </IonCol>
              </IonButton>
            </IonCol>
          </IonRow>
        ));

      return (
        <IonRow>
          <IonCol>
            <Chivo18 centeredText text={`No ${title}`} color="danger" />
          </IonCol>
        </IonRow>
      );
    };

    const renderSkeletonList = (): JSX.Element[] => {
      return _.range(3).map((i) => (
        <IonRow key={i}>
          <IonCol className={styles.skeleton}>
            <IonSkeletonText animated style={{ width: "30%" }} />
            <IonSkeletonText animated style={{ width: "60%" }} />
          </IonCol>
        </IonRow>
      ));
    };

    return (
      <IonGrid
        ref={ref}
        /* className={styles.grid} */ style={
          isDesktop ? {} : { margin: "0 3vw" }
        }
      >
        <IonRow>
          <IonCol>
            <Chivo18 text={title} centeredText={centeredTitle} />
          </IonCol>
        </IonRow>
        {isLoading ? renderSkeletonList() : renderList()}
      </IonGrid>
    );
  }
);

export default List;
