import LocalizedStrings from "react-localization";

import en from "./en/index";
import pt from "./pt/index";
// import es from "./es/index";
// import fr from "./fr/index";

const strings = new LocalizedStrings({ en, pt /* , es, fr  */ });

export default strings;

export type tAvailableLanguages = "pt" | "en" /* | "fr" | "es" */;

const translation_table_language2full: Record<tAvailableLanguages, string> = {
  pt: strings.language_pt,
  en: strings.language_en,
  // es: strings.language_es,
  // fr: strings.language_fr,
};

const available_languages: tAvailableLanguages[] = [
  "en" /* , "es", "fr" */,
  "pt",
];

export { available_languages, translation_table_language2full };
