import React from "react";
import IonContentColor from "../../../../components/IonContentColor";
import TwoSegmentedFiltersWithMiddleButton from "../../../../components/SegmentedFilterWithMiddleButton";
import ViewSelectionButtons from "../../../../components/ViewSelectionButtons";
import { ManagerCompetitionsContextProvider } from "../../../../context/Manager/CompetitionsContext";
import { modal_id_new_competition } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";

import { useGamesViewFilter } from "../hooks";
import Competitions from "./Competitions";
import NewCompetitionModal from "./Competitions/NewCompetitionModal";

interface iGamesProps {}

const GamesWrapped: React.FC<iGamesProps> = () => {
  const { openModal } = useOpenModal();
  const {
    FILTERS,
    viewSelectionButtons,
    view,
    filter,
    setFilter,
    handleClickAdd,
  } = useGamesViewFilter();

  const handleAdd = () => {
    const handleAddCompetition = () => {
      openModal(modal_id_new_competition);
    };
    const handleAddChallenge = () => {};

    handleClickAdd(handleAddCompetition, handleAddChallenge);
  };

  return (
    <IonContentColor>
      <NewCompetitionModal />
      <ViewSelectionButtons
        {...{
          buttons: viewSelectionButtons,
          active: view,
          buttonColor: "secondary",
          textColor: "light",
        }}
      />
      <TwoSegmentedFiltersWithMiddleButton
        value={filter}
        onChange={setFilter}
        filters={FILTERS}
        onClickIcon={handleAdd}
      />
      <Competitions {...{ filter }} />
    </IonContentColor>
  );
};

const Games = () => {
  return (
    <ManagerCompetitionsContextProvider>
      <GamesWrapped />
    </ManagerCompetitionsContextProvider>
  );
};

export default Games;
