import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { modal_id_add_booking } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import strings from "../../../../localization/localization";

import "./no_current_reservations.css";

const AddBooking: React.FC = () => {
  const { openModal } = useOpenModal();

  return (
    <IonGrid className="add_booking_button">
      <IonRow>
        <IonCol>
          <IonButton
            color="tertiary"
            onClick={() => openModal(modal_id_add_booking)}
          >
            <IonText color="primary">
              <p className="chivo">{strings.add_booking}</p>
            </IonText>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const Text: React.FC = () => (
  <div className="no_current_reservations-text-item">
    <IonText color="secondary_dim">
      <p className="Montserrat">{strings.no_reservations_caption}</p>
      {/* <p className="Montserrat">{strings.explore_stays_caption}</p> */}
    </IonText>
  </div>
);

const ExploreButton: React.FC = () => {
  const history = useHistory();

  const handleClick = (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ): void => {
    history.push("/explore");
  };

  return (
    <div className="no_current_reservations-explore_button flexr-center">
      <IonButton type="button" onClick={handleClick} color="secondary">
        <IonText color="light">
          <h3>{strings.explore_stays_button}</h3>
        </IonText>
      </IonButton>
    </div>
  );
};

interface iNoCurrentReservationsProps {}

const NoCurrentReservations: React.FC<iNoCurrentReservationsProps> = () => {
  return (
    <React.Fragment>
      <Text />
      <AddBooking />
      {/* <ExploreButton /> */}
    </React.Fragment>
  );
};

export default NoCurrentReservations;
