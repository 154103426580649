import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import { ellipse } from "ionicons/icons";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useDataModal } from "../../../context/DataModalContext/DataModalContext";
import { tDataLabel } from "../../../context/DataModalContext/interfaces";
import { translateDataLabelToColor } from "../../../utils/graph";
import Donut from "../../ChartJsGraphs/Donut/Donut";
import DonutLabels from "../../ChartJsGraphs/Donut/DonutLabels";

import styles from "./content.module.css";

interface iContentProps {}

const Content: React.FC<iContentProps> = () => {
  return (
    <IonGrid>
      <Title />
      <Graph />
      <Lables />
    </IonGrid>
  );
};

const Title: React.FC = () => {
  const { getActiveDataType } = useDataModal();

  return (
    <IonRow>
      <IonCol className={styles.title}>
        <IonText color="secondary">
          <h2 className="chivo">
            <strong> {getActiveDataType().title}</strong>
          </h2>
        </IonText>
      </IonCol>
    </IonRow>
  );
};

const Graph: React.FC = () => {
  const {
    getActiveGraphData,
    state: { graph_type },
  } = useDataModal();

  return (
    <IonRow>
      <IonCol className={styles.graph}>
        {graph_type === "donut" ? (
          <Donut data={getActiveGraphData()} />
        ) : (
          <Bar data={getActiveGraphData()} />
        )}
      </IonCol>
    </IonRow>
  );
};

const Lables: React.FC = () => {
  const { getActiveGraphLabels } = useDataModal();

  return <DonutLabels labels={getActiveGraphLabels()} />;
};

export default Content;
