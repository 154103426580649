import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

import { Route, useHistory } from "react-router-dom";
import React, { Fragment, useEffect } from "react";

import withAuthHeader from "../hoc/WithAuthHeader";

import Stays from "../pages/Hotel/Stays";

import stays from "../img/stays_logo.svg";
import calendar_tick from "../img/calendar_tick.svg";
import rewards from "../img/rewards_logo.svg";
import games from "../img/games_icon.svg";
import hotel from "../img/hotel-icon.svg";

import HotelDesktop from "../pages/Hotel/Hotel/Desktop";
import HotelMobile from "../pages/Hotel/Hotel/Mobile";

import Menu from "../components/Menu/Menu";

import ReservesMobile from "../pages/Hotel/Reserves/Mobile";
import ReservesDesktop from "../pages/Hotel/Reserves/Desktop";

import GamesMobile from "../pages/Hotel/Games/Mobile";
import GamesDesktop from "../pages/Hotel/Games/Desktop";

import RewardsMobile from "../pages/Hotel/Rewards/Mobile";
import RewardsDesktop from "../pages/Hotel/Rewards/Desktop";

import strings from "../localization/localization";

import useMediaQuery from "../hooks/mediaQuery";
import WithDesktopPage from "../hoc/DesktopPage";
import { ManagerAccountContextProvider } from "../context/Manager/AccountContext/AccountContext";
import { ManagerReservationsContextProvider } from "../context/Manager/ReservationsContext";
import { ManagerRewardsContextProvider } from "../context/Manager/RewardsContext";
import DataInsertion from "../pages/Hotel/DataInsertion";
import Dashboard from "../pages/Hotel/Dashboard";

const NavigationHotelWrapped: React.FC = () => {
  const { isDesktop } = useMediaQuery();
  const history = useHistory();

  useEffect(() => {
    const allowed = [
      "/stays",
      "/alerts",
      "/rewards",
      "/hotel",
      "/bookings",
      // "/dashboard",
    ].includes(window.location.pathname);
    if (!allowed) history.push("/hotel");
  });

  const renderMobileNavbar = (): JSX.Element[] => {
    const navs = [
      { label: strings.stays, tab: "stays", icon: stays },
      { label: strings.bookings, tab: "bookings", icon: calendar_tick },
      { label: strings.games, tab: "games", icon: games },
      { label: strings.rewards.rewards, tab: "rewards", icon: rewards },
      { label: strings.hotel, tab: "hotel", icon: hotel },
    ];

    return navs.map(({ tab, icon, label }, i) => (
      <IonTabButton key={i} tab={tab} href={`/${tab}`}>
        <IonIcon src={icon} />
        <IonLabel>{`${label}`}</IonLabel>
      </IonTabButton>
    ));
  };

  return (
    <Fragment>
      <Menu />

      {isDesktop ? (
        <IonRouterOutlet>
          <Route path="/:tab(stays)" component={WithDesktopPage(Stays)} exact />
          <Route
            path="/:tab(bookings)"
            component={WithDesktopPage(ReservesDesktop)}
            exact
          />
          <Route
            path="/:tab(games)"
            component={WithDesktopPage(GamesDesktop)}
            exact
          />
          <Route
            path="/:tab(rewards)"
            component={WithDesktopPage(RewardsDesktop)}
            exact
          />
          <Route
            path="/:tab(hotel)"
            component={WithDesktopPage(HotelDesktop)}
            exact
          />
          {/* <Route
            path="/:tab(data-insertion)"
            component={WithDesktopPage(DataInsertion)}
            exact
          /> */}
          {/* <Route
            path="/:tab(dashboard)"
            component={WithDesktopPage(Dashboard)}
            exact
          /> */}
        </IonRouterOutlet>
      ) : (
        <IonTabs>
          <IonRouterOutlet>
            <Route
              path="/:tab(stays)"
              component={withAuthHeader(Stays)}
              exact
            />
            <Route
              path="/:tab(bookings)"
              component={withAuthHeader(ReservesMobile)}
              exact
            />
            <Route
              path="/:tab(games)"
              component={withAuthHeader(GamesMobile)}
              exact
            />
            <Route
              path="/:tab(rewards)"
              component={withAuthHeader(RewardsMobile)}
              exact
            />
            <Route
              path="/:tab(hotel)"
              component={withAuthHeader(HotelMobile)}
              exact
            />
          </IonRouterOutlet>
          <IonTabBar id="navbar" color="navbar" slot="bottom">
            {renderMobileNavbar()}
          </IonTabBar>
        </IonTabs>
      )}
    </Fragment>
  );
};

const NavigationManager: React.FC = () => {
  useEffect(() => {
    // axios.defaults.baseURL = "http://127.0.0.1:5000";
  }, []);

  return (
    <ManagerAccountContextProvider>
      <ManagerReservationsContextProvider>
        <ManagerRewardsContextProvider>
          {/* <ManagerContextProvider> */}
          <NavigationHotelWrapped />
          {/* </ManagerContextProvider> */}
        </ManagerRewardsContextProvider>
      </ManagerReservationsContextProvider>
    </ManagerAccountContextProvider>
  );
};

export default NavigationManager;
