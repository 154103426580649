import {
  IonCol,
  IonGrid,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import axios from "axios";
import { isString, sum } from "lodash";
import React, { Fragment, useEffect, useMemo } from "react";
import IonContentColor from "../../../../../../../components/IonContentColor";
import IonHeaderColored from "../../../../../../../components/IonHeaderColored";
import IonListColored from "../../../../../../../components/IonListColored";
import RewardCard from "../../../../../../../components/RewardCard";
import {
  Chivo14,
  Chivo20,
  Chivo24,
} from "../../../../../../../components/Text/Chivo";
import {
  Text10,
  Text14,
  Text16,
} from "../../../../../../../components/Text/Montserrat";
import useLoading from "../../../../../../../hooks/loading";
import iHotel from "../../../../../../../interfaces/models/hotel";
import { iReward } from "../../../../../../../interfaces/models/reward";
import { iSensorData2 } from "../../../../../../../interfaces/models/sensorData";
import { epochToDate } from "../../../../../../../utils/others";

import calendar from "../../../../../../../img/calendar.svg";
import footprint from "../../../../../../../img/footprint.svg";

import styles from "./styles.module.css";
import useManagerCompetitionsState from "../../../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsState";
import useManagerCompetitionsDispatch from "../../../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsDispatch";

interface iContentProps {
  competitionId: string;
}

const Content: React.FC<iContentProps> = ({ competitionId }) => {
  const { getLoading, setLoading } = useLoading();
  const { getCompetition, getCompetitionRoomsData } =
    useManagerCompetitionsState();
  const { addCompetitionData } = useManagerCompetitionsDispatch();

  const roomsData = getCompetitionRoomsData(competitionId);
  useEffect(() => {
    const main = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `/competitions/competition/${competitionId}`
        );

        const { roomsData, competition: comp } = res.data;
        addCompetitionData(comp, roomsData);
      } catch (err) {
        console.log(`err`, err);
      } finally {
        setLoading(false);
      }
    };
    if (getCompetitionRoomsData(competitionId) === null) main();
  }, [competitionId, setLoading, addCompetitionData, getCompetitionRoomsData]);

  const competition = getCompetition(competitionId)!;
  const { start_at, end_at, title, rewards, rooms } = competition;
  const hotel = isString(competition.hotel) ? null : competition.hotel;

  return (
    <Fragment>
      {/* <IonLoading isOpen={getLoading()} /> */}
      <Header
        {...{
          start_at,
          end_at,
          hotel_name: !getLoading() && hotel ? hotel.nameToDisplay : "",
        }}
      />
      <IonContentColor>
        <IonGrid>
          <Title {...{ title }} />
          <Labels />
          {getLoading() ? (
            <IonRow>
              <IonCol className="flexr-center">
                <IonSpinner name="crescent" color="secondary" />
              </IonCol>
            </IonRow>
          ) : (
            roomsData && hotel && <Rooms {...{ rooms, roomsData, hotel }} />
          )}
          <Rewards {...{ rewards: rewards as iReward[] }} />
        </IonGrid>
      </IonContentColor>
    </Fragment>
  );
};

interface iHeaderProps {
  hotel_name: string;
  start_at: number;
  end_at: number;
}

const Header: React.FC<iHeaderProps> = ({ hotel_name, start_at, end_at }) => {
  return (
    <IonHeaderColored className={styles.header}>
      <IonGrid className={styles.header_grid}>
        <IonRow className={styles.header_title}>
          <IonCol>
            <Chivo24 text={hotel_name} centeredText color="primary" />
          </IonCol>
        </IonRow>
        <IonRow className={styles.header_timespan}>
          <IonCol>
            <Text10 text={epochToDate(start_at)} />
            <IonIcon src={calendar} />
            <Text10 text={epochToDate(end_at)} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonHeaderColored>
  );
};

interface iTitleProps {
  title: string;
}

const Title: React.FC<iTitleProps> = ({ title }) => {
  return (
    <IonRow className={styles.title}>
      <IonCol>
        <Chivo20 text={title} color="secondary" centeredText />
      </IonCol>
    </IonRow>
  );
};

const Labels: React.FC = () => {
  return (
    <IonRow className={styles.labels}>
      <IonCol size="3">
        <Text16 text="Room" />
      </IonCol>
      <IonCol className={styles.labels_mid_col}>
        <IonIcon src={footprint} />
        <Text16 text="Footprint" />
      </IonCol>
      <IonCol size="3">
        <Chivo14 text="kg CO2e" bold />
      </IonCol>
    </IonRow>
  );
};

interface iRoomsProps {
  rooms: string[];
  roomsData: Record<string, iSensorData2>;
  hotel: iHotel;
}

const Rooms: React.FC<iRoomsProps> = ({ rooms, roomsData, hotel }) => {
  const rooms_info = useMemo(() => {
    const { energy2footprint, water2footprint } = hotel;
    const footprints: Record<string, number> = {};
    let max_footprint: number = 0;

    rooms.forEach((room) => {
      if (!roomsData[room]) footprints[room] = 0;
      else {
        footprints[room] =
          energy2footprint * (sum(roomsData[room].te) || 0) +
          water2footprint * (sum(roomsData[room].tw) || 0);

        if (footprints[room] > max_footprint) max_footprint = footprints[room];
      }
    });

    return rooms.map((room, key) => {
      return (
        <IonRow className={styles.room_info} {...{ key }}>
          <IonCol size="3">
            <Text14 text={room} />
          </IonCol>
          <IonCol className={styles.bar}>
            <IonProgressBar
              value={
                footprints[room] === 0
                  ? footprints[room]
                  : footprints[room] / max_footprint
              }
              color="primary"
              className={"shadow_b"}
            />
          </IonCol>
          <IonCol size="3">
            <Chivo20 text={footprints[room].toFixed(2)} color="secondary" />
          </IonCol>
        </IonRow>
      );
    });
  }, [hotel, rooms, roomsData]);

  return <Fragment>{rooms_info}</Fragment>;
};

interface iRewardsProps {
  rewards: iReward[];
}

const Rewards: React.FC<iRewardsProps> = ({ rewards }) => {
  const rewards_list = useMemo(() => {
    return rewards.map((reward, key) => (
      <RewardCard {...{ reward, style: { marginTop: "1vh" }, key }} />
    ));
  }, [rewards]);

  return (
    <Fragment>
      <IonRow className={styles.rewards_title}>
        <IonCol>
          <Chivo20 text="Rewards" centeredText color="secondary" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonListColored>{rewards_list}</IonListColored>
        </IonCol>
      </IonRow>
    </Fragment>
  );
};

export default Content;
