import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";

import lock from "../../../img/lock.svg";
import info from "../../../img/info.svg";
import logout from "../../../img/logout.svg";

import "./menu_buttons.css";
import { useAuth } from "../../../context/AuthContext/AuthContext";
import { useOpenModal } from "../../../context/OpenModalContext/OpenModalContext";
import {
  modal_id_help,
  modal_id_privacy,
  modal_id_notifications,
  modal_id_account,
  modal_id_theme,
  modal_id_language,
} from "../../../context/OpenModalContext/modal_ids";
import strings from "../../../localization/localization";
import {
  colorPaletteSharp,
  fingerPrint,
  languageSharp,
  notifications,
} from "ionicons/icons";

const MenuButtons: React.FC = () => {
  const { Logout } = useAuth();
  const { openModal } = useOpenModal();

  const handleClickPrivacy = () => {
    openModal(modal_id_privacy);
  };

  const handleClickHelp = () => {
    openModal(modal_id_help);
  };

  const handleClickLogout = () => {
    Logout();
  };

  const handleClickNotifications = () => {
    openModal(modal_id_notifications);
  };

  const handleClickAccount = () => {
    openModal(modal_id_account);
  };

  const handleClickTheme = () => {
    openModal(modal_id_theme);
  };

  const handleClickLanguage = () => {
    openModal(modal_id_language);
  };

  return (
    <IonList className="menu-buttons">
      <MenuButtonsItem
        color="light"
        text={strings.notifications_menu}
        icon={notifications}
        border={true}
        onClick={handleClickNotifications}
      />
      <MenuButtonsItem
        color="light"
        text={strings.account_menu}
        icon={fingerPrint}
        border={true}
        onClick={handleClickAccount}
      />

      <MenuButtonsItem
        color="light"
        text={strings.theme_menu}
        icon={colorPaletteSharp}
        border={true}
        onClick={handleClickTheme}
      />

      <MenuButtonsItem
        color="light"
        text={strings.language_menu}
        icon={languageSharp}
        border={true}
        onClick={handleClickLanguage}
      />

      <MenuButtonsItem
        color="light"
        text={strings.help_menu}
        icon={info}
        border={true}
        onClick={handleClickHelp}
      />

      <MenuButtonsItem
        color="light"
        text={strings.privacy_menu}
        icon={lock}
        border={true}
        onClick={handleClickPrivacy}
      />

      <MenuButtonsItem
        color="secondary"
        text={strings.logout_menu}
        icon={logout}
        border={false}
        onClick={handleClickLogout}
      />
    </IonList>
  );
};

interface iMenuButtonsItemProps {
  color: "light" | "secondary";
  border: boolean;
  text: string;
  icon: string;
  onClick?(): void;
}

const MenuButtonsItem: React.FC<iMenuButtonsItemProps> = ({
  color,
  border,
  text,
  icon,
  onClick,
}) => {
  return (
    <div className="menu-item">
      <IonItem
        button
        detail={false}
        color="primary"
        lines="none"
        onClick={onClick}
      >
        <IonGrid>
          <IonRow>
            <IonCol size="1">
              <IonIcon color={color} src={icon} />
            </IonCol>
            <IonCol>
              <IonText color={color}>
                <p className="montserrat">{text}</p>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      {border && <div className="menu-item-line"></div>}
    </div>
  );
};

export default MenuButtons;
