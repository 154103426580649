import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonProgressBar,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import axios from "axios";
import { closeOutline } from "ionicons/icons";
import { Fragment, useEffect, useMemo } from "react";
import {
  Chivo14,
  Chivo20,
  Chivo24,
  Chivo32,
} from "../../../../../../components/Text/Chivo";
import {
  Text10,
  Text14,
  Text16,
} from "../../../../../../components/Text/Montserrat";
import useLoading from "../../../../../../hooks/loading";
import iHotel from "../../../../../../interfaces/models/hotel";
import { iSensorData2 } from "../../../../../../interfaces/models/sensorData";
import { epochToDate } from "../../../../../../utils/others";

import calendar from "../../../../../../img/calendar.svg";

import styles from "./styles.module.css";
import { iReward } from "../../../../../../interfaces/models/reward";
import RewardCard from "../../../../../../components/RewardCard";
import IonListColored from "../../../../../../components/IonListColored";
import footprint from "../../../../../../img/footprint.svg";
import { isString, sum } from "lodash";
import useManagerCompetitionsState from "../../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsState";
import useManagerCompetitionsDispatch from "../../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsDispatch";

interface iCompetitionInfoProps {
  competitionId: string;
  onClose(): void;
}

const CompetitionInfo: React.FC<iCompetitionInfoProps> = ({
  competitionId,
  onClose,
}) => {
  const { getCompetitionRoomsData, getCompetition } =
    useManagerCompetitionsState();
  const { addCompetitionData } = useManagerCompetitionsDispatch();

  const { getLoading, setLoading } = useLoading();

  const roomsData = getCompetitionRoomsData(competitionId);
  useEffect(() => {
    const main = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `/competitions/competition/${competitionId}`
        );
        console.log(`res`, res);

        const { roomsData, competition: comp } = res.data;
        addCompetitionData(comp, roomsData);
      } catch (err) {
        console.log(`err`, err);
      } finally {
        setLoading(false);
      }
    };
    if (getCompetitionRoomsData(competitionId) === null) main();
  }, [competitionId, setLoading, addCompetitionData, getCompetitionRoomsData]);

  const competition = getCompetition(competitionId)!;
  const { start_at, end_at, title, rewards, rooms } = competition;
  const hotel = isString(competition.hotel) ? null : competition.hotel;

  return (
    <IonGrid>
      <Header
        {...{
          start_at,
          end_at,
          hotel,
          onClose,
          loading: getLoading(),
        }}
      />
      <IonRow style={{ marginTop: "3vh" }}>
        <IonCol>
          <Chivo24 text={title} bold centeredText />
        </IonCol>
      </IonRow>
      <Labels />
      {getLoading() ? (
        <IonRow>
          <IonCol className="flexr-center">
            <IonSpinner name="crescent" color="secondary" />
          </IonCol>
        </IonRow>
      ) : (
        roomsData && hotel && <Rooms {...{ rooms, roomsData, hotel }} />
      )}
      <Rewards {...{ rewards: rewards as iReward[] }} />
    </IonGrid>
  );
};

interface iHeaderProps {
  loading: boolean;
  hotel: iHotel | null;
  onClose(): void;
  start_at: number;
  end_at: number;
}

const Header: React.FC<iHeaderProps> = ({
  loading,
  hotel,
  onClose,
  start_at,
  end_at,
}) => {
  return (
    <Fragment>
      <IonRow style={{ marginTop: "3vh" }}>
        <IonCol size="1"></IonCol>
        <IonCol>
          <Chivo32
            text={!loading && hotel ? hotel?.nameToDisplay : ""}
            color="primary"
            bold
            centeredText
          />
        </IonCol>
        <IonCol size="1">
          <IonButton fill="clear" onClick={onClose}>
            <IonIcon
              src={closeOutline}
              color="danger"
              style={{ fontSize: "50px" }}
            />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow className={styles.header_timespan}>
        <IonCol className="flexr-center">
          <Text10 text={epochToDate(start_at)} mr={1} />
          <IonIcon src={calendar} />
          <Text10 text={epochToDate(end_at)} ml={1} />
        </IonCol>
      </IonRow>
    </Fragment>
  );
};

interface iRoomsProps {
  rooms: string[];
  roomsData: Record<string, iSensorData2>;
  hotel: iHotel;
}

const Rooms: React.FC<iRoomsProps> = ({ rooms, roomsData, hotel }) => {
  const roomsInfo = useMemo(() => {
    const { energy2footprint, water2footprint } = hotel;
    const footprints: Record<string, number> = {};
    let max_footprint: number = 0;

    rooms.forEach((room) => {
      if (!roomsData[room]) footprints[room] = 0;
      else {
        footprints[room] =
          energy2footprint * (sum(roomsData[room].te) || 0) +
          water2footprint * (sum(roomsData[room].tw) || 0);

        if (footprints[room] > max_footprint) max_footprint = footprints[room];
      }
    });

    return rooms.map((room, key) => {
      return (
        <IonRow className={styles.room_info} {...{ key }}>
          <IonCol size="3">
            <Text14 text={room} />
          </IonCol>
          <IonCol className={styles.bar}>
            <IonProgressBar
              value={
                footprints[room] === 0
                  ? footprints[room]
                  : footprints[room] / max_footprint
              }
              color="primary"
              className={"shadow_b"}
            />
          </IonCol>
          <IonCol size="3">
            <Chivo20 text={footprints[room].toFixed(2)} color="secondary" />
          </IonCol>
        </IonRow>
      );
    });
  }, [rooms, roomsData, hotel]);

  return <Fragment>{roomsInfo}</Fragment>;
};

const Labels: React.FC = () => {
  return (
    <IonRow className={styles.labels}>
      <IonCol size="3">
        <Text16 text="Room" />
      </IonCol>
      <IonCol className={styles.labels_mid_col}>
        <IonIcon src={footprint} />
        <Text16 text="Footprint" />
      </IonCol>
      <IonCol size="3">
        <Chivo14 text="kg CO2e" bold />
      </IonCol>
    </IonRow>
  );
};

interface iRewardsProps {
  rewards: iReward[];
}

const Rewards: React.FC<iRewardsProps> = ({ rewards }) => {
  const rewards_list = useMemo(() => {
    return rewards.map((reward, key) => (
      <RewardCard {...{ reward, style: { marginTop: "1vh" }, key }} />
    ));
  }, [rewards]);

  return (
    <Fragment>
      <IonRow style={{ marginTop: "3vh" }}>
        <IonCol>
          <Chivo20 text="Rewards" centeredText color="secondary" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol style={{ margin: "0 500px" }}>
          <IonListColored>{rewards_list}</IonListColored>
        </IonCol>
      </IonRow>
    </Fragment>
  );
};

export default CompetitionInfo;
