const profile = {
  noachievements_profile_caption:
    "Ainda não tem proezas registadas. Sê mais sustentável com a noytrall para conquistares proezas.",
  follow_profile_caption:
    "Segue-nos nas redes sociais para saberes mais sobre a noytrall",
  points_profile: "pontos",
  level_profile: "nível",
  friends_profile: "amigos",
};

export default profile;
