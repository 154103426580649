import React from "react";

interface iHiddenTagProps {
  style?: Record<string, string>;
  [props: string]: any;
}

const HiddenTag: React.FC<iHiddenTagProps> = ({ style = {}, ...props }) => {
  return (
    <p style={{ visibility: "hidden", ...style }} {...props}>
      T
    </p>
  );
};

export default HiddenTag;
