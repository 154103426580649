import { IonHeader, IonImg, IonToolbar } from "@ionic/react";
import React from "react";

import { useDataModal } from "../../../context/DataModalContext/DataModalContext";

import hotel_img from "../../../img/hotel.png";

import styles from "./header.module.css";
import HotelNameAddress from "../../ManagerComponents/HotelInfo/HotelNameAddress/HotelNameAddress";

interface iHeaderProps {}

const Header: React.FC<iHeaderProps> = () => {
  const {
    state: { reservation },
  } = useDataModal();
  const {
    hotel: { nameToDisplay, location, images },
    startAt,
    endAt,
  } = reservation!;

  return (
    <IonHeader className={styles.container}>
      <IonToolbar color="header">
        <IonImg src={images ? images[0] : hotel_img} />
        <HotelNameAddress {...{ nameToDisplay, location, startAt, endAt }} />
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
