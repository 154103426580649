import axios from "axios";
import React from "react";
import { nUserContext } from "../../interfaces";
import { UserAccountDispatchContext } from "../../UserContext";

const useUserAccountDispatch = () => {
  const dispatch = React.useContext(UserAccountDispatchContext);

  if (!dispatch)
    throw Error(
      "useUserAccountDispatch must be used within UserAccountDispatchContext"
    );

  const setAccount = React.useCallback(
    (data: nUserContext.tStateData) => dispatch({ type: "resolved", data }),
    [dispatch]
  );

  const getAccountFromAPI = async () => {
    return axios.get("/users/user/");
  };

  const loadAccountFromAPI = async () => {
    try {
      const res = await axios.get("/users/user/");
      const { user } = res.data;
      console.log("res.user", user);
      setAccount({ user });
    } catch (err) {
      dispatch({
        type: "rejected",
        error: "Failed to load account",
      });
    }
  };

  return { setAccount, getAccountFromAPI, loadAccountFromAPI };
};
export default useUserAccountDispatch;
