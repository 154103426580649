const rewards = {
  new_reward: "New Reward",
  rewards: "Rewards",
  estimated_value: "",
  sector: "",
  type: "",
  location: "",
};

export default rewards;
