import { IonText } from "@ionic/react";
import { isArray } from "lodash";
import React from "react";

const DEFAULT_TEXT_COLOR = "secondary";

export interface iTextProps {
  text: any | any[];

  color?: string;
  className?: string;
  size?: string;
  ml?: number; // left margin
  mr?: number; // right margin
  mb?: number; // bottom margin
  mt?: number; // top margin
  mh?: number; // horizontal margin
  mv?: number; // vertical margin
  style?: Record<string, string>;
  bold?: boolean;
  withTrailingWhiteSpace?: boolean;
  centeredText?: boolean;
  wordBreak_breakAll?: boolean;
  textAlign?: "justify" | "center";
  [key: string]: any;
}

interface iTag extends iTextProps {
  font: "chivo" | "montserrat";
  onClick?: (() => void) | (() => void)[];
}

const Tag: React.FC<iTag> = ({
  font,
  text,
  color,
  className,
  size,
  style,
  ml,
  mr,
  mt,
  mb,
  mh,
  mv,
  bold = false,
  withTrailingWhiteSpace = false,
  centeredText = false,
  wordBreak_breakAll = false,
  textAlign,
  onClick,
  ...rest
}) => {
  const renderPTags = () => {
    return (isArray(text) ? text : [text]).map((t, i) => {
      return (
        <IonText
          onClick={!isArray(onClick) ? onClick : onClick[i]}
          key={t}
          color={color || DEFAULT_TEXT_COLOR}
          className={`${className}`}
          style={
            {
              ...style,
              ...(centeredText !== false
                ? { display: "flex", justifyContent: "center" }
                : {}),
            } || {}
          }
          {...rest}
        >
          <p
            className={font}
            style={{
              fontWeight: bold !== false ? "bold" : "normal",
              whiteSpace: withTrailingWhiteSpace !== false ? "pre" : "normal",
              ...(size ? { fontSize: size } : {}),
              ...(mh
                ? { marginLeft: `${mh}vw`, marginRight: `${mh}vw` }
                : {
                    ...(ml ? { marginLeft: `${ml}vw` } : {}),
                    ...(mr ? { marginRight: `${mr}vw` } : {}),
                  }),
              ...(mt ? { marginTop: `${mt}vh` } : {}),
              ...(mb ? { marginBottom: `${mb}vh` } : {}),
              ...(wordBreak_breakAll !== false
                ? { wordBreak: `break-all` }
                : {}),
              ...(textAlign ? { textAlign } : {}),
            }}
          >
            {t}
          </p>
        </IonText>
      );
    });
  };

  return <>{renderPTags()}</>;
};
export default Tag;
