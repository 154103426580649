import { IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import React from "react";
import { useDataModal } from "../../../../context/DataModalContext/DataModalContext";

import energy_icon from "../../../../img/energy.svg";
import water_icon from "../../../../img/water.svg";
import waste_icon from "../../../../img/waste.svg";
import footprint_icon from "../../../../img/footprint.svg";

import styles from "./styles.module.css";
import Title from "../Title/Title";
import {
  convertUnitEnergy,
  convertUnitMass,
  convertUnitVolume,
} from "../../../../utils/convertUnit";
import { getNumberOfNights } from "../../../../utils/reservations/reservation";
import iReservation from "../../../../interfaces/models/reservation";

interface iSummaryProps {}

const Summary: React.FC<iSummaryProps> = () => {
  const {
    state: { reservation },
  } = useDataModal();

  const { Water, Energy, Waste, Footprint } = reservation!;

  const renderData = (): JSX.Element[] => {
    const DATA: iDataProps[] = [
      { type: "Energy", icon: energy_icon, value: convertUnitEnergy(Energy) },
      { type: "Water", icon: water_icon, value: convertUnitVolume(Water) },
      {
        type: "Footprint",
        icon: footprint_icon,
        value: convertUnitMass(Footprint),
        sub_unit: "CO2 eq",
      },
      // { type: "Waste", icon: waste_icon, value: convertUnitMass(Waste) },
    ];

    return DATA.map((data, i) => <Data key={i} {...data} />);
  };

  return <>{renderData()}</>;
};

interface iDataProps {
  type: string;
  icon: string;
  value?: number | string;
  sub_unit?: string;
}

const Data: React.FC<iDataProps> = ({ type, icon, value, sub_unit }) => {
  return (
    <IonRow className={styles.data}>
      <IonCol className={styles["data-type"]}>
        <IonText color="secondary">
          <p className="montserrat">
            <strong>{type}</strong>
          </p>
        </IonText>
        <IonIcon src={icon} />
      </IonCol>
      <IonCol size="0.5"></IonCol>
      <IonCol className={styles.value}>
        {value !== undefined ? (
          <IonText color="secondary">
            <p className="chivo">
              <strong>
                {value}
                {sub_unit && <small>{` ${sub_unit}`}</small>}
              </strong>
            </p>
          </IonText>
        ) : (
          <IonText color="danger">
            <p className="montserrat">Not being monitored</p>
          </IonText>
        )}
      </IonCol>
    </IonRow>
  );
};

export default Summary;
