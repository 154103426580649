import axios from "axios";
import { useCallback, useMemo, useState } from "react";

import FileUploader from ".";

export interface iFileUploaderHook {
  handleChooseFile(): void;
  handleSubmit(cb: (error: boolean, message: any) => void): void;
  renderInput: JSX.Element;
  getFile(): File | null;
  image: string | null;
  file: File | null;
  reset(): void;
}

const useFileUploader = (inputId: string, path: string): iFileUploaderHook => {
  const [file, setFile] = useState<File | null>(null);

  const handleSubmit = (cb: (error: boolean, message: any) => void) => {
    if (file === null) return;

    const filesToUpload = new FormData();
    // @ts-ignore
    filesToUpload.append("file", file);

    axios
      .post(`images/upload/${path}`, filesToUpload)
      .then((res) => {
        console.log(`res`, res);
        try {
          cb(false, res.data.data.account);
        } catch (err) {
          cb(true, "Something went wrong");
        }
      })
      .catch((err) => {
        console.log(`err`, err);
        try {
          cb(true, err.response.data.message);
        } catch (err) {
          cb(true, "Something went wrong");
        }
      });
  };

  const handleChooseFile = useCallback(() => {
    try {
      document.getElementById(inputId)?.click();
    } catch (e) {}
  }, [inputId]);

  const handleChange = (event: any) => {
    try {
      setFile(event.target.files[0]);
    } catch (e) {}
  };

  const renderInput = useMemo(() => {
    return (
      <FileUploader
        {...{
          inputId,
          handleChange,
        }}
      />
    );
  }, [inputId]);

  const getFile = (): File | null => file;

  const image = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);

  const reset = () => setFile(null);

  return {
    handleChooseFile,
    handleSubmit,
    renderInput,
    getFile,
    image,
    file,
    reset,
  };
};

export const useFileUploaderGuest = (inputId: string) =>
  useFileUploader(inputId, "user");

export default useFileUploader;
