import { IonCol, IonIcon, IonLoading, IonRow } from "@ionic/react";
import React, { Fragment } from "react";
import IonContentColor from "../../../../components/IonContentColor";
import { Text22 } from "../../../../components/Text/Montserrat";
import { modal_id_new_reward } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import NewRewardModal from "../NewRewardModal";
import RewardsList from "./RewardsList";

import voucher from "../../../../img/vouchers.svg";
import { addCircleOutline } from "ionicons/icons";

import styles from "./styles.module.css";
import Refresher from "../../../../components/Refresher/Refresher";
import { ManagerRewardsContextProvider } from "../../../../context/Manager/RewardsContext";
import useManagerRewardsState from "../../../../context/Manager/RewardsContext/hooks/managerRewardsState";
import useManagerRewardsDispatch from "../../../../context/Manager/RewardsContext/hooks/managerRewardsDispatch";
import useManagerAccountState from "../../../../context/Manager/AccountContext/hooks/useManagerAccountState";

interface iRewardsProps {}

const RewardsWrapped: React.FC<iRewardsProps> = () => {
  const { isLoading } = useManagerRewardsState();
  const { loadRewardsFromAPI } = useManagerRewardsDispatch();
  const { currentHotel } = useManagerAccountState();

  const handleRefresh = async (cb: () => void) => {
    await loadRewardsFromAPI(currentHotel?._id);
    cb();
  };

  return (
    <IonContentColor>
      <Refresher onRefresh={handleRefresh} />
      <IonLoading isOpen={isLoading} />
      {!isLoading && (
        <Fragment>
          <NewRewardModal />
          <Header />
          <RewardsList />
        </Fragment>
      )}
    </IonContentColor>
  );
};

interface iHeaderProps {}

const Header: React.FC<iHeaderProps> = () => {
  const { openModal } = useOpenModal();
  const { numberOfRewards } = useManagerRewardsState();

  return (
    <IonRow className={styles.header}>
      <IonCol className={styles.header_left}>
        <IonIcon src={voucher} color="secondary" />
        <Text22 text={numberOfRewards} />
      </IonCol>
      <IonCol className={styles.header_right}>
        <IonIcon
          onClick={() => openModal(modal_id_new_reward)}
          src={addCircleOutline}
          color="secondary"
        />
      </IonCol>
    </IonRow>
  );
};

const Rewards: React.FC = () => {
  return <RewardsWrapped />;
};

export default Rewards;
