import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Fragment, useMemo } from "react";
import { Chivo18 } from "../../../../../../components/Text/Chivo";
import { Text10, Text14 } from "../../../../../../components/Text/Montserrat";
import iCompetition from "../../../../../../interfaces/models/competitions";
import { epochToDate } from "../../../../../../utils/others";

import styles from "./styles.module.css";

interface iCompetitionCardProps {
  competition: iCompetition;
  onClickCard?(): void;
}

const CompetitionCard: React.FC<iCompetitionCardProps> = ({
  competition: { title, start_at, end_at, rooms, winner },
  onClickCard,
}) => {
  const roomsList = useMemo(() => {
    return rooms.join(", ");
  }, [rooms]);

  return (
    <IonCard onClick={onClickCard} button={onClickCard !== undefined}>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <Chivo18 text={title} />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <Text10 text="Rooms" />
              <Text14 text={roomsList} />
            </IonCol>
            <Date text="From" date={start_at} />
          </IonRow>
          <IonRow>
            <IonCol>
              {winner && (
                <Fragment>
                  <Text10 text="Winner" />
                  <Text14 text={winner.room} />
                </Fragment>
              )}
            </IonCol>
            <Date text="To" date={end_at} />
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

interface iDateProps {
  text: string;
  date: number;
}

const Date: React.FC<iDateProps> = ({ text, date }) => {
  return (
    <IonCol className={styles.date}>
      <Text10 {...{ text, style: { marginRight: "3vw" } }} />
      <Text14 text={epochToDate(date)} />
    </IonCol>
  );
};

export default CompetitionCard;
