import { IonCol, useIonPicker } from "@ionic/react";
import React, { forwardRef } from "react";

import house from "../../img/house.svg";
import SlidePicker1, { getSliderId } from "../SlidePicker/First";
import { Text16 } from "../Text/Montserrat";

const HOTEL_INDEX: number = 0;

interface iRoomsProps {
  setRoom(room: number): void;
  rooms: string[];
  slider_id: string;
}

const RoomSelectionSlider = forwardRef<React.RefAttributes<any>, iRoomsProps>(
  ({ setRoom, slider_id, rooms }, ref) => {
    const [present] = useIonPicker();

    const handleChangeRoom = async (e: CustomEvent<void>) => {
      setRoom(await (e.target as HTMLIonSlidesElement).getActiveIndex());
    };

    const handleClickShowAvailableRooms = () => {
      const name = "room";
      present({
        buttons: [
          {
            text: "confirm",
            handler: (selected) => {
              const room = selected[name].value;
              setRoom(room);
              (
                document.getElementById(
                  getSliderId(slider_id)
                ) as HTMLIonSlidesElement
              ).slideTo(room);
            },
          },
        ],
        columns: [
          {
            name,
            options: getRooms().map((room, i) => ({ text: room, value: i })),
          },
        ],
        animated: true,
        // mode: "md",
      });
    };

    const handleClickHomeIcon = async (slider_id: string) => {
      setRoom(HOTEL_INDEX);
      await (
        document.getElementById(slider_id) as HTMLIonSlidesElement
      ).slideTo(HOTEL_INDEX);
    };

    const getRooms = () => {
      const list: string[] = ["Hotel"];
      rooms.forEach((room, i) => {
        list.push(`Room ${room}`);
      });

      return list;
    };

    return (
      <SlidePicker1
        ref={ref}
        onSlideDidChange={handleChangeRoom}
        list={getRooms()}
        icon={house}
        slider_id={slider_id}
        onClickIcon={handleClickHomeIcon}
        onClickCurrentValue={handleClickShowAvailableRooms}
      />
    );
  }
);

interface iRoomSelectionSliderWithTitleProps extends iRoomsProps {
  margin_bottom?: number;
}

export const RoomSelectionSliderWithTitle = forwardRef<
  any,
  iRoomSelectionSliderWithTitleProps
>(({ margin_bottom = 2, ...props }, ref) => {
  return (
    <IonCol className="flexc-center">
      <Text16 text="Room Selection" mb={margin_bottom} />
      <RoomSelectionSlider {...{ ...props, ref }} />
    </IonCol>
  );
});

export default RoomSelectionSlider;
