import axios from "axios";
import { has } from "lodash";
import moment from "moment";
import React, { useReducer, useEffect, useCallback } from "react";
import { DEFAULT_ERROR_MESSAGE } from "../../../utils/httpResponses/consts";
import { sortReservations } from "../../../utils/reservations/sortReservations";
import useManagerAccountState from "../AccountContext/hooks/useManagerAccountState";
import { nManagerReservations } from "./interfaces";

const LS_DATA = "__mr__";
const SAVE_DATA_TO_LS = true;
const save = (data: nManagerReservations.iStateData) => {
  if (SAVE_DATA_TO_LS) localStorage.setItem(LS_DATA, JSON.stringify(data));
};

const ManagerReservationsDispatchContext = React.createContext<
  nManagerReservations.tDispatchContext | undefined
>(undefined);
ManagerReservationsDispatchContext.displayName =
  "ManagerReservationsDispatchContext";
const ManagerReservationsStateContext = React.createContext<
  nManagerReservations.tStateContext | undefined
>(undefined);
ManagerReservationsStateContext.displayName = "ManagerReservationsStateContext";

const initialState: nManagerReservations.iState = {
  data: {
    sortedReservations: { past: [], ongoing: [], upcoming: [] },
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nManagerReservations.iState,
  action: nManagerReservations.tAction
): nManagerReservations.iState => {
  switch (action.type) {
    case "delete reservation": {
      const { reservationId } = action;
      const data = state.data;
      const { past, upcoming, ongoing } = data.sortedReservations;
      let i = 0;
      for (i = 0; i < past.length; i++) {
        if (past[i]._id === reservationId) {
          past.splice(i, 1);
          break;
        }
      }
      if (i === past.length) {
        for (i = 0; i < upcoming.length; i++) {
          if (upcoming[i]._id === reservationId) {
            upcoming.splice(i, 1);
            break;
          }
        }
        if (i === upcoming.length) {
          for (i = 0; i < ongoing.length; i++) {
            if (ongoing[i]._id === reservationId) {
              ongoing.splice(i, 1);
              break;
            }
          }
        }
      }

      return { ...state, data, status: "resolved", error: null };
    }

    case "new reservation": {
      const data = state.data;
      const now = moment();

      if (moment(action.reservation.startAt).isAfter(now)) {
        data.sortedReservations.upcoming.push(action.reservation);
      } else if (moment(action.reservation.endAt).isBefore(now)) {
        data.sortedReservations.past.push(action.reservation);
      } else {
        data.sortedReservations.ongoing.push(action.reservation);
      }
      save(data);
      return { ...state, data, status: "resolved", error: null };
    }
    case "set reservations": {
      const data = {
        sortedReservations: sortReservations(action.reservations),
      };
      save(data);
      return { ...state, data };
    }
    case "set data":
      save(action.data);
      return { ...state, data: action.data, status: "resolved", error: null };
    case "resolved":
      return { ...state, status: "pending", error: null };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    default:
      return { ...state };
  }
};

const ManagerReservationsContextProvider: React.FC<
  nManagerReservations.iContextProps
> = ({ children }) => {
  const [state, dispatch]: [
    nManagerReservations.iState,
    React.Dispatch<nManagerReservations.tAction>
  ] = useReducer(reducer, initialState);
  const { currentHotel } = useManagerAccountState();

  const get = useCallback((hotelId) => {
    console.log(`/reservations/hotel/${hotelId}`);
    dispatch({ type: "pending" });
    axios
      .get(`/reservations/hotel/${hotelId}`)
      .then((res) => {
        const {
          data: { reservations },
        } = res;
        const sortedReservations = sortReservations(reservations);
        const data = { sortedReservations };
        dispatch({ type: "set data", data });
      })
      .catch((err) => {
        dispatch({
          type: "rejected",
          error: has(err, "response.data.message")
            ? err.response.data.message
            : DEFAULT_ERROR_MESSAGE,
        });
      });
  }, []);

  useEffect(() => {
    function main() {
      dispatch({ type: "pending" });
      const data = localStorage.getItem(LS_DATA);

      if (!data) return get(currentHotel._id);

      try {
        const parsed = JSON.parse(data);
        dispatch({ type: "set data", data: parsed });
      } catch (err) {
        localStorage.removeItem(LS_DATA);
        get(currentHotel._id);
      }
    }
    if (currentHotel && currentHotel._id) main();
  }, [get, currentHotel]);

  return (
    <ManagerReservationsStateContext.Provider value={state}>
      <ManagerReservationsDispatchContext.Provider value={dispatch}>
        {children}
      </ManagerReservationsDispatchContext.Provider>
    </ManagerReservationsStateContext.Provider>
  );
};

export {
  ManagerReservationsContextProvider,
  ManagerReservationsDispatchContext,
  ManagerReservationsStateContext,
};
