import React from "react";
import IonDesktopContentColor from "../../../../components/IonDesktopContentColor";
import { ManagerRewardsContextProvider } from "../../../../context/Manager/RewardsContext";
import MainView from "./MainView";

interface iRewardsDesktopProps {}

const RewardsDesktopWrapped: React.FC<iRewardsDesktopProps> = () => {
  return (
    <IonDesktopContentColor>
      <MainView />
    </IonDesktopContentColor>
  );
};

const RewardsDesktop: React.FC = () => {
  return (
    <ManagerRewardsContextProvider>
      <RewardsDesktopWrapped />
    </ManagerRewardsContextProvider>
  );
};

export default RewardsDesktop;
