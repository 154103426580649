import { IonList } from "@ionic/react";
import React from "react";
import { iWrapperComponent } from "../../interfaces";

interface iIonListColoredProps extends iWrapperComponent {}

const IonListColored: React.FC<iIonListColoredProps> = ({
  children,
  ...props
}) => {
  return (
    <IonList
      style={{ backgroundColor: "var(--ion-color-light)" }}
      {...{ props }}
    >
      {children}
    </IonList>
  );
};

export default IonListColored;
