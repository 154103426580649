const global = {
  water: "Water",
  energy: "Energy",
  footprint: "Footprint",

  overall: "overall",
  occupied: "occupied",
  vacant: "vacant",

  title: "Title",

  competitions: "Competitions",

  friends: "Friends",
  friend_requests: "Friend Requests",
};

export default global;
