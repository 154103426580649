import { IonCol, IonRow } from "@ionic/react";
import React from "react";

import OptionsPicker from "./OptionsPicker";
import TimePicker from "./TimePicker";
import DatePicker from "./DatePicker";

import styles from "./styles.module.css";

interface iPickerRowProps {
  children: React.ReactChild;
  row_styles?: string;
}

const PickerRow: React.FC<iPickerRowProps> = ({ children, row_styles }) => {
  return (
    <IonRow className={`${styles.picker_row} ${row_styles || ""}`}>
      <IonCol>{children}</IonCol>
    </IonRow>
  );
};

export { DatePicker, TimePicker, OptionsPicker };
export default PickerRow;
