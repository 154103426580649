import {
  IonCol,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonRow,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

import { Route, useHistory } from "react-router-dom";
import React, { useEffect } from "react";

import withAuthHeader from "../hoc/WithAuthHeader";

import Stays from "../pages/User/Stays/Stays";
import Profile from "../pages/User/Profile/";
import Stats from "../pages/User/Stats/Stats";
import Explore from "../pages/User/Explore/Explore";
import Rewards from "../pages/User/Rewards/MainView";

import profile from "../img/profile_logo.svg";
import rewards from "../img/rewards_logo.svg";
import explore from "../img/explore_logo.svg";
import stats from "../img/noytrall_symbol.svg";
import stays from "../img/stays_logo.svg";

import Menu from "../components/Menu/Menu";
import { ProfileContextProvider } from "../context/ProfileContext/ProfileContext";
import strings from "../localization/localization";

import useMediaQuery from "../hooks/mediaQuery";
import IonContentColor from "../components/IonContentColor";
import { Chivo32 } from "../components/Text/Chivo";
import SlimButton from "../components/SlimButton";
import { useAuth } from "../context/AuthContext/AuthContext";
import EditUsernameModal from "../components/Account/EditUsernameModal";
import AddFriendModal from "../components/Account/AddFriendModal";
import UserContextProvider from "../context/User/UserContext/UserContext";
import UserReservationsContextProvider from "../context/User/UserReservationsContext/UserReservationsContext";
import StoreRewards from "../pages/User/Rewards/StoreRewards";
import HotelRewards from "../pages/User/Rewards/HotelRewards";
import { NotificationsContextProvider } from "../context/User/NotificationsContext";
import Notifications from "../components/Menu/Notifications";
import AccountModal from "../components/Account";
import Redeem from "../pages/User/Redeem";

const NavigationUserWrapped: React.FC = () => {
  const { Logout } = useAuth();
  const { isDesktop } = useMediaQuery();
  const history = useHistory();

  useEffect(() => {
    const allowed = [
      "/stays",
      "/explore",
      "/stats",
      "/rewards",
      "/profile",
    ].includes(window.location.pathname);

    if (!allowed) history.push("/stays");
  }, [history]);

  if (isDesktop) {
    return (
      <IonContentColor>
        <IonRow>
          <IonCol>
            <Chivo32
              text="Guests Page is only available in mobile format"
              color="danger"
              centeredText
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ margin: "0 35%" }}>
            <SlimButton color="primary" text="Log out" onClick={Logout} />
          </IonCol>
        </IonRow>
      </IonContentColor>
    );
  }

  return (
    <React.Fragment>
      <Notifications />
      <AccountModal />
      <EditUsernameModal />
      <AddFriendModal />
      {/* <CompleteProfileModal /> */}
      <Menu />
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/:tab(stays)" component={withAuthHeader(Stays)} exact />
          {/* <Route
            path="/:tab(explore)"
            component={withAuthHeader(Explore)}
            exact
          /> */}
          <Route path="/:tab(zero)" component={withAuthHeader(Stats)} exact />
          <Route
            path="/:tab(redeem)"
            component={withAuthHeader(Redeem)}
            exact
          />
          {/* <Route
            path="/:tab(rewards)"
            component={withAuthHeader(Rewards)}
            exact
          />
          <Route
            path="/:tab(rewards)/store-rewards/:storeId"
            component={withAuthHeader(StoreRewards, { withBackButton: true })}
            exact
          />
          <Route
            path="/:tab(rewards)/hotel-rewards/:hotelId"
            component={withAuthHeader(HotelRewards, { withBackButton: true })}
            exact
          /> */}
          <Route
            path="/:tab(profile)"
            component={withAuthHeader(Profile)}
            exact
          />
        </IonRouterOutlet>

        <IonTabBar
          // style={{ display: "none" }}
          id="navbar"
          color="navbar"
          slot="bottom"
        >
          <IonTabButton tab="stays" href="/stays">
            <IonIcon src={stays} />
            <IonLabel>{strings.stays}</IonLabel>
          </IonTabButton>
          {/* <IonTabButton tab="explore" href="/explore">
            <IonIcon src={explore} />
            <IonLabel>{strings.explore}</IonLabel>
          </IonTabButton> */}
          <IonTabButton tab="zero" href="/zero">
            <IonIcon src={stats} />
            <IonLabel>{strings.stats}</IonLabel>
          </IonTabButton>
          {/* <IonTabButton tab="redeem" href="/redeem">
            <IonIcon src={rewards} />
            <IonLabel>Redeem</IonLabel>
          </IonTabButton> */}
          {/* <IonTabButton tab="reward" href="/rewards">
            <IonIcon src={rewards} />
            <IonLabel>{strings.rewards.rewards}</IonLabel>
          </IonTabButton> */}
          <IonTabButton tab="profile" href="/profile">
            <IonIcon src={profile} />
            <IonLabel>{strings.profile}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </React.Fragment>
  );
};

const NavigationUser: React.FC = () => {
  return (
    <>
      <ProfileContextProvider>
        <UserContextProvider>
          <UserReservationsContextProvider>
            <NotificationsContextProvider>
              {/* <GlobalErrorBoundary> */}
              {/* <ReservationContextProvider> */}
              {/* <RewardsContextProvider> */}
              {/* <LoadingContextProvider> */}
              <NavigationUserWrapped />
              {/* </LoadingContextProvider> */}
              {/* </RewardsContextProvider> */}
              {/* </ReservationContextProvider> */}
              {/* </GlobalErrorBoundary> */}
            </NotificationsContextProvider>
          </UserReservationsContextProvider>
        </UserContextProvider>
      </ProfileContextProvider>
    </>
  );
};

export default NavigationUser;
