import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonImg,
  IonLoading,
  IonRow,
} from "@ionic/react";
import React from "react";
import { useOpenModal } from "../../../context/OpenModalContext/OpenModalContext";
import useLoading from "../../../hooks/loading";
import { iFileUploaderHook } from "../../FileUploader/hooks";
import HeaderWithInfo from "../../HeaderWithInfo";
import IonContentColor from "../../IonContentColor";
import ModalContentFooter2Buttons from "../../ModalContentFooter2Buttons";

export interface iContentProps extends iFileUploaderHook {
  defaultImage?: any;
  title: string;
  modal_id: number;
  // hook(input_id: string): any;
  onConfirm(account: any): void;
}

const Content: React.FC<iContentProps> = ({
  defaultImage = null,
  title,

  file,
  handleChooseFile,
  handleSubmit,
  image,
  renderInput,
  reset,

  modal_id,
  // hook,
  onConfirm,
}) => {
  const { setLoading, getLoading } = useLoading();
  const { closeModal } = useOpenModal();

  const handleConfirm = () => {
    if (!file) return;

    const cb = (error: boolean, message: any) => {
      console.log(`message`, message);
      if (!error) {
        reset();
        onConfirm(message);
        handleCloseModal();
      } else {
        // setLoading(false);
      }
      setLoading(false);
    };

    setLoading(true);
    handleSubmit(cb);
  };

  const handleCloseModal = () => {
    closeModal(modal_id);
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  const pic_size = "150px";

  return (
    <IonContentColor color="beje">
      <IonLoading isOpen={getLoading()} />
      {renderInput}
      <IonGrid>
        <HeaderWithInfo {...{ title }} info="Click the image to change it" />
        <IonRow>
          <IonCol className="flexr-center">
            <IonAvatar
              style={{ height: pic_size, width: pic_size }}
              onClick={handleChooseFile}
            >
              <IonImg
                src={
                  image ||
                  defaultImage ||
                  "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg"
                }
              />
            </IonAvatar>
          </IonCol>
        </IonRow>
        <ModalContentFooter2Buttons
          disabled_buttons={getLoading()}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      </IonGrid>
    </IonContentColor>
  );
};

export default Content;
