import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import React from "react";

import { locationSharp } from "ionicons/icons";

import { epochTimelineToString } from "../../../../../utils/others";

import "./card_header.css";
import iHotel from "../../../../../interfaces/models/hotel";

interface iCardHeaderProps {
  hotel: iHotel;
  startAt: number;
  endAt: number;
}

const CardHeader: React.FC<iCardHeaderProps> = ({ hotel, startAt, endAt }) => {
  const { nameToDisplay } = hotel;
  const { address } = hotel.location;

  return (
    <IonCardHeader className="current_res-card-header">
      <div>
        <IonCardTitle>
          <h4 className="chivo">{nameToDisplay.substring(0, 20)}</h4>
        </IonCardTitle>
        <IonCardSubtitle>
          <p className="montserrat">{address}</p>
          <IonIcon icon={locationSharp} />
        </IonCardSubtitle>
      </div>
      <div className="current_res-card-header-date">
        <p className="montserrat">{`${epochTimelineToString(
          startAt,
          endAt
        )}`}</p>
      </div>
    </IonCardHeader>
  );
};
export default CardHeader;
