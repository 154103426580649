import { IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import React from "react";
import { tDataLabel } from "../../../../context/DataModalContext/interfaces";
import { translateDataLabelToColor } from "../../../../utils/graph";

import styles from "./styles.module.css";

interface iDonutLabelsProps {
  labels: tDataLabel[];
}

const DonutLabels: React.FC<iDonutLabelsProps> = ({ labels }) => {
  const renderDonutLabels = (): JSX.Element[] => {
    return labels.map((label, i) => {
      return (
        <IonRow key={i}>
          <IonCol className={styles.label + " flexr-center"}>
            <IonIcon
              style={{ color: translateDataLabelToColor(label) }}
              src={ellipse}
            />
            <IonText color="secondary">
              <p className="montserrat">{label}</p>
            </IonText>
          </IonCol>
        </IonRow>
      );
    });
  };

  return (
    <IonRow>
      <IonCol className={styles.labels}>{renderDonutLabels()}</IonCol>
    </IonRow>
  );
};

export default DonutLabels;
