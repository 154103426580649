import React from "react";
import {
  modal_id_add_friend,
  modal_id_friends_list,
} from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import MenuModalHeader from "../../../../components/MenuModalHeader";
import ModalWrapper from "../../../../components/ModalWrapper";

import add_friend from "../../../../img/add_friend.svg";

import IonContentColor from "../../../../components/IonContentColor";
import UserCard from "../../../../components/UserCard";
import useUserAccountState from "../../../../context/User/UserContext/hooks/Account/useUserAccountState";
import { isString } from "lodash";

interface iFriendsListModalProps {}

const FriendsListModal: React.FC<iFriendsListModalProps> = () => {
  const { openModal, closeModal } = useOpenModal();
  const {
    user: { friends },
  } = useUserAccountState();

  const handleClickAddFriend = () => {
    openModal(modal_id_add_friend);
  };

  const renderFriends = () => {
    return [...friends]
      .filter((f) => !isString(f.user))
      .map((friend, i) =>
        isString(friend.user) ? null : (
          <UserCard
            key={i}
            user={friend.user}
            cardColor="light"
            friendsUsernameColor="secondary"
            // display_level_instead_of_nFriends
            // displayLevelInsteadOfNumberOfFriends
          />
        )
      );
  };

  return (
    <ModalWrapper modal_id={modal_id_friends_list}>
      <MenuModalHeader
        title="Friends"
        onExit={() => closeModal(modal_id_friends_list)}
        extra_icon={add_friend}
        onClickExtraIcon={handleClickAddFriend}
      />
      <IonContentColor>{renderFriends()}</IonContentColor>
    </ModalWrapper>
  );
};

export default FriendsListModal;
