import React, { useMemo } from "react";
import SlidePicker1, {
  getSliderId,
} from "../../../../../components/SlidePicker/First";
import {
  iTimespanElement,
  MONTH_FILTER,
  useHotelStays,
  YEAR_FILTER,
} from "../../../../../context/HotelStaysContext";
import moment from "moment";
import calendar from "../../../../../img/calendar.svg";
import { slider_id_hotel_stays_timespan } from "../../../../../utils/sliders";

import styles from "./styles.module.css";
import { useIonPicker } from "@ionic/react";
import useManagerAccountState from "../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";

interface iTimespanProps {}

const Timespan: React.FC<iTimespanProps> = () => {
  const [present] = useIonPicker();
  const { minYear } = useManagerAccountState();
  const {
    getTimespan,
    setTimespanIndex,
    setTimespanAndTimespanIndex,
    getTimespanList,
    getTimespanIndex,
    generateTimespanFilterName,
  } = useHotelStays();

  const handleChangeTimespanIndex = async (e: CustomEvent<void>) => {
    const active_index = await (
      e.target as HTMLIonSlidesElement
    ).getActiveIndex();
    setTimespanIndex(
      active_index,
      generateTimespanFilterName(getTimespan(), active_index)
    );
  };

  const handleClickCalendar = (slider_id: string) => {};

  const getFiltersList = (): string[] => {
    return getTimespanList()[getTimespan()].values;
  };

  const handleChooseTimespan = (timespan: number) => {
    if (timespan === getTimespan()) return;

    const { filter }: iTimespanElement = getTimespanList()[timespan];

    const timespan_index: number =
      filter === YEAR_FILTER
        ? moment().year() - minYear
        : // @ts-ignore
          (moment().year() - minYear) * 12 +
          moment().month() -
          (filter === MONTH_FILTER ? 0 : 1);

    setTimespanAndTimespanIndex(
      timespan,
      timespan_index,
      generateTimespanFilterName(filter, timespan_index)
    );

    (
      document.getElementById(
        getSliderId(slider_id_hotel_stays_timespan)
      ) as HTMLIonSlidesElement
    ).slideTo(timespan_index);
  };

  const handleOpenTimespanPicker = () => {
    const name = "timespan";
    present({
      buttons: [
        {
          text: "confirm",
          handler: (selected) => {
            handleChooseTimespan(selected[name].value);
          },
        },
      ],
      columns: [
        {
          name,
          options: getTimespanList().map(({ filter }, i) => {
            return {
              text: filter,
              value: i,
            };
          }),
        },
      ],
      animated: true,
    });
  };

  return (
    // <>
    //   <button onClick={handleClick}>oi</button>
    <SlidePicker1
      onSlideDidChange={handleChangeTimespanIndex}
      onClickCurrentValue={handleOpenTimespanPicker}
      onClickIcon={handleClickCalendar}
      list={getFiltersList()}
      icon={calendar}
      slider_id={slider_id_hotel_stays_timespan}
      className={styles.slider}
      initial_index={getTimespanIndex()}
    />
    // </>
  );
};

export default Timespan;
