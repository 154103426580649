import axios from "axios";
import React, { useReducer, useEffect, useCallback } from "react";
import useManagerAccountState from "../AccountContext/hooks/useManagerAccountState";
import { nManagerRewards } from "./interfaces";

const LS_DATA = "__mrw__";

const ManagerRewardsDispatchContext = React.createContext<
  nManagerRewards.tDispatchContext | undefined
>(undefined);
ManagerRewardsDispatchContext.displayName = "ManagerRewardsDispatchContext";
const ManagerRewardsStateContext = React.createContext<
  nManagerRewards.tStateContext | undefined
>(undefined);
ManagerRewardsStateContext.displayName = "ManagerRewardsStateContext";

const initialState: nManagerRewards.iState = {
  data: {
    rewards: {},
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nManagerRewards.iState,
  action: nManagerRewards.tAction
): nManagerRewards.iState => {
  switch (action.type) {
    case "set rewards":
      const data = {
        rewards: { ...state.data.rewards, [action.hotelId]: action.rewards },
      };
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return {
        ...state,
        status: "resolved",
        error: null,
        data,
      };
    case "set data":
      return { ...state, data: action.data, status: "resolved", error: null };
    case "resolved":
      return { ...state, status: "resolved", error: null };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    case "idle":
      return { ...state, status: "idle", error: null };
    default:
      return { ...state };
  }
};

const ManagerRewardsContextProvider: React.FC<
  nManagerRewards.iContextProps
> = ({ children }) => {
  const [state, dispatch]: [
    nManagerRewards.iState,
    React.Dispatch<nManagerRewards.tAction>
  ] = useReducer(reducer, initialState);
  const { currentHotel } = useManagerAccountState();

  const get = useCallback(async (hotelId) => {
    dispatch({ type: "pending" });
    return axios
      .get(`/rewards/hotel/${hotelId}`)
      .then((res) => {
        console.log("res", res);
        const {
          data: { rewards },
        } = res;

        dispatch({ type: "set rewards", rewards, hotelId });
      })
      .catch((err) => {
        try {
          dispatch({ type: "rejected", error: err.response.data.message });
        } catch (e) {
          dispatch({ type: "rejected", error: err.toJSON().message });
        }
      });
  }, []);

  useEffect(() => {
    async function main() {
      dispatch({ type: "pending" });

      const data = localStorage.getItem(LS_DATA);

      if (!data) return await get(currentHotel._id);

      try {
        const parsed = JSON.parse(data);
        dispatch({ type: "set data", data: parsed });
      } catch (err) {
        localStorage.removeItem(LS_DATA);
        await get(currentHotel._id);
      }
    }
    if (currentHotel && currentHotel._id) main();
  }, [currentHotel, get]);

  return (
    <ManagerRewardsStateContext.Provider value={state}>
      <ManagerRewardsDispatchContext.Provider value={{ dispatch, get }}>
        {children}
      </ManagerRewardsDispatchContext.Provider>
    </ManagerRewardsStateContext.Provider>
  );
};

export {
  ManagerRewardsContextProvider,
  ManagerRewardsDispatchContext,
  ManagerRewardsStateContext,
};
