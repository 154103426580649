import { IonButton, IonCol } from "@ionic/react";
import React from "react";
import { Chivo18 } from "../Text/Chivo";

import styles from "./styles.module.css";

interface iSlimButtonProps {
  color: string;
  onClick?(): void;
  text: string;
  width?: number;
  textColor?: "primary" | "light";
  textStyles?: Record<string, any>;
  buttonStyles?: Record<string, any>;
  disabled?: boolean;
  [key: string]: any;
}

const SlimButton: React.FC<iSlimButtonProps> = ({
  color,
  text,
  onClick = () => {},
  width = 90,
  textColor = "light",
  textStyles,
  buttonStyles,
  disabled = false,
  ...rest
}) => {
  return (
    <IonCol className={styles.button_container} {...rest}>
      <IonButton
        {...{
          disabled,
          fill: "solid",
          color,
          style: { width: `${width}%`, ...(buttonStyles || {}) },
          onClick,
        }}
      >
        <Chivo18 {...{ text, style: textStyles || {} }} color={textColor} />
      </IonButton>
    </IonCol>
  );
};

export default SlimButton;
