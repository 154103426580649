import { IonRouterOutlet } from "@ionic/react";
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router";
import withNonAuthHeader from "../hoc/withNonAuthHeader";
import useMediaQuery from "../hooks/mediaQuery";
import Launch from "../pages/Launch/Launch";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";

interface iNavigationNoAuthProps {}

const NavigationNoAuth: React.FC<iNavigationNoAuthProps> = () => {
  const history = useHistory();
  const { isDesktop } = useMediaQuery();

  useEffect(() => {
    let allowed_paths: string[] = [];
    if (isDesktop) allowed_paths = ["/home"];
    else allowed_paths = ["/login", "/home", "/signup"];

    const allowed = allowed_paths.includes(window.location.pathname);
    if (!allowed) history.push("/home");
  }, [isDesktop, history]);

  if (isDesktop)
    return (
      <IonRouterOutlet>
        <Route path="/home" component={Launch} exact={true} />
      </IonRouterOutlet>
    );

  return (
    <IonRouterOutlet>
      <Route path="/home" component={Launch} exact={true} />
      <Route path="/signup" component={Signup} exact={true} />
      <Route path="/login" component={withNonAuthHeader(Login)} exact={true} />
    </IonRouterOutlet>
  );
};

export default NavigationNoAuth;
