import React from "react";
import { HotelStaysContextProvider } from "../../../context/HotelStaysContext";
import WithMobileDesktopQuery from "../../../hoc/WithMobileDesktopQuery";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

interface iStaysProps {}

const Stays: React.FC<iStaysProps> = () => {
  return (
    <>
      <HotelStaysContextProvider>
        {WithMobileDesktopQuery(Mobile, Desktop)()}
      </HotelStaysContextProvider>
    </>
  );
};

export default Stays;
