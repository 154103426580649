import React, { useEffect, useReducer } from "react";
import _ from "lodash";
import iReservation from "../../interfaces/models/reservation";
import { createCtx } from "../CreateContext";
import { useOpenModal } from "../OpenModalContext/OpenModalContext";
import {
  iDataModalContext,
  iDataModalContextProps,
  iDataModalState,
  iAction,
  tDataModalGraphIndex,
  iOverallDataType,
  tGraph,
  iBarGraph,
  iDonutGraph,
  tDataLabel,
} from "./interfaces";
import { overall_data_types } from "./consts";
import { iSensorData2 } from "../../interfaces/models/sensorData";

import {
  modal_id_data,
  modal_id_stay_report,
} from "../OpenModalContext/modal_ids";
import axios from "axios";
import {
  calculateBarGraphData,
  calculateDonutGraphData2,
  getGraphLabels,
  translateDataLabelToColor,
} from "../../utils/graph";
import { useProfile } from "../ProfileContext/ProfileContext";

const initial_state: iDataModalState = {
  data_type_index: 0,
  reservation: null,
  donut_graph_data: {
    Water: {},
    Energy: {},
    Footprint: {},
    Money: {},
    Waste: {},
  },
  bar_graph_data: {
    Water: {},
    Energy: {},
    Footprint: {},
    Money: {},
    Waste: {},
  },
  tariff: {},
  graph_type: "donut",
  available_types: [],
  loading: true,
};
const reducer = (state: iDataModalState, action: iAction): iDataModalState => {
  switch (action.type) {
    case "set data type":
      return { ...state, data_type_index: action.data_type_index! };
    // return { ...state };

    case "set reservation":
      return { ...state, reservation: action.reservation! };

    case "set graph type":
      return { ...state, graph_type: action.graph_type! };

    case "close report modal":
      return { ...initial_state };

    case "save graph data":
      return {
        ...state,
        loading: false,
        donut_graph_data: action.donut_graph_data!,
        bar_graph_data: action.bar_graph_data!,
        available_types: action.available_types!,
      };

    default:
      return { ...state };
  }
};

const [useDataModal, CtxProvider] = createCtx<iDataModalContext>();

const DataModalContextProvider: React.FC<iDataModalContextProps> = ({
  children,
}) => {
  const modal = useOpenModal();
  const { updateReservation } = useProfile();
  const [state, dispatch]: [iDataModalState, React.Dispatch<iAction>] =
    useReducer(reducer, initial_state);

  useEffect(() => {
    modal.createModal(modal_id_data);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const handleData = (sensor_data: iSensorData2) => {
      const reservation = state.reservation!;
      const { startAt, endAt } = reservation;

      const { energy2footprint, water2footprint } = reservation.hotel;

      const bar_graph_data = calculateBarGraphData(sensor_data, startAt, endAt);

      const donut_graph_data = calculateDonutGraphData2(
        sensor_data,
        energy2footprint,
        water2footprint
      );

      const available_types: iOverallDataType[] = [];
      Object.entries(donut_graph_data).forEach((entry) => {
        const [type, data] = entry;
        if (_.sum(data.datasets[0].data)) {
          for (let i = 0; i < overall_data_types.length; i++) {
            const info = overall_data_types[i];

            if (type === info.title) {
              available_types.push(info as iOverallDataType);
              break;
            }
          }
        }
      });

      dispatch({
        type: "save graph data",
        donut_graph_data,
        bar_graph_data,
        available_types,
      });
    };

    const getData = (): void => {
      const reservation = state.reservation!;
      const { _id } = reservation;

      const check = localStorage.getItem(_id);

      if (!check) {
        axios
          .get(`/reservations/${reservation._id}/user/data`)
          .then((res) => {
            console.log(`res.data`, res.data);
            const { measures, reservation } = res.data;

            updateReservation(reservation);

            localStorage.setItem(_id, JSON.stringify(measures));

            handleData(measures);
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        handleData(JSON.parse(check));
      }
    };

    if (state.reservation) {
      getData();
    }
  }, [state.reservation]);

  const setReservation = (reservation: iReservation) => {
    dispatch({ type: "set reservation", reservation });
  };

  const openGraphModal = (): void => {
    modal.openModal(modal_id_data);
  };

  const closeGraphModal = (): void => {
    modal.closeModal(modal_id_data);
  };

  const openReportModal = (reservation: iReservation): void => {
    setReservation(reservation);
    modal.openModal(modal_id_stay_report);
  };

  const closeReportModal = (): void => {
    modal.closeModal(modal_id_stay_report);
    dispatch({ type: "close report modal" });
  };

  const alterDataType = (inc: 1 | -1) => {
    dispatch({
      type: "set data type",
      data_type_index: (state.data_type_index + inc) as tDataModalGraphIndex,
    });
  };

  const setDataType = (index: tDataModalGraphIndex) => {
    dispatch({ type: "set data type", data_type_index: index });
  };

  const switchGraphType = () => {
    dispatch({
      type: "set graph type",
      graph_type: state.graph_type === "donut" ? "bar" : "donut",
    });
  };

  const getActiveDataType = (): iOverallDataType => {
    const { available_types, data_type_index } = state;
    return available_types[data_type_index];
  };

  const getActiveGraphData = (): iBarGraph | iDonutGraph => {
    const handleData = (sensor_data: iSensorData2) => {
      const reservation = state.reservation!;
      const { startAt, endAt } = reservation;

      const { energy2footprint, water2footprint } = reservation.hotel;

      const bar_graph_data = calculateBarGraphData(sensor_data, startAt, endAt);

      const donut_graph_data = calculateDonutGraphData2(
        sensor_data,
        energy2footprint,
        water2footprint
      );

      const available_types: iOverallDataType[] = [];
      Object.entries(donut_graph_data).forEach((entry) => {
        const [type, data] = entry;
        if (_.sum(data.datasets[0].data)) {
          for (let i = 0; i < overall_data_types.length; i++) {
            const info = overall_data_types[i];

            if (type === info.title) {
              available_types.push(info as iOverallDataType);
              break;
            }
          }
        }
      });
      return [donut_graph_data, bar_graph_data];
    };
    const getData = (): ReturnType<typeof handleData> => {
      const reservation = state.reservation!;
      const { _id } = reservation;

      const check = localStorage.getItem(_id);
      return handleData(JSON.parse(check!));
    };

    const { graph_type } = state;
    const [donut_graph_data, bar_graph_data] = getData();
    const title = getActiveDataType().title;

    return graph_type === "donut"
      ? (donut_graph_data as tGraph<iDonutGraph>)[title]
      : (bar_graph_data as tGraph<iBarGraph>)[title];
  };

  const getActiveGraphLabels = (): tDataLabel[] => {
    const { donut_graph_data } = state;
    return getGraphLabels(
      donut_graph_data as tGraph<iDonutGraph>,
      getActiveDataType()
    );
  };

  const value = {
    state,
    setReservation,
    alterDataType,
    getActiveDataType,
    setDataType,
    translateDataLabelToColor,
    openGraphModal,
    closeGraphModal,
    openReportModal,
    closeReportModal,
    switchGraphType,
    getActiveGraphData,
    getActiveGraphLabels,
    is_open: modal.isOpen(modal_id_data),
  };
  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export { useDataModal, DataModalContextProvider };
