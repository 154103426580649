import { isNull } from "lodash";
import { useCallback } from "react";
import { iHotelRoomMeasures } from "../../../../interfaces/models/hotel";
import {
  defaultMeasureObject,
  tMeasure,
  tSpending,
} from "../../../../interfaces/models/sensorData";
import useManagerAccountState from "./useManagerAccountState";

const useManagerHotelMeasures = () => {
  const { currentHotel } = useManagerAccountState();

  const getOOMeasuresList = useCallback(
    ({
      year = null,
      month = null,
      room = null,
    }: {
      year?: number | null;
      month?: number | null;
      room?: string | null;
    } = {}): iHotelRoomMeasures[] => {
      const { measures } = currentHotel;
      const ooMeasuresList: iHotelRoomMeasures[] = [];

      for (const yearlyMeasures of measures) {
        if (isNull(year) || yearlyMeasures.year === year) {
          for (const monthlyMeasures of yearlyMeasures.months) {
            if (isNull(month) || monthlyMeasures.month === month) {
              for (const roomMeasures of monthlyMeasures.rooms) {
                if (
                  isNull(room) ||
                  roomMeasures.room === `room${room}` ||
                  roomMeasures.room === room
                ) {
                  ooMeasuresList.push(roomMeasures.data);
                }
              }
            }
          }
        }
      }
      return ooMeasuresList;
    },
    [currentHotel]
  );

  const addOOMeasures = useCallback((measures: iHotelRoomMeasures[]) => {
    const ooMeasures = {
      occupied: { ...defaultMeasureObject },
      overall: { ...defaultMeasureObject },
    };

    for (const measure of measures) {
      (["overall", "occupied"] as ["overall", "occupied"]).forEach((ooKey) => {
        if (measure.hasOwnProperty(ooKey)) {
          (Object.keys(measure[ooKey]) as tMeasure[]).forEach((key) => {
            ooMeasures[ooKey][key] += measure[ooKey][key] || 0;
          });
        }
      });
    }
    return ooMeasures;
  }, []);

  const getOOMeasures = useCallback(
    ({
      ooKey = "overall",
      year = null,
      month = null,
      room = null,
    }: {
      year?: number | null;
      month?: number | null;
      room?: string | null;
      ooKey?: "occupied" | "overall" | "vacant";
    } = {}) => {
      const list = getOOMeasuresList({ year, month, room });
      if (!list.length) return null;
      if (ooKey === "vacant") {
        const vacant = { ...defaultMeasureObject };
        const { overall, occupied } = addOOMeasures(list);
        (Object.keys(overall) as tMeasure[]).forEach((key) => {
          vacant[key] = overall[key] - occupied[key];
        });
        return vacant;
      }
      return addOOMeasures(list)[ooKey];
    },
    [getOOMeasuresList, addOOMeasures]
  );

  const getOOSpending = useCallback(
    ({
      ooKey = "overall",
      year = null,
      month = null,
      room = null,
    }: {
      year?: number | null;
      month?: number | null;
      room?: string | null;
      ooKey?: "occupied" | "overall" | "vacant";
    } = {}) => {
      const measures = getOOMeasures({ ooKey, year, month, room });

      const spending: tSpending = {
        Water: 0,
        Energy: 0,
        Footprint: 0,
        Waste: 0,
      };
      if (measures) {
        const { energy2footprint, water2footprint } = currentHotel;
        const { tw, cw, gw, hw, te, es, el, ac } = measures;
        spending.Water = tw || gw + hw + cw;
        spending.Energy = te || es + el + ac;
        spending.Footprint =
          energy2footprint * spending.Energy + water2footprint * spending.Water;
      }
      return spending;
    },
    [currentHotel, getOOMeasures]
  );

  return {
    getOOMeasures,
    getOOSpending,
  };
};

export default useManagerHotelMeasures;
