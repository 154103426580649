import { IonCol, IonRow } from "@ionic/react";
import React, { Fragment, useState } from "react";

import styles from "./styles.module.css";
import Donut from "../../../../../components/ChartJsGraphs/Donut/Donut";
import { calculateDonutGraphData2Money } from "../../../../../utils/graph";
import { useTheme } from "../../../../../context/ThemeContext";
import { useHotelStays } from "../../../../../context/HotelStaysContext";
import { SpendingDataCardIonCol } from "../../../../../components/SpendingDataCard";
import HotelSpendingDataModal from "../../../../../components/HotelSpendingDataModal";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";
import { modal_id_hotel_spending_data } from "../../../../../context/OpenModalContext/modal_ids";
import {
  iSensorData1,
  tSpending,
} from "../../../../../interfaces/models/sensorData";
import { useHotelStaysFunctions } from "../../hooks";
import useManagerAccountState from "../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";

const HOTEL_INDEX: number = 0;

interface iSpendingProps {}

const Spending: React.FC<iSpendingProps> = () => {
  const { openModal, isOpen } = useOpenModal();
  const { getRoom, getTimespan, getTimespanIndex, getTimespanList } =
    useHotelStays();
  const { getBackgroundColor } = useTheme();
  const { currentHotel } = useManagerAccountState();
  const { getCurrentMeasures, getCurrentSpending } = useHotelStaysFunctions();

  const [modalData, setModalData] = useState<iSensorData1 | null>(null);

  const handleClickCard = () => {
    openModal(modal_id_hotel_spending_data);
    setModalData(getCurrentMeasures());
  };

  const renderSpendingData = (): JSX.Element => {
    const { energy2currency, water2currency } = currentHotel;

    const spending: tSpending = getCurrentSpending();
    const { Water: tw, Energy: te } = spending;
    return (
      <Fragment>
        <IonRow>
          <SpendingDataCardIonCol
            display_detail_icon={true}
            onClickCard={handleClickCard}
            color="secondary"
            {...spending}
          />
        </IonRow>
        <IonRow className={styles.graph}>
          <IonCol>
            <Donut
              data={calculateDonutGraphData2Money(
                { te, tw },
                energy2currency,
                water2currency,
                getBackgroundColor()
              )}
            />
          </IonCol>
        </IonRow>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isOpen(modal_id_hotel_spending_data) && modalData && (
        <HotelSpendingDataModal
          room_title={getRoom() === HOTEL_INDEX ? "Hotel" : `Room ${getRoom()}`}
          time_period_title={
            getTimespanList()[getTimespan()].values[getTimespanIndex()]
          }
          data={modalData}
          onClose={() => setModalData(null)}
        />
      )}
      {renderSpendingData()}
    </Fragment>
  );
};

export default Spending;
