import React from "react";
import { TextFieldTypes } from "@ionic/core";

import styles from "./styles.module.css";
import { IonInput, IonItem } from "@ionic/react";

interface iRoundInputProps {
  onChange(value: string): void;
  value: string;
  error: boolean;
  placeholder: string;
  input_type?: TextFieldTypes | undefined;
}

const RoundInput: React.FC<iRoundInputProps> = ({
  onChange,
  value,
  error,
  placeholder,
  input_type = "text",
}) => {
  return (
    <IonItem
      className={styles.item}
      color="tertiary"
      lines="none"
      style={{ border: error ? "1px red solid" : "" }}
    >
      <IonInput
        {...{
          onIonChange: (e) => onChange(e.detail.value!),
          color: "secondary",
          placeholder,
          value,
          type: input_type,
        }}
      ></IonInput>
    </IonItem>
  );
};

export default RoundInput;
