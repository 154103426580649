import React, { useMemo } from "react";
import { UserAccountStateContext } from "../../UserContext";

const useUserAccountState = () => {
  const state = React.useContext(UserAccountStateContext);

  if (!state)
    throw Error(
      "useUserAccountState must be used within UserAccountStateContext"
    );

  const user = useMemo(() => {
    return state.data.user;
  }, [state.data.user]);

  const numberOfRewards = useMemo(() => {
    let totalCount = 0;

    for (const { count } of user.rewards) {
      totalCount += count;
    }

    return totalCount;
  }, [user]);

  return { error: state.error, status: state.status, user, numberOfRewards };
};
export default useUserAccountState;
