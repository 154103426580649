const navbar = {
  stays: "Estadias",
  stats: "Zero",
  explore: "Explore",
  profile: "Perfil",
  search: "Pesquisa",
  games: "Jogos",
  hotel: "Hotel",
  bookings: "Reservas",
};

export default navbar;
