import { IonCol } from "@ionic/react";
import React from "react";
import { epochTimelineToString } from "../../../utils/others";
import Address, { iAddressProps } from "../../Address/Address";
import { Chivo18 } from "../../Text/Chivo";
import { Text10 } from "../../Text/Montserrat";

import styles from "./hotel_info.module.css";

export interface iHotelInfoProps {
  nameToDisplay: string;
  endAt?: number;
  startAt?: number;
  position: "start" | "end";
  hotel_name_max_characters?: number;
  addressProps?: iAddressProps;
}

const HotelInfo: React.FC<iHotelInfoProps> = ({
  nameToDisplay,
  startAt,
  endAt,
  position,
  hotel_name_max_characters = 20,
  addressProps,
}) => {
  return (
    <IonCol
      className={styles.container}
      style={{ alignItems: `flex-${position}` }}
    >
      <Chivo18
        color="light"
        text={nameToDisplay.substring(0, hotel_name_max_characters)}
      />
      {startAt && endAt && (
        <Text10
          color="light"
          text={`${epochTimelineToString(startAt, endAt)}`}
        />
      )}
      {addressProps && addressProps.location && (
        <Address {...{ ...addressProps, fontSize: "10px" }} color="light" />
      )}
    </IonCol>
  );
};

export default HotelInfo;
