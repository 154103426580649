import {
  iBarGraphDatasetOptions,
  iBarGraph,
  tDataColor,
  tDataLabel,
  tDataModalData,
  tDataSignature,
  iDonutGraph,
  iOverallDataType,
} from "./interfaces";

import leaf_unselected from "../../img/leaf_unselected.svg";
import leaf_selected from "../../img/leaf_selected.svg";
import water_unselected from "../../img/water_unselected.svg";
import water_selected from "../../img/water_selected.svg";
import energy_unselected from "../../img/energy_unselected.svg";
import energy_selected from "../../img/energy_selected.svg";
import waste_unselected from "../../img/waste_unselected.svg";
import waste_selected from "../../img/waste_selected.svg";

export const overall_data_types: iOverallDataType[] = [
  {
    title: "Water",
    icon_selected: water_selected,
    icon_unselected: water_unselected,
  },
  {
    title: "Energy",
    icon_selected: energy_selected,
    icon_unselected: energy_unselected,
  },
  {
    title: "Footprint",
    icon_selected: leaf_selected,
    icon_unselected: leaf_unselected,
  },
  {
    title: "Waste",
    icon_selected: waste_selected,
    icon_unselected: waste_unselected,
  },
];
