import { IonButton, IonCol, useIonPicker } from "@ionic/react";
import React, { Fragment } from "react";
import PickerRow from "..";
import useMediaQuery from "../../../hooks/mediaQuery";
import { Text14, Text16 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

interface iClickerPickerProps extends iPickerProps {}

const ClickerPicker: React.FC<iClickerPickerProps> = ({
  title,
  value,
  onClick,
}) => {
  return (
    <PickerRow>
      <Picker {...{ title, value, onClick }} />
    </PickerRow>
  );
};

export interface iClickerPickerOption {
  text: string;
  value: string | number;
}

interface iPickerProps {
  title: string;
  value: string | number;
  onClick(): void;
}

const Picker: React.FC<iPickerProps> = ({
  title,

  value,
  onClick,
}) => {
  const { isDesktop } = useMediaQuery();

  return (
    <IonButton className={styles.clicker_picker} fill="clear" onClick={onClick}>
      <IonCol>
        {isDesktop ? (
          <Fragment>
            <Text16 text={title} />
            <Text16
              text={
                typeof value === "string"
                  ? value.length
                    ? value
                    : "None"
                  : value
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Text14 text={title} />
            <Text14
              text={
                typeof value === "string"
                  ? value.length
                    ? value
                    : "None"
                  : value
              }
            />
          </Fragment>
        )}
      </IonCol>
    </IonButton>
  );
};

export default ClickerPicker;
