import React, { Fragment, useEffect } from "react";
// import { IonContent } from "@ionic/react";

import CurrentReservation from "./CurrentReservation/CurrentReservation";
import NoCurrentReservations from "./NoCurrentReservation/NoCurrentReservations";
import ReservationsList from "./ReservationsList/ReservationsList";
import StayDataModal from "../../../components/StayDataModal/StayDataModal";
import { DataModalContextProvider } from "../../../context/DataModalContext/DataModalContext";
import { ScrollDetail } from "@ionic/core";
import { useOpenModal } from "../../../context/OpenModalContext/OpenModalContext";
import AddBookingModal from "./AddBookingModal/AddBookingModal";
import { modal_id_add_booking } from "../../../context/OpenModalContext/modal_ids";
// import { useProfile } from "../../../context/ProfileContext/ProfileContext";
// import Refresher from "../../../components/Refresher/Refresher";
import StayReportModal from "../../../components/StayReportModal/StayReportModal";
import IonContentColor from "../../../components/IonContentColor";
import useUserReservationsState from "../../../context/User/UserReservationsContext/hooks/useUserReservationsState";
import { IonLoading } from "@ionic/react";

interface iStaysProps {}

const StaysWrapped: React.FC<iStaysProps> = () => {
  const { isLoading } = useUserReservationsState();
  const { createModal } = useOpenModal();

  useEffect(() => {
    createModal(modal_id_add_booking);
    // eslint-disable-next-line
  }, []);

  return (
    <IonContentColor
      scrollEvents={true}
      onIonScroll={(event: CustomEvent<ScrollDetail>) => {
        // console.log(event);
      }}
    >
      <IonLoading isOpen={isLoading} />
      {/* <Refresher onRefresh={loadAccountFromAPI} /> */}
      <StayDataModal />
      <StayReportModal />
      <AddBookingModal />
      <ConditionalRender />
    </IonContentColor>
  );
};

const ConditionalRender: React.FC = () => {
  const { ongoingReservations } = useUserReservationsState();

  return (
    <Fragment>
      {ongoingReservations.length ? (
        <CurrentReservation />
      ) : (
        <NoCurrentReservations />
      )}
      <ReservationsList />
    </Fragment>
  );
};

const Stays: React.FC = () => (
  // <UserReservationsContextProvider>
  <DataModalContextProvider>
    <StaysWrapped />
  </DataModalContextProvider>
  // </UserReservationsContextProvider>
);

export default Stays;
