import React, { useCallback } from "react";
import { ManagerCompetitionsDispatchContext } from "../";
import iCompetition from "../../../../interfaces/models/competitions";
import { nManagerCompetitions } from "../interfaces";

const useManagerCompetitionsDispatch = () => {
  const context = React.useContext(ManagerCompetitionsDispatchContext);

  if (!context)
    throw Error(
      "useManagerCompetitionsDispatch must be used within ManagerCompetitionsStateContext"
    );
  const { get, dispatch } = context;

  const addCompetition = useCallback(
    (competition: iCompetition) => {
      dispatch({ type: "new competition", competition });
    },
    [dispatch]
  );

  const addCompetitionData = useCallback(
    (competition: iCompetition, roomsData: nManagerCompetitions.tRoomsData) => {
      dispatch({ type: "competition data", competition, roomsData });
    },
    [dispatch]
  );

  // const getCompetitionInfo = useCallback(() => {}, []);

  return { addCompetition, addCompetitionData, loadCompetitionsFromApi: get };
};

export default useManagerCompetitionsDispatch;
