import moment from "moment";
import React, { useMemo } from "react";
import iHotel from "../../../../interfaces/models/hotel";
import { defaultHotel } from "../../../../utils/hotels/consts";
import { ManagerAccountStateContext } from "../AccountContext";

const useManagerAccountState = () => {
  const state = React.useContext(ManagerAccountStateContext);

  if (!state)
    throw Error(
      "useManagerAccountState must be used within ManagerAccountStateContext"
    );

  const {
    data: { hotels, currentHotelIndex, roomsPerHotel },
  } = state;

  const currentHotel: iHotel = useMemo(() => {
    return hotels[currentHotelIndex] || { ...defaultHotel };
  }, [currentHotelIndex, hotels]);

  const hotelRooms = useMemo((): string[] => {
    if (roomsPerHotel && currentHotel) return roomsPerHotel[currentHotel?._id];
    return [];
  }, [roomsPerHotel, currentHotel]);

  const minYear = useMemo(() => {
    let y = moment().year();

    for (const { year } of currentHotel.measures) {
      if (year < y) y = year;
    }
    return y;
  }, [currentHotel]);

  return {
    currentHotel,
    currentHotelIndex,
    isLoading: state.status === "pending",
    hotels,
    hotelRooms,
    state,
    minYear,
  };
};

export default useManagerAccountState;
