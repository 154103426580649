import axios from "axios";
import React from "react";
import { modal_id_add_friend } from "../../../context/OpenModalContext/modal_ids";
import EnterTextModal, {
  iEnterTextModalInfo,
} from "../../EnterTextModal/EnterTextModal";

interface iAddFriendModalProps {}

const AddFriendModal: React.FC<iAddFriendModalProps> = () => {
  const handleAddFriend = (
    text: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    const [value] = text;
    axios
      .post("/users/user/send-friend-request", { value: value.trim() })
      .then((res) => {
        console.log(res);
        cb(false, "Friend Request Sent");
      })
      .catch((err) => {
        console.log(err.response);
        try {
          cb(true, err.response.data.message);
        } catch (err) {
          cb(true, "Something went wrong");
        }
      })
      .finally(() => {});
  };

  const modal_info: iEnterTextModalInfo = {
    title: "Send friend request",
    input_placeholder: ["Enter username, phone or e-mail"],
    input_type: "text",
    onConfirm: handleAddFriend,
    dismiss_alert_on_true: false,
    dismiss_alert_after_success: true,
  };

  return (
    <EnterTextModal {...{ modal_id: modal_id_add_friend, ...modal_info }} />
  );
};

export default AddFriendModal;
