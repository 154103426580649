import { IonFooter, IonToolbar } from "@ionic/react";
import React from "react";
import TwoSlimButtons from "../TwoSlimButtons";

import styles from "./modal_footer2.module.css";

export interface iModalFooter2Props {
  onGreen?(): void;
  green_text?: string;
  onRed?(): void;
  red_text?: string;
  color?: string;
  disable_green?: boolean;
  disable_red?: boolean;
}

const ModalFooter2: React.FC<iModalFooter2Props> = ({
  green_text = "Confirm",
  red_text = "Cancel",
  color = "header",
  ...rest
}) => {
  return (
    <IonFooter className={styles.footer}>
      <IonToolbar {...{ color }}>
        <TwoSlimButtons
          {...{
            green_text,
            red_text,
            ...rest,
          }}
        />
      </IonToolbar>
    </IonFooter>
  );
};

export default ModalFooter2;
