import { energy, volume, mass } from "units-converter";

const DECIMAL = 1;
const DECIMAL_MULTIPLIER = Math.pow(10, DECIMAL);

const convertUnit = (unit, value, from, exclude) => {
  if (typeof value !== "number" || value <= 0) return `0 ${from}`;

  const best = unit(value).from(from).toBest({ exclude });

  return `${Math.round(best.value * DECIMAL_MULTIPLIER) / DECIMAL_MULTIPLIER} ${
    best.unit === "mt" ? "t" : best.unit
  }`;
};

const convertUnitEnergy = (value, from = "Wh") => {
  return convertUnit(energy, value, from, ["J", "kJ"]);
};

const convertUnitVolume = (value, from = "l") => {
  return convertUnit(volume, value, from, [
    "ml",
    "cl",
    "dl",
    "mm3",
    "cm3",
    "kl",
    "km3",
    "krm",
    "tsk",
    "msk",
    "kkp",
    "glas",
    "kanna",
    "tsp",
    "Tbs",
    "in3",
    "fl-oz",
    "cup",
    "pnt",
    "qt",
    "gal",
    "ft3",
    "yd3",
  ]);
};

const convertUnitMass = (value, from = "kg") => {
  const calculated = convertUnit(mass, value, from, [
    "mcg",
    "mg",
    "oz",
    "lb",
    "t",
  ]);
  return calculated;
};

export { convertUnitEnergy, convertUnitVolume, convertUnitMass };
