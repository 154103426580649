const profile = {
  noachievements_profile_caption:
    "You haven’t got any achievements yet. Go and be nøytrall to earn achievements.",
  follow_profile_caption: "Follow us on social media to find more!",
  points_profile: "points",
  level_profile: "level",
  friends_profile: "friends",
};

export default profile;
