import React, { Fragment, useMemo, useState } from "react";
import IonContentColor from "../../../components/IonContentColor";
import ViewSelectionButtons, {
  iViewSelectionButton,
} from "../../../components/ViewSelectionButtons";
import Achievements from "./Achievements";
import BadgeCard from "./BadgeCard";
import Leaderboard from "./Leaderboard";

import achievements from "../../../img/achievements.svg";
import leaderboard from "../../../img/light_leaderboard_icon.svg";

import { ProfilePageContextProvider, useProfilePage } from "./context";
import UserCard from "../../../components/UserCard";
import { useOpenModal } from "../../../context/OpenModalContext/OpenModalContext";
import {
  modal_id_edit_username,
  modal_id_friends_list,
  modal_id_select_guest_profile_image,
} from "../../../context/OpenModalContext/modal_ids";
import FriendsListModal from "./FriendsListModal";
import SelectImageModal from "../../../components/SelectImageModal";
import { input_id_user_profile_avatar } from "../../../utils/input_ids";
import { useFileUploaderGuest } from "../../../components/FileUploader/hooks";
import Refresher from "../../../components/Refresher/Refresher";
import useUserAccountState from "../../../context/User/UserContext/hooks/Account/useUserAccountState";
import useUserAccountDispatch from "../../../context/User/UserContext/hooks/Account/useUserAccountDispatch";
import iUser from "../../../interfaces/models/user";

const ProfileWrapped: React.FC = () => {
  const file_uploader = useFileUploaderGuest(input_id_user_profile_avatar);

  const { badgeIsSelected } = useProfilePage();
  const { setAccount, loadAccountFromAPI } = useUserAccountDispatch();
  const { user } = useUserAccountState();
  const { openModal } = useOpenModal();

  const handleChangeAvatar = (account: iUser) => {
    setAccount({ user: account });
  };

  const renderUserCard = useMemo(() => {
    const handleClickUsername = () => {
      openModal(modal_id_edit_username);
    };
    const handleClickAvatar = () => {
      openModal(modal_id_select_guest_profile_image);
    };

    const handleClickFriends = () => {
      openModal(modal_id_friends_list);
    };
    const handleClickReferenceCode = () => {
      console.log("CLICK REFERENCE CODE");
    };

    return (
      <UserCard
        cardColor="secondary"
        // display_level_progress_bar
        displayLevelProgressBar
        user={user}
        {...{
          handleClickFriends,
          handleClickAvatar,
          handleClickUsername,
          handleClickReferenceCode,
        }}
      />
    );
  }, [user, openModal]);

  const handleRefresh = async (cb: () => void) => {
    await loadAccountFromAPI();
    cb();
  };

  return (
    <IonContentColor>
      <Refresher onRefresh={handleRefresh} />
      {!badgeIsSelected() ? (
        <Fragment>
          <FriendsListModal />
          <SelectImageModal
            defaultImage={
              user.images && user.images.length ? user.images[0] : null
            }
            title="Edit profile pic"
            onConfirm={handleChangeAvatar}
            modal_id={modal_id_select_guest_profile_image}
            {...{ ...file_uploader }}
          />
          {renderUserCard}
        </Fragment>
      ) : (
        <BadgeCard />
      )}
      {/* <Views /> */}
    </IonContentColor>
  );
};

interface iViewsProps {}

const Views: React.FC<iViewsProps> = () => {
  const [active, setActive] = useState<0 | 1>(0);

  const buttons: iViewSelectionButton[] = [
    { text: "Leaderboard", onClick: () => setActive(0), icon: leaderboard },
    // { text: "Achievements", onClick: () => setActive(1), icon: achievements },
  ];

  return (
    <Fragment>
      <ViewSelectionButtons
        {...{ buttons, active, buttonColor: "secondary", textColor: "light" }}
      />
      <Leaderboard />
      {/* {active === 0 ? <Achievements /> : <Leaderboard />} */}
    </Fragment>
  );
};

const Profile: React.FC = () => {
  return (
    <ProfilePageContextProvider>
      <ProfileWrapped />
    </ProfilePageContextProvider>
  );
};
export default Profile;
