const zero = {
  water_tip:
    "Tomar um banho longo gasta, em média, 260 litros, enquanto que um duche gasta apenas 25 litros",
  energy_tip:
    "A utilização de painel solar para aquecimento de água evita a emissão de mais de 50 toneladas de carbono por ano ",
  footprint_tip:
    "As emissões médias de carbono por hotel são de 311kg de carbono por cada quarto, por cada noite",
  overall_stats: "Total",
  average_stats: "Média",
};

export default zero;
