import axios from "axios";
import React from "react";
import iReservation from "../../../../interfaces/models/reservation";
import calculateData from "../functions/calculateData";
import {
  LS_USER_RESERVATIONS_DATA,
  UserReservationsDispatchContext,
} from "../UserReservationsContext";
import useUserReservationsState from "./useUserReservationsState";

const useUserReservationsDispatch = () => {
  const dispatch = React.useContext(UserReservationsDispatchContext);

  if (!dispatch)
    throw Error(
      "useUserReservationsDispatch must be used within UserReservationsDispatchContext"
    );
  const context = useUserReservationsState();
  const setData = React.useCallback(
    (reservations: iReservation[]) => {
      const data = calculateData(reservations);
      localStorage.setItem(LS_USER_RESERVATIONS_DATA, JSON.stringify(data));
      dispatch({ type: "resolved", data });
    },
    [dispatch]
  );

  const insertReservationCode = (code: string) => {
    dispatch({ type: "pending" });
    axios
      .post("/users/user/add-reservation", { code })
      .then((res) => {
        console.log("res.data", res.data);
        const { reservation } = res.data;

        if (!reservation) {
          dispatch({ type: "resolved" });
        } else {
          console.log("context", context.state.data.reservations);
          const data = calculateData([
            ...context.state.data.reservations,
            reservation,
          ]);
          console.log("data", data);
          localStorage.setItem(LS_USER_RESERVATIONS_DATA, JSON.stringify(data));
          dispatch({ type: "resolved", data });
        }
      })
      .catch((err) => {
        dispatch({ type: "rejected", error: err.response.data.message });
      });
  };

  return { setData, insertReservationCode };
};
export default useUserReservationsDispatch;
