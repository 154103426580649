import React, { useCallback, useEffect, useState } from "react";
import { createCtx } from "../CreateContext";
import { iOpenModalContext, iOpenModalContextProps } from "./interfaces";

import _ from "lodash";

const [useOpenModal, CtxProvider] = createCtx<iOpenModalContext>();

const OpenModalContextProvider: React.FC<iOpenModalContextProps> = ({
  children,
}) => {
  const [is_open, setIsOpen] = useState<Record<number, boolean>>({});

  useEffect(() => {
    setIsOpen((prev) => _.mapValues(prev, () => false));
  }, []);

  const createModal = useCallback((id: number): void => {
    setIsOpen((prev) => ({ ...prev, [id]: false }));
  }, []);
  const openModal = useCallback((id: number): void => {
    setTimeout(() => {
      setIsOpen((prev) => ({ ...prev, [id]: true }));
    }, 250);
  }, []);
  const closeModal = useCallback((id: number): void => {
    setIsOpen((prev) => ({ ...prev, [id]: false }));
  }, []);

  const isOpen = (id: number): boolean => is_open[id];

  const getModalIdFunctions = (id: number) => {
    return {
      openModal: () => openModal(id),
      closeModal: () => closeModal(id),
      isOpen: () => isOpen(id),
    };
  };

  const value = {
    isOpen,
    openModal,
    closeModal,
    createModal,
    getModalIdFunctions,
  };

  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export { useOpenModal, OpenModalContextProvider };
