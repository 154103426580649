import { IonCol, IonRow } from "@ionic/react";
import React, { Fragment, useMemo, useState } from "react";
import { LoadingSkeletonCardList } from "../../../../../components/LoadingSkeletonCard";
import { Text20 } from "../../../../../components/Text/Montserrat";
import useManagerCompetitionsState from "../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsState";
import { modal_id_competition_info } from "../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";
import iCompetition from "../../../../../interfaces/models/competitions";
import CompetitionCard from "./CompetitionCard";
import ComeptitionInfoModal from "./CompetitionInfoModal";

interface iCompetitionsProps {
  filter: number;
}

const Competitions: React.FC<iCompetitionsProps> = ({ filter }) => {
  const {
    sortedCompetitions: {
      ongoing: ongoingCompetitions,
      past: pastCompetitions,
    },
    isLoading,
  } = useManagerCompetitionsState();

  const [clickedCompetition, setClickedCompetition] =
    useState<iCompetition | null>(null);
  const { openModal } = useOpenModal();

  const [competition_cards] = useMemo(() => {
    const handleClickCard = (competition: iCompetition) => {
      setClickedCompetition(competition);
      openModal(modal_id_competition_info);
    };
    const competitions: iCompetition[] =
      filter === 0 ? ongoingCompetitions : pastCompetitions;

    const numberOfSkeleton: number = 0 - competitions.length;

    const competition_cards = competitions.map((comp, key) => (
      <CompetitionCard
        {...{
          key,
          competition: comp,
          onClickCard: () => handleClickCard(comp),
        }}
      />
    ));

    return [competition_cards, numberOfSkeleton];
  }, [filter, ongoingCompetitions, pastCompetitions, openModal]);

  const handleExitModal = () => {
    setClickedCompetition(null);
  };

  return isLoading ? (
    <LoadingSkeletonCardList n_cards={3} />
  ) : (
    <Fragment>
      {clickedCompetition && (
        <ComeptitionInfoModal
          {...{
            competitionId: clickedCompetition._id,
            handleExit: handleExitModal,
          }}
        />
      )}
      {competition_cards.length ? (
        competition_cards
      ) : (
        <IonRow style={{ marginTop: "5vh" }}>
          <IonCol className="flexr-center">
            <Text20 color="danger" text="No competitions available" />
          </IonCol>
        </IonRow>
      )}
    </Fragment>
  );
};

export default Competitions;
