import React, { Fragment } from "react";
import {
  IonPage,
  IonContent,
  IonItem,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useFirebase } from "../../context/FirebaseContext";
import Button, { iButtonProps } from "../../components/Button";

// eslint-disable-next-line
// import Policies from "./Policies/Policies";

import logo from "../../img/logo.svg";

import styles from "./styles.module.css";
import useMediaQuery from "../../hooks/mediaQuery";

import IonContentColor from "../../components/IonContentColor";
import LaunchDesktop from "./Desktop";

interface iLaunchProps {}

const Launch: React.FC<iLaunchProps> = () => {
  const { isDesktop } = useMediaQuery();

  return (
    <IonPage id={styles.page}>
      {isDesktop ? (
        <IonContentColor>
          <LaunchDesktop />
        </IonContentColor>
      ) : (
        <IonContent>
          <MobileLaunch />
        </IonContent>
      )}
    </IonPage>
  );
};

const MobileLaunch: React.FC = () => {
  return (
    <Fragment>
      <IonItem lines="none" className={styles["item-logo"]}>
        <IonImg src={logo} className={styles["logo"]} />
      </IonItem>
      <ButtonList />
      {/* <Policies /> */}
    </Fragment>
  );
};

interface iButtonListProps {}

const ButtonList: React.FC<iButtonListProps> = () => {
  const { loginWithGoogle } = useFirebase();

  const buttons: iButtonProps[] = [
    { fill: "solid", link: "/login", text: "Login", textColor: "light" },
    { fill: "outline", link: "/signup", text: "Signup", textColor: "primary" },
    {
      fill: "outline",
      onClick: loginWithGoogle,
      text: "Signup/Login with Google",
      textColor: "primary",
    },
  ];

  const renderButtons = (): JSX.Element[] => {
    return buttons.map((button, i) => {
      return (
        <IonRow key={i}>
          <IonCol>
            <Button {...button} className={styles.button} />
          </IonCol>
        </IonRow>
      );
    });
  };

  return <IonGrid className={styles.grid}>{renderButtons()}</IonGrid>;
};

export default Launch;
