import { IonItem } from "@ionic/react";
import React, { Fragment, useMemo } from "react";
import {
  modal_id_account,
  modal_id_add_friend,
  modal_id_edit_username,
  modal_id_new_password,
} from "../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import IonContentColor from "../IonContentColor";
import IonListColored from "../IonListColored";
import MenuModalHeader from "../MenuModalHeader";
import ModalWrapper from "../ModalWrapper";
import { Text18 } from "../Text/Montserrat";
import EditUsernameModal from "./EditUsernameModal";
import NewPasswordModal from "./NewPasswordModal";

import styles from "./styles.module.css";

interface iAccountModalProps {}

const AccountModal: React.FC<iAccountModalProps> = () => {
  const { closeModal, openModal } = useOpenModal();

  const handleCloseModal = () => {
    closeModal(modal_id_account);
  };

  const handleClickEditUsername = () => {
    openModal(modal_id_edit_username);
  };
  const handleClickEditPersonalInfo = () => {};
  const handleClickChangePassword = () => {
    openModal(modal_id_new_password);
  };
  const handleClickAddFriend = () => {
    openModal(modal_id_add_friend);
  };
  const handleClickRemoveAccount = () => {};

  const rendered_buttons = useMemo(() => {
    interface button {
      onClick(): void;
      text: string;
      Modal?: React.FC;
    }
    const buttons: button[] = [
      {
        text: "Edit username",
        onClick: handleClickEditUsername,
        Modal: EditUsernameModal,
      },
      // {
      //   text: "Edit personal information",
      //   onClick: handleClickEditPersonalInfo,
      // },
      { text: "Change password", onClick: handleClickChangePassword },
      // { text: "Add friend", onClick: handleClickAddFriend },
      // { text: "Remove account", onClick: handleClickRemoveAccount },
    ];

    return buttons.map(({ text, onClick, Modal }) => (
      <Fragment key={text}>
        {/* {Modal && <Modal />} */}
        <Item {...{ text, onClick }} />
      </Fragment>
    ));
  }, []);

  return (
    <ModalWrapper modal_id={modal_id_account}>
      <MenuModalHeader title="Account" onExit={handleCloseModal} />
      <IonContentColor>
        <NewPasswordModal />
        <IonListColored>{rendered_buttons}</IonListColored>
      </IonContentColor>
    </ModalWrapper>
  );
};

interface iItemProps {
  text: string;
  onClick(): void;
}

const Item: React.FC<iItemProps> = ({ text, onClick }) => {
  return (
    <Fragment>
      <IonItem
        lines="none"
        color="none"
        button={true}
        detail={false}
        {...{ onClick }}
      >
        <Text18 {...{ text }} style={{ margin: "0 10vw", padding: "3vh 0" }} />
      </IonItem>
      <div className={styles.separator} />
    </Fragment>
  );
};

export default AccountModal;
