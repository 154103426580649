import React from "react";
import { modal_id_hotel_spending_data } from "../../context/OpenModalContext/modal_ids";
import ModalWrapper from "../ModalWrapper";
import Content, { iContentProps } from "./Content";

interface iHotelSpendingDataModalProps extends iContentProps {
  onClose(): void;
}

const HotelSpendingDataModal: React.FC<iHotelSpendingDataModalProps> = (
  props
) => {
  return (
    <ModalWrapper modal_id={modal_id_hotel_spending_data}>
      <Content {...props} />
    </ModalWrapper>
  );
};

export default HotelSpendingDataModal;
