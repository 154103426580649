import { IonSkeletonText } from "@ionic/react";
import React, { useMemo } from "react";
import useBoxShadow from "../../hooks/boxShadow";
import IonListColored from "../IonListColored";

interface iLoadingSkeletonCardProps {
  height?: number;
  width?: number;
}

const LoadingSkeletonCard: React.FC<iLoadingSkeletonCardProps> = ({
  height = 17,
  width = 91,
}) => {
  const boxShadow = useBoxShadow();

  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "2.5vh 0" }}
    >
      <IonSkeletonText
        animated
        style={{
          height: `${height}vh`,
          width: `${width}%`,
          borderRadius: "8px",
          boxShadow,
          "--background-rgb": "0, 0, 0",
        }}
      />
    </div>
  );
};

interface iLoadingSkeletonCardListProps {
  height?: number;
  n_cards: number;
}

export const LoadingSkeletonCardList: React.FC<iLoadingSkeletonCardListProps> =
  ({ height, n_cards }) => {
    const skeletonCards = useMemo(() => {
      const array = [];
      for (let i = 0; i < n_cards; i++) array.push(0);
      return array.map((elem, key) => (
        <LoadingSkeletonCard {...{ key, height }} />
      ));
    }, [n_cards, height]);

    // console.log(`skeletonCards`, skeletonCards);

    return <IonListColored>{skeletonCards}</IonListColored>;
  };

export default LoadingSkeletonCard;
