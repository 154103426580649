const zero = {
  water_tip:
    "On average, taking a bath spends 260 litres, while a 5 minute shower spends 25 litres",
  energy_tip:
    "Using solar thermal to heat water saves over 50 tonnes of carbon per year",
  footprint_tip:
    "Average hotel emissions is 311 kg of carbon per room, per night",
  overall_stats: "Overall",
  average_stats: "Average",
};

export default zero;
