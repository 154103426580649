import { IonItem, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext/AuthContext";
import { modal_id_forgot_password } from "../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../context/OpenModalContext/OpenModalContext";
import EnterTextModal, {
  iEnterTextModalInfo,
} from "../../../components/EnterTextModal/EnterTextModal";

import styles from "./styles.module.css";

interface iInfo extends iEnterTextModalInfo {}

interface iForgotPasswordProps {}

const strings = {
  forgot_password_button: "Forgot your password?",

  forgot_password_modal_title: "Forgot Password",
  forgot_password_modal_enter_email_info:
    "Enter your email. A code will be sent. If no email is provided, you'll be prompted to insert a code",
  forgot_password_modal_enter_email_input_placeholder: "Enter your email",
  passwords_dont_match: "Passwords don't match",
  forgot_password_modal_enter_code_title: "Enter code to identify you",
  forgot_password_modal_enter_code_info:
    "A code was sent to your email. Enter such code.",
  forgot_password_modal_enter_code_input_placeholder: "Enter received code",
  forgot_password_modal_enter_new_password_title: "Enter your new password",
  forgot_password_modal_enter_new_password_info:
    "After confirming and receiving validation, your password will be altered",
  forgot_password_modal_enter_new_password_input_placeholder:
    "Enter your new password",
  forgot_password_modal_enter_new_password_input2_placeholder:
    "Confirm your new password",
};

const ForgotPassword: React.FC<iForgotPasswordProps> = () => {
  const [dismiss, setDismiss] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const { forgotPassword, forgotPasswordValidateCode, setNewPassword } =
    useAuth();
  const { openModal, isOpen, closeModal } = useOpenModal();

  const b = isOpen(modal_id_forgot_password);
  useEffect(() => {
    if (b) setDismiss(false);
  }, [b]);

  const handleClick = () => {
    if (!isOpen(modal_id_forgot_password)) openModal(modal_id_forgot_password);
  };

  const handleSendEmail = (
    values: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    const email = values[0];
    if (email.length === 0) {
      cb(false, "");
      setIndex(1);
    } else {
      forgotPassword(email, (error: boolean, message: string) => {
        cb(error, message);
        if (!error) setIndex(1);
      });
    }
  };

  const handleValidateCode = (
    code: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    forgotPasswordValidateCode(code[0], (error: boolean, message: string) => {
      cb(error, message);
      if (!error) setIndex(2);
    });
  };

  const handleNewPassword = (
    values: string[],
    cb: (error: boolean, message: string) => void
  ) => {
    const [password, password2] = values;

    console.log(password, password2);
    if (password !== password2) return cb(true, strings.passwords_dont_match);

    setNewPassword(password, (error: boolean, message: string) => {
      console.log("DONE");
      setDismiss(true);
      cb(error, message);
      setTimeout(() => {
        handleCloseModal();
      }, 1000);
    });
  };

  const handleCloseModal = () => {
    closeModal(modal_id_forgot_password);
    setIndex(0);
  };

  const forgotInfo: iInfo[] = [
    {
      title: strings.forgot_password_modal_title,
      info: strings.forgot_password_modal_enter_email_info,
      input_placeholder: [
        strings.forgot_password_modal_enter_email_input_placeholder,
      ],
      onConfirm: handleSendEmail,
      dismiss_alert_on_true: dismiss,
      dismiss_alert_after_success: false,
      input_is_required: false,
    },
    {
      title: strings.forgot_password_modal_enter_code_title,
      info: strings.forgot_password_modal_enter_code_info,
      input_placeholder: [
        strings.forgot_password_modal_enter_code_input_placeholder,
      ],
      onConfirm: handleValidateCode,
      dismiss_alert_on_true: dismiss,
      dismiss_alert_after_success: false,
    },
    {
      title: strings.forgot_password_modal_enter_new_password_title,
      info: strings.forgot_password_modal_enter_new_password_info,
      input_placeholder: [
        strings.forgot_password_modal_enter_new_password_input_placeholder,
        strings.forgot_password_modal_enter_new_password_input2_placeholder,
      ],
      input_type: ["password", "password"],
      onConfirm: handleNewPassword,
      dismiss_alert_on_true: false,
      dismiss_alert_after_success: dismiss,
    },
  ];

  const info: iInfo = forgotInfo[index];

  return (
    <IonItem
      {...{
        color: "light",
        lines: "none",
        onClick: handleClick,
        className: styles.item,
        button: true,
        detail: false,
      }}
    >
      <EnterTextModal
        {...{
          modal_id: modal_id_forgot_password,
          onCancel: handleCloseModal,
          ...info,
        }}
      />
      <IonText color="secondary">
        <p className="montserrat">{strings.forgot_password_button}</p>
      </IonText>
    </IonItem>
  );
};

export default ForgotPassword;
