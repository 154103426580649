import { IonContent, IonItem, IonList, IonText } from "@ionic/react";
import React, { useMemo, useState } from "react";
import strings from "../../../localization/localization";
import { Text14, Text18, Text11 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

interface iContentProps {}

const Content: React.FC<iContentProps> = () => {
  const [index, setIndex] = useState<number>(-1);

  const INFO = strings.help;

  let total = useMemo(() => {
    let t = 0;
    INFO.forEach((info) => {
      t += info.qas.length;
    });
    return t;
  }, [strings.help]);

  const handleClick = (i: number) => {
    setIndex(i === index ? -1 : i);
  };

  const renderItems = (): JSX.Element[][] => {
    return INFO.map((info, i) =>
      info.qas.map(({ q, a }, j) => {
        const value = i * total + j;
        console.log(i, j, value);

        return (
          <IonItem
            className={styles.item}
            key={value}
            onClick={() => handleClick(value)}
          >
            <Text18 text={q} className={styles.question} />
            <Text11
              text={a}
              style={{
                display: index === value ? "inherit" : "none",
              }}
            />
          </IonItem>
        );
      })
    );
  };

  return (
    <IonContent>
      <IonList>{renderItems()}</IonList>
    </IonContent>
  );
};

export default Content;
