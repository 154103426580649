import React from "react";

export function createCtx<ContextType>(displayName: string = "Context") {
  const ctx = React.createContext<ContextType | undefined>(undefined);
  ctx.displayName = displayName;
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c)
      throw new Error(
        `use${displayName} must be inside a ${displayName}ContextProvider with a value`
      );
    return c;
  }
  return [useCtx, ctx.Provider, ctx.Consumer, ctx] as const;
}
