import { IonCol, IonRow, IonSegment, IonSegmentButton } from "@ionic/react";
import React from "react";
import { Text14 } from "../Text/Montserrat";

import styles from "./styles.module.css";

interface iSegmentedFilterProps {
  value: number;
  onChange(index: number): void;
  filters: string[];
  className?: string;
}

const SegmentedFilter: React.FC<iSegmentedFilterProps> = ({
  filters,
  value,
  onChange,
  className,
}) => {
  const renderSegmentButtons = (): JSX.Element[] => {
    return filters.map((filter, i) => (
      <IonSegmentButton value={filter} key={i}>
        <Text14
          text={filter}
          bold
          style={{
            color: `var(--ion-color-secondary${i === value ? "" : "_dim"}`,
          }}
        />
      </IonSegmentButton>
    ));
  };

  return (
    <IonRow className={`${styles.segments} ${className}`}>
      <IonCol>
        <IonSegment
          value={filters[value as number]}
          onIonChange={(e) => {
            onChange(e.detail.value ? filters.indexOf(e.detail.value) : 0);
          }}
        >
          {renderSegmentButtons()}
        </IonSegment>
      </IonCol>
    </IonRow>
  );
};

export default SegmentedFilter;
