import { IonCardContent, IonText } from "@ionic/react";
import React from "react";

import "./card_content.css";
import iReservation from "../../../../../interfaces/models/reservation";
import SpendingData from "../../../../../components/SpendingData/SpendingData";
import { footprint2points } from "../../../../../utils/reservations/reservation";

interface iCardContentProps {
  reservation: iReservation;
}

const CardContent: React.FC<iCardContentProps> = ({ reservation }) => {
  const { Energy, Water, Waste, Footprint } = reservation;

  return (
    <IonCardContent className="current_res-card-content">
      <SpendingData
        {...{ Energy, Water, Waste, Footprint }}
        display_detail_icon
      />
      {/* <PointsDetail {...{ Footprint }} /> */}
    </IonCardContent>
  );
};

interface PointsDetailProps {
  Footprint: number | undefined;
}

const PointsDetail: React.FC<PointsDetailProps> = ({ Footprint }) => {
  const amount = 2.57;

  return (
    <IonText className="ion-text-center">
      <p className="montserrat">
        You will pay <strong>{amount}€</strong>{" "}
        {Footprint != undefined &&
          ` and get ${footprint2points(Footprint)} points`}
      </p>
    </IonText>
  );
};

export default CardContent;
