import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { forwardRef } from "react";
import { DatePicker, OptionsPicker } from "../../../../../components/PickerRow";
import { Chivo24 } from "../../../../../components/Text/Chivo";
import TwoSlimButtons from "../../../../../components/TwoSlimButtons";
import iReservation from "../../../../../interfaces/models/reservation";
import { useAddBooking } from "../../hooks";

interface iAddProps {
  onClickCode(reservation: iReservation): void;
}

const Add = forwardRef<any, iAddProps>(({ onClickCode }, ref) => {
  const {
    state,
    handleReset,
    setCheckInDate,
    setCheckOutDate,
    setRoom,
    setNumPeople,
    handleAdd,
    getCode,
    numPeopleOptions,
    roomOptions,
  } = useAddBooking();
  const {
    check_in_date,
    check_out_date,
    room,
    num_people,
    loading,
    // eslint-disable-next-line
    reservation,
  } = state;
  // eslint-disable-next-line
  const code = getCode();

  const afterAdd = (reservation: iReservation) => {
    onClickCode(reservation);
    handleReset();
  };

  return (
    <IonGrid /* style={{ paddingBottom: "5vh" }} */ ref={ref}>
      <IonLoading isOpen={state.loading} />
      <IonRow>
        <IonCol>
          <Chivo24 centeredText text="Add Booking" />
        </IonCol>
      </IonRow>
      <DatePicker
        title="Check-In Date"
        value={check_in_date}
        onSelect={setCheckInDate}
        id="1"
      />
      <DatePicker
        title="Check-Out Date"
        value={check_out_date}
        onSelect={setCheckOutDate}
        id="2"
      />
      {/* <SelectionPicker */}
      {/* onChange={setRoom} */}
      <OptionsPicker
        onSelect={setRoom}
        title="Room"
        value={room}
        options={roomOptions}
      />
      <OptionsPicker
        title="Number of people"
        value={num_people}
        options={numPeopleOptions}
        onSelect={setNumPeople}
      />
      {/* <SlimButton
        onClick={reservation ? () => onClickCode(reservation) : () => {}}
        text={code === "" ? "Booking Code will appear here" : code}
        text2={code === "" ? undefined : "Click to send"}
        color="tertiary"
        textColor="primary"
        width={53}
        disabled={code === ""}
      /> */}
      <TwoSlimButtons
        green_text="Add"
        red_text="Reset"
        onGreen={() => handleAdd(afterAdd)}
        onRed={handleReset}
        grid={false}
        disable_green={loading}
        style={{ margin: "0 7vw" }}
      />
    </IonGrid>
  );
});

export default Add;
