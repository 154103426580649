import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import validator from "validator";

import { createCtx } from "../CreateContext";
import {
  iProfileContext,
  iProfileState,
  iAction,
  iProfileContextProps,
  iProfileUser,
} from "./interfaces";

import axios from "axios";
import { useAuth } from "../AuthContext/AuthContext";
import iUser, {
  iAchievementElement,
  iUserReward,
} from "../../interfaces/models/user";
import { useOpenModal } from "../OpenModalContext/OpenModalContext";
import { modal_id_complete_profile } from "../OpenModalContext/modal_ids";
import {
  MAX_FAVORITE_ACHIEVEMENTS,
  POINTS_PER_LEVEL,
} from "../../utils/consts";

import _ from "lodash";
import { getLevelFromTotalPoints } from "../../utils/user";
import iReservation from "../../interfaces/models/reservation";

const initial_state: iProfileState = {
  account: {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    country: "",
    phoneNumber: "",
    total_points: 0,
    points: 0,
    nFriends: 0,
    friends: [],
    achievements: [],
    rewards: [],
    reservations: [],
    images: [],
    footprint: 0,
    referralCode: "",
  },
  sorted_reservations: {
    past_reservations: [],
    ongoing_reservations: [],
    future_reservations: [],
  },
  initial_request_done: false,
  loading: true,
};

const reducer = (state: iProfileState, action: iAction): iProfileState => {
  switch (action.type) {
    case "save&load user data":
    case "update state":
      localStorage.setItem("account", JSON.stringify(action.data!));

      return {
        ...state,
        account: { ...state.account, ...action.data! },
        loading: false,
      };

    case "update account":
      localStorage.setItem("account", JSON.stringify(action.data!));
      return {
        ...state,
        loading: false,
        account: { ...state.account, ...action.account },
      };

    case "set achievements":
      return {
        ...state,
        account: {
          ...state.account,
          achievements: action.account!.achievements!,
        },
      };

    case "set username":
      return {
        ...state,
        account: { ...state.account, username: action.account!.username! },
      };

    case "load user data":
      return {
        ...state,
        account: { ...state.account, ...action.data! },
        loading: false,
      };

    case "set loading":
      return { ...state, loading: action.loading! };

    default:
      return { ...state };
  }
};

const [useProfile, CtxProvider] = createCtx<iProfileContext>();

const ProfileContextProvider: React.FC<iProfileContextProps> = ({
  children,
}) => {
  const { openModal, closeModal } = useOpenModal();
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { Logout } = useAuth();

  const checkEmailIsValid = useCallback(
    (): boolean =>
      state.account.email ? validator.isEmail(state.account.email) : false,
    [state.account]
  );

  const checkPhoneNumberIsValid = useCallback(
    (): boolean =>
      state.account.phoneNumber
        ? validator.isMobilePhone(state.account.phoneNumber)
        : false,
    [state.account]
  );

  const checkCountryIsValid = useCallback(
    (): boolean =>
      state.account.country ? validator.isAlpha(state.account.country) : false,
    [state.account]
  );

  const checkStringPropertyIsValid = useCallback(
    (prop: string): boolean =>
      state.account[prop] && state.account[prop].length,
    [state.account]
  );

  const checkUsernameIsValid = useCallback(
    (): boolean => checkStringPropertyIsValid("username"),
    [checkStringPropertyIsValid]
  );
  const checkFirstNameIsValid = useCallback(
    (): boolean => checkStringPropertyIsValid("firstName"),
    [checkStringPropertyIsValid]
  );
  const checkLastNameIsValid = useCallback(
    (): boolean => checkStringPropertyIsValid("lastName"),
    [checkStringPropertyIsValid]
  );

  const checkProfileIsValid = useCallback((): boolean => {
    if (!checkPhoneNumberIsValid()) return false;
    if (!checkEmailIsValid()) return false;
    if (!checkCountryIsValid()) return false;
    if (!checkUsernameIsValid()) return false;
    if (!checkLastNameIsValid()) return false;
    if (!checkFirstNameIsValid()) return false;

    return true;
  }, [
    checkPhoneNumberIsValid,
    checkFirstNameIsValid,
    checkEmailIsValid,
    checkLastNameIsValid,
    checkCountryIsValid,
    checkUsernameIsValid,
  ]);

  useEffect(() => {
    const loadData = (): void => {
      const user = localStorage.getItem("account");

      // console.log(`user`, user);

      if (!user) getAccountFromAPI();
      else {
        let parsed;
        try {
          parsed = JSON.parse(user);
          dispatch({ type: "load user data", data: parsed });
        } catch (err) {
          console.log("LOGOUT");
          Logout();
        }
      }
    };
    loadData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const main = () => {
      const ls: iProfileUser = state.account;
      localStorage.setItem("account", JSON.stringify(ls));

      if (!checkProfileIsValid()) openModal(modal_id_complete_profile);
      else closeModal(modal_id_complete_profile);
    };
    if (!state.loading) main();
  }, [state, closeModal, openModal, checkProfileIsValid]);

  const updateState = (data: iProfileState): void =>
    dispatch({ type: "update state", data });

  const saveAndLoadData = (data: iProfileState): void => {
    dispatch({ type: "save&load user data", data });
  };

  const setLoading = (loading: boolean) => {
    dispatch({ type: "set loading", loading });
  };
  const getProfileLoading = (): boolean => state.loading;

  // const checkProfileIsValidDescriminate = (): string[] => {
  //   const arr: string[] = [];
  //   if (!checkPhoneNumberIsValid()) arr.push("phoneNumber");
  //   if (!checkEmailIsValid()) arr.push("email");
  //   if (!checkCountryIsValid()) arr.push("country");
  //   if (!checkUsernameIsValid()) arr.push("username");
  //   if (!checkLastNameIsValid()) arr.push("lastName");
  //   if (!checkFirstNameIsValid()) arr.push("firstName");

  //   return arr;
  // };

  const getAccountFromAPI = (cb?: () => void): void => {
    // dispatch({ type: "set loading", loading: true });
    setLoading(true);
    axios
      .get("/users/user")
      .then(({ data: { user } }) => {
        // dispatch({ type: "save&load user data", data: user });
        saveAndLoadData(user);
        if (cb) cb();
      })
      .catch((err) => {
        console.log(err.response);
        // alert("Server Error");
        Logout();
      });
  };

  const getUsername = (): string => {
    const { username, firstName, lastName } = state.account;
    return username || `${firstName} ${lastName}`;
  };
  const setUsername = (username: string) => {
    dispatch({ type: "set username", account: { username } });
  };

  const getFullName = (): string => {
    return `${state.account.firstName} ${state.account.lastName}`;
  };

  const getProfileImages = (): string[] => state.account.images;

  const getNumberOfFriends = (): number =>
    state.account.friends.length || state.account.nFriends;

  const getAchievements = (): iAchievementElement[] =>
    state.account.achievements;

  const getFavoriteAchievements = (): iAchievementElement[] => {
    const a: iAchievementElement[] = [];

    getAchievements().forEach((ach) => {
      if (ach.favorite_position >= 0) a.push(ach);
    });

    _.orderBy(a, ["favorite_position"], ["asc"]);

    console.log(`a`, a);

    return a;
  };

  const setFavoriteAchievement = (achievement_id: string): boolean => {
    console.log(1);
    const position: number = getFavoriteAchievements().length;
    if (position >= MAX_FAVORITE_ACHIEVEMENTS) return false;
    console.log(2);

    const achievements = [...getAchievements()];

    for (let i = 0; i < achievements.length; i++)
      if (achievements[i].achievement._id === achievement_id) {
        achievements[i].favorite_position = position;
        break;
      }
    console.log(`achievements`, achievements);
    dispatch({ type: "set achievements", account: { achievements } });
    return true;
  };

  const removeFavoriteAchievement = (achievement_id: string): boolean => {
    const achievements = [...getAchievements()];
    for (let i = 0; i < achievements.length; i++)
      if (achievements[i].achievement._id === achievement_id) {
        achievements[i].favorite_position = -1;
        break;
      }

    dispatch({ type: "set achievements", account: { achievements } });
    return true;
  };

  const getRewards = useCallback(
    (): iUserReward[] => state.account.rewards,
    [state.account.rewards]
  );

  const getNumOfRewards = (): number => {
    let total = 0;

    getRewards().forEach((reward) => (total += reward.count));

    return total;
  };

  const getPoints = (): number => state.account.points;

  const getLevel = (): number =>
    getLevelFromTotalPoints(state.account.total_points);

  const getLevelProgress = (): number =>
    state.account.total_points % POINTS_PER_LEVEL;

  const getTotalPoints = (): number => state.account.total_points;

  const getState = (): iProfileState => ({ ...state });

  const getUser = (): iUser => {
    // @ts-ignore
    return state.account;
  };

  const getFriends = (): iUser[] => state.account.friends as iUser[];

  const updateReservation = (reservation: iReservation): void => {
    const new_state: iProfileState = { ...state };

    for (let i = 0; i < new_state.account.reservations.length; i++) {
      if (new_state.account.reservations[i]._id === reservation._id) {
        new_state.account.reservations[i] = {
          ...new_state.account.reservations[i],
          ...reservation,
        };
        break;
      }
    }

    updateState(new_state);
  };

  const setAccount = (account: iProfileUser) => {
    dispatch({ type: "update account", account });
  };

  const getAccount = useCallback((): iProfileUser => {
    const account = state.account;

    return account;
  }, [state.account]);

  const getReservations = (): iReservation[] => state.account.reservations;

  const getReservationByIndex = (index: number): iReservation => {
    return state.account.reservations[index];
  };

  const getReservationsByIndex = (arr: number[]): iReservation[] => {
    const reservations: iReservation[] = [];

    arr.forEach((i) => {
      if (i < arr.length) reservations.push(state.account.reservations[i]);
    });

    return reservations;
  };

  const value: iProfileContext = {
    // state,
    getPoints,
    getState,
    getAccountFromAPI,
    getUsername,
    checkProfileIsValid,
    checkCountryIsValid,
    checkEmailIsValid,
    checkFirstNameIsValid,
    checkLastNameIsValid,
    checkPhoneNumberIsValid,
    checkUsernameIsValid,
    getProfileLoading,
    saveAndLoadData,
    getProfileImages,
    getFullName,
    getNumberOfFriends,
    getAchievements,
    setUsername,
    getLevel,
    getTotalPoints,
    getLevelProgress,
    getFavoriteAchievements,
    setFavoriteAchievement,
    removeFavoriteAchievement,
    getUser,
    getFriends,
    getRewards,
    getNumOfRewards,
    updateReservation,
    getReservationByIndex,
    getReservations,
    getReservationsByIndex,
    getAccount,
    setAccount,
  };

  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export { useProfile, ProfileContextProvider };
