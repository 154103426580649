import axios from "axios";
import React, { useReducer, useEffect } from "react";
import { USER_NOTIFICATIONS_TIMEOUT } from "../../../utils/consts";
import useUserAccountDispatch from "../UserContext/hooks/Account/useUserAccountDispatch";
import useUserAccountState from "../UserContext/hooks/Account/useUserAccountState";
import { nNotifications } from "./interfaces";

const NotificationsDispatchContext = React.createContext<
  nNotifications.tDispatchContext | undefined
>(undefined);
NotificationsDispatchContext.displayName = "NotificationsDispatchContext";
const NotificationsStateContext = React.createContext<
  nNotifications.tStateContext | undefined
>(undefined);
NotificationsStateContext.displayName = "NotificationsStateContext";

const initialState: nNotifications.iState = {
  data: {
    friendship: {
      friends: [],
      friendRequestsSent: [],
      friendRequestsReceived: [],
    },
    notifications: [],
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nNotifications.iState,
  action: nNotifications.tAction
): nNotifications.iState => {
  switch (action.type) {
    case "set friendship data":
      return {
        ...state,
        data: { ...state.data, friendship: { ...action.data } },
        status: "resolved",
        error: null,
      };
    case "set data":
      return { ...state, data: action.data, status: "resolved", error: null };
    case "resolved":
      return { ...state, status: "resolved", error: null };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    default:
      return { ...state };
  }
};

const NotificationsContextProvider: React.FC<nNotifications.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [
    nNotifications.iState,
    React.Dispatch<nNotifications.tAction>
  ] = useReducer(reducer, initialState);

  const { setAccount } = useUserAccountDispatch();
  const {
    user: { friends, friendRequestsReceived, friendRequestsSent },
  } = useUserAccountState();

  useEffect(() => {
    dispatch({
      type: "set friendship data",
      data: {
        friends,
        friendRequestsReceived,
        friendRequestsSent,
      },
    });
  }, [friends, friendRequestsReceived, friendRequestsSent]);

  // useEffect(() => {
  // const interval = setInterval(() => {
  //   axios
  //     .get("/users/user/notifications")
  //     .then((res) => {
  //       const {
  //         data: { user },
  //       } = res;
  //       console.log("res", user);
  //       setAccount({ user });
  //     })
  //     .catch((err) => {
  //       console.log("err.response", err.response);
  //     });
  // }, USER_NOTIFICATIONS_TIMEOUT);
  // return () => clearInterval(interval);
  // }, [setAccount]);

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
};

export {
  NotificationsContextProvider,
  NotificationsDispatchContext,
  NotificationsStateContext,
};
