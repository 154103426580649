import { IonButton, IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import axios from "axios";
import { has } from "lodash";
import React, { useMemo, useReducer } from "react";
import useNotificationsState from "../../../../context/User/NotificationsContext/hooks/useNotificationsState";
import useUserAccountDispatch from "../../../../context/User/UserContext/hooks/Account/useUserAccountDispatch";
import { iUserNotOwnAccount } from "../../../../interfaces/models/user";
import strings from "../../../../localization/localization";
import { DEFAULT_ERROR_MESSAGE } from "../../../../utils/httpResponses/consts";
import Button from "../../../Button";
import IonContentColor from "../../../IonContentColor";
import {
  Chivo14,
  Chivo16,
  Chivo18,
  Chivo20,
  Chivo22,
  Chivo24,
  Chivo30,
  Chivo32,
} from "../../../Text/Chivo";
import { Text16 } from "../../../Text/Montserrat";
import TitledComponent from "../../../TitledComponent";

import styles from "./styles.module.css";

interface iStateData {}

interface iState {
  status: "idle" | "pending" | "resolved" | "rejected";
  error: string | null;
  data: iStateData;
}

type tAction =
  | { type: "resolved" }
  | { type: "pending" }
  | { type: "rejected"; error: string }
  | { type: "set data"; data: iStateData };

const initialState: iState = {
  status: "idle",
  error: null,
  data: {},
};

const reducer = (state: iState, action: tAction): iState => {
  switch (action.type) {
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      return { ...state, status: "rejected", error: action.error };
    }
    case "set data": {
      return { ...state, status: "resolved", data: action.data, error: null };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const Content: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    data: {
      friendship: { friends, friendRequestsReceived, friendRequestsSent },
      notifications,
    },
  } = useNotificationsState();
  const { setAccount } = useUserAccountDispatch();

  console.log("friendRequestsReceived", friendRequestsReceived);

  const friendRequestsReceivedList = () => {
    const acceptRequest = (userId: string) => () => {
      dispatch({ type: "pending" });
      axios
        .post(`/users/user/accept-friend-request`, { userId })
        .then((res) => {
          const {
            data: { user },
            data,
          } = res;
          console.log("res", res);
          setAccount({ user });
          dispatch({ type: "resolved" });
        })
        .catch((err) => {
          console.log("err", err);
          const error = has(err, "response.data.message")
            ? err.response.data.message
            : DEFAULT_ERROR_MESSAGE;
          dispatch({ type: "rejected", error });
        });
    };
    const declineRequest = (userId: string) => () => {
      dispatch({ type: "pending" });
      axios
        .post(`/users/user/decline-friend-request`, { userId })
        .then((res) => {
          const {
            data: { user },
            data,
          } = res;
          console.log("res", res);
          setAccount({ user });
          dispatch({ type: "resolved" });
        })
        .catch((err) => {
          console.log("err", err);
          const error = has(err, "response.data.message")
            ? err.response.data.message
            : DEFAULT_ERROR_MESSAGE;
          dispatch({ type: "rejected", error });
        });
    };

    const ret: JSX.Element[] = [];
    for (const { user, sentAt } of friendRequestsReceived) {
      const u: iUserNotOwnAccount = user as iUserNotOwnAccount;
      ret.push(
        <IonRow key={u._id}>
          <Chivo18 bold={false} ml={10} text={`--- ${u.username}`} />
          <Button
            onClick={acceptRequest(u._id)}
            buttonColor="primary"
            text="Accept"
            textColor="secondary"
          />
          <Button
            onClick={declineRequest(u._id)}
            buttonColor="danger"
            text="Decline"
            textColor="secondary"
          />
        </IonRow>
      );
    }

    return ret;
  };

  const friendRequestsSentList = () => {
    const cancelRequest = (userId: string) => () => {
      dispatch({ type: "pending" });
      axios
        .post(`/users/user/cancel-friend-request`, { userId })
        .then((res) => {
          const {
            data,
            data: { user },
          } = res;
          console.log("data", data);
          setAccount({ user });
          dispatch({ type: "resolved" });
        })
        .catch((err) => {
          console.log("err", err);
          const error = has(err, "response.data.message")
            ? err.response.data.message
            : DEFAULT_ERROR_MESSAGE;
          dispatch({ type: "rejected", error });
        });
    };

    const ret: JSX.Element[] = [];
    for (const { user, sentAt } of friendRequestsSent) {
      const u: iUserNotOwnAccount = user as iUserNotOwnAccount;
      ret.push(
        <IonRow key={u._id}>
          <Chivo18 bold={false} ml={10} text={`--- ${u.username}`} />
          <Button
            text="Cancel Request"
            buttonColor="warning"
            onClick={cancelRequest(u._id)}
          />
        </IonRow>
      );
    }

    return ret;
  };

  return (
    <IonContentColor>
      <IonLoading isOpen={state.status === "pending"} />
      <IonGrid>
        <IonRow>
          <IonCol>
            <Chivo32 text={strings.friend_requests} />
          </IonCol>
        </IonRow>
        {/* <TitledComponent {...{ title: strings.friend_requests }}> */}
        <IonRow>
          <IonCol>
            <Chivo24 color="secondary" bold={false} ml={6} text={"Received"} />
          </IonCol>
        </IonRow>
        {friendRequestsReceivedList()}
        <IonRow>
          <IonCol>
            <Chivo24 color="secondary" bold={false} ml={6} text={"Sent"} />
          </IonCol>
        </IonRow>
        {friendRequestsSentList()}
        {/* </TitledComponent> */}
      </IonGrid>
    </IonContentColor>
  );
};

export default Content;
