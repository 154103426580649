import React from "react";
import // useFileUploaderHotel,
"../FileUploader/hooks";
import ModalWrapper from "../ModalWrapper";
import Content, { iContentProps } from "./Content";

import styles from "./styles.module.css";

interface iSelectImageModalProps extends Omit<iContentProps, "hook"> {}

const SelectImageModal: React.FC<iSelectImageModalProps> = ({
  modal_id,
  ...rest
}) => {
  return (
    <ModalWrapper cssClass={styles.modal} {...{ modal_id }}>
      <Content {...{ ...rest, modal_id }} />
    </ModalWrapper>
  );
};

export default SelectImageModal;
