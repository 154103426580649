import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useReducer } from "react";
import iUser from "../../interfaces/models/user";
// eslint-disable-next-line
import {
  API_PORT,
  API_URL,
  LOCAL_API_URL,
  USE_LOCAL_API,
} from "../../utils/consts";
import { createCtx } from "../CreateContext";
import {
  AuthContextType,
  AuthContextProps,
  AuthState,
  iAction,
  Token,
  SignupDataType,
} from "./interfaces";

const initial_state: AuthState = {
  loading: true,
  token: null,
  manager: false,
};

const STORAGE_TIMESTAMP = "timestamp";

const reducer = (state: AuthState, action: iAction): AuthState => {
  switch (action.type) {
    case "log in":
      localStorage.setItem("token", action.token!);
      localStorage.setItem("manager", action.manager! ? "true" : "false");

      axios.defaults.headers.common["token"] = action.token!;

      return { ...state, token: action.token!, manager: action.manager! };

    case "check auth state":
      const token: Token = localStorage.getItem("token");
      const manager: string | null = localStorage.getItem("manager");

      if (token) axios.defaults.headers.common["token"] = token;

      return { ...state, loading: false, token, manager: manager === "true" };

    case "log out":
      return { ...state, token: null, manager: false };

    default:
      return { ...state };
  }
};

const [useAuth, CtxProvider, ctxConsumer] = createCtx<AuthContextType>();

const AuthContextProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [state, dispatch]: [AuthState, React.Dispatch<iAction>] = useReducer(
    reducer,
    initial_state
  );

  useEffect(() => {
    const getOrigin = () => {
      if (!USE_LOCAL_API) return API_URL;
      return LOCAL_API_URL;

      const live_origin = "noytrall";
      let origin = window.location.origin;

      if (origin.indexOf(live_origin) !== -1) return API_URL;

      const valid_origins = ["localhost", "127.0.0.1", "192.168.", "19"];
      if (new RegExp(valid_origins.join("|")).test(origin)) {
        origin = origin.slice(0, origin.lastIndexOf(":") + 1) + API_PORT;
      }
      return origin;
    };
    axios.defaults.baseURL = getOrigin();

    dispatch({ type: "check auth state" });
  }, []);

  useEffect(() => {
    const timestamp = localStorage.getItem(STORAGE_TIMESTAMP);

    try {
      if (
        !timestamp ||
        moment(parseInt(timestamp)).isBefore(moment().subtract(1, "hours"))
      )
        Logout();
    } catch (err) {
      console.log(`err.response`, err);
      Logout();
    }
  }, []);

  const getUsersEndpoint = (point: string): string => {
    return "/users/" + point;
  };

  const onLogin = useCallback(
    (token: string, manager: boolean, account: Record<string, any>) => {
      // localStorage.setItem("account", JSON.stringify(account));
      localStorage.setItem(STORAGE_TIMESTAMP, `${moment().unix() * 1000}`);
      dispatch({ type: "log in", token, manager });
    },
    []
  );

  const Login = (
    data: Pick<iUser, "email" | "password">,
    cb: (error: string) => void
  ): void => {
    axios
      .post(getUsersEndpoint("login"), data)
      .then(({ data: { token, manager, account } }) => {
        console.log(`account`, account);
        if (token) {
          onLogin(
            token as string,
            manager as boolean,
            account as Record<string, any>
          );
        } else {
          cb("Server error, please try again");
        }
      })
      .catch((err) => {
        const error =
          !err.response ||
          err.response.status >= 500 ||
          err.response.status < 400
            ? "Server error, please try again"
            : "Your email or password is incorrect.";
        cb(error);
      });
  };

  const Signup = (data: SignupDataType, cb: (error: string) => void): void => {
    const { email, password, referralCode } = data;
    axios
      .post(getUsersEndpoint("signup"), { email, password, referralCode })
      .then(({ data: { token } }) => {
        dispatch({ type: "log in", token, manager: false });
      })
      .catch((err) => {
        console.log(err.response);
        const error =
          !err.response ||
          err.response.status >= 500 ||
          err.response.status < 400
            ? "Server error, please try again"
            : err.response.data.message;
        cb(error);
      });
  };
  const SignupPromise = (data: SignupDataType) => {
    console.log({
      ...data,
      referralCode: data.referralCode || undefined,
      password2: data.password2 || undefined,
    });
    return axios.post(getUsersEndpoint("signup"), {
      ...data,
      referralCode: data.referralCode || undefined,
      password2: data.password2 || undefined,
    });
  };

  const forgotPassword = (
    email: string,
    cb: (error: boolean, message: string) => void
  ): void => {
    console.log(email);
    axios
      .post(getUsersEndpoint("forgotPassword"), { email })
      .then((res) => {
        console.log(res);
        cb(false, res.data.message || "Email sent!");
      })
      .catch((err) => {
        console.log(Object.entries(err));
        console.log(err);
        console.log(err.status);
        cb(
          true,
          err.response === undefined
            ? "Couldn't connect to server"
            : err.response.data.message
        );
      })
      .finally(() => {});
  };

  const forgotPasswordValidateCode = (
    code: string,
    cb: (error: boolean, message: string) => void
  ): void => {
    axios
      .post(getUsersEndpoint("forgotPassword/validateCode"), { code })
      .then((res) => {
        const { token } = res.data;
        console.log(res);

        axios.defaults.headers.common["token"] = token;
        cb(false, "Valid code!");
      })
      .catch((err) => {
        cb(
          true,
          err.response === undefined
            ? "Couldn't connect to server"
            : err.response.data.message
        );
      });
  };

  const setNewPassword = (
    password: string,
    cb: (error: boolean, message: string) => void
  ): void => {
    axios
      .post(getUsersEndpoint("newPassword"), { password })
      .then((res) => {
        console.log(res);
        cb(false, res.data.message);
      })
      .catch((err) => {
        console.log(err);
        cb(
          true,
          err.response === undefined
            ? "Couldn't connect to server"
            : err.response.data.message
        );
      });
  };

  const Logout = (): void => {
    dispatch({ type: "log out" });

    const to_keep = [
      { key: "theme", default_value: "light" },
      { key: "language", default_value: "en" },
    ];
    const obj = {};

    to_keep.forEach(({ key, default_value }) => {
      //@ts-ignore
      obj[key] = localStorage.getItem(key) || default_value;
    });
    localStorage.clear();
    to_keep.forEach(({ key }) => {
      //@ts-ignore
      localStorage.setItem(key, obj[key]);
    });
  };

  const isLogged = (): boolean => {
    const { token } = state;
    return token !== null && token !== undefined;
  };

  const value = {
    state,
    onLogin,
    Login,
    Signup,
    SignupPromise,
    Logout,
    forgotPassword,
    forgotPasswordValidateCode,
    setNewPassword,
    isLogged,
  };

  return <CtxProvider {...{ value }}>{!state.loading && children}</CtxProvider>;
};

export { useAuth, AuthContextProvider, ctxConsumer as AuthContextConsumer };
