import { IonCol, IonGrid, IonIcon, IonImg, IonRow } from "@ionic/react";
import { locationSharp } from "ionicons/icons";
import React from "react";
import { Chivo } from "../../../../components/Text/Chivo";

import edit_pics from "../../../../img/edit_pics-icon.svg";

import styles from "./styles.module.css";
import { getHotelImage } from "../../../../utils/hotels/hotel";
import IonDesktopContentColor from "../../../../components/IonDesktopContentColor";
import { AboutEdit } from "../../../../components/ManagerComponents/HotelInfo/Content/About/About";

import useManagerAccountState from "../../../../context/Manager/AccountContext/hooks/useManagerAccountState";

interface iDesktopProps {}

const Desktop: React.FC<iDesktopProps> = () => {
  const { currentHotel } = useManagerAccountState();
  const { nameToDisplay, images, location, description } = currentHotel;

  return (
    <IonDesktopContentColor>
      <IonGrid style={{ "--ion-grid-padding": "30px" }}>
        <IonRow>
          <IonCol>
            <Chivo text={nameToDisplay} bold size="3.2vh" />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="flexr-start">
            <IonIcon src={locationSharp} color="secondary" />
            <Chivo text={location.address} ml={0.5} size="1.5vh" />
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "3vh" }}>
          <IonCol className="flexr-start">
            <Chivo text="Gallery" bold size="2.5vh" mr={0.5} />
            {/* <IonIcon src={edit_pics} style={{ fontSize: "2vw" }} /> */}
          </IonCol>
        </IonRow>
        <IonRow className={styles.gallery}>
          <IonCol size="3">
            <IonImg src={getHotelImage(images[0])} />
          </IonCol>
        </IonRow>

        <IonRow style={{ marginTop: "2vh" }}>
          <IonCol>
            <AboutEdit {...{ description }} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonDesktopContentColor>
  );
};

export default Desktop;
