import { IonButton, IonCol, useIonPicker } from "@ionic/react";
import React, { Fragment } from "react";
import PickerRow from "..";
import useMediaQuery from "../../../hooks/mediaQuery";
import { Text14, Text16 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

interface iOptionsPickerProps extends iPickerProps {}

const OptionsPicker: React.FC<iOptionsPickerProps> = ({
  title,
  options,
  onSelect,
  value,
}) => {
  return (
    <PickerRow>
      <Picker {...{ options, title, value, onSelect }} />
    </PickerRow>
  );
};

export interface iOptionsPickerOption {
  text: string;
  value: string | number;
}

interface iPickerProps {
  options: iOptionsPickerOption[];
  title: string;
  onSelect(value: number | string): void;
  value: string | number;
}

const Picker: React.FC<iPickerProps> = ({
  title,
  options,
  onSelect,
  value,
}) => {
  const { isDesktop } = useMediaQuery();
  const [present] = useIonPicker();

  const handleClick = () => {
    return present({
      buttons: [
        { text: "Cancel", handler: () => {} },
        {
          text: "Confirm",
          handler: (selected) => {
            onSelect(selected[title].value);
          },
        },
      ],
      columns: [
        {
          name: title,
          options,
        },
      ],
    });
  };

  return (
    <IonButton
      className={styles.options_picker}
      fill="clear"
      onClick={handleClick}
    >
      <IonCol>
        {isDesktop ? (
          <Fragment>
            <Text16 text={title} />
            <Text16
              text={
                typeof value === "string"
                  ? value.length
                    ? value
                    : "None"
                  : value
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            <Text14 text={title} />
            <Text14
              text={
                typeof value === "string"
                  ? value.length
                    ? value
                    : "None"
                  : value
              }
            />
          </Fragment>
        )}
      </IonCol>
    </IonButton>
  );
};

export default OptionsPicker;
