import moment from "moment";
import _ from "lodash";

// -----------------------------------------------
interface i {
  [key: string]: string;
}

export const arrayToObject = (
  array: string[],
  func: (key: string) => string
): Record<string, any> => {
  const obj: Record<string, any> = {};

  _.forEach(array, (elem) => {
    obj[elem] = func(elem);
  });

  return obj;
};
// +++++++++++++++++++++++++++++++++++++++++++++++

// -----------------------------------------------
export const epochToDate = (epoch: number): string =>
  moment(epoch).format("DD/MM/yy");

export const epochTimelineToString = (
  start_at: number,
  end_at: number
): string =>
  `${moment(start_at).format("DD/MM/yy")} - ${moment(end_at).format(
    "DD/MM/yy"
  )}`;
// +++++++++++++++++++++++++++++++++++++++++++++++

// -----------------------------------------------
export const fakeApiCall = (cb: () => void): void => {
  console.log("Fake Api Call");
  setTimeout(cb, 2000);
};
// +++++++++++++++++++++++++++++++++++++++++++++++

export const getNowInMs = () => moment().unix() * 1000;

export const getUnixMomentInMs = (date: string): number =>
  moment(date).unix() * 1000;

export const groupArrayOfObjectsByProperty = (
  array: Record<string, any>[],
  property: string
): /* Record<string, Record<string, string>> */ Object => {
  const obj: Record<string, Record<string, any>[]> = {};

  array.forEach((elem) => {
    if (!obj.hasOwnProperty(elem[property])) obj[elem[property]] = [];
    obj[elem[property]].push(elem);
  });

  return obj;
};

export const roundNumberToFixed = (value: number, decimal_places: number) => {
  const comma = Math.pow(10, decimal_places);
  return Math.round(value * comma) / comma;
};
