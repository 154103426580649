import { IonCol, IonRow } from "@ionic/react";
import { Fragment } from "react";

import Timespan from "../../Filters/Timespan";
import Rooms from "../../Filters/Rooms";

import styles from "./styles.module.css";
import { OccupiedSegments } from "../../Filters/Occupied";

interface iFiltersProps {}

const Filters: React.FC<iFiltersProps> = () => {
  return (
    <Fragment>
      <IonRow>
        <IonCol className={styles.slider_col}>
          <Timespan />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className={styles.slider_col}>
          <Rooms />
        </IonCol>
      </IonRow>
      <OccupiedSegments />
    </Fragment>
  );
};

export default Filters;
