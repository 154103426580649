import React from "react";
import iReservation from "../../interfaces/models/reservation";

import energy_icon from "../../img/energy.svg";
import waste_icon from "../../img/waste.svg";
import footprint_icon from "../../img/footprint.svg";
import water_icon from "../../img/water.svg";
import share_icon from "../../img/share-icon.svg";

import "./spending_data.css";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  convertUnitEnergy,
  convertUnitMass,
  convertUnitVolume,
} from "../../utils/convertUnit";
import strings from "../../localization/localization";

export interface iSpendingDataProps
  extends Pick<iReservation, "Energy" | "Water" | "Footprint" /* | "Waste" */> {
  display_detail_icon?: boolean;
  display_waste?: boolean;
}

const SpendingData: React.FC<iSpendingDataProps> = ({
  Energy,
  Footprint,
  Water,
  // Waste,
  display_detail_icon = false,
  display_waste = false,
}) => {
  const WATER_INFO: Omit<iInfoProps, "col_size"> = {
    icon: water_icon,
    label: strings.water,
    value: convertUnitVolume(Water),
  };
  const ENERGY_INFO: Omit<iInfoProps, "col_size"> = {
    icon: energy_icon,
    label: strings.energy,
    value: convertUnitEnergy(Energy),
  };
  const FOOTPRINT_INFO: Omit<iInfoProps, "col_size"> = {
    icon: footprint_icon,
    label: strings.footprint,
    value: convertUnitMass(Footprint),
    sub_unit: "CO2 eq",
  };
  const SHARE_INFO: Omit<iInfoProps, "col_size"> = {
    icon: share_icon,
    label: "",
    value: "See in detail",
    value_xs: true,
    icon_size: "5.5vw",
  };

  const INFO: iInfoProps[][] = [
    [
      { ...ENERGY_INFO, col_size: 7 },
      { ...WATER_INFO, col_size: 5 },
    ],
    display_detail_icon
      ? [
          { ...FOOTPRINT_INFO, col_size: 7 },
          { ...SHARE_INFO, col_size: 5 },
        ]
      : [{ ...FOOTPRINT_INFO, col_size: 12 }],
  ];

  const renderInfo = (): JSX.Element[] => {
    return INFO.map((info, i) => (
      <IonRow key={i}>
        {info.map((elem, i) => (
          <Info key={i} {...elem} />
        ))}
      </IonRow>
    ));
  };

  return <IonGrid>{renderInfo()}</IonGrid>;
};

interface iInfoProps {
  icon: string;
  icon_size?: string;
  label: string;
  value: string;
  value_xs?: boolean;
  sub_unit?: "CO2 eq";
  col_size: number;
}

const Info: React.FC<iInfoProps> = ({
  icon,
  icon_size = "7vw",
  label,
  value,
  value_xs = false,
  sub_unit,
  col_size,
}) => {
  return (
    <IonCol
      size={`${col_size}`}
      style={
        col_size === 12 ? { display: "flex", justifyContent: "center" } : {}
      }
    >
      <div className="spending_data-item">
        <div className="spending_data-item-label">
          <IonLabel>
            {label ? (
              <p className="montserrat">{label}</p>
            ) : (
              <p style={{ visibility: "hidden" }}>hidden</p>
            )}
          </IonLabel>
        </div>
        <div className="spending_data-item-content">
          <IonIcon src={icon} style={{ fontSize: icon_size }} />
          <IonText>
            <p
              className="chivo"
              style={{ fontSize: value_xs ? "2.6vw" : "5.5vw" }}
            >
              {value}
              {sub_unit && <small className="chivo">{` ${sub_unit}`}</small>}
            </p>
          </IonText>
        </div>
      </div>
    </IonCol>
  );
};

export default SpendingData;
