import { IonCol, IonRow, IonText } from "@ionic/react";
import React from "react";

import styles from "./title.module.css";

interface iTitleProps {
  title: string;
}

const Title: React.FC<iTitleProps> = ({ title }) => {
  return (
    <IonRow className={styles.title}>
      <IonCol>
        <IonText color="secondary">
          <p className="chivo">
            <strong>{title}</strong>
          </p>
        </IonText>
      </IonCol>
    </IonRow>
  );
};

export default Title;
