import React from "react";
import { IonDatetime, IonButton } from "@ionic/react";

import styles from "./styles.module.css";
import { Text14, Text16 } from "../../Text/Montserrat";
import PickerRow from "..";
import useMediaQuery from "../../../hooks/mediaQuery";

const DatePicker /* : React.FC<iDatePickerProps> */ = ({
  id,
  title,
  value,
  onSelect,
  hour_minutes = true,
  default_hour = undefined,
  default_minute = undefined,
}) => {
  const { isDesktop } = useMediaQuery();

  return (
    <PickerRow row_styles={styles.container}>
      <IonButton
        fill="clear"
        onClick={() => {
          const elem = document.getElementById(id);
          elem.open();
        }}
      >
        {isDesktop ? <Text16 text={title} /> : <Text14 text={title} />}
      </IonButton>
      <IonDatetime
        id={id}
        displayFormat={`D MMM YYYY${hour_minutes ? "H:mm" : ""}`}
        minuteValues="0,15,30,45"
        min="2000"
        max="2030"
        value={value}
        placeholder="None"
        onIonChange={(e) => onSelect(e.detail.value /* ! */)}
      ></IonDatetime>
    </PickerRow>
  );
};

export default DatePicker;
