import React from "react";

interface iFileUploaderProps {
  inputId: string;
  handleChange(e: any): void;
}

const FileUploader: React.FC<iFileUploaderProps> = ({
  inputId,
  handleChange,
}) => {
  return (
    <div style={{ display: "none" }}>
      <input type="file" name="file" onChange={handleChange} id={inputId} />
    </div>
  );
};

export default FileUploader;
