const menu = {
  privacy_menu: "Privacy",
  help_menu: "Help",
  notifications_menu: "Notifications",
  account_menu: "Account",
  theme_menu: "Theme",
  language_menu: "Language",
  logout_menu: "Log Out",
  settings_menu: "Settings",
};

export default menu;
