import { IonLoading } from "@ionic/react";
import axios from "axios";
import React, { useState } from "react";
import IonContentColor from "../../../components/IonContentColor";
import RoundInput from "../../../components/RoundInput";
import SlimButton from "../../../components/SlimButton";
import { getErrorMessage } from "../../../utils/httpResponses/others";

import useUserDispatch from "../../../context/User/UserContext/hooks/Account/useUserAccountDispatch";

const Redeem: React.FC = () => {
  const { setAccount } = useUserDispatch();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const redeem = () => {
    if (!code || code.length === 0) {
      alert("Enter a code");
      return;
    }
    setLoading(true);
    axios
      .post("/users/user/redeem-code", { code })
      .then((res) => {
        const {
          data: { user },
        } = res;
        setAccount({ user });
      })
      .catch((err) => {
        alert(getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IonContentColor>
      <IonLoading isOpen={loading} />
      <div>
        <div
          style={{
            marginTop: "70px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "70%",
            }}
          >
            <RoundInput
              onChange={setCode}
              value={code}
              error={false}
              placeholder="Code"
            />
            <SlimButton onClick={redeem} color="secondary" text="REDEEM" />
          </div>
        </div>
      </div>
    </IonContentColor>
  );
};

export default Redeem;
