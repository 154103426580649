import React from "react";
import { modal_id_notifications } from "../../../context/OpenModalContext/modal_ids";
import MenuModal from "../../MenuModal";
import Content from "./Content";

const Notifications: React.FC = () => {
  return (
    <MenuModal
      {...{ title: "Notifications", modal_id: modal_id_notifications }}
    >
      <Content />
    </MenuModal>
  );
};

export default Notifications;
