// export const API_URL: string = "https://safe-mesa-94282.herokuapp.com/";
// export const API_URL: string = "https://noytrall-backend-v1.herokuapp.com/";
const IPV4 = "192.168.1.181";
export const API_URL: string =
  "https://1x2be1smjf.execute-api.eu-west-1.amazonaws.com/old";
// export const API_URL: string = "http://188.166.170.2:5000";
export const LOCAL_API_URL: string = `http://${IPV4}:5000/old`;
export const API_PORT: number = 5000;
export const USE_LOCAL_API: boolean = false;

export const USER_NOTIFICATIONS_TIMEOUT = 50000;

export const LOG_LEVEL: number = 3;

export const PEOPLE_IN_ROOM_MAX = 10;
export const PEOPLE_IN_ROOM_MIN = 1;

export const POINTS_PER_LEVEL: number = 1000;

export const POINTS_PER_EURO: number = 20;

export const MAX_FAVORITE_ACHIEVEMENTS: number = 6;
export const ACHIEVEMENTS_LEVELS: string[] = [
  "Bronze",
  "Silver",
  "Gold",
  "Platinum",
];

export type tLanguageCodes = "en" | "pt" | "es" | "it" | "fr";

// export const LANGUAGE_CODES: Record<string, { code: string; name: string }> = {
export const LANGUAGE_CODES: Record<
  string,
  { code: tLanguageCodes; name: string }
> = {
  en: { code: "en", name: "English" },
  pt: { code: "pt", name: "Português" },
  es: { code: "es", name: "Español" },
  it: { code: "it", name: "Italiano" },
};
