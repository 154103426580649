import { IonContent, IonModal } from "@ionic/react";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";

import { modal_id_privacy } from "../../context/OpenModalContext/modal_ids";
import Content from "./Content/Content";

const Privacy = () => {
  const { isOpen } = useOpenModal();
  return (
    <IonModal isOpen={isOpen(modal_id_privacy)}>
      <IonContent>
        <Content />
      </IonContent>
    </IonModal>
  );
};

export default Privacy;
