import { IonLoading } from "@ionic/react";
import axios from "axios";
import React, { Fragment } from "react";
import IonContentColor from "../../../../../components/IonContentColor";
import MenuModalHeader from "../../../../../components/MenuModalHeader";
import ModalFooter2 from "../../../../../components/ModalFooter2/ModalFooter2";
import ModalWrapper from "../../../../../components/ModalWrapper";
import useManagerRewardsDispatch from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsDispatch";
import useManagerRewardsState from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsState";
import { modal_id_edit_reward } from "../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";
import useIonAlertFunctions from "../../../../../hooks/ionAlert";
import { iReward } from "../../../../../interfaces/models/reward";
import EditReward from "../../EditReward";
import { useEditRewardInfoReducer } from "../../hooks";

interface iEditRewardModalProps {
  reward: iReward;
  afterConfirmEdit(): void;
}

const EditRewardModal: React.FC<iEditRewardModalProps> = ({
  reward,
  afterConfirmEdit,
}) => {
  return (
    <ModalWrapper modal_id={modal_id_edit_reward}>
      <Content {...{ reward, afterConfirmEdit }} />
    </ModalWrapper>
  );
};

interface iContentProps extends iEditRewardModalProps {}

const Content: React.FC<iContentProps> = ({ reward, afterConfirmEdit }) => {
  const { editReward, setError, setLoading } = useManagerRewardsDispatch();
  const { isLoading } = useManagerRewardsState();
  const { present } = useIonAlertFunctions();

  const edit_reward_info = useEditRewardInfoReducer(reward);
  const { closeModal } =
    useOpenModal().getModalIdFunctions(modal_id_edit_reward);

  const handleCloseModal = () => {
    closeModal();
  };

  const handleError = (error: string) => {
    setError(error);
    present(error);
  };

  const handleConfirm = () => {
    setLoading(true);
    const properties = edit_reward_info.editedProperties();

    console.log(
      `edit_reward_info.editedProperties()`,
      edit_reward_info.editedProperties()
    );

    if (Object.keys(properties).length === 0) return;

    axios
      .put(`/rewards/reward/${reward._id}`, properties)
      .then((res) => {
        console.log(`res`, res);
        const { reward } = res.data;
        editReward(reward);
        present("Reward edited");
        afterConfirmEdit();
      })
      .catch((err) => {
        console.log(`err`, err);
        try {
          handleError(err.response.data.message);
        } catch (err) {
          handleError("Something went wrong.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <IonLoading isOpen={isLoading} />

      <MenuModalHeader title={"Edit Reward"} />
      <IonContentColor>
        <EditReward edit_reward_info={edit_reward_info} display_preview />
      </IonContentColor>
      <ModalFooter2
        onGreen={handleConfirm}
        red_text="Close"
        onRed={handleCloseModal}
      />
    </Fragment>
  );
};

export default EditRewardModal;
