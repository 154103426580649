// @ts-ignore
import { IonContent } from "@ionic/react";

import { useLocalization } from "../../../context/LocalizationContext";
import strings, {
  available_languages,
} from "../../../localization/localization";
import Flags from "country-flag-icons/react/3x2";

import styles from "./styles.module.css";

import RadioButtonList from "../../RadioButtonList";

const language2Flag = (localization) => {
  if (localization == "en") return "GB";
  return localization.toUpperCase();
};

const Content = ({ onSwitchLangauge }) => {
  const {
    state: { language: current_lang },
  } = useLocalization();

  const getItems = () => {
    const flags = [];
    available_languages.forEach((lang) => {
      const f = language2Flag(lang);
      if (Flags[f]) {
        const Component = Flags[f];
        flags.push({
          Component: <Component className={styles.flag} />,
          label: strings[`language_${lang}`],
          value: lang,
        });
      }
    });
    return flags.map((c) => c);
  };

  return (
    <IonContent>
      <RadioButtonList
        items={getItems()}
        value={current_lang}
        onClickRadio={onSwitchLangauge}
      />
    </IonContent>
  );
};

export default Content;
