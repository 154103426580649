import { has } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { ManagerCompetitionsStateContext } from "../";
import iCompetition from "../../../../interfaces/models/competitions";
import { nManagerCompetitions } from "../interfaces";

const useManagerCompetitionsState = () => {
  const state = React.useContext(ManagerCompetitionsStateContext);

  if (!state)
    throw Error(
      "useManagerCompetitionsState must be used within ManagerCompetitionsStateContext"
    );

  const {
    data: { competitions, competitionsData },
  } = state;

  const sortedCompetitions = useMemo(() => {
    const sorted: {
      past: iCompetition[];
      ongoing: iCompetition[];
      upcoming: iCompetition[];
    } = { past: [], ongoing: [], upcoming: [] };

    for (const competition of competitions) {
      const startAt = moment(competition.start_at);
      const endAt = moment(competition.end_at);
      const now = moment();
      if (startAt.isAfter(now)) {
        sorted.upcoming.push(competition);
      } else if (endAt.isAfter(now)) {
        sorted.ongoing.push(competition);
      } else {
        sorted.past.push(competition);
      }
    }
    return sorted;
  }, [competitions]);

  const getCompetitionRoomsData = useCallback(
    (competitionId: string): nManagerCompetitions.tRoomsData | null => {
      if (has(competitionsData, `${competitionId}.roomsData`))
        return competitionsData[competitionId].roomsData;
      return null;
    },
    [competitionsData]
  );
  const getCompetition = useCallback(
    (competitionId: string): iCompetition | null => {
      for (const competition of competitions) {
        if (competition._id === competitionId) return competition;
      }
      return null;
    },
    [competitions]
  );

  return {
    competitions,
    sortedCompetitions,
    getCompetitionRoomsData,
    getCompetition,
    isLoading: state.status === "pending",
  };
};

export default useManagerCompetitionsState;
