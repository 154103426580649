import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { sum } from "lodash";
import React, { Fragment, useEffect, useReducer } from "react";
import { overall_data_types } from "../../../context/DataModalContext/consts";
import {
  iDonutGraph,
  iOverallDataType,
  tDataLabel,
  tGraph,
} from "../../../context/DataModalContext/interfaces";
import useManagerAccountState from "../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import { modal_id_hotel_spending_data } from "../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../context/OpenModalContext/OpenModalContext";
import { iSensorData1 } from "../../../interfaces/models/sensorData";
import { calculateDonutGraphData1, getGraphLabels } from "../../../utils/graph";
import Donut from "../../ChartJsGraphs/Donut/Donut";
import DonutLabels from "../../ChartJsGraphs/Donut/DonutLabels";
import GraphsFooterDataTypes from "../../GraphsFooterDataTypes";
import IonContentColor from "../../IonContentColor";
import IonHeaderColored from "../../IonHeaderColored";
import { Chivo32 } from "../../Text/Chivo";
import { Text20 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

interface iState {
  data_type_index: number;
  available_types: iOverallDataType[];
  donutGraphData: tGraph<iDonutGraph> | {};
  loading: boolean;
}

interface iAction extends Partial<iState> {
  type: string;
}

const initial_state: iState = {
  data_type_index: 0,
  available_types: [],
  donutGraphData: {
    Water: {},
    Energy: {},
    Footprint: {},
    Money: {},
    Waste: {},
  },
  loading: true,
};

const reducer = (state: iState, action: iAction) => {
  const { type } = action;
  switch (type) {
    case "set data type":
      return { ...state, data_type_index: action.data_type_index! };

    case "save graph data":
      return {
        ...state,
        loading: false,
        donutGraphData: action.donutGraphData!,
        available_types: action.available_types!,
      };

    default:
      return { ...state };
  }
};

export interface iContentProps {
  data: iSensorData1;
  time_period_title: string;
  room_title: string;
  onClose(): void;
}

const Content: React.FC<iContentProps> = ({
  data,
  time_period_title,
  room_title,
  onClose,
}) => {
  const { closeModal } = useOpenModal();
  const { currentHotel } = useManagerAccountState();
  const [state, dispatch] = useReducer(reducer, initial_state);

  useEffect(() => {
    const available_types: iOverallDataType[] = [];
    const { water2footprint, energy2footprint } = currentHotel;
    const donutGraphData = calculateDonutGraphData1(
      data,
      energy2footprint,
      water2footprint
    );
    Object.entries(donutGraphData).forEach((entry) => {
      const [type, data] = entry;
      if (sum(data.datasets[0].data)) {
        for (let i = 0; i < overall_data_types.length; i++) {
          const info = overall_data_types[i];

          if (type === info.title) {
            available_types.push(info as iOverallDataType);
            break;
          }
        }
      }
    });

    dispatch({
      type: "save graph data",
      donutGraphData,
      available_types,
    });
  }, [currentHotel, data]);

  const getActiveDataType = (): iOverallDataType => {
    const { available_types, data_type_index } = state;
    return available_types[data_type_index];
  };

  const getActiveGraphData = (): iDonutGraph => {
    const handleData = (sensor_data: iSensorData1) => {
      const new_sensor_data = { ...sensor_data };
      const hotel = currentHotel;
      const { water2footprint, energy2footprint } = hotel;

      const donutGraphData = calculateDonutGraphData1(
        new_sensor_data,
        energy2footprint,
        water2footprint
      );

      const available_types: iOverallDataType[] = [];
      Object.entries(donutGraphData).forEach((entry) => {
        const [type, data] = entry;
        if (sum(data.datasets[0].data)) {
          for (let i = 0; i < overall_data_types.length; i++) {
            const info = overall_data_types[i];

            if (type === info.title) {
              available_types.push(info as iOverallDataType);
              break;
            }
          }
        }
      });
      return donutGraphData;
    };
    const getData = (): ReturnType<typeof handleData> => {
      return handleData(data);
    };

    const donutGraphData = getData();
    const title = getActiveDataType().title;

    return (donutGraphData as tGraph<iDonutGraph>)[title];
  };

  const getActiveGraphLabels = (): tDataLabel[] => {
    const { donutGraphData } = state;
    return getGraphLabels(
      donutGraphData as tGraph<iDonutGraph>,
      getActiveDataType()
    );
  };

  const handleCloseModal = () => {
    closeModal(modal_id_hotel_spending_data);
    onClose();
  };

  const setDataType = (index: number) => {
    dispatch({ type: "set data type", data_type_index: index });
  };

  const { available_types, data_type_index, loading } = state;

  console.log(data);

  return (
    <Fragment>
      <Header {...{ room_title, time_period_title }} />
      <IonContentColor>
        {!loading && available_types.length ? (
          <IonGrid className={styles.content_grid}>
            <IonRow className={styles.content_title}>
              <IonCol>
                <Chivo32 text={getActiveDataType().title} bold />
              </IonCol>
            </IonRow>
            <IonRow className={styles.content_graph}>
              <IonCol>
                <Donut data={getActiveGraphData()} />
              </IonCol>
            </IonRow>
            <DonutLabels labels={getActiveGraphLabels()} />
          </IonGrid>
        ) : (
          <></>
        )}
      </IonContentColor>
      <GraphsFooterDataTypes
        {...{
          available_types,
          data_type_index,
          setDataType,
          modal_id: modal_id_hotel_spending_data,
          onCloseModal: handleCloseModal,
        }}
      />
    </Fragment>
  );
};

interface iHeaderProps
  extends Pick<iContentProps, "time_period_title" | "room_title"> {}

const Header: React.FC<iHeaderProps> = ({ time_period_title, room_title }) => {
  return (
    <IonHeaderColored>
      <IonGrid className={styles.header_grid}>
        <IonRow>
          <IonCol>
            <Text20 text={time_period_title} />
          </IonCol>
        </IonRow>
        <IonRow className={styles.header_room}>
          <IonCol>
            <Text20 text={room_title} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonHeaderColored>
  );
};

export default Content;
