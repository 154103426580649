import { IonLoading } from "@ionic/react";
import axios from "axios";
import React, { useEffect, useReducer } from "react";
import { nManagerAccount } from "./interfaces";

const LS_ACCOUNT = "__mngr-acc__";

const ManagerAccountDispatchContext = React.createContext<
  nManagerAccount.tDispatchContext | undefined
>(undefined);
ManagerAccountDispatchContext.displayName = "ManagerAccountDispatchContext";
const ManagerAccountStateContext = React.createContext<
  nManagerAccount.tStateContext | undefined
>(undefined);
ManagerAccountStateContext.displayName = "ManagerAccountStateContext";

const initialState: nManagerAccount.iState = {
  status: "idle",
  error: null,
  data: {
    _id: "",
    email: "",
    hotels: [],
    currentHotelIndex: 0,
    roomsPerHotel: {},
  },
};

const reducer = (
  state: nManagerAccount.iState,
  action: nManagerAccount.tAction
): nManagerAccount.iState => {
  switch (action.type) {
    case "set-data no-status-update":
      return { ...state, data: { ...state.data, ...action.data } };
    case "set data":
      localStorage.setItem(LS_ACCOUNT, JSON.stringify(action.data));
      return {
        ...state,
        data: { ...state.data, ...action.data },
        status: "resolved",
        error: null,
      };
    case "set hotels":
      const data = { ...state.data, hotels: action.hotels };
      return { ...state, data, status: "resolved", error: null };
    case "resolved":
      return { ...state, status: "resolved" };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    default:
      return { ...state };
  }
};

const ManagerAccountContextProvider: React.FC<
  nManagerAccount.iContextProps
> = ({ children }) => {
  const [state, dispatch]: [
    nManagerAccount.iState,
    React.Dispatch<nManagerAccount.tAction>
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    const loadAccoutFromAPI = () => {
      axios
        .get("/managers/manager")
        .then((res) => {
          const {
            data: { info },
          } = res;
          console.log("res", res);
          dispatch({
            type: "set data",
            data: { ...state.data, ...info },
          });
        })
        .catch((err) => {
          console.log("err.response", err.response);
          dispatch({ type: "rejected", error: err.response?.message });
        });
    };

    dispatch({ type: "pending" });
    const manager = localStorage.getItem(LS_ACCOUNT);

    if (!manager) {
      const defaultAccount = localStorage.getItem("account");
      if (defaultAccount) {
        try {
          const parsed = JSON.parse(defaultAccount);
          dispatch({
            type: "set-data no-status-update",
            data: { ...state.data, ...parsed },
          });
        } catch (err) {}
      }
      return loadAccoutFromAPI();
    }
    try {
      const parsed = JSON.parse(manager);
      dispatch({
        type: "set data",
        data: { ...state.data, ...parsed },
      });
    } catch (err) {
      localStorage.removeItem(LS_ACCOUNT);
      loadAccoutFromAPI();
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <ManagerAccountStateContext.Provider value={state}>
      <ManagerAccountDispatchContext.Provider value={dispatch}>
        <IonLoading isOpen={state.status === "pending"} />
        <>{children}</>
      </ManagerAccountDispatchContext.Provider>
    </ManagerAccountStateContext.Provider>
  );
};

export {
  ManagerAccountContextProvider,
  ManagerAccountDispatchContext,
  ManagerAccountStateContext,
};
