const menu = {
  privacy_menu: "Privacidade",
  help_menu: "Ajuda",
  notifications_menu: "Notificações",
  account_menu: "Conta",
  theme_menu: "Tema",
  language_menu: "Idioma",
  logout_menu: "Sair da conta",
  settings_menu: "Definições",
};

export default menu;
