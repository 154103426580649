import {
  IonCol,
  IonGrid,
  IonLoading,
  IonModal,
  IonRow,
  IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useOpenModal } from "../../../../../../context/OpenModalContext/OpenModalContext";

import { modal_id_edit_about_hotel } from "../../../../../../context/OpenModalContext/modal_ids";

import styles from "./edit.module.css";
import ModalFooter2 from "../../../../../ModalFooter2/ModalFooter2";
import axios from "axios";
import { Chivo18 } from "../../../../../Text/Chivo";
import IonContentColor from "../../../../../IonContentColor";
import useManagerAccountState from "../../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerAccountDispatch from "../../../../../../context/Manager/AccountContext/hooks/useManagerAccountDispatch";

interface iEditProps {
  init_text: string;
}

const Edit: React.FC<iEditProps> = ({ init_text }) => {
  const [text, setText] = useState<string>(init_text);
  const { isOpen, closeModal } = useOpenModal();
  const { currentHotel, isLoading } = useManagerAccountState();
  const { updateHotel, setLoading } = useManagerAccountDispatch();

  useEffect(() => {
    setText(init_text);
  }, [init_text]);

  const handleConfirm = () => {
    setLoading(true);
    axios
      .put(`/hotels/hotel/${currentHotel._id}`, { description: text })
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        console.log("res", res);
        updateHotel(hotel);
        closeModal(modal_id_edit_about_hotel);
      })
      .catch((err) => {
        setText(init_text);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    closeModal(modal_id_edit_about_hotel);
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonModal
        mode="ios"
        onDidDismiss={handleClose}
        isOpen={isOpen(modal_id_edit_about_hotel)}
      >
        <IonContentColor className={styles.content}>
          <IonGrid slot="fixed" className={styles.title}>
            <IonRow>
              <IonCol>
                <Chivo18 text="About" />
              </IonCol>
              <IonCol>
                <Chivo18 text="edit mode" />
              </IonCol>
            </IonRow>
          </IonGrid>
          <div>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonTextarea
                    className="montserrat"
                    value={text}
                    onIonChange={(e) => setText(e.detail.value!)}
                    autoGrow={true}
                  ></IonTextarea>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContentColor>
        <ModalFooter2
          green_text="Confirm"
          red_text="Cancel"
          onRed={handleClose}
          onGreen={handleConfirm}
        />
      </IonModal>
    </>
  );
};

export default Edit;
