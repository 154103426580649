const navbar = {
  stays: "Stays",
  stats: "Zero",
  explore: "Explore",
  profile: "Profile",
  search: "Search",
  games: "Games",
  hotel: "hotel",
  bookings: "Bookings",
};

export default navbar;
