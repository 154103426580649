import React from "react";
import RoomSelectionSlider from "../../../../../components/RoomSelectionSlider";
import { useHotelStays } from "../../../../../context/HotelStaysContext";
import { slider_id_hotel_stays_rooms_stays } from "../../../../../utils/sliders";

interface iRoomsProps {}

const Rooms: React.FC<iRoomsProps> = () => {
  const { setRoom } = useHotelStays();
  return (
    <RoomSelectionSlider
      {...{ setRoom, slider_id: slider_id_hotel_stays_rooms_stays, rooms: [] }}
    />
  );
};

export default Rooms;
