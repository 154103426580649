import React, { useEffect, useReducer } from "react";
import { createCtx } from "../CreateContext";
import { nFirebase } from "./interfaces";
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import useAxios from "axios-hooks";
import { useAuth } from "../AuthContext/AuthContext";

const initial_state: nFirebase.iState = {};

const reducer = (
  state: nFirebase.iState,
  action: nFirebase.iAction
): nFirebase.iState => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

const config = {
  apiKey: "AIzaSyBVD8HTDa5xtwxnaebV1HVrpL6Eve_9MH0",
  authDomain: "noytrall.firebaseapp.com",
  projectId: "noytrall",
  storageBucket: "noytrall.appspot.com",
  messagingSenderId: "193702719829",
  appId: "1:193702719829:web:e8c860e49036fec6d33fdc",
  measurementId: "G-G3MYPYX22M",
};

const [useFirebase, CtxProvider] = createCtx<nFirebase.iContext>();

const FirebaseContextProvider: React.FC<nFirebase.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [
    nFirebase.iState,
    React.Dispatch<nFirebase.iAction>
  ] = useReducer(reducer, initial_state);
  const { onLogin } = useAuth();

  const [
    { data: googleData, loading: googleLoading, error: googleError },
    executeGoogleSignup,
  ] = useAxios(
    { url: "/users/signupWithGoogle", method: "POST" },
    { manual: true }
  );
  const [
    { data: dbData, loading: fbLoading, error: dbError },
    executeFbSignup,
  ] = useAxios(
    { url: "/users/signupWithFb", method: "POST" },
    { manual: true }
  );

  useEffect(() => {
    if (!firebase.apps.length) firebase.initializeApp(config);
  }, []);

  useEffect(() => {
    if (!googleLoading) {
      if (googleError) {
      }
      if (googleData) {
        const { token, account, manager } = googleData;
        onLogin(token, manager, account);
      }
    }
  }, [googleLoading, googleData, googleError, onLogin]);

  const loginWithFb = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebase.auth().signOut();
  };

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.setCustomParameters({
      display: "popup",
    });

    firebase.auth().signOut();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log("result", result);
        //@ts-ignore
        const { id, family_name, given_name, locale } =
          //@ts-ignore
          result.additionalUserInfo.profile;
        //@ts-ignore
        const { email } = result.user;

        const data = {
          id,
          lastName: family_name,
          firstName: given_name,
          email,
        };

        executeGoogleSignup({ data });
      })
      .catch((error) => {
        //   alert(error);
        localStorage.setItem("ERROR", JSON.stringify(error));
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      })
      .finally(() => {
        firebase.auth().signOut();
      });
  };

  const value = { state, loginWithGoogle };

  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export { useFirebase, FirebaseContextProvider };
