const languages = {
  default_language: "System Default",
  selected_language: "Selected",
  language_pt: "Portuguese",
  language_en: "English",
  language_fr: "French",
  language_es: "Spanish",
};

export default languages;
