import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
} from "@ionic/react";
import React from "react";
import { Chivo14, Chivo20 } from "../../../../components/Text/Chivo";
import { useProfile } from "../../../../context/ProfileContext/ProfileContext";
import { useProfilePage } from "../context";

import default_user from "../../../../img/default_user.png";

import styles from "./styles.module.css";
import { Text10, Text12, Text18 } from "../../../../components/Text/Montserrat";
import { heart, heartOutline } from "ionicons/icons";
import useBoxShadow from "../../../../hooks/boxShadow";
import { ACHIEVEMENTS_LEVELS } from "../../../../utils/consts";

interface iBadgeCardProps {}

const BadgeCard: React.FC<iBadgeCardProps> = () => {
  const boxShadow = useBoxShadow();
  const { getBadge } = useProfilePage();
  const {
    getAchievements,
    getFavoriteAchievements,
    setFavoriteAchievement,
    removeFavoriteAchievement,
  } = useProfile();

  getFavoriteAchievements();

  if (getBadge() >= getAchievements().length) return <></>;

  const handleClickHeart = () => {
    const achievement = getAchievements()[getBadge()];
    if (achievement.favorite_position === -1)
      setFavoriteAchievement(achievement.achievement._id);
    else removeFavoriteAchievement(achievement.achievement._id);
  };

  const achievement = getAchievements()[getBadge()] || null;

  console.log(`achievement`, achievement);
  const {
    achievement: { icon, points, title, description, max_level },
    level,
    favorite_position,
  } = achievement;

  return (
    <IonCard color="primary">
      <IonCardContent>
        <IonGrid className={styles.grid}>
          <IonRow>
            <IonCol size="3" className={styles.left}>
              <IonAvatar
                style={{
                  boxShadow,
                }}
              >
                <IonImg src={icon || default_user} />
              </IonAvatar>
              <Chivo14
                text={ACHIEVEMENTS_LEVELS[max_level - level]}
                color="light"
              />
              <div>
                <Text18 text={points} />
                <Text10 text="points" />
              </div>
            </IonCol>
            <IonCol className={styles.right}>
              <IonIcon
                onClick={handleClickHeart}
                src={favorite_position >= 0 ? heart : heartOutline}
                color="light"
              />
              <Chivo20 text={title} color="light" />
              <Text12 text={description} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default BadgeCard;
