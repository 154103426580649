import { IonCard, IonCardContent, IonGrid, IonImg, IonRow } from "@ionic/react";
import React from "react";
import { useDataModal } from "../../context/DataModalContext/DataModalContext";
import iReservation from "../../interfaces/models/reservation";

import Data from "./Data/Data";
import HotelInfo, { iHotelInfoProps } from "./HotelInfo/HotelInfo";

import styles from "./hotel_card.module.css";
import { getHotelImage } from "../../utils/hotels/hotel";

interface iHotelCardProps {
  reservation: iReservation;
  with_data: boolean;
}

const HotelCard: React.FC<iHotelCardProps> = ({ reservation, with_data }) => {
  const { openReportModal } = useDataModal();
  const {
    hotel: { images, location, nameToDisplay },
    startAt,
    endAt,
  } = reservation;

  const handleClickCard = () => {
    openReportModal(reservation);
  };

  return (
    <IonCard
      mode="ios"
      onClick={with_data ? () => handleClickCard() : () => {}}
      color="primary"
      className={styles.card}
    >
      <IonImg src={getHotelImage(images[0])} />
      {with_data ? (
        <ContentWithData {...{ reservation }} />
      ) : (
        <ContentNoData
          {...{ addressProps: { location }, nameToDisplay, endAt, startAt }}
        />
      )}
    </IonCard>
  );
};

interface iContentProps {
  reservation: iReservation;
  onClickAddressGotoMaps?: boolean;
}

const ContentWithData: React.FC<iContentProps> = ({
  reservation,
  onClickAddressGotoMaps = true,
}) => {
  const {
    hotel: { location, nameToDisplay },
    startAt,
    endAt,
    Footprint,
  } = reservation;
  return (
    <IonCardContent className={styles["card-content"]}>
      <IonGrid>
        <IonRow>
          <Data
            footprint={Footprint || 0}
            points={reservation.points || 0}
            cost={
              (reservation.Energy || 0) *
                ((reservation.hotel || {}).energy2currency || 0) +
              (reservation.Water || 0) *
                ((reservation.hotel || {}).water2currency || 0)
            }
          />
          <HotelInfo
            position={"end"}
            {...{
              nameToDisplay,
              endAt,
              startAt,
              addressProps: { location, gotoMaps: onClickAddressGotoMaps },
            }}
          />
        </IonRow>
      </IonGrid>
    </IonCardContent>
  );
};

interface iContentNoDataProps
  extends Pick<
    iHotelInfoProps,
    "nameToDisplay" | "addressProps" | "endAt" | "startAt"
  > {}

const ContentNoData: React.FC<iContentNoDataProps> = ({ ...props }) => {
  return (
    <IonCardContent
      className={styles["card-content"]}
      id={styles["card-content-no_data"]}
    >
      <IonGrid>
        <IonRow>
          <HotelInfo
            {...{
              position: "start",

              hotel_name_max_characters: 40,
              ...props,
            }}
          />
        </IonRow>
      </IonGrid>
    </IonCardContent>
  );
};

interface iHotelCardNoReservationProps
  extends Pick<iContentNoDataProps, "nameToDisplay">,
    Partial<Pick<iContentNoDataProps, "addressProps">> {
  images: string[];
  onClick?(): void;
}

export const HotelCardNoReservation: React.FC<iHotelCardNoReservationProps> = ({
  images,
  onClick = () => {},
  ...props
}) => {
  return (
    // <IonItem color="none" lines="none">
    <IonCard
      mode="ios"
      onClick={onClick}
      style={{
        marginBottom: "1vh",
      }}
      color="primary"
      className={styles.hotel + " " + styles.card}
    >
      <IonImg src={getHotelImage(images[0])} />
      <ContentNoData {...props} />
    </IonCard>
    // </IonItem>
  );
};

export default HotelCard;
