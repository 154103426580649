import React from "react";
import Button, { iButtonProps } from "../ItemButton/Button";
import Input, { iInputData } from "../Input/Input";
import Select, { iSelectData } from "../Select";

import "./form.css";
import { Chivo32 } from "../Text/Chivo";
import FormErrorMessage from "../FormErrorMessage";

export type iFormData = (iInputData | iSelectData)[];

interface iFormProps {
  formData: iFormData;
  onChange(name: string, value: string): void;
  onSubmit(e: React.FormEvent<HTMLFormElement>): void;
  error: string | null;
  buttonProps?: iButtonProps;
  title: string;
  color: string;
  className?: string;
}

const Form: React.FC<iFormProps> = ({
  formData,
  color,
  onChange,
  onSubmit,
  error,
  buttonProps,
  title,
  className = "",
}) => {
  const renderInputs = () => {
    return formData.map((input_data: iInputData | iSelectData, i: number) => {
      return input_data.hasOwnProperty("options") ? (
        <Select
          key={i}
          color={color}
          onChange={onChange}
          {...(input_data as iSelectData)}
        ></Select>
      ) : (
        <Input
          key={i}
          {...(input_data as iInputData)}
          color={color}
          onChange={onChange}
        />
      );
    });
  };

  return (
    <div className={"form-container " + className}>
      <Chivo32 text={title} centeredText />

      {error && <FormErrorMessage text={error} />}
      <form onSubmit={onSubmit} className="form-form">
        {renderInputs()}
        <input type="submit" hidden />
        {buttonProps && <Button type="submit" {...buttonProps} />}
      </form>
    </div>
  );
};

export default Form;
