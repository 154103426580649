import { AlertButton, useIonAlert } from "@ionic/react";

const useIonAlertFunctions = () => {
  const [present] = useIonAlert();

  const alert = (
    message: string,
    onDidDismiss: (value?: any) => void = () => {}
  ) => {
    present({
      message,
      buttons: [{ text: "Ok", handler: onDidDismiss }],
      onDidDismiss,
    });
  };

  const presentChoice = (
    message: string,
    {
      onDidDismiss,
      buttons,
    }: {
      onDidDismiss?: (value?: any) => void;
      buttons?: (string | AlertButton)[];
    } = { onDidDismiss: () => {}, buttons: [] }
  ) => {
    present({
      message,
      buttons,
      onDidDismiss,
    });
  };

  return { present: alert, presentChoice };
};

export default useIonAlertFunctions;
