import { IonLoading } from "@ionic/react";
import React, { Fragment } from "react";

import IonContentColor from "../../../../../components/IonContentColor";
import MenuModalHeader from "../../../../../components/MenuModalHeader";
import ModalFooter2 from "../../../../../components/ModalFooter2/ModalFooter2";
import { modal_id_new_reward } from "../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";

import { trash } from "ionicons/icons";

import axios from "axios";
import strings from "../../../../../localization/localization";
import EditReward from "../../EditReward";
import { useEditRewardInfoReducer } from "../../hooks";
import useManagerAccountState from "../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useIonAlertFunctions from "../../../../../hooks/ionAlert";
import useManagerRewardsDispatch from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsDispatch";

interface iContentProps {}

const Content: React.FC<iContentProps> = () => {
  const edit_reward_info = useEditRewardInfoReducer();
  const { state, handleResetInputs, validateState } = edit_reward_info;
  const { closeModal } = useOpenModal();
  const { currentHotel, isLoading } = useManagerAccountState();
  const { setLoading, setError, addReward } = useManagerRewardsDispatch();
  const { present } = useIonAlertFunctions();

  const handleCloseModal = () => {
    closeModal(modal_id_new_reward);
  };

  const handleClickConfirm = () => {
    const missing: string[] = validateState();

    if (!missing.length) {
      setLoading(true);
      axios
        .post("/rewards/", {
          ...state,
          discount: state.type === "Offer" ? 100 : state.discount,
          image: "/image",
          hotel: currentHotel._id,
        })
        .then((res) => {
          const { reward } = res.data;
          addReward(reward, currentHotel._id);
          present("Reward created");
        })
        .catch((err) => {
          console.log(`err`, err.response);
          try {
            setError(err.response.data.message);
          } catch (err) {
            setError("Something went wrong.");
          }
        });
    } else {
    }
  };

  const handleClickCancel = () => {
    handleCloseModal();
  };

  return (
    <Fragment>
      <MenuModalHeader
        title={strings.rewards.new_reward}
        onExit={handleCloseModal}
        extra_icon={trash}
        extra_icon_color="secondary"
        onClickExtraIcon={handleResetInputs}
      />
      <IonContentColor>
        <IonLoading isOpen={isLoading} />

        <EditReward edit_reward_info={edit_reward_info} display_preview />
      </IonContentColor>
      <ModalFooter2
        disable_green={isLoading}
        disable_red={isLoading}
        onGreen={handleClickConfirm}
        onRed={handleClickCancel}
      />
    </Fragment>
  );
};

export default Content;
