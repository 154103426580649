import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import React from "react";

import cross from "../../img/cross.svg";
import { Chivo20 } from "../Text/Chivo";

import styles from "./styles.module.css";

export interface iMenuModalHeaderProps {
  title: string;
  color?: "primary" | "secondary";
  exit_icon?: string;
  extra_icon?: string;
  extra_icon_color?: string;
  onClickExtraIcon?(): void;
  onExit?(): void;
}

const MenuModalHeader: React.FC<iMenuModalHeaderProps> = ({
  title,
  color = "primary",
  exit_icon = cross,
  extra_icon,
  extra_icon_color = "primary",
  onExit,
  onClickExtraIcon,
}) => {
  return (
    <IonHeader className={`${styles.header} shadow_b`}>
      <IonToolbar>
        <IonGrid>
          <IonRow>
            <IonCol className={styles.exit} size="2">
              <IonButton
                style={{ visibility: onExit ? "visible" : "hidden" }}
                fill="clear"
                onClick={() => onExit && onExit()}
              >
                <IonIcon src={exit_icon} />
              </IonButton>
            </IonCol>
            <IonCol>
              <Chivo20 {...{ text: title, color }} />
            </IonCol>
            <IonCol size="2" className={`${styles.extra_button}`}>
              {extra_icon && onClickExtraIcon && (
                <button onClick={() => onClickExtraIcon()}>
                  <IonIcon src={extra_icon} color={extra_icon_color} />
                </button>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};

export default MenuModalHeader;
