import { IonRefresher, IonRefresherContent } from "@ionic/react";
import { chevronDownCircleOutline } from "ionicons/icons";
import React from "react";
import { RefresherEventDetail } from "@ionic/core";

interface iRefresherProps {
  onRefresh(cb: () => void): void;
}

const Refresher: React.FC<iRefresherProps> = ({ onRefresh }) => {
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    onRefresh(() => {
      event.detail.complete();
    });
  }

  return (
    <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
      <IonRefresherContent
        pullingIcon={chevronDownCircleOutline}
        pullingText="Pull to refresh"
        refreshingSpinner="circles"
        //   refreshingText="Refreshing..."
      ></IonRefresherContent>
    </IonRefresher>
  );
};

export default Refresher;
