import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { modal_id_warning } from "../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import ModalWrapper from "../ModalWrapper";
import { Chivo20 } from "../Text/Chivo";
import { Text16 } from "../Text/Montserrat";
import TwoSlimButtons from "../TwoSlimButtons";

import styles from "./styles.module.css";

interface iWarningModalProps {
  onConfirm?(): void;
  onCancel?(): void;
}

const WarningModal: React.FC<iWarningModalProps> = ({
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const { closeModal } = useOpenModal();

  const handleConfirm = () => {
    onConfirm();
    closeModal(modal_id_warning);
  };

  const handleCancel = () => {
    onCancel();
    closeModal(modal_id_warning);
  };

  return (
    <ModalWrapper cssClass={styles.modal} modal_id={modal_id_warning}>
      <IonContent scrollY={false} color="beje">
        <IonGrid>
          <IonRow>
            <IonCol>
              <Chivo20
                centeredText
                bold
                text="Delete Booking"
                color="secondary"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Text16 text="Are you sure you want to delete the booking? Guest will lose access to information!" />
            </IonCol>
          </IonRow>
          <TwoSlimButtons
            onGreen={handleConfirm}
            onRed={handleCancel}
            green_text="Confirm"
            red_text="Cancel"
          />
        </IonGrid>
      </IonContent>
    </ModalWrapper>
  );
};

export default WarningModal;
