export let modal_id: number = 0;

export const modal_id_enter_text_modal_default: number = modal_id++;
export const modal_id_data: number = modal_id++;
export const modal_id_add_booking: number = modal_id++;
export const modal_id_buy_rewards: number = modal_id++;
export const modal_id_hotel: number = modal_id++;
export const modal_id_privacy: number = modal_id++;
export const modal_id_help: number = modal_id++;
export const modal_id_stay_report: number = modal_id++;
export const modal_id_edit_about_hotel: number = modal_id++;
export const modal_id_setings: number = modal_id++;
export const modal_id_notifications: number = modal_id++;
export const modal_id_account: number = modal_id++;
export const modal_id_theme: number = modal_id++;
export const modal_id_language: number = modal_id++;
export const modal_id_forgot_password: number = modal_id++;
export const modal_id_send_booking_code: number = modal_id++;
export const modal_id_filters: number = modal_id++;
export const modal_id_complete_profile: number = modal_id++;
export const modal_id_friends_list: number = modal_id++;
export const modal_id_add_friend: number = modal_id++;
export const modal_id_competition_info: number = modal_id++;
export const modal_id_new_competition: number = modal_id++;
export const modal_id_new_competition_desktop: number = modal_id++;
export const modal_id_new_reward: number = modal_id++;
export const modal_id_view_reward: number = modal_id++;
export const modal_id_edit_reward: number = modal_id++;
export const modal_id_new_reward_enter_text: number = modal_id++;
export const modal_id_reward_info: number = modal_id++;
export const modal_id_add_to_cart: number = modal_id++;
export const modal_id_cart: number = modal_id++;
export const modal_id_hotel_spending_data: number = modal_id++;
export const modal_id_warning: number = modal_id++;
export const modal_id_select_guest_profile_image: number = modal_id++;
export const modal_id_select_image_hotel: number = modal_id++;
export const modal_id_edit_username: number = modal_id++;
export const modal_id_new_password: number = modal_id++;
