import { IonCard, IonCardContent, IonCol } from "@ionic/react";
import React from "react";
import SpendingData, { iSpendingDataProps } from "../SpendingData/SpendingData";

import styles from "./styles.module.css";

interface iSpendingDataCardProps extends iSpendingDataProps {
  color?: string;
  onClickCard?(): void;
}

const SpendingDataCard: React.FC<iSpendingDataCardProps> = ({
  color = "secondary",
  display_detail_icon,
  onClickCard,
  ...rest
}) => {
  return (
    <IonCard
      button={true}
      // button={onClickCard && display_detail_icon ? true : false}
      {...{ color }}
      className={styles.card}
      onClick={display_detail_icon ? onClickCard : undefined}
    >
      <IonCardContent>
        <SpendingData {...{ display_detail_icon, ...rest }} />
      </IonCardContent>
    </IonCard>
  );
};

interface iSpendingDataCardIonColProps extends iSpendingDataCardProps {
  className?: string;
}

export const SpendingDataCardIonCol: React.FC<iSpendingDataCardIonColProps> = ({
  className,
  ...rest
}) => {
  return (
    <IonCol className={className || styles.col}>
      <SpendingDataCard {...rest} />
    </IonCol>
  );
};

export default SpendingDataCard;
