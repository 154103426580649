import axios from "axios";
import { has } from "lodash";
import React, { useEffect, useReducer } from "react";
import { DEFAULT_ERROR_MESSAGE } from "../../../utils/httpResponses/consts";
import calculateData from "./functions/calculateData";
import { nReservationsContext } from "./interfaces";

export const LS_USER_RESERVATIONS_DATA = "__usr-res__";

const UserReservationsStateContext = React.createContext<
  nReservationsContext.tStateContext | undefined
>(undefined);
UserReservationsStateContext.displayName = "UserReservationsStateContext";
const UserReservationsDispatchContext = React.createContext<
  nReservationsContext.tDispatchContext | undefined
>(undefined);
UserReservationsDispatchContext.displayName = "UserReservationsDispatchContext";

const reservationsInitialState: nReservationsContext.iState = {
  data: {
    reservations: [],
    sortedReservationsById: { past: [], ongoing: [], upcoming: [] },
    measures: {
      overall: { Water: 0, Energy: 0, Footprint: 0, Waste: 0 },
      average: { Water: 0, Energy: 0, Footprint: 0, Waste: 0 },
    },
  },
  error: null,
  status: "idle",
};

const reservationsReducer = (
  state: nReservationsContext.iState,
  action: nReservationsContext.tAction
): nReservationsContext.iState => {
  switch (action.type) {
    case "resolved":
      return {
        ...state,
        status: "resolved",
        error: null,
        ...(action.data ? { data: action.data } : {}),
      };
    case "rejected":
      return { ...state, status: "rejected", error: action.error };
    case "pending":
      return { ...state, status: "pending", error: null };
    default:
      return { ...state };
  }
};

const UserReservationsContextProvider: React.FC<
  nReservationsContext.iContextProps
> = ({ children }) => {
  const [state, dispatch]: [
    nReservationsContext.iState,
    React.Dispatch<nReservationsContext.tAction>
  ] = useReducer(reservationsReducer, reservationsInitialState);

  useEffect(() => {
    const getData = () => {
      axios
        .get("/users/user/reservations-data")
        .then((res) => {
          const {
            user: { reservations },
          } = res.data;
          const data = calculateData(reservations);
          localStorage.setItem(LS_USER_RESERVATIONS_DATA, JSON.stringify(data));
          dispatch({ type: "resolved", data });
        })
        .catch((err) => {
          dispatch({
            type: "rejected",
            error: has(err, "response.data.message")
              ? err.response.data.message
              : DEFAULT_ERROR_MESSAGE,
          });
        });
    };

    dispatch({ type: "pending" });
    const data = localStorage.getItem(LS_USER_RESERVATIONS_DATA);

    if (!data) return getData();

    try {
      const parsed = JSON.parse(data);
      dispatch({ type: "resolved", data: parsed });
    } catch (err) {
      localStorage.removeItem(LS_USER_RESERVATIONS_DATA);
      getData();
    }
  }, []);

  return (
    <UserReservationsStateContext.Provider value={state}>
      <UserReservationsDispatchContext.Provider value={dispatch}>
        {children}
      </UserReservationsDispatchContext.Provider>
    </UserReservationsStateContext.Provider>
  );
};

export default UserReservationsContextProvider;
export { UserReservationsStateContext, UserReservationsDispatchContext };
