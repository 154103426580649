import { range } from "lodash";
import { useMemo, useReducer } from "react";
import useManagerAccountState from "../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerReservationsDispatch from "../../../context/Manager/ReservationsContext/hooks/useManagerReservationsDispatch";
import iReservation from "../../../interfaces/models/reservation";
import { PEOPLE_IN_ROOM_MAX, PEOPLE_IN_ROOM_MIN } from "../../../utils/consts";

interface iState {
  check_in_date: string;
  check_out_date: string;
  room: string;
  num_people: number;
  loading: boolean;
  reservation: iReservation | null;
  error: string | null;
}

interface iAction extends Partial<iState> {
  type: string;
}

const initial_state: iState = {
  check_in_date: "",
  check_out_date: "",
  room: "",
  num_people: PEOPLE_IN_ROOM_MIN,
  loading: false,
  error: null,
  reservation: null,
};

const reducer = (state: iState, action: iAction): iState => {
  switch (action.type) {
    case "reset":
      return { ...initial_state };

    case "set":
      const {
        error,
        check_in_date,
        check_out_date,
        room,
        num_people,
        reservation,
      } = action;
      if (check_in_date) return { ...state, check_in_date };
      if (check_out_date) return { ...state, check_out_date };
      if (room) return { ...state, room };
      if (num_people) return { ...state, num_people };
      if (error) return { ...state, error, loading: false };
      if (reservation) return { ...state, reservation, loading: false };
      return { ...state };

    case "toggle loading":
      return { ...state, loading: !state.loading };

    case "set loading":
      return { ...state, loading: action.loading! };

    default:
      return { ...state };
  }
};

export const useAddBooking = () => {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { createReservation } = useManagerReservationsDispatch();
  const { hotelRooms } = useManagerAccountState();

  const handleReset = () => {
    dispatch({ type: "reset" });
  };

  const toggleLoading = (): void => {
    dispatch({ type: "set loading", loading: !state.loading });
  };

  const setLoading = (loading: boolean) =>
    dispatch({ type: "set loading", loading });

  const setReservation = (reservation: iReservation | null) =>
    dispatch({ type: "set", reservation });
  const setCheckInDate = (check_in_date: string): void => {
    dispatch({ type: "set", check_in_date });
  };
  const setCheckOutDate = (check_out_date: string): void => {
    dispatch({ type: "set", check_out_date });
  };
  const setRoom = (room: string): void => {
    dispatch({ type: "set", room });
  };
  const setNumPeople = (num_people: number): void => {
    dispatch({ type: "set", num_people });
  };
  const setError = (error: string | null) => dispatch({ type: "set", error });

  const getCode = (): string =>
    state.reservation ? state.reservation.code : "";

  const roomOptions = useMemo(() => {
    return hotelRooms.map((value) => ({
      value,
      text: `${value}`,
    }));
  }, [hotelRooms]);

  const numPeopleOptions = useMemo(() => {
    return range(PEOPLE_IN_ROOM_MIN, PEOPLE_IN_ROOM_MAX + 1).map((value) => ({
      value,
      text: `${value}`,
    }));
  }, []);

  const handleAdd = (func?: (reservation: iReservation) => void) => {
    const cb = (
      error: boolean,
      message: string,
      reservation: iReservation | null
    ) => {
      if (error) return setError(message);
      setReservation(reservation);
      if (func && reservation) func(reservation);
    };
    const { check_in_date, check_out_date, room, num_people } = state;
    setLoading(true);
    createReservation(check_in_date, check_out_date, room, num_people, cb);
  };

  return {
    handleReset,
    toggleLoading,
    handleAdd,
    setLoading,
    setCheckInDate,
    setCheckOutDate,
    setRoom,
    setNumPeople,
    getCode,
    roomOptions,
    numPeopleOptions,
    state,
  };
};
