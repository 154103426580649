import React from "react";
import SegmentedFilter from "../../../../../components/SegmentedFilter";
import SlidePicker1 from "../../../../../components/SlidePicker/First";
import { useHotelStays } from "../../../../../context/HotelStaysContext";
import strings from "../../../../../localization/localization";
import { slider_id_hotel_stays_occupied } from "../../../../../utils/sliders";

import styles from "./styles.module.css";

interface iOccupiedProps {}

export const OccupiedSegments: React.FC<iOccupiedProps> = () => {
  const { setBookedFilter, getOccupiedFilter } = useHotelStays();

  const OCCUPIED_FILTERS_LOCALIZED: string[] = [
    strings.overall,
    strings.occupied,
    strings.vacant,
  ];

  return (
    <SegmentedFilter
      filters={OCCUPIED_FILTERS_LOCALIZED}
      value={getOccupiedFilter()}
      onChange={setBookedFilter}
      className={styles.segmented_filters}
    />
  );
};

export const OccupiedSlider: React.FC<iOccupiedProps> = () => {
  const { setBookedFilter } = useHotelStays();

  const OCCUPIED_FILTERS_LOCALIZED: string[] = [
    strings.overall,
    strings.occupied,
    strings.vacant,
  ];

  const handleChangeFilter = async (e: CustomEvent<void>) => {
    setBookedFilter(await (e.target as HTMLIonSlidesElement).getActiveIndex());
  };

  return (
    <SlidePicker1
      slider_id={slider_id_hotel_stays_occupied}
      list={OCCUPIED_FILTERS_LOCALIZED}
      onSlideDidChange={handleChangeFilter}
    />
  );
};
