import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { iInputData } from "../Input/Input";

import styles from "./select.module.css";

export interface iSelectData
  extends Pick<iInputData, "name" | "required" | "label" | "value"> {
  options: string[];
  [key: string]: any;
}

interface iSelectProps extends iSelectData {
  color: string;
  onChange(name: string, value: string): void;
  itemStyles?: Record<string, string>;
}

const Select: React.FC<iSelectProps> = ({
  color,
  onChange,
  label,
  name,
  value,
  required,
  options,
  itemStyles,
}) => {
  const renderOptions = (): JSX.Element[] => {
    return options.map((op: string, i: number) => (
      <IonSelectOption key={i} value={op}>
        {op}
      </IonSelectOption>
    ));
  };

  return (
    <IonItem
      color="light"
      className={`input_item ${styles.item}`}
      style={{ ...itemStyles, "--border-color": `var(--ion-color-${color})` }}
    >
      <IonLabel color={"secondary"}>
        <p className="montserrat">{label}</p>
      </IonLabel>
      <IonSelect
        style={{ color: `var(--ion-color-${color})` }}
        multiple={false}
        value={value}
        onIonChange={(e) => onChange(name, e.detail.value)}
      >
        {renderOptions()}
      </IonSelect>
    </IonItem>
  );
};

export default Select;
