/*eslint no-unused-vars: "off"*/
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { createCtx } from "../CreateContext";
import { nHotelStays } from "./interfaces";
import moment from "moment";
import { tHotelRoomsOccupiedFilter } from "../../interfaces/models/manager";
import { find } from "lodash";
import { MONTHS } from "../../utils/calendar";
import useManagerAccountState from "../Manager/AccountContext/hooks/useManagerAccountState";

export const OCCUPIED_FILTERS: tHotelRoomsOccupiedFilter[] = [
  "overall",
  "occupied",
  "vacant",
];

export const GRAPH_TYPES: string[] = ["donut", "bar"];

export type tTimespanElementStartEnd = [string, string][];

export interface iTimespanElement {
  moment_method: string;
  filter: string; // == "Yearly"|"Quarterly"|"Monthly"|"Weekly"
  values: string[];
  start_end: tTimespanElementStartEnd;
}

export const QUARTERS = [
  [
    moment().set({ month: 0, date: 1 }).startOf("day"),
    moment().set({ month: 2, date: 31 }).endOf("day"),
  ],
  [
    moment().set({ month: 3, date: 1 }).startOf("day"),
    moment().set({ month: 5, date: 30 }).endOf("day"),
  ],
  [
    moment().set({ month: 6, date: 1 }).startOf("day"),
    moment().set({ month: 8, date: 30 }).endOf("day"),
  ],
  [
    moment().set({ month: 9, date: 1 }).startOf("day"),
    moment().set({ month: 11, date: 31 }).endOf("day"),
  ],
];

export const YEAR_FILTER: string = "Yearly";
export const QUARTER_FILTER: string = "Quarterly";
export const MONTH_FILTER: string = "Monthly";
export const WEEK_FILTER: string = "Weekly";

export const FILTERS_ORDER = [
  YEAR_FILTER,
  // QUARTER_FILTER,
  MONTH_FILTER,
  // WEEK_FILTER,
];

export const initialRequestTimespanFilterName: string = `${moment()
  .set("date", 1)
  .startOf("day")}_${moment().endOf("month")}`;

const initial_state: nHotelStays.iState = {
  timespan: 1,
  timespan_index: 0,
  timespan_filter_name: "",
  room: 0,
  spending_filter: 0,
  occupied_filter: 0,
  graph_type: 0,
};

const reducer = (
  state: nHotelStays.iState,
  action: nHotelStays.iAction
): nHotelStays.iState => {
  const {
    type,
    room,
    timespan,
    timespan_index,
    timespan_filter_name,
    spending_filter,
    occupied_filter,
    graph_type,
  } = action;
  switch (type) {
    case "set room":
      return { ...state, room: room! };

    case "set timespan":
      return {
        ...state,
        timespan: timespan!,
        timespan_index: 0,
        timespan_filter_name: timespan_filter_name!,
      };
    case "set timespan index":
      return {
        ...state,
        timespan_index: timespan_index!,
        timespan_filter_name: timespan_filter_name!,
      };
    case "set timespan & timespan index":
      return {
        ...state,
        timespan: timespan!,
        timespan_index: timespan_index!,
        timespan_filter_name: timespan_filter_name!,
      };

    case "set graph type":
      return { ...state, graph_type: graph_type! };

    case "set spending filter":
      return { ...state, spending_filter: spending_filter! };

    case "set booked filter":
      return { ...state, occupied_filter: occupied_filter! };
    default:
      return { ...state };
  }
};

const [useHotelStays, CtxProvider] = createCtx<nHotelStays.iContext>();

const HotelStaysContextProvider: React.FC<nHotelStays.iContextProps> = ({
  children,
}) => {
  const { minYear } = useManagerAccountState();
  const [state, dispatch]: [
    nHotelStays.iState,
    React.Dispatch<nHotelStays.iAction>
  ] = useReducer(reducer, initial_state);
  const [loading, setLoading] = useState<boolean>(true);

  const TIMESPAN_LIST: iTimespanElement[] = useMemo((): iTimespanElement[] => {
    const cutYear = (year: string | number): string => year.toString().slice(2);

    const getNumYears = () => {
      const num_years = moment().year() - minYear + 1;
      return num_years;
    };

    const getYears = (): [string[], tTimespanElementStartEnd] => {
      const relative_years = Array.from(
        Array(moment().year() - minYear + 1),
        (x, index) => `${minYear + index}`
      );

      return [
        relative_years,
        relative_years.map((year) => [
          moment().set("year", parseInt(year)).startOf("year").toISOString(),
          moment().set("year", parseInt(year)).endOf("year").toISOString(),
        ]),
      ];
    };

    const getQuarters = (): [string[], tTimespanElementStartEnd] => {
      const num_years = getNumYears();
      const filters: [string[], tTimespanElementStartEnd] = [[], []];

      for (let i = 0; i < num_years; i++) {
        for (let j = 0; j < QUARTERS.length; j++) {
          const year = cutYear(minYear + i);

          const [start_at, end_at] = QUARTERS[j];

          start_at.set("year", minYear + i);
          end_at.set("year", minYear + i);

          const start = {
            day: start_at.get("date"),
            month: start_at.get("month") + 1,
            year: start_at.get("year"),
          };
          const end = {
            day: end_at.get("date"),
            month: end_at.get("month") + 1,
            year: end_at.get("year"),
          };

          filters[0].push(
            `${start.day}/${start.month}/${year} - ${end.day}/${end.month}/${year}`
          );
          filters[1].push([start_at.toISOString(), end_at.toISOString()]);
        }
      }
      return filters;
    };

    const getMonths = (): [string[], tTimespanElementStartEnd] => {
      const ret: [string[], tTimespanElementStartEnd] = [
        getFiltersFromArray(MONTHS),
        [],
      ];
      const today = moment();

      for (
        let m = moment().set({ year: minYear, month: 0, date: 1 });
        m.isBefore(today) || m.isSame(today);
        // today.get("month") >= m.get("month");
        m.add(1, "month")
      ) {
        const m1 = moment(m) /* .set("date", 1) */
          .startOf("month")
          .toISOString();
        const m2 = moment(m).endOf("month").toISOString();
        // console.log("m1", m1);
        ret[1].push([m1, m2]);
      }
      ret[0].splice(ret[1].length);
      return ret;
    };

    const getWeeks = (): [string[], tTimespanElementStartEnd] => {
      const today = moment();
      const first_day_of_year = moment()
        .set("year", minYear)
        .subtract(moment().weekday() + 7 * (moment().week() - 1), "days");
      const format = "DD/MM/YY";
      const filters: [string[], tTimespanElementStartEnd] = [[], []];

      for (let m = first_day_of_year; m.isBefore(today); m.add(7, "days")) {
        const m1 = m;
        const m2 = moment(m).add(6, "days");

        filters[0].push(
          `${moment(m1).startOf("week").format(format)}-${moment(m2)
            .endOf("week")
            .format(format)}`
        );
        filters[1].push([
          moment(m1).startOf("week").toISOString(),
          moment(m2).endOf("week").toISOString(),
        ]);
      }

      return filters;
    };

    const getFiltersFromArray = (values: string[]): string[] => {
      const num_years = getNumYears();
      const filters: string[] = [];

      for (let i = 0; i < num_years; i++) {
        for (let j = 0; j < values!.length; j++) {
          filters.push(`${values![j]}/${cutYear(minYear + i)}`);
        }
      }
      return filters;
    };

    const months = getMonths();
    const years = getYears();
    const weeks = getWeeks();
    const quarters = getQuarters();

    return [
      {
        moment_method: "year",
        filter: YEAR_FILTER,
        values: years[0],
        start_end: years[1],
      },
      // {
      //   moment_method: "quarter",
      //   filter: QUARTER_FILTER,
      //   values: quarters[0],
      //   start_end: quarters[1],
      // },
      {
        moment_method: "month",
        filter: MONTH_FILTER,
        values: months[0],
        start_end: months[1],
      },
      // {
      //   moment_method: "week",
      //   filter: WEEK_FILTER,
      //   values: weeks[0],
      //   start_end: weeks[1],
      // },
    ];
  }, [minYear]);

  // console.log("TIMESPAN_LIST", TIMESPAN_LIST);
  // console.log("state", state);
  const getTimespanList = useCallback(
    (): iTimespanElement[] => TIMESPAN_LIST,
    [TIMESPAN_LIST]
  );

  const generateTimespanFilterName = useCallback(
    (timespan: number | string, timespan_index: number): string => {
      const filter: iTimespanElement | undefined =
        typeof timespan === "string"
          ? find(getTimespanList(), (f) => f.filter === timespan)
          : getTimespanList()[timespan];

      if (filter) {
        try {
          const filter_name = `${filter.start_end[timespan_index][0]}_${filter.start_end[timespan_index][1]}`;
          return filter_name;
        } catch (e) {
          return "";
        }
      }

      return `${
        typeof timespan === "string"
          ? timespan
          : getTimespanList()[timespan].filter
      }_${timespan_index}`;
    },
    [getTimespanList]
  );

  useEffect(() => {
    function main() {
      if (loading) {
        const month_index: number =
          moment().month() + (moment().year() - minYear) * 12;

        setTimespanAndTimespanIndex(
          1,
          month_index,
          generateTimespanFilterName(1, month_index)
        );
        setLoading(false);
      }
    }
    main();
    // setLoading(false);
  }, [loading, generateTimespanFilterName, minYear]);

  const setRoom = (room: number) => {
    dispatch({ type: "set room", room });
  };
  const getRoom = (): number => state.room;

  const setSpendingFilter = (spending_filter: number) => {
    dispatch({ type: "set spending filter", spending_filter });
  };
  const getSpendingfilter = (): number => state.spending_filter;

  const setBookedFilter = (occupied_filter: number) => {
    dispatch({ type: "set booked filter", occupied_filter });
  };
  const getOccupiedFilter = (): number => state.occupied_filter;

  const setTimespan = (timespan: number, timespan_filter_name: string) => {
    dispatch({ type: "set timespan", timespan, timespan_filter_name });
  };
  const getTimespan = (): number => state.timespan;

  const setTimespanIndex = (
    timespan_index: number,
    timespan_filter_name: string
  ) => {
    dispatch({
      type: "set timespan index",
      timespan_index,
      timespan_filter_name,
    });
  };
  const getTimespanIndex = (): number => state.timespan_index;

  const setTimespanAndTimespanIndex = (
    timespan: number,
    timespan_index: number,
    timespan_filter_name: string
  ) => {
    dispatch({
      type: "set timespan & timespan index",
      timespan,
      timespan_index,
      timespan_filter_name,
    });
  };

  const getTimespanFilterName = (): string => state.timespan_filter_name;

  const setGraphType = (graph_type: number) =>
    dispatch({ type: "set graph type", graph_type });

  const getGraphType = (): number => state.graph_type;

  const getState = (): nHotelStays.iState => state;

  const getMonthYear = (): Record<string, number> => {
    const moment_ = moment(
      getTimespanList()[getTimespan()].start_end[getTimespanIndex()][0]
    ).add({ day: 10 });

    return { year: moment_.get("year"), month: moment_.get("month") };
  };

  const value = {
    setBookedFilter,
    setRoom,
    setSpendingFilter,
    setTimespan,
    setTimespanIndex,
    getState,
    getTimespanIndex,
    getTimespan,
    getOccupiedFilter,
    getRoom,
    getSpendingfilter,
    setTimespanAndTimespanIndex,
    getTimespanFilterName,
    getTimespanList,
    generateTimespanFilterName,
    getGraphType,
    setGraphType,
    getMonthYear,
    TIMESPAN_LIST,
    state,
  };

  return <CtxProvider value={value}>{!loading && children}</CtxProvider>;
};

export { useHotelStays, HotelStaysContextProvider };
