import { IonList } from "@ionic/react";
import React from "react";
import TipCard from "./TipCard/TipCard";

import strings from "../../../../localization/localization";

import water from "../../../../img/water.svg";
import energy from "../../../../img/energy.svg";
import footprint from "../../../../img/footprint.svg";

import styles from "./styles.module.css";

interface iTipListProps {}

const TipList: React.FC<iTipListProps> = () => {
  const INFO = [
    {
      icon: water,
      title: strings.water,
      text: strings.water_tip,
    },
    {
      icon: energy,
      title: strings.energy,
      text: strings.energy_tip,
    },
    {
      icon: footprint,
      title: strings.footprint,
      text: strings.footprint_tip,
    },
  ];

  const renderCards = (): JSX.Element[] => {
    return INFO.map((info, i) => <TipCard {...info} key={i} />);
  };

  return (
    <IonList color="none" className={styles.container}>
      {renderCards()}
    </IonList>
  );
};

export default TipList;
