import { IonPage } from "@ionic/react";

interface Props {}

const WithDesktopPage =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P & Props> =>
  ({ ...props }: Props) =>
    (
      <IonPage color="background" id="main">
        {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
        <Component {...(props as P)} />
        {/* </div> */}
      </IonPage>
    );

export default WithDesktopPage;
