import { IonApp } from "@ionic/react";

import { IonReactRouter } from "@ionic/react-router";
import React, { Fragment } from "react";

import { useAuth } from "./context/AuthContext/AuthContext";
import Help from "./components/HelpCenterModal/Help";
import Privacy from "./components/Privacy/Privacy";
import Theme from "./components/ThemeModal/";
import Language from "./components/LanguageModal";
import NavigationNoAuth from "./navigation/NavigationNoAuth";
import NavigationManager from "./navigation/NavigationManager";
import NavigationUser from "./navigation/NavigationUser";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Global styles */
import "./styles/global.css";

/* Navbar Styles */
import "./styles/navbar.css";

import "./styles/login_signup.css";
import { getNowInMs } from "./utils/others";

const NavigationAuth: React.FC = () => {
  const {
    state: { manager },
  } = useAuth();
  setInterval(() => {
    localStorage.setItem("timestamp", `${getNowInMs()}`);
  }, 10000);

  return (
    <Fragment>{manager ? <NavigationManager /> : <NavigationUser />}</Fragment>
  );
};

const AppWrapped: React.FC = () => {
  const {
    state: { token },
  } = useAuth();
  return token ? <NavigationAuth /> : <NavigationNoAuth />;
};

const App: React.FC = () => {
  return (
    <IonApp>
      <Help />
      <Privacy />
      <Theme />
      <Language />
      <IonReactRouter>
        <AppWrapped />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
