import { filter, find, map } from "lodash";
import React, { useCallback, useMemo } from "react";
import iReservation from "../../../../interfaces/models/reservation";
import { UserReservationsStateContext } from "../UserReservationsContext";

const useUserReservationsState = () => {
  const state = React.useContext(UserReservationsStateContext);
  if (!state)
    throw Error(
      "useUserReservationsState must be used within UserReservationsStateContext"
    );
  const {
    data: { reservations, sortedReservationsById, measures },
    status,
    error,
  } = state;

  const getReservationsById = useCallback(
    (id: string): iReservation | undefined => {
      return find(reservations, (r) => r._id === id);
    },
    [reservations]
  );

  const getSortedReservations = useCallback(() => {
    const pastList = map(sortedReservationsById.past, (id) =>
      getReservationsById(id)
    );
    const pastFiltered = filter(pastList, (r) => r) as iReservation[];

    const ongoingList = map(sortedReservationsById.ongoing, (id) =>
      getReservationsById(id)
    );
    const ongoingFiltered = filter(ongoingList, (r) => r) as iReservation[];

    const upcomingList = map(sortedReservationsById.upcoming, (id) =>
      getReservationsById(id)
    );
    const upcomingFiltered = filter(upcomingList, (r) => r) as iReservation[];

    return {
      past: pastFiltered,
      ongoing: ongoingFiltered,
      upcoming: upcomingFiltered,
    };
  }, [sortedReservationsById, getReservationsById]);

  const pastReservations = useMemo(() => {
    return getSortedReservations().past;
  }, [getSortedReservations]);
  const ongoingReservations = useMemo(() => {
    return getSortedReservations().ongoing;
  }, [getSortedReservations]);
  const upcomingReservations = useMemo(() => {
    return getSortedReservations().upcoming;
  }, [getSortedReservations]);

  const isLoading = useMemo(() => {
    return status === "pending";
  }, [status]);
  const isResolved = useMemo(() => {
    return status === "resolved";
  }, [status]);
  const isRejected = useMemo(() => {
    return status === "rejected";
  }, [status]);

  return {
    state,
    pastReservations,
    ongoingReservations,
    upcomingReservations,
    isLoading,
    isRejected,
    isResolved,
    measures,
    error,
  };
};
export default useUserReservationsState;
