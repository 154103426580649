import { IonHeader, IonToolbar } from "@ionic/react";
import React from "react";
import { iWrapperComponent } from "../../interfaces";

import styles from "./styles.module.css";

interface iIonHeaderColoredProps extends iWrapperComponent {
  className?: string;
  [key: string]: any;
}

const IonHeaderColored: React.FC<iIonHeaderColoredProps> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <IonHeader className={`${styles.header} shadow_b ${className}`} {...rest}>
      <IonToolbar>{children}</IonToolbar>
    </IonHeader>
  );
};

export default IonHeaderColored;
