import { IonGrid } from "@ionic/react";
import React, { useEffect } from "react";
import IonContentColor from "../../../../components/IonContentColor";
import {
  HotelStaysContextProvider,
  useHotelStays,
} from "../../../../context/HotelStaysContext";
import Filters from "./Filters";
import Spending from "./Spending";

import styles from "./styles.module.css";

interface iStaysWrappedProps {}

const Mobile: React.FC<iStaysWrappedProps> = () => {
  return (
    <IonContentColor className={styles.content}>
      <IonGrid>
        <Filters />
        <Spending />
      </IonGrid>
    </IonContentColor>
  );
};

// interface iStaysProps {}

// const Stays: React.FC<iStaysProps> = () => {
//   return (
//     <HotelStaysContextProvider>
//       <StaysWrapped />
//     </HotelStaysContextProvider>
//   );
// };

export default Mobile;
