import React, { useMemo } from "react";
import { ManagerRewardsStateContext } from "../";
import { iReward } from "../../../../interfaces/models/reward";
import useManagerAccountState from "../../AccountContext/hooks/useManagerAccountState";

const useManagerRewardsState = () => {
  const state = React.useContext(ManagerRewardsStateContext);
  const { currentHotel } = useManagerAccountState();
  if (!state)
    throw Error(
      "useManagerRewardsState must be used within ManagerRewardsStateContext"
    );
  // console.log("state", state);
  const [rewards, activeRewards, inactiveRewards] = useMemo(() => {
    const rewards: iReward[] = [];
    const active: iReward[] = [];
    const inactive: iReward[] = [];

    if (currentHotel) {
      (state.data.rewards[currentHotel._id] || []).forEach((r) => {
        if (r.active) active.push(r);
        else inactive.push(r);
        rewards.push(r);
      });
    }

    return [rewards, active, inactive];
  }, [currentHotel, state.data.rewards]);

  const getHotelRewardById = (
    rewardId: string,
    status?: "active" | "inactive",
    hotelId: string | null = null
  ) => {
    for (const r of activeRewards) if (r._id === rewardId) return r;
    for (const r of inactiveRewards) if (r._id === rewardId) return r;
    return null;
  };

  return {
    error: state.error,
    hasError: state.status === "rejected",
    isLoading: state.status === "pending",
    numberOfRewards: rewards.length,
    activeRewards,
    inactiveRewards,
    rewards,
    getHotelRewardById,
  };
};

export default useManagerRewardsState;
