import { IonCol, IonRow } from "@ionic/react";
import React, { Fragment, useMemo, useState } from "react";
import IonListColored from "../../../../../components/IonListColored";
import RewardCard from "../../../../../components/RewardCard";
import { Text20 } from "../../../../../components/Text/Montserrat";
import useManagerRewardsState from "../../../../../context/Manager/RewardsContext/hooks/managerRewardsState";
import { modal_id_view_reward } from "../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";
import { iReward } from "../../../../../interfaces/models/reward";
import ViewRewardModal from "../ViewRewardModal";

interface iRewardsListProps {}

const RewardsList: React.FC<iRewardsListProps> = () => {
  const { openModal } = useOpenModal();
  const [selected_reward, setSelectedReward] = useState<iReward | null>(null);
  const { rewards } = useManagerRewardsState();

  const afterCloseModal = () => {
    setSelectedReward(null);
  };

  const renderedRewards = useMemo(() => {
    return rewards.map((reward, key) => (
      <RewardCard
        {...{
          key,
          reward,
          onClickCard: () => {
            setSelectedReward(reward);
            openModal(modal_id_view_reward);
          },
        }}
      />
    ));
  }, [rewards, openModal]);

  return (
    <IonListColored>
      {selected_reward && (
        <ViewRewardModal
          reward={selected_reward}
          onCloseModal={afterCloseModal}
        />
      )}
      {renderedRewards.length ? (
        <Fragment>{renderedRewards}</Fragment>
      ) : (
        <IonRow style={{ marginTop: "5vh" }}>
          <IonCol className="flexr-center">
            <Text20 color="danger" text="No rewards created" />
          </IonCol>
        </IonRow>
      )}
    </IonListColored>
  );
};

export default RewardsList;
