import { IonCol, IonIcon, IonRow } from "@ionic/react";
import React, { Fragment } from "react";
import RewardInfoModal from "../../../../../components/RewardInfoModal";
import { Chivo18, Chivo20 } from "../../../../../components/Text/Chivo";
import { Text18 } from "../../../../../components/Text/Montserrat";
import {
  modal_id_edit_reward,
  modal_id_view_reward,
} from "../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../context/OpenModalContext/OpenModalContext";
import { iReward } from "../../../../../interfaces/models/reward";

import edit from "../../../../../img/edit_text-icon.svg";

import styles from "./styles.module.css";
import EditRewardModal from "../EditRewardModal";

interface iViewRewardModalProps {
  reward: iReward;
  onCloseModal(): void;
}

const ViewRewardModal: React.FC<iViewRewardModalProps> = ({
  reward,
  onCloseModal,
}) => {
  return <Content {...{ reward, onCloseModal }} />;
};

interface iContentProps extends iViewRewardModalProps {}

const Content: React.FC<iContentProps> = ({ reward, onCloseModal }) => {
  const { getModalIdFunctions } = useOpenModal();

  const { closeModal } = getModalIdFunctions(modal_id_view_reward);
  const { openModal } = getModalIdFunctions(modal_id_edit_reward);

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  const handleClickEdit = () => {
    openModal();
  };

  return (
    <Fragment>
      <EditRewardModal {...{ reward, afterConfirmEdit: handleCloseModal }} />
      <RewardInfoModal
        {...{ reward, title: null, footerProps: { onRed: handleCloseModal } }}
      >
        <IonRow style={{ marginTop: "4vh" }}>
          <IonCol size="8">
            <Text18 text="Quantity Purchased" bold={false} />
          </IonCol>
          <IonCol className="flexr-end">
            <Chivo20 text={reward.purchased} color="secondary" />
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "1vh" }}>
          <IonCol>
            <Text18 text="Activate" bold={false} />
          </IonCol>
          <IonCol className="flexr-end" size="1">
            <div className={styles.radio_button}>
              <div />
            </div>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "1vh" }}>
          <IonCol>
            <Text18 text="Edit" bold={false} />
          </IonCol>
          <IonCol className="flexr-end">
            <IonIcon
              onClick={handleClickEdit}
              src={edit}
              style={{ cursor: "pointer", fontSize: "23px" }}
            />
          </IonCol>
        </IonRow>
        <IonRow style={{ marginTop: "3vh", marginBottom: "2vh" }}>
          <IonCol>
            <Chivo18 text="Preview" />
          </IonCol>
        </IonRow>
      </RewardInfoModal>
    </Fragment>
  );
};

export default ViewRewardModal;
