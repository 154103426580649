import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import React from "react";
import { Text14, Text18 } from "../Text/Montserrat";

import styles from "./styles.module.css";

interface iFormErrorMessageProps {
  text: string;
}

const FormErrorMessage: React.FC<iFormErrorMessageProps> = ({ text }) => {
  return (
    <IonGrid
      className={styles.grid}
      style={{ visibility: text.length === 0 ? "hidden" : "visible" }}
    >
      <IonRow>
        <IonCol>
          <Text14 text={text} color="dark" centeredText />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default FormErrorMessage;
