import { IonModal } from "@ionic/react";
import React from "react";
import { modal_id_enter_text_modal_default } from "../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";

import { TextFieldTypes } from "@ionic/core";

import styles from "./enter_text_modal.module.css";
import ModalContent, { iModalContentProps } from "./ModalContent";

export interface iEnterTextModalInfo {
  title: string;
  info?: string;
  input_placeholder: string[]; // SPECIFIES THE NUMBER OF INPUTS
  input_type?: (TextFieldTypes | undefined) | (TextFieldTypes | undefined)[];
  onConfirm(
    text: string[],
    cb: (error: boolean, message: string) => void
  ): void;
  dismiss_alert_on_true?: boolean;
  input_is_required?: boolean;
  dismiss_alert_after_success?: boolean;
  isLoading?: boolean;
}

interface iEnterTextModalProps extends iEnterTextModalInfo, iModalContentProps {
  modal_id?: number;
}

const EnterTextModal: React.FC<iEnterTextModalProps> = ({
  modal_id = modal_id_enter_text_modal_default,
  title,
  info = "",
  input_placeholder,
  input_type = "text",
  onConfirm,
  onCancel,
  dismiss_alert_on_true = false,
  dismiss_alert_after_success = true,
  input_is_required = true,
  confirm_text,
  cancel_text,
  isLoading,
}) => {
  const modal = useOpenModal();

  const closeModal = () => {
    modal.closeModal(modal_id);
  };

  return (
    <IonModal
      isOpen={modal.isOpen(modal_id)}
      cssClass={
        styles.modal +
        " " +
        (input_placeholder.length === 1 ? styles.height1 : styles.height2)
      }
      onDidDismiss={closeModal}
    >
      <ModalContent
        {...{
          confirm_text,
          cancel_text,
          title,
          info,
          onConfirm,
          onCancel: onCancel || closeModal,
          input_placeholder,
          input_type,
          dismiss_alert_on_true,
          dismiss_alert_after_success,
          input_is_required,
          isLoading,
        }}
      />
    </IonModal>
  );
};

export default EnterTextModal;
