import {
  IonAlert,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonRow,
} from "@ionic/react";

import { TextFieldTypes } from "@ionic/core";

import React, { useEffect, useReducer } from "react";

import styles from "./styles.module.css";
import { iEnterTextModalInfo } from "../EnterTextModal";
import ModalContentFooter2Buttons from "../../ModalContentFooter2Buttons";
import RoundInput from "../../RoundInput";
import HeaderWithInfo from "../../HeaderWithInfo";

interface iState {
  error: boolean;
  alert_is_open: boolean;
  message: string;
  text: string;
  text2: string;
  loading: boolean;
}

interface iAction extends Partial<iState> {
  type: string;
}

const initial_state: iState = {
  error: false,
  alert_is_open: false,
  message: "",
  text: "",
  text2: "",
  loading: false,
};

const reducer = (state: iState, action: iAction): iState => {
  switch (action.type) {
    case "change":
      return { ...state, text: action.text!, error: false };
    case "change2":
      return { ...state, text2: action.text!, error: false };

    case "set":
      const { error, alert_is_open, message } = action;
      return {
        ...state,
        error: error!,
        alert_is_open: alert_is_open!,
        message: message!,
        loading: false,
      };

    case "dismiss":
      return { ...initial_state };

    case "open/close alert":
      return { ...state, alert_is_open: action.alert_is_open! };

    case "set error":
      return { ...state, error: action.error! };

    case "set loading":
      return { ...state, loading: action.loading! };

    default:
      return { ...state };
  }
};

export interface iModalContentProps extends iEnterTextModalInfo {
  onCancel?(): void;
  confirm_text?: string;
  cancel_text?: string;
}

const ModalContent: React.FC<iModalContentProps> = ({
  title,
  info = "",
  input_placeholder,
  input_type = "text",
  onCancel = () => {},
  onConfirm,
  dismiss_alert_on_true,
  dismiss_alert_after_success,
  input_is_required = true,
  confirm_text,
  cancel_text,
  isLoading,
}) => {
  const [{ error, alert_is_open, message, text, text2, loading }, dispatch] =
    useReducer(reducer, initial_state);

  const setText = (value: string) => {
    dispatch({ type: "change", text: value });
  };
  const setText2 = (value: string) => {
    dispatch({ type: "change2", text: value });
  };

  const setAlertIsOpen = (b: boolean): void => {
    dispatch({ type: "open/close alert", alert_is_open: b });
  };

  const handleConfirm = (): void => {
    if (
      !input_is_required ||
      (input_placeholder.length === 1
        ? text.length !== 0
        : text.length !== 0 && text2.length !== 0)
    ) {
      dispatch({ type: "set loading", loading: true });
      const cb = (error: boolean, message: string) => {
        dispatch({
          type: "set",
          error,
          message,
          alert_is_open: message !== undefined && message.length !== 0,
        });
        if (!error && dismiss_alert_after_success && !message.length)
          handleCancel();
      };
      onConfirm([text, text2], cb);
    } else dispatch({ type: "set error", error: true });
  };

  const handleCancel = (): void => {
    dispatch({ type: "dismiss" });
    onCancel();
  };

  const handleDismissAlert = (): void => {
    dispatch({ type: "dismiss" });
  };

  useEffect(() => {
    if (dismiss_alert_on_true) {
      dispatch({
        type: "dismiss",
      });
    }
    // eslint-disable-next-line
  }, [dismiss_alert_on_true]);

  const renderInputs = (): JSX.Element[] => {
    const texts = [text, text2];
    const setTexts = [setText, setText2];

    return input_placeholder.map((placeholder, i) => {
      return (
        <Input
          {...{
            key: i,
            onChange: setTexts[i],
            value: texts[i],
            error,
            placeholder:
              i >= input_placeholder.length
                ? input_placeholder[0]
                : input_placeholder[i],
            input_type:
              typeof input_type === "string" ? input_type : input_type[i],
          }}
        />
      );
    });
  };

  return (
    <IonContent
      /* scrollEvents={false} scrollY={false} */ color="beje"
      className={styles.content}
    >
      <IonLoading isOpen={isLoading !== undefined ? isLoading : loading} />
      <IonAlert
        isOpen={alert_is_open && message !== undefined && message.length !== 0}
        onDidDismiss={() => {
          setAlertIsOpen(false);
          if (!error) {
            if (dismiss_alert_after_success) onCancel();
            else handleDismissAlert();
          }
        }}
        message={message}
        buttons={["OK"]}
      />
      <IonGrid>
        <HeaderWithInfo {...{ title, info }} />

        {renderInputs()}
        <ModalContentFooter2Buttons
          {...{
            onCancel: handleCancel,
            onConfirm: handleConfirm,
            confirm_text,
            cancel_text,
          }}
        />
      </IonGrid>
    </IonContent>
  );
};

interface iInputProps {
  onChange(value: string): void;
  value: string;
  error: boolean;
  placeholder: string;
  input_type: TextFieldTypes | undefined;
}

const Input: React.FC<iInputProps> = ({ ...props }) => {
  return (
    <IonRow>
      <IonCol className={styles.input}>
        <RoundInput {...props} />
      </IonCol>
    </IonRow>
  );
};

export default ModalContent;
