import { IonModal } from "@ionic/react";
import React from "react";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import { iWrapperComponent } from "../../interfaces";

interface iModalWrapperProps extends iWrapperComponent {
  // children?: (boolean | JSX.Element | null)[] | JSX.Element | null | boolean;
  modal_id: number;
  [props: string]: any;
}

const ModalWrapper: React.FC<iModalWrapperProps> = ({
  children,
  modal_id,
  ...props
}) => {
  const { isOpen, closeModal } = useOpenModal().getModalIdFunctions(modal_id);

  return (
    <IonModal
      {...{
        isOpen: isOpen(),
        onDidDismiss: () => closeModal(),
        ...props,
      }}
    >
      {isOpen() && children}
    </IonModal>
  );
};

export default ModalWrapper;
