import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import React, { forwardRef, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { iWrapperComponent } from "../../interfaces";
import IonContentColor from "../IonContentColor";

import hotel from "../../img/hotel-icon.svg";
import stays from "../../img/stays_logo.svg";
import calendar_tick from "../../img/calendar_tick.svg";
import rewards from "../../img/rewards_logo.svg";
import games from "../../img/games_icon.svg";
import dashboard from "../../img/dashboard-icon.svg";

import logout from "../../img/logout.svg";
import hamburger from "../../img/hamburger.svg";

import styles from "./styles.module.css";
import { Chivo18 } from "../Text/Chivo";
import { useAuth } from "../../context/AuthContext/AuthContext";
import strings from "../../localization/localization";

const FULL_MENU_STORAGE = "full_menu";

interface iIonDesktopContentColorProps extends iWrapperComponent {}

const IonDesktopContentColor = forwardRef<any, iIonDesktopContentColorProps>(
  ({ children, ...rest }, ref) => {
    const [displayText, setDisplayText] = useState(false);
    const history = useHistory();
    const { Logout } = useAuth();

    useEffect(() => {
      const display = localStorage.getItem(FULL_MENU_STORAGE);

      if (display !== undefined) {
        if (display === "true" && !displayText) setDisplayText(true);
        else if (display === "false" && displayText) setDisplayText(false);
      }
    }, [setDisplayText, displayText]);

    const handleHistoryPush = (path: string) => () => history.push(path);

    const getPathButtonsColor = (path: string) =>
      history.location.pathname === path ? "primary" : "light";

    const renderPathButton = (
      icon: string,
      text: string,
      path: string
    ): JSX.Element => {
      return (
        <Button
          {...{
            icon,
            text,
            color: getPathButtonsColor(path),
            onClick: handleHistoryPush(path),
            displayText,
          }}
        />
      );
    };

    const renderClickableButton = (
      icon: string,
      text: string,
      onClick: () => void
    ) => {
      return (
        <Button {...{ icon, text, color: "light", onClick, displayText }} />
      );
    };

    const handleClickMenuButton = () => {
      localStorage.setItem(FULL_MENU_STORAGE, displayText ? "false" : "true");
      setDisplayText((prev) => !prev);
    };

    return (
      <Fragment>
        <div style={{ display: "flex", height: "100%", position: "relative" }}>
          <div
            ref={ref}
            style={{
              // width: displayText ? "200px" : "60px",
              height: "100%",
              display: "flex",
              background: "var(--ion-color-secondary)",
              // position: "fixed",
            }}
            className={`${styles.menu_grid} ${
              displayText ? styles.menu_grid_open : styles.menu_grid_close
            }`}
          >
            {/* <IonGrid > */}
            <IonRow></IonRow>
            <IonRow>
              <IonCol>
                {/* {renderPathButton(
                  dashboard,
                  "Data Insertion",
                  "/data-insertion"
                )} */}
                {renderPathButton(hotel, "Hotel", "/hotel")}
                {/* {renderPathButton(dashboard, "Dashboard", "/dashboard")} */}
                {renderPathButton(stays, "Stays", "/stays")}
                {renderPathButton(games, "Games", "/games")}
                {renderPathButton(calendar_tick, "Bookings", "/bookings")}
                {renderPathButton(rewards, "Rewards", "/rewards")}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {renderClickableButton(logout, strings.logout_menu, Logout)}
                {renderClickableButton(
                  hamburger,
                  "Menu",
                  handleClickMenuButton
                )}
              </IonCol>
            </IonRow>
            {/* </IonGrid> */}
          </div>
          {/* <div
          style={{
            height: "100%",
            width: `${window.innerWidth - (displayText ? 200 : 60)}px`,
            position: "absolute",
            left: displayText ? "200px" : "60px",
          }}
        > */}
          <IonContentColor {...rest}>{children}</IonContentColor>
          {/* </div> */}
        </div>
      </Fragment>
    );
  }
);

interface iButtonProps {
  onClick(): void;
  color: string;
  text: string;
  icon: string;
  displayText: boolean;
}

const Button: React.FC<iButtonProps> = ({
  icon,
  color,
  text,
  onClick,
  displayText,
}) => {
  return (
    <IonRow className={styles.button_row}>
      <IonCol>
        <IonButton fill="clear" className={`${styles.button}`} {...{ onClick }}>
          <IonIcon className={styles.icon} src={icon} {...{ color }} />
          {(displayText || !true) && (
            <Chivo18
              {...{ text }}
              style={{ marginLeft: "20px" }}
              color="light"
            />
          )}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default IonDesktopContentColor;
