import {
  IonItem,
  IonItemSliding,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React from "react";
import { Text18 } from "../Text/Montserrat";

import { RadioGroupChangeEventDetail } from "@ionic/core";

import styles from "./styles.module.css";

interface iComponent {
  [key: string]: any;
}

export interface iRadioButtonListItem {
  Component: React.FC<iComponent> | JSX.Element;
  label: string;
  value: string;
}

export interface iRadioButtonListProps {
  items: iRadioButtonListItem[];
  value: string;
  onClickRadio(value: string): void;
}

const RadioButtonList: React.FC<iRadioButtonListProps> = ({
  items,
  value,
  onClickRadio,
}) => {
  const renderItems = (): JSX.Element[] => {
    return items.map(({ Component, label, value }, i) => (
      <React.Fragment key={i}>
        <IonItem lines="none" className={styles.item}>
          {Component}
          <IonLabel>
            <Text18 text={label} />
          </IonLabel>
          <IonRadio value={value} mode="md" color="secondary" />
        </IonItem>
        <div className={styles.separator}></div>
      </React.Fragment>
    ));
  };

  return (
    <IonList>
      <IonRadioGroup
        onIonChange={(e) => {
          onClickRadio(e.detail.value);
        }}
        {...{ value }}
      >
        {renderItems()}
      </IonRadioGroup>
    </IonList>
  );
};

export default RadioButtonList;
