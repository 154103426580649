import { useCallback, useReducer, useState } from "react";

const reducer = (state: Record<string, any>, action: Record<string, any>) => {
  const { type, name, value, error, ...rest } = action;
  switch (type) {
    case "change":
      return { ...state, [name]: value };

    case "initialize state":
      return { ...state, ...rest };
    default:
      return { ...state };
  }
};

const useForm = <T>(initialState: T) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [error, setError] = useState<string>("");

  const handleSetError = useCallback(
    (error: string) => setError(error || ""),
    []
  );

  const handleChange = useCallback(
    (name: string, value: string) => {
      if (error) setError("");
      dispatch({ type: "change", name, value });
    },
    [error]
  );

  return {
    state: state as T,
    dispatch,
    handleChange,
    error,
    setError: handleSetError,
  };
};

export default useForm;
