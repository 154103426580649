import useMediaQuery from "../hooks/mediaQuery";

const WithMobileDesktopQuery =
  (MobileComponent, DesktopComponent) => (props) => {
    // const { isDesktop } = useMediaQuery();
    const isDesktop = window.innerWidth > 600;
    return isDesktop ? <DesktopComponent /> : <MobileComponent />;
  };

export default WithMobileDesktopQuery;
