import React, { useEffect, useReducer } from "react";
import iHotel from "../../interfaces/models/hotel";
import { createCtx } from "../CreateContext";
import { modal_id_hotel } from "../OpenModalContext/modal_ids";
import { useOpenModal } from "../OpenModalContext/OpenModalContext";
import { nHotelModal } from "./interfaces";

const initial_state: nHotelModal.iState = {
  hotel: null,
};

const reducer = (
  state: nHotelModal.iState,
  action: nHotelModal.iAction
): nHotelModal.iState => {
  switch (action.type) {
    case "set hotel":
      return { ...state, hotel: action.hotel! };

    case "reset":
      return { ...state, hotel: null };

    default:
      return { ...state };
  }
};

const [useHotelModal, CtxProvider] = createCtx<nHotelModal.iContext>();

const HotelModalContextProvider: React.FC<nHotelModal.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [
    nHotelModal.iState,
    React.Dispatch<nHotelModal.iAction>
  ] = useReducer(reducer, initial_state);
  const modal = useOpenModal();

  useEffect(() => {}, []);

  const openModal = (hotel: iHotel) => {
    dispatch({ type: "set hotel", hotel });
    modal.openModal(modal_id_hotel);
  };

  const closeModal = () => {
    modal.closeModal(modal_id_hotel);
    dispatch({ type: "reset" });
  };

  const getState = (): nHotelModal.iState => state;

  const getHotel = (): iHotel | null => state.hotel;

  const openHotelWebsite = (): void => {
    window.open(`${state.hotel?.website}`, "_blank")?.focus();
  };

  const value = {
    state,
    openModal,
    closeModal,
    getState,
    getHotel,
    openHotelWebsite,
  };
  return <CtxProvider value={value}>{children}</CtxProvider>;
};

export { useHotelModal, HotelModalContextProvider };
