import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { Fragment } from "react";
import { Chivo18 } from "../../../../../components/Text/Chivo";

import styles from "./styles.module.css";

import { DatePicker, OptionsPicker } from "../../../../../components/PickerRow";
import TwoSlimButtons from "../../../../../components/TwoSlimButtons";
import SlimButton from "../../../../../components/SlimButton";
import { useAddBooking } from "../../hooks";

interface iAddProps {
  onSend(text: string): void;
}

const Add: React.FC<iAddProps> = ({ onSend }) => {
  const {
    state,
    handleReset,
    setCheckInDate,
    setCheckOutDate,
    setRoom,
    setNumPeople,
    handleAdd,
    getCode,
    numPeopleOptions,
    roomOptions,
  } = useAddBooking();
  const { check_in_date, check_out_date, room, num_people, loading } = state;
  const code = getCode();

  return (
    <Fragment>
      <IonLoading isOpen={loading} />
      <IonGrid className={styles.grid}>
        <IonRow>
          <IonCol className={styles.title}>
            <Chivo18 text="Add Booking" />
          </IonCol>
        </IonRow>
        <DatePicker
          title="Check-In Date"
          value={check_in_date}
          onSelect={setCheckInDate}
          id="1"
        />
        <DatePicker
          title="Check-Out Date"
          value={check_out_date}
          onSelect={setCheckOutDate}
          id="2"
        />
        <OptionsPicker
          title="Room"
          options={roomOptions}
          onSelect={(value: string) => {
            setRoom(value);
          }}
          value={room}
        />
        <OptionsPicker
          title="Number of People"
          options={numPeopleOptions}
          onSelect={(value: number) => {
            setNumPeople(value);
          }}
          value={num_people}
        />

        <TwoSlimButtons
          green_text="Add"
          red_text="Reset"
          onGreen={handleAdd}
          onRed={handleReset}
          grid={false}
        />
        <SlimButton
          onClick={() => onSend(code)}
          text={code === "" ? "Booking Code will appear here" : code}
          text2={code === "" ? undefined : "Click to send"}
          color="tertiary"
          textColor="primary"
          width={75}
          disabled={code === ""}
        />
      </IonGrid>
    </Fragment>
  );
};

export default Add;
