import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React, { useMemo } from "react";
import useBoxShadow from "../../hooks/boxShadow";
import { Chivo16 } from "../Text/Chivo";

import styles from "./styles.module.css";

export interface iViewSelectionButton {
  text: string;
  icon: string;
  onClick(): void;
}

interface iViewSelectionButtonsProps {
  buttons: iViewSelectionButton[];
  active: number;
  buttonColor?: string;
  textColor?: string;
  [key: string]: any;
}

const ViewSelectionButtons: React.FC<iViewSelectionButtonsProps> = ({
  buttons,
  active,
  buttonColor = "secondary",
  textColor = "light",
  ...rest
}) => {
  const renderButtons = useMemo(() => {
    // console.log("VIEW SELECTION BUTTONS");
    const col_size = 12 / (buttons.length || 1);
    return buttons.map(({ text, icon, onClick }, i) => (
      <IonCol size={`${col_size}`} key={i} className={styles.button}>
        <ViewSelectionButton
          disabled={active !== i}
          color={buttonColor}
          onClick={() => active !== i && onClick()}
          {...{ icon, textColor, text }}
        />
      </IonCol>
    ));
  }, [active]);

  return (
    <IonGrid {...rest}>
      <IonRow>{renderButtons}</IonRow>
    </IonGrid>
  );
};

interface iViewSelectionButtonProps {
  disabled?: boolean;
  color: string;
  onClick?(): void;
  icon: string;
  textColor: string;
  text: string;
}

export const ViewSelectionButton: React.FC<iViewSelectionButtonProps> = ({
  disabled = false,
  color,
  onClick,
  icon,
  textColor,
  text,
}) => {
  const boxShadow = useBoxShadow();
  return (
    <IonButton
      {...{ disabled, color, onClick }}
      style={{ pointerEvents: "initial", width: "100%", boxShadow }}
    >
      <IonIcon className={styles.icon} src={icon} color={textColor} />
      <Chivo16
        {...{
          text,
          color: textColor,
          bold: false,
        }}
      />
    </IonButton>
  );
};

export default ViewSelectionButtons;
