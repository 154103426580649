import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React from "react";

import { useDataModal } from "../../../context/DataModalContext/DataModalContext";
import { tDataModalGraphIndex } from "../../../context/DataModalContext/interfaces";
import { modal_id_hotel_spending_data } from "../../../context/OpenModalContext/modal_ids";
import GraphsFooterDataTypes from "../../GraphsFooterDataTypes";

import styles from "./footer.module.css";

interface iFooterProps {
  width?: number;
}

const Footer: React.FC<iFooterProps> = ({ width = 80 }) => {
  const {
    state: { data_type_index, available_types },
    setDataType,
    closeGraphModal,
  } = useDataModal();

  return (
    <GraphsFooterDataTypes
      {...{
        available_types,
        data_type_index,
        setDataType,
        modal_id: modal_id_hotel_spending_data,
        width,
        onCloseModal: closeGraphModal,
      }}
    />
  );
};

export default Footer;
