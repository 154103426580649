import { IonCol, IonGrid, IonImg, IonRow, IonToggle } from "@ionic/react";
import React, { useState } from "react";

import logo_slogan__white from "../../../img/logo_slogan-white.svg";
import logo from "../../../img/logo.svg";

import styles from "./styles.module.css";
import Login from "./Login";
import Signup from "./Signup";

interface iLaucnDesktopProps {}

const LaunchDesktop: React.FC<iLaucnDesktopProps> = () => {
  const [view, setView] = useState(false);

  return (
    <div className={styles.desktop_launch}>
      <div className={styles["desktop_launch-left"]}>
        <IonImg src={logo_slogan__white} />
        {/* <IonImg src={logo} /> */}
      </div>
      <div className={styles["desktop_launch-right"]}>
        <IonGrid>
          <IonRow className={styles.toggle_row}>
            <IonCol>
              <IonToggle
                mode="ios"
                checked={view}
                onIonChange={(e) => setView(e.detail!.checked!)}
                className={`${styles.toggle} ${
                  !view ? styles.toggle_login : styles.toggle_signup
                }`}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{view ? <Signup /> : <Login />}</IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default LaunchDesktop;
