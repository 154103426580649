import { sortReservationsById } from "../../../../utils/reservations/sortReservations";
import iReservation, {
  tReservationsSpendingStats,
} from "../../../../interfaces/models/reservation";

const calculateData = (reservations: iReservation[]) => {
  const calculateOverallAverage = (
    reservations: iReservation[]
  ): tReservationsSpendingStats => {
    const data = {
      overall: {
        Water: 0,
        Energy: 0,
        Footprint: 0,
        Waste: 0,
      },
      average: {
        Water: 0,
        Energy: 0,
        Footprint: 0,
        Waste: 0,
      },
    };
    const add = (r: iReservation) => {
      const { Water, Energy, Footprint, Waste } = r;
      data.overall.Water += Water || 0;
      data.overall.Energy += Energy || 0;
      data.overall.Footprint += Footprint || 0;
      data.overall.Waste += Waste || 0;
    };

    for (const r of reservations) {
      add(r);
    }
    if (reservations.length) {
      data.average.Water = data.overall.Water / reservations.length;
      data.average.Energy = data.overall.Energy / reservations.length;
      data.average.Footprint = data.overall.Footprint / reservations.length;
      data.average.Waste = data.overall.Waste / reservations.length;
    }
    return data;
  };

  return {
    reservations,
    sortedReservationsById: sortReservationsById(reservations),
    measures: calculateOverallAverage(reservations),
  };
};

export default calculateData;
