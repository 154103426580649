import React from "react";
import { modal_id_theme } from "../../context/OpenModalContext/modal_ids";
import MenuModal from "../MenuModal";
import Content from "./Content";

interface iThemeModalProps {}

const ThemeModal: React.FC<iThemeModalProps> = () => {
  return (
    <MenuModal {...{ title: "Theme", modal_id: modal_id_theme }}>
      <Content />
    </MenuModal>
  );
};

export default ThemeModal;
