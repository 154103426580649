import React from "react";

import "./stats.css";
import StatsCard from "./StatsCard/StatsCard";
import TipList from "./TipList/TipList";
import IonContentColor from "../../../components/IonContentColor";

interface iStatsProps {}

const Stats: React.FC<iStatsProps> = () => {
  return (
    <IonContentColor>
      <StatsCard />
      <TipList />
    </IonContentColor>
  );
};

export default Stats;
