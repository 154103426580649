export const generateRandomRGBA = (opacity: number = 1) => {
  return `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
    Math.random() * 255
  }, ${opacity})`;
};

export const energyColorsHomologue: string[] = [
  "#62C890",
  "#D7F0BC",
  "#16bb60",
  "#5fae37",
  "#079246",
  "#37ae69",
];
export const waterColorsHomologue: string[] = [
  "#2D9BB4",
  "#85DEF3",
  "#12829b",
  "#addbe6",
  "#0a5c71",
  "#14d0ff",
];

export const GRAY = "#c4c4c4";
