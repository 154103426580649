import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import React, { forwardRef, useEffect, useMemo } from "react";
import useBoxShadow from "../../../hooks/boxShadow";

import left from "../../../img/arrow_left.svg";
import right from "../../../img/arrow_right.svg";
import { slider_id_hotel_stays_graph } from "../../../utils/sliders";
import HiddenTag from "../../HiddenTag";
import { Text18 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

const SLIDER_ID_TEMPLATE = "slider_picker-slider-#";
export const getSliderId = (id: string) => {
  return SLIDER_ID_TEMPLATE.replace("#", id);
};

const DEFAULT_LIST = ["Nothing"];

const slideOpts = {
  initialSlide: 0,
  speed: 400,
};

interface iSlidePickerProps {
  icon?: string;
  list: string[];
  icons_list?: string[];
  slider_id: string;
  onSlideDidChange(e: CustomEvent<void>): void;
  onClickIcon?(slider_id: string): void;
  onClickCurrentValue?(): void;
  className?: string;
  initial_index?: number;
}

const SlidePicker1 = forwardRef<any, iSlidePickerProps>(
  (
    {
      icon,
      icons_list,
      list,
      slider_id,
      onSlideDidChange,
      onClickIcon = () => {},
      onClickCurrentValue = () => {},
      className,
      initial_index,
    },
    ref
  ) => {
    const shadow = useBoxShadow();

    const id: string = useMemo(() => getSliderId(slider_id), [slider_id]);
    useEffect(() => {
      (document.getElementById(id) as HTMLIonSlidesElement).slideTo(
        initial_index || 0
      );
    }, [initial_index, id]);

    const handleSlideNext = async () => {
      const slider = document.getElementById(id) as HTMLIonSlidesElement;
      slider.slideNext();
    };
    const handleSlidePrev = async () => {
      const slider = document.getElementById(id) as HTMLIonSlidesElement;
      slider.slidePrev();
    };

    const renderSlides = (): JSX.Element => {
      const col_size_side: number = 2;
      const col_size_mid: number = 12 - col_size_side * 2;

      return (
        <IonSlides
          ref={ref}
          key={list[0]}
          options={slideOpts}
          className={styles.slider}
          id={id}
          onIonSlideDidChange={onSlideDidChange}
        >
          {(list.length ? list : DEFAULT_LIST).map((text, i) => (
            <IonSlide key={text}>
              <IonCol size={`${col_size_side}`}>
                <IonButton
                  onClick={() => {
                    onClickIcon(id);
                  }}
                  className={`${styles.icon} button`}
                  fill="clear"
                  style={{ visibility: icon ? "visible" : "hidden" }}
                >
                  {icon ? <IonIcon src={icon} /> : <HiddenTag />}
                </IonButton>
              </IonCol>
              <IonCol size={`${col_size_mid}`} className={styles.main_text}>
                {slider_id === slider_id_hotel_stays_graph ? (
                  <button onClick={onClickCurrentValue} className="button">
                    <Text18 text={text} />
                    {icons_list && icons_list.length > i && (
                      <IonIcon
                        src={icons_list[i]}
                        color="secondary"
                        className={styles.icon_next_to_text}
                      />
                    )}
                  </button>
                ) : (
                  <IonButton fill="clear" onClick={onClickCurrentValue}>
                    <Text18 text={text} />
                    {icons_list && icons_list.length > i && (
                      <IonIcon
                        src={icons_list[i]}
                        color="secondary"
                        className={styles.icon_next_to_text}
                      />
                    )}
                  </IonButton>
                )}
              </IonCol>
              <IonCol size={`${col_size_side}`}>
                <HiddenTag />
              </IonCol>
            </IonSlide>
          ))}
        </IonSlides>
      );
    };

    const col_size_arrows: number = 1.5;
    const col_size_mid: number = 12 - col_size_arrows * 2;

    return (
      <IonGrid
        className={`${styles.grid} ${className}`}
        style={{
          boxShadow: shadow,
        }}
      >
        <IonRow>
          <IonCol size={`${col_size_arrows}`} className={styles.left}>
            <IonButton
              className={styles.arrow}
              fill="clear"
              onClick={handleSlidePrev}
            >
              <IonIcon src={left} />
            </IonButton>
          </IonCol>
          <IonCol size={`${col_size_mid}`} className={styles.center}>
            {renderSlides()}
          </IonCol>
          <IonCol size={`${col_size_arrows}`} className={styles.right}>
            <IonButton
              className={styles.arrow}
              fill="clear"
              onClick={handleSlideNext}
            >
              <IonIcon src={right} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }
);

export default SlidePicker1;
