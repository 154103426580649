import React from "react";
import ModalFooter2 from "../../../../../../components/ModalFooter2/ModalFooter2";
import ModalWrapper from "../../../../../../components/ModalWrapper";
import { modal_id_competition_info } from "../../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../../context/OpenModalContext/OpenModalContext";
import Content from "./Content";

interface iComeptitionInfoModalProps {
  competitionId: string;
  handleExit(): void;
}

const ComeptitionInfoModal: React.FC<iComeptitionInfoModalProps> = ({
  competitionId,
  handleExit,
}) => {
  const { closeModal } = useOpenModal();

  const handleExitModal = () => {
    handleExit();
    closeModal(modal_id_competition_info);
  };

  return (
    <ModalWrapper modal_id={modal_id_competition_info}>
      <Content {...{ competitionId }} />
      <ModalFooter2 red_text="Close" onRed={handleExitModal} />
    </ModalWrapper>
  );
};

export default ComeptitionInfoModal;
