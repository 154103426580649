import { IonText } from "@ionic/react";
import React from "react";
import Tag, { iTextProps } from "..";

import styles from "./styles.module.css";

const MontserratTag: React.FC<iTextProps> = ({ className, ...props }) => {
  return (
    <Tag
      {...props}
      className={`${className} ${styles.tag}`}
      font="montserrat"
    />
  );
};

const Text10: React.FC<iTextProps> = ({ className, bold, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text10} ${className || ""}`,
        ...rest,
      }}
    />
  );
};

const Text11: React.FC<iTextProps> = ({ className, bold, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text11} ${className || ""}`,
        ...rest,
      }}
    />
  );
};
const Text12: React.FC<iTextProps> = ({ className, bold, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text12} ${className || ""}`,
        ...rest,
      }}
    />
  );
};

const Text14: React.FC<iTextProps> = ({ className, bold, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text14} ${className || ""}`,
        ...rest,
      }}
    />
  );
};
const Text16: React.FC<iTextProps> = ({ className, bold, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text16} ${className || ""}`,
        ...rest,
      }}
    />
  );
};

const Text18: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text18} ${className || ""}`,
        style: { fontWeight: "10vw" },
        ...rest,
      }}
    />
  );
};
const Text20: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text20} ${className || ""}`,
        ...rest,
      }}
    />
  );
};

const Text22: React.FC<iTextProps> = ({ className, bold = true, ...rest }) => {
  return (
    <MontserratTag
      {...{
        bold,
        className: `${styles.text22} ${className || ""}`,
        ...rest,
      }}
    />
  );
};

export { Text14, Text18, Text11, Text10, Text12, Text22, Text20, Text16 };
