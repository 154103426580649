import { IonAvatar, IonCol, IonImg, IonRow } from "@ionic/react";
import React, { Fragment } from "react";

import default_user from "../../../img/default_user.png";
import { getLevelFromTotalPoints } from "../../../utils/user";

import { Chivo22 } from "../../Text/Chivo";
import { Text12, Text18 } from "../../Text/Montserrat";

import styles from "./styles.module.css";

interface iMainInfoProps {
  username: string;
  referralCode: string;
  lastName: string;
  firstName: string;
  nFriends: number;
  images: string[];
  totalPoints: number;

  friendsUsernameColor?: string;

  displayLevelInsteadOfNumberOfFriends?: boolean;

  handleClickFriends?(): void;
  handleClickUsername?(): void;
  handleClickAvatar?(): void;
  handleClickReferenceCode?(): void;
}

const MainInfo: React.FC<iMainInfoProps> = ({
  username,
  referralCode,
  firstName,
  lastName,
  nFriends,
  images,
  totalPoints,

  friendsUsernameColor = "light",
  displayLevelInsteadOfNumberOfFriends = false,

  handleClickFriends = void 0,
  handleClickUsername = void 0,
  handleClickAvatar = void 0,
  handleClickReferenceCode = void 0,
}) => {
  const col_size_friends = 2;
  const col_size_avatar = 3;
  const col_size_middle = 12 - col_size_friends - col_size_avatar;

  return (
    <IonRow className={styles.container}>
      <IonCol
        className={styles.avatar}
        // onClickCapture={}
        size={`${col_size_avatar}`}
      >
        <IonAvatar
          onClick={() => {
            handleClickAvatar && handleClickAvatar();
          }}
        >
          <IonImg src={images[0] || default_user} />
        </IonAvatar>
      </IonCol>
      <IonCol className={styles.name} size={`${col_size_middle}`}>
        <Text18
          onClick={[handleClickUsername, handleClickReferenceCode]}
          color={friendsUsernameColor}
          text={
            referralCode ? [`${username}`, `#${referralCode}`] : `${username}`
          }
          style={{ fontWeight: "normal" }}
        />
        {(firstName || (firstName && username)) && (
          <Text18
            color="primary"
            text={`${firstName} ${lastName}`}
            style={{ fontWeight: "normal" }}
          />
        )}
      </IonCol>
      <IonCol
        className={styles.friends}
        size={`${col_size_friends}`}
        // onClick={handleClickFriends}
      >
        {/* {displayLevelInsteadOfNumberOfFriends ? ( */}
        <Fragment>
          <Chivo22 color={friendsUsernameColor} text={totalPoints} />
          <Text12 text="points" color={friendsUsernameColor} />
        </Fragment>
        {/* ) : ( */}
        {/* <Fragment> */}
        {/* <Chivo22 color={friendsUsernameColor} text={nFriends} />
            <Text12 text="friends" color={friendsUsernameColor} /> */}
        {/* </Fragment> */}
        {/* )} */}
      </IonCol>
    </IonRow>
  );
};
export default MainInfo;
