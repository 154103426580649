import { IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import React from "react";
import { epochToDate } from "../../../../utils/others";
import Address from "../../../Address/Address";

import calendar from "../../../../img/calendar.svg";

import styles from "./hotel_name_address.module.css";
import { iHotelLocation } from "../../../../interfaces/models/hotel";
import { Chivo20 } from "../../../Text/Chivo";

interface iHotelNameAddressProps {
  nameToDisplay: string;
  location: iHotelLocation;
  startAt?: number;
  endAt?: number;
}

export const HotelNameAddress: React.FC<iHotelNameAddressProps> = ({
  nameToDisplay,
  location,
  startAt,
  endAt,
}) => {
  return (
    <IonGrid className={styles.grid}>
      <IonRow>
        <IonCol className={styles.name}>
          <Chivo20 text={nameToDisplay} color="secondary" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={startAt && endAt ? "7" : "12"} className={styles.address}>
          <Address {...{ location }} color="secondary" weight="bold" />
        </IonCol>
        {startAt && endAt && (
          <IonCol className={styles.timespan}>
            <IonText color="secondary">
              <p className="montserrat">{epochToDate(startAt)}</p>
            </IonText>
            <IonIcon src={calendar} />
            <IonText color="secondary">
              <p className="montserrat">{epochToDate(endAt)}</p>
            </IonText>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default HotelNameAddress;
