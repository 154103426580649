import { IonButton, IonIcon } from "@ionic/react";
import React from "react";

import styles from "./edit_button.module.css";

interface iEditButtonProps {
  icon: string;
  onClick?(): void;
}

const EditButton: React.FC<iEditButtonProps> = ({ icon, onClick }) => {
  return (
    <IonButton fill="clear" className={styles.edit_button} onClick={onClick}>
      <IonIcon src={icon} />
    </IonButton>
  );
};

export default EditButton;
