import React from "react";
import { NotificationsStateContext } from "../";

const useNotificationsState = () => {
  const state = React.useContext(NotificationsStateContext);

  if (!state)
    throw Error(
      "useNotificationsState must be used within NotificationsStateContext"
    );

  const { data } = state;

  return { data };
};

export default useNotificationsState;
