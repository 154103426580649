import React, { Fragment, useState } from "react";
import IonDesktopContentColor from "../../../../components/IonDesktopContentColor";
import TwoSegmentedFiltersWithMiddleButton from "../../../../components/SegmentedFilterWithMiddleButton";
import ViewSelectionButtons from "../../../../components/ViewSelectionButtons";
import { ManagerCompetitionsContextProvider } from "../../../../context/Manager/CompetitionsContext";
import { modal_id_new_competition_desktop } from "../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../context/OpenModalContext/OpenModalContext";
import { useGamesViewFilter } from "../hooks";
import Competitions from "./Competitions";
import NewCompetitionModal from "./NewCompetitionModal";

interface iGamesDesktopProps {}

const GamesDesktopWrapped: React.FC<iGamesDesktopProps> = () => {
  const [displayHeader, setDisplayHeader] = useState<boolean>(true);
  const { openModal } = useOpenModal();
  const {
    FILTERS,
    viewSelectionButtons,
    view,
    filter,
    setFilter,
    handleClickAdd,
  } = useGamesViewFilter();

  const handleAdd = () => {
    const handleAddCompetition = () => {
      openModal(modal_id_new_competition_desktop);
    };
    const handleAddChallenge = () => {};

    handleClickAdd(handleAddCompetition, handleAddChallenge);
  };

  const handleClickCompetitionCard = (show_competition_info: boolean) =>
    setDisplayHeader(!show_competition_info);

  return (
    <IonDesktopContentColor>
      <NewCompetitionModal />
      {displayHeader && (
        <Fragment>
          <ViewSelectionButtons
            {...{
              buttons: viewSelectionButtons,
              active: view,
              buttonColor: "secondary",
              textColor: "light",
            }}
            style={{ margin: "0 30vw" }}
          />
          <TwoSegmentedFiltersWithMiddleButton
            value={filter}
            onChange={setFilter}
            filters={FILTERS}
            onClickIcon={handleAdd}
            style={{ margin: "0 35vw" }}
          />
        </Fragment>
      )}

      <Competitions
        {...{ filter, onClickCompetitionCard: handleClickCompetitionCard }}
      />
    </IonDesktopContentColor>
  );
};

const GamesDesktop = () => {
  return (
    <ManagerCompetitionsContextProvider>
      <GamesDesktopWrapped />
    </ManagerCompetitionsContextProvider>
  );
};

export default GamesDesktop;
