import { tLanguageCodes } from "../../utils/consts";
import iHotel from "./hotel";
import leisure from "../../img/rewards_icon_leisure.svg";
import adventure from "../../img/rewards_icon_adventure.svg";
import cultural from "../../img/rewards_icon_cultural.svg";
import hospitality from "../../img/rewards_icon_hospitality.svg";
import products from "../../img/rewards_icon_products.svg";
import services from "../../img/rewards_icon_services.svg";
import wellness from "../../img/rewards_icon_wellness.svg";
import foodDrinks from "../../img/rewards_icon_foodDrinks.svg";
import all from "../../img/rewards_icon_all.svg";

export interface iRewardGroup {
  name: string;
  rewards: iReward[];
}

export type tRewardType = "Discount" | "Offer";

export interface iRewardDescription
  extends Partial<Record<tLanguageCodes, string>> {}

export interface iReward {
  _id: string;

  title: string;
  estimated_value: number;
  sector: tRewardSector;
  type: tRewardType;
  discount?: number;
  location: string;
  image: string;
  short_description: iRewardDescription;
  full_description: iRewardDescription;
  hotel: string | iHotel;
  active: boolean;
  purchased: number;
}

export type tRewardSector =
  | "Adventure"
  | "Cultural"
  | "Food&Drinks"
  | "Hospitality"
  | "Leisure"
  | "Products"
  | "Services"
  | "Wellness"
  | "All";

export const all_reward_sectors: tRewardSector[] = [
  "All",
  "Adventure",
  "Food&Drinks",
  "Hospitality",
  "Leisure",
  "Products",
  "Services",
  "Wellness",
  "Cultural",
];

export interface iRewardSectorInfo {
  card_color: string;
  textColor: string;
  icon: string;
}

export interface iRewardSectorsInfo
  extends Record<tRewardSector, iRewardSectorInfo> {}

export const REWARDS_SECTOR_INFO: iRewardSectorsInfo = {
  All: {
    card_color: "var(--ion-color-secondary)",
    textColor: "light",
    icon: all,
  },
  Adventure: { card_color: "#813F0D", textColor: "light", icon: adventure },
  Cultural: { card_color: "#AC3638", textColor: "light", icon: cultural },
  "Food&Drinks": {
    card_color: "#2D9BB4",
    textColor: "light",
    icon: foodDrinks,
  },
  Hospitality: {
    card_color: "#5AA367",
    textColor: "light",
    icon: hospitality,
  },
  Leisure: { card_color: "#F5BE36", textColor: "light", icon: leisure },
  Products: { card_color: "#404144", textColor: "light", icon: products },
  Services: { card_color: "#77A3B1", textColor: "light", icon: services },
  Wellness: { card_color: "#948178", textColor: "light", icon: wellness },
};
