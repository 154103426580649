import { IonContent } from "@ionic/react";
import { forwardRef } from "react";
import { iWrapperComponent } from "../../interfaces";

interface iIonContentColorProps extends iWrapperComponent {
  display?: boolean;
}

const IonContentColor = forwardRef<any, iIonContentColorProps>(
  ({ children, display = true, ...props }, ref) => {
    return (
      <IonContent ref={ref} color="background" {...props}>
        {display && children}
      </IonContent>
    );
  }
);

export default IonContentColor;
