import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React, { useMemo, useReducer } from "react";
import MenuModalHeader from "../../../../../../components/MenuModalHeader";
import ModalFooter2 from "../../../../../../components/ModalFooter2/ModalFooter2";
import ModalWrapper from "../../../../../../components/ModalWrapper";
import { Text10, Text18 } from "../../../../../../components/Text/Montserrat";
import { modal_id_new_competition } from "../../../../../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../../../../../context/OpenModalContext/OpenModalContext";
import { DatePicker } from "../../../../../../components/PickerRow";

import styles from "./styles.module.css";
import { trash } from "ionicons/icons";
import TitledComponent, {
  TitledConfinedInput,
} from "../../../../../../components/TitledComponent";
import SelectionPicker, {
  iSelectionPickerOption,
} from "../../../../../../components/PickerRow/SelectionPicker";
import axios from "axios";
import useLoading from "../../../../../../hooks/loading";
import { getUnixMomentInMs } from "../../../../../../utils/others";
import iCompetition from "../../../../../../interfaces/models/competitions";
import IonContentColor from "../../../../../../components/IonContentColor";
import useManagerAccountState from "../../../../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerCompetitionsDispatch from "../../../../../../context/Manager/CompetitionsContext/hooks/ManagerCompetitionsDispatch";
import useIonAlertFunctions from "../../../../../../hooks/ionAlert";
import { has } from "lodash";
import useManagerRewardsState from "../../../../../../context/Manager/RewardsContext/hooks/managerRewardsState";

interface iState {
  start_at: string;
  end_at: string;
  title: string;
  rewards: string[];
  rooms: string[];
}

interface iAction extends Partial<iState> {
  type: string;
}

const initial_state: iState = {
  title: "",
  start_at: "",
  end_at: "",
  rewards: [],
  rooms: [],
};

const reducer = (state: iState, action: iAction) => {
  const { type } = action;
  switch (type) {
    case "set":
      const { title, start_at, end_at, rewards, rooms } = action;

      return {
        ...state,
        title: title !== undefined ? title : state.title,
        rooms: rooms !== undefined ? rooms : state.rooms,
        start_at: start_at !== undefined ? start_at : state.start_at,
        end_at: end_at !== undefined ? end_at : state.end_at,
        rewards: rewards !== undefined ? rewards : state.rewards,
      };

    case "reset":
      return { ...initial_state };

    default:
      return { ...state };
  }
};

interface iNewCompetitionModalProps {}

const NewCompetitionModal: React.FC<iNewCompetitionModalProps> = () => {
  const [state, dispatch] = useReducer(reducer, initial_state);
  const { getLoading, setLoading } = useLoading();
  const { closeModal } = useOpenModal();
  const { currentHotel } = useManagerAccountState();
  const { addCompetition } = useManagerCompetitionsDispatch();
  const { present } = useIonAlertFunctions();
  const handleCloseModal = () => {
    closeModal(modal_id_new_competition);
  };

  const handleCreateCompetition = () => {
    const state2competition = (): Omit<
      iCompetition,
      "_id" | "winner" | "rooms_info"
    > | null => {
      const { rewards, rooms, title, start_at, end_at } = state;

      if (
        !rewards.length ||
        !rooms.length ||
        !title.length ||
        !start_at.length ||
        !end_at.length
      )
        return null;

      const competition: Omit<iCompetition, "_id" | "winner" | "rooms_info"> = {
        rewards,
        rooms,
        title,
        hotel: currentHotel._id,
        start_at: getUnixMomentInMs(start_at),
        end_at: getUnixMomentInMs(end_at),
      };

      return competition;
    };
    const handleDismissAlert = () => {
      dispatch({ type: "reset" });
      closeModal(modal_id_new_competition);
    };
    const competition = state2competition();

    if (!competition) return;

    axios
      .post("/competitions/", {
        ...competition,
      })
      .then((res) => {
        console.log(`res`, res);
        const {
          data: { competition },
        } = res;
        addCompetition(competition);
        present("Competition created", handleDismissAlert);
      })
      .catch((err) => {
        present(
          has(err, "response.data.message")
            ? err.response.data.message
            : "Something went wrong"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeTitle = (value: string) => {
    dispatch({ type: "set", title: value });
  };

  const handleChangeCheckInDate = (start_at: string): void => {
    dispatch({ type: "set", start_at });
  };
  const handleChangeCheckOutDate = (end_at: string): void => {
    dispatch({ type: "set", end_at });
  };

  const handleChangeRewards = (rewards: string[]): void => {
    dispatch({ type: "set", rewards });
  };

  const handleChangeRoom = (rooms: string[]): void => {
    dispatch({ type: "set", rooms });
  };

  const handleReset = () => {
    dispatch({ type: "reset" });
  };

  const { title, start_at, end_at, rewards, rooms } = state;

  return (
    <ModalWrapper modal_id={modal_id_new_competition}>
      <IonLoading isOpen={getLoading()} />

      <MenuModalHeader
        title="New Competition"
        onExit={handleCloseModal}
        extra_icon={trash}
        extra_icon_color="secondary"
        onClickExtraIcon={handleReset}
      />
      <IonContentColor>
        <IonGrid>
          <CompetitionTitle
            {...{ value: title, onChange: handleChangeTitle }}
          />
          <CompetitionPeriod
            {...{
              start_at,
              end_at,
              onChangeCheckIn: handleChangeCheckInDate,
              onChangeCheckOut: handleChangeCheckOutDate,
            }}
          />
          <RoomsInCompetition onChange={handleChangeRoom} value={rooms} />
          <Rewards {...{ onChange: handleChangeRewards, value: rewards }} />

          <IonRow className={styles.margin}>
            <IonCol>
              <Text10 text="Select a reward from your Reward pool. Only the winner of the competition will receive it. The winner is the room with the most points from sustainable evaluation." />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContentColor>
      <ModalFooter2
        green_text="Confirm"
        onGreen={handleCreateCompetition}
        red_text="Cancel"
        onRed={handleCloseModal}
      />
    </ModalWrapper>
  );
};

interface iCompetitionTitleProps {
  value: string;
  onChange(value: string): void;
}

const CompetitionTitle: React.FC<iCompetitionTitleProps> = ({
  value,
  onChange,
}) => {
  return (
    <TitledConfinedInput
      {...{
        title: "Competition Title",
        value,
        onChange,
        placeholder: "New Competition Title",
      }}
    />
  );
};

interface iCompetitionPeriodProps {
  start_at: string;
  end_at: string;
  onChangeCheckIn(value: string): void;
  onChangeCheckOut(value: string): void;
}

const CompetitionPeriod: React.FC<iCompetitionPeriodProps> = ({
  onChangeCheckIn,
  onChangeCheckOut,
  start_at,
  end_at,
}) => {
  return (
    <TitledComponent title="Competition Period">
      <DatePicker
        title="Check-In Date"
        id="1"
        value={start_at}
        onSelect={onChangeCheckIn}
      />
      <DatePicker
        title="Check-Out Date"
        id="1"
        value={end_at}
        onSelect={onChangeCheckOut}
      />
    </TitledComponent>
  );
};

interface iRoomsInCompetitionProps {
  onChange(value: string[]): void;
  value: string[];
}

const RoomsInCompetition: React.FC<iRoomsInCompetitionProps> = ({
  value,
  onChange,
}) => {
  const { hotelRooms } = useManagerAccountState();

  const handleSelect = (val: string[]) => {
    onChange(val);
  };

  const options: iSelectionPickerOption[] = useMemo(() => {
    return hotelRooms.map((room) => ({
      text: room,
      value: room,
    }));
  }, [hotelRooms]);

  return (
    <TitledComponent title="Rooms in Competition">
      <div className={styles.selection_picker}>
        <SelectionPicker
          {...{
            options,
            onChange: handleSelect,
            value,
            title: "Rooms",
            multiple: true,
          }}
        />
      </div>
    </TitledComponent>
  );
};

interface iRewardsProps {
  onChange(value: string[]): void;
  value: string[];
}

const Rewards: React.FC<iRewardsProps> = ({ onChange, value }) => {
  const { activeRewards } = useManagerRewardsState();

  const options = useMemo(() => {
    return [...activeRewards].map((r) => ({
      text: r.title,
      value: r._id,
    }));
  }, [activeRewards]);

  return (
    <TitledComponent title="Rewards">
      {options.length ? (
        <div className={styles.selection_picker}>
          <SelectionPicker
            {...{
              options,
              value,
              onChange,
              title: "Winner Rewards",
              multiple: true,
            }}
          />
        </div>
      ) : (
        <IonRow className={styles.margin}>
          <IonCol>
            <Text18 color="danger" text="No rewards available. Create one" />
          </IonCol>
        </IonRow>
      )}
    </TitledComponent>
  );
};

export default NewCompetitionModal;
