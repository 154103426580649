import moment from "moment";
import { useHotelStays } from "../../../context/HotelStaysContext";
import useManagerAccountState from "../../../context/Manager/AccountContext/hooks/useManagerAccountState";
import useManagerHotelMeasures from "../../../context/Manager/AccountContext/hooks/useManagerHotelMeasures";

export const useHotelStaysFunctions = () => {
  const { state, TIMESPAN_LIST } = useHotelStays();
  const { getOOMeasures, getOOSpending } = useManagerHotelMeasures();
  const { hotelRooms } = useManagerAccountState();

  const getYearMonthRoomOOKey = () => {
    const timespan = TIMESPAN_LIST[state.timespan];

    const ooKey: "overall" | "occupied" | "vacant" =
      state.occupied_filter === 0
        ? "overall"
        : state.occupied_filter === 1
        ? "occupied"
        : "vacant";

    let year = null;
    let month = null;
    let room = null;

    if (state.room !== 0) {
      room = hotelRooms[state.room - 1];
    }

    if (timespan.filter === "Monthly") {
      // console.log("timespan", timespan);
      // console.log("timespan.start_end", timespan.start_end);
      // console.log(
      //   "timespan.start_end[state.timespan_index]",
      //   timespan.start_end[state.timespan_index]
      // );
      const m = moment(timespan.start_end[state.timespan_index][0]);
      month = m.get("month");
      year = m.get("year");
    } else if (timespan.filter === "Yearly") {
      const m = moment(timespan.start_end[state.timespan_index][0]);
      year = m.get("year");
    }
    return { year, month, room, ooKey };
  };

  const getCurrentMeasures = () => {
    const measures = getOOMeasures({ ...getYearMonthRoomOOKey() });
    return measures;
  };

  const getCurrentSpending = () => {
    const spending = getOOSpending(getYearMonthRoomOOKey());
    return spending;
  };

  return { getCurrentMeasures, getCurrentSpending };
};
