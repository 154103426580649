import { useCallback, useReducer } from "react";

type tType = Record<string, any>;

interface iState {
  loading: boolean;
  message: string | null;
  error: boolean;
  [key: string]: any;
}

interface iAction extends Partial<iState> {
  type: string;
  names?: string[];
  values?: any[];
}

const initial_state: iState = {
  loading: false,
  message: null,
  error: false,
};

const reducer = (state: iState, action: iAction) => {
  const { type, loading, message, error, names, values, ...rest } = action;
  switch (type) {
    case "update state":
      return { ...state, ...rest };

    case "set":
      const obj: Record<string, any> = {};

      (names || []).forEach((k, i) => {
        obj[k] = values![i];
      });

      return { ...state, ...obj };

    case "set loading":
      return { ...state, loading: loading!, error: false };
    case "set message":
      return { ...state, error: false, loading: false, message: message! };

    case "set error message":
      return { ...state, error: true, loading: false, message: message! };

    case "set error":
      return { ...state, error: error! };

    case "reset":
      return { ...state, ...initial_state };

    case "set state":
      return { ...state, loading: loading!, error: error!, message: message! };

    default:
      return { ...state };
  }
};

const useLoading = (obj: tType = {}) => {
  const [state, dispatch] = useReducer(reducer, { ...initial_state, ...obj });

  const getState = (): iState => state;

  const getLoading = (): boolean => state.loading;
  const getMessage = (): string => state.message || "";
  const getError = (): boolean => state.error;

  const setLoading = useCallback(
    (loading: boolean) => dispatch({ type: "set loading", loading }),
    []
  );

  const setMessage = useCallback(
    (message: string | null) => dispatch({ type: "set message", message }),
    []
  );

  const hasMessage = (): boolean =>
    state.message !== null && state.message !== "";

  const setError = useCallback(
    (error: boolean) => dispatch({ type: "set error", error }),
    []
  );

  const setErrorMessage = useCallback(
    (message: string | null) =>
      dispatch({ type: "set error message", message }),
    []
  );

  const setState = (loading: boolean, error: boolean, message: string | null) =>
    dispatch({ type: "set state", loading, error, message });

  const reset = () => dispatch({ type: "reset" });

  const setValues = (
    names: string[],
    values: any[],
    obj: Partial<iState> = { error: false, message: null, loading: false }
  ) => {
    if (names.length !== values.length) return;

    const { error, message, loading } = obj;
    dispatch({
      type: "set",
      names: [...names, "error", "message", "loading"],
      values: [...values, error, message, loading],
    });
  };

  const setValue = (name: string, value: any, obj?: Partial<iState>) =>
    setValues([name], [value], obj);

  const getValues = (names: string[]): tType => {
    const ret: tType = {};

    names.forEach((name) => ret[state[name]]);

    return ret;
  };

  const getValue = <T>(name: string): T => state[name];

  return {
    getLoading,
    isLoading: state.loading,
    getMessage,
    setMessage,
    setLoading,
    getError,
    setError,
    setErrorMessage,
    hasMessage,
    reset,
    getState,
    setState,
    getValue,
    getValues,
    setValues,
    setValue,
  };
};

export default useLoading;
