import { IonModal } from "@ionic/react";
import React from "react";
import { modal_id_stay_report } from "../../context/OpenModalContext/modal_ids";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import { useDataModal } from "../../context/DataModalContext/DataModalContext";
import Content from "./Content/Content";
import Header from "./Header/Header";
import ModalFooter2 from "../ModalFooter2/ModalFooter2";

const StayReportModal: React.FC = () => {
  const { isOpen } = useOpenModal();
  const {
    state: { reservation, available_types },
    openGraphModal,
    closeReportModal,
  } = useDataModal();

  return (
    <IonModal isOpen={isOpen(modal_id_stay_report)}>
      {reservation !== null && (
        <>
          <Header />
          <Content />
        </>
      )}
      <ModalFooter2
        green_text="See details"
        red_text="Close report"
        onGreen={openGraphModal}
        disable_green={available_types.length <= 0}
        onRed={closeReportModal}
      />
    </IonModal>
  );
};

export default StayReportModal;
