import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { Fragment } from "react";
import { iWrapperComponent } from "../../interfaces";
import { iReward } from "../../interfaces/models/reward";
import { estimatedValue2points } from "../../utils/user";
import RewardSectorCard from "../RewardSectorCard";
import { Chivo12, Chivo18, Chivo20 } from "../Text/Chivo";
import { Text14 } from "../Text/Montserrat";

import styles from "./styles.module.css";

interface iRewardFullInfoProps extends iWrapperComponent {
  reward: iReward;
}

const RewardFullInfo: React.FC<iRewardFullInfoProps> = ({
  children,
  reward,
}) => {
  const {
    title,
    full_description,
    image,
    sector,
    type,
    estimated_value,
    discount,
  } = reward;

  return (
    <IonGrid className={styles.grid}>
      {children && children}
      <IonRow>
        <IonCol>
          <Chivo18 text={title} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <Text14 text={full_description["en"] || ""} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-center">
          {image === "/image" ? (
            <RewardSectorCard
              {...{
                sector,
                card_size: 80,
                icon_size: 30,
                text_size: 7,
              }}
            />
          ) : (
            <IonImg src={image} />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="flexr-start">
          {type === "Discount" ? (
            <Fragment>
              <Text14 text="Discount" color="secondary" />
              <Chivo20
                text={`  ${discount}%`}
                withTrailingWhiteSpace
                color="secondary"
              />
            </Fragment>
          ) : (
            <Chivo20 text="Offer" color="secondary" />
          )}
        </IonCol>
        <IonCol className="flexr-end">
          <Chivo20
            text={estimatedValue2points(estimated_value)}
            color="secondary"
          />
          <Chivo12 text="  pts" withTrailingWhiteSpace />
        </IonCol>
      </IonRow>

      {/* {children && (
        <IonRow>
          <IonCol className="flexr-center">{children}</IonCol>
        </IonRow>
      )} */}
    </IonGrid>
  );
};

export default RewardFullInfo;
