import { IonButton, IonCol, IonRow } from "@ionic/react";
import React from "react";
import { Text16 } from "../Text/Montserrat";

import styles from "./styles.module.css";

interface iModalContentFooter2ButtonsProps {
  disabled_confirm?: boolean;
  disabled_cancel?: boolean;
  disabled_buttons?: boolean;
  confirm_text?: string;
  cancel_text?: string;
  onConfirm(): void;
  onCancel(): void;
}

const ModalContentFooter2Buttons: React.FC<
  iModalContentFooter2ButtonsProps
> = ({
  onConfirm,
  onCancel,
  confirm_text = "Confirm",
  cancel_text = "Cancel",
  disabled_cancel = false,
  disabled_confirm = false,
  disabled_buttons = false,
}) => {
  return (
    <IonRow className={styles.buttons}>
      <IonCol>
        <IonButton
          disabled={disabled_buttons || disabled_confirm}
          onClick={onConfirm}
        >
          <Text16 text={confirm_text} color="light" />
        </IonButton>
        <IonButton
          color="danger"
          disabled={disabled_buttons || disabled_cancel}
          onClick={onCancel}
        >
          <Text16 text={cancel_text} color="light" />
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default ModalContentFooter2Buttons;
