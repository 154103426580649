import { IonButton, IonFooter, IonText, IonToolbar } from "@ionic/react";
import React from "react";
import { useOpenModal } from "../../context/OpenModalContext/OpenModalContext";
import { Chivo18 } from "../Text/Chivo";

import styles from "./modal_footer.module.css";

interface iModalFooter {
  closeModal?(): void;
  modal_id?: number;
  width?: number;
  buttonColor?: string;
  children?: React.ReactChild;
  top_shadow?: boolean;
}

const ModalFooter: React.FC<iModalFooter> = ({
  modal_id,
  closeModal,
  width = 80,
  buttonColor = "primary",
  top_shadow = false,
  children,
}) => {
  const modal = useOpenModal();

  return (
    <IonFooter
      mode="ios"
      className={
        styles.container + ` flexc-center ${top_shadow ? "shadow_t" : ""}`
      }
    >
      {children && children}
      <IonButton
        mode="ios"
        color={buttonColor}
        onClick={() =>
          closeModal ? closeModal() : modal.closeModal(modal_id!)
        }
        style={{ width: `${width}%` }}
      >
        <Chivo18 color="light" text="Close" />
      </IonButton>
    </IonFooter>
  );
};

export default ModalFooter;
