import {
  IonCol,
  IonGrid,
  IonHeader,
  IonRow,
  IonText,
  IonToolbar,
  IonIcon,
  IonButton,
} from "@ionic/react";
import React from "react";
import { useDataModal } from "../../../context/DataModalContext/DataModalContext";
import iReservation from "../../../interfaces/models/reservation";
import { epochTimelineToString } from "../../../utils/others";

import calendar from "../../../img/calendar.svg";
import { pieChart, barChart } from "ionicons/icons";

import styles from "./header.module.css";

interface iHeaderProps {}

const Header: React.FC<iHeaderProps> = () => {
  const {
    state: { reservation, graph_type },
    switchGraphType,
  } = useDataModal();
  const {
    hotel: { nameToDisplay },
    endAt,
    startAt,
  } = reservation as iReservation;

  const handleClickGraphType = () => {
    switchGraphType();
  };

  const handleClickCalendar = () => {};

  return (
    <IonHeader className={styles.container + " shadow_b"}>
      <IonToolbar color="header">
        <IonGrid>
          <IonRow className={styles.row1}>
            <IonCol className={styles.hotel_name}>
              <IonText color="primary">
                <h1 className="chivo">
                  <strong>{nameToDisplay}</strong>
                </h1>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className={styles.row2}>
            <IonCol className={styles.calendar}>
              <Button onClick={handleClickCalendar} icon={calendar} />
            </IonCol>
            <IonCol className={styles.timeline} size="8">
              <IonText color="secondary">
                <p className="montserrat">
                  <strong>{epochTimelineToString(startAt, endAt)}</strong>
                </p>
              </IonText>
            </IonCol>
            <IonCol className={styles.graph_type}>
              {/* <Button
                onClick={handleClickGraphType}
                icon={graph_type !== "donut" ? pieChart : barChart}
              /> */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};

interface iButtonProps {
  icon: string;
  onClick(): void;
}

const Button: React.FC<iButtonProps> = ({ onClick, icon }) => {
  return (
    <IonButton onClick={onClick} fill="clear">
      <IonIcon color="secondary" src={icon} />
    </IonButton>
  );
};

export default Header;
